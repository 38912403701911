// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Auth = require("../Auth/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Locale = require("../Locale/index.js");
var Milkis = require("../Milkis/index.js");
var Milkis_Impl_Window = require("../Milkis.Impl.Window/index.js");
var Text_Parsing_StringParser = require("../Text.Parsing.StringParser/index.js");
var Text_Parsing_StringParser_CodeUnits = require("../Text.Parsing.StringParser.CodeUnits/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Utils = require("../Utils/index.js");
var versionNumberGreaterThan = function (x) {
    return function (y) {
        var minorMatch = x.minor === y.minor;
        var majorMatch = x.major === y.major;
        return x.major > y.major || (majorMatch && x.minor > y.minor || majorMatch && (minorMatch && x.patch > y.patch));
    };
};
var versionGreaterThan = function (x) {
    return function (y) {
        if (x.versionNumber instanceof Data_Maybe.Just && y.versionNumber instanceof Data_Maybe.Just) {
            return versionNumberGreaterThan(x.versionNumber.value0)(y.versionNumber.value0);
        };
        return x.string > y.string;
    };
};
var removeRequestInProgress = function (dictMonadEffect) {
    var $51 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($52) {
        return $51($foreign.removeRequestInProgress_($52));
    };
};
var numberSegment = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_CodeUnits.regex("\\d+"))(function (string) {
    var v = Data_Int.fromString(string);
    if (v instanceof Data_Maybe.Just) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(v.value0);
    };
    if (v instanceof Data_Maybe.Nothing) {
        return Text_Parsing_StringParser.fail("failed to parse int from string: " + string);
    };
    throw new Error("Failed pattern match at RequestUtils (line 221, column 3 - line 223, column 71): " + [ v.constructor.name ]);
});
var versionNumber = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(numberSegment)(function (major) {
    return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_CodeUnits.string("."))(function () {
        return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(numberSegment)(function (minor) {
            return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_CodeUnits.string("."))(function () {
                return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(numberSegment)(function (patch) {
                    return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)({
                        major: major,
                        minor: minor,
                        patch: patch
                    });
                });
            });
        });
    });
});
var isMemberRequestInProgress = function (dictMonadEffect) {
    var $53 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($54) {
        return $53($foreign.isMemberRequestInProgress_($54));
    };
};
var handleFetchResponse = function (readJSON) {
    return function (v) {
        return function (response) {
            var statusCode = Milkis.statusCode(response);
            var $23 = statusCode === 200;
            if ($23) {
                return Control_Bind.bindFlipped(Effect_Aff.bindAff)((function () {
                    var $55 = Utils["mapForeignErrors'"](Effect_Aff.monadThrowAff);
                    return function ($56) {
                        return $55(readJSON($56));
                    };
                })())(Milkis.json(response));
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Milkis.text(response))(function (e) {
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error(e));
            });
        };
    };
};
var getRouteUrl = function (v) {
    return function (params) {
        return v.value0.formatRouteURL(params);
    };
};
var getAuthorization = Control_Bind.bind(Effect_Aff.bindAff)(Control_Promise.toAffE(Auth.getAccessToken))(function (token) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)(token);
});
var mkHeaders = Control_Bind.bind(Effect_Aff.bindAff)(getAuthorization)(function (authorization) {
    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Milkis.makeHeaders()({
        Authorization: authorization,
        "Content-Type": "application/json"
    }));
});
var fetch = function (dictUnion) {
    return function (v) {
        return function (options) {
            return Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(Milkis.fetch(Milkis_Impl_Window.windowFetch)()(v)(options))(function (e) {
                var message = Effect_Exception.message(e);
                var error = Effect_Exception.error("Error in fetch for " + (v + (":\x0a  " + message)));
                return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(error);
            });
        };
    };
};
var getRoute = function (v) {
    return function (v1) {
        return function (url) {
            return Control_Bind.bind(Effect_Aff.bindAff)(mkHeaders)(function (headers) {
                var sendTime = Effect_Unsafe.unsafePerformEffect(Data_JSDate.now);
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(fetch()(url)({
                    method: Milkis.getMethod,
                    headers: headers
                })))(function (response) {
                    if (response instanceof Data_Either.Right) {
                        return handleFetchResponse(v.value0.readResponse)(sendTime)(response.value0);
                    };
                    if (response instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger["logError'"](Effect_Aff.monadEffectAff)()({
                            error: response.value0,
                            values: {
                                fetchUrl: url
                            }
                        }))(function () {
                            return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(response.value0);
                        });
                    };
                    throw new Error("Failed pattern match at RequestUtils (line 98, column 3 - line 105, column 27): " + [ response.constructor.name ]);
                });
            });
        };
    };
};
var bodyRoute = function (v) {
    return function (params) {
        return function (req) {
            var url = v.value0.formatRouteURL(params);
            var sendTime = Effect_Unsafe.unsafePerformEffect(Data_JSDate.now);
            var body = v.value0.writeRequestJSON(req);
            return Control_Bind.bind(Effect_Aff.bindAff)(mkHeaders)(function (headers) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(fetch()(url)({
                    method: v.value0.method,
                    headers: headers,
                    body: body
                })))(function (response) {
                    if (response instanceof Data_Either.Right) {
                        return handleFetchResponse(v.value0.readResponse)(sendTime)(response.value0);
                    };
                    if (response instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger["logError'"](Effect_Aff.monadEffectAff)()({
                            error: response.value0,
                            values: {
                                fetchUrl: url,
                                body: body
                            }
                        }))(function () {
                            return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(response.value0);
                        });
                    };
                    throw new Error("Failed pattern match at RequestUtils (line 120, column 3 - line 127, column 27): " + [ response.constructor.name ]);
                });
            });
        };
    };
};
var alertVersions = function (dispatch) {
    return function (v) {
        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(DOMUtils.getLocalStorageItem("ui:json:alert-version")))(function (mJSON) {
            var v1 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(TofuJSON.readJSON_(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "backend";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "frontend";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "backend";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "frontend";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "string";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "major";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "minor";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "patch";
                }
            })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()())))(mJSON);
            var frontendMismatch = versionGreaterThan(v.frontend)(v.backend) && Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "versionNumber";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "patch";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "minor";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "major";
                }
            })(Data_Eq.eqInt)))))()({
                reflectSymbol: function () {
                    return "string";
                }
            })(Data_Eq.eqString))))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                return v2.frontend;
            })(v1))(new Data_Maybe.Just(v.frontend));
            var backendMismatch = versionGreaterThan(v.backend)(v.frontend);
            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Applicative.when(Effect_Aff.applicativeAff)(frontendMismatch)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger.log(Effect_Aff.monadEffectAff)("Version mismatch"))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatch(Actions.AddAlert.create(Types_Alert.AlertInfo.value)(Locale.lookup_("error_app_versions_mismatch_old_backend"))))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.log(Effect_Aff.monadEffectAff)("server: " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "string";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "versionNumber";
                        }
                    })(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "major";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "minor";
                        }
                    })(Data_Show.showRecordFieldsCons({
                        reflectSymbol: function () {
                            return "patch";
                        }
                    })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))))(Data_Show.showString)))(v.backend)))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("client: " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))))(Data_Show.showString)))(v.frontend));
                    });
                });
            })))(function () {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Applicative.when(Effect_Aff.applicativeAff)(backendMismatch)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger.log(Effect_Aff.monadEffectAff)("Version mismatch"))(function () {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatch(Actions.AddAlert.create(Types_Alert.AlertInfo.value)(Locale.lookup_("error_app_versions_mismatch_old_frontend"))))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.log(Effect_Aff.monadEffectAff)("server: " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(Data_Show.showRecordFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))))(Data_Show.showString)))(v.backend)))(function () {
                            return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("client: " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                                reflectSymbol: function () {
                                    return "string";
                                }
                            })(Data_Show.showRecordFieldsCons({
                                reflectSymbol: function () {
                                    return "versionNumber";
                                }
                            })(Data_Show.showRecordFieldsNil)(Data_Maybe.showMaybe(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                                reflectSymbol: function () {
                                    return "major";
                                }
                            })(Data_Show.showRecordFieldsCons({
                                reflectSymbol: function () {
                                    return "minor";
                                }
                            })(Data_Show.showRecordFieldsCons({
                                reflectSymbol: function () {
                                    return "patch";
                                }
                            })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Data_Show.showInt)))))(Data_Show.showString)))(v.frontend));
                        });
                    });
                })))(function () {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(DOMUtils.setLocalStorageItem({
                        key: "ui:json:alert-version",
                        value: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "backend";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "frontend";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "backend";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "frontend";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "string";
                            }
                        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "versionNumber";
                            }
                        })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "major";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "minor";
                            }
                        })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "patch";
                            }
                        })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())()()()))(v)
                    }));
                });
            });
        });
    };
};
var checkVersions = function (v) {
    return function (dispatch) {
        return Effect_Aff.launchAff_((function () {
            var parsedClientVersion = Text_Parsing_StringParser.runParser(versionNumber)($foreign.clientVersion);
            var parsedBackendVersion = Text_Parsing_StringParser.runParser(versionNumber)(v);
            var versions = {
                backend: {
                    string: v,
                    versionNumber: Data_Either.hush(parsedBackendVersion)
                },
                frontend: {
                    string: $foreign.clientVersion,
                    versionNumber: Data_Either.hush(parsedClientVersion)
                }
            };
            return alertVersions(dispatch)(versions);
        })());
    };
};
var addRequestInProgress = function (dictMonadEffect) {
    var $57 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($58) {
        return $57($foreign.addRequestInProgress_($58));
    };
};
var withGetRoute = function (route) {
    return function (params) {
        return function (handler) {
            var url = getRouteUrl(route)(params);
            return Control_Bind.bind(Effect_Aff.bindAff)(isMemberRequestInProgress(Effect_Aff.monadEffectAff)(url))(function (isRequestInProgress) {
                if (isRequestInProgress) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                };
                return Effect_Aff.bracket(addRequestInProgress(Effect_Aff.monadEffectAff)(url))(function (v) {
                    return removeRequestInProgress(Effect_Aff.monadEffectAff)(url);
                })(function (v) {
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(getRoute(route)(params)(url)))(function (res) {
                        return handler(res);
                    });
                });
            });
        };
    };
};
module.exports = {
    addRequestInProgress: addRequestInProgress,
    removeRequestInProgress: removeRequestInProgress,
    isMemberRequestInProgress: isMemberRequestInProgress,
    getAuthorization: getAuthorization,
    mkHeaders: mkHeaders,
    fetch: fetch,
    withGetRoute: withGetRoute,
    getRouteUrl: getRouteUrl,
    getRoute: getRoute,
    bodyRoute: bodyRoute,
    handleFetchResponse: handleFetchResponse,
    alertVersions: alertVersions,
    checkVersions: checkVersions,
    versionGreaterThan: versionGreaterThan,
    versionNumberGreaterThan: versionNumberGreaterThan,
    numberSegment: numberSegment,
    versionNumber: versionNumber,
    clientVersion: $foreign.clientVersion,
    requestsInProgress: $foreign.requestsInProgress,
    addRequestInProgress_: $foreign.addRequestInProgress_,
    removeRequestInProgress_: $foreign.removeRequestInProgress_,
    isMemberRequestInProgress_: $foreign.isMemberRequestInProgress_
};
