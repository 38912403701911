// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var header_ = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (nodeRef) {
                return TofuHooks.mkHookComponent("TableHeader" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (props) {
                    var mkHeaderCell = function (column) {
                        return TacoTable_Cells.headerCell(dictGetName)(dictEq)({
                            column: column,
                            sortProperty: props.sortProperty,
                            sortingOrder: props.sortingOrder,
                            styleSet: props.styleSet.headerCellStyles,
                            onClick: props.onSort
                        });
                    };
                    var foldingIndicator = Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                        if (props.foldingElement instanceof Data_Maybe.Just) {
                            return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                className: "folding-indicator",
                                css: TacoTable_Styles.foldingIndicatorStyles
                            });
                        };
                        return React_Basic.empty;
                    })());
                    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "table-header",
                        css: props.styleSet.headerStyles,
                        children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(foldingIndicator)(Data_Functor.map(Data_Functor.functorArray)(mkHeaderCell)(props.columns)),
                        ref: nodeRef
                    }));
                });
            };
        };
    };
};
var header = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return TofuHooks.mkHookComponent("TableHeader_" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (props) {
                return function __do() {
                    var ref = UseNullableRef.useNullableRef();
                    return header_(dictGetName)(dictEq)(dictEq1)(ref)(props);
                };
            });
        };
    };
};
var simpleHeader = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (columns) {
                return function (styleSet) {
                    return header(dictGetName)(dictEq)(dictEq1)({
                        sortProperty: Data_Maybe.Nothing.value,
                        sortingOrder: TacoTable_Types.Ascending.value,
                        columns: columns,
                        onSort: function (v) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        },
                        foldingElement: Data_Maybe.Nothing.value,
                        styleSet: styleSet
                    });
                };
            };
        };
    };
};
module.exports = {
    simpleHeader: simpleHeader,
    header: header,
    header_: header_
};
