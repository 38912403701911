// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Components_SubNavigation = require("../Components.SubNavigation/index.js");
var Containers_DixonContainer = require("../Containers.DixonContainer/index.js");
var CostEstimation_ElementsEditView = require("../CostEstimation.ElementsEditView/index.js");
var CostEstimation_ElementsReferenceView = require("../CostEstimation.ElementsReferenceView/index.js");
var CostEstimation_ResourcesView = require("../CostEstimation.ResourcesView/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var EditView = require("../EditView/index.js");
var EstimationEditProject = require("../EstimationEditProject/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Header = require("../Header/index.js");
var OfferPageView = require("../OfferPageView/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ReferenceView = require("../ReferenceView/index.js");
var ResourcePriceListView = require("../ResourcePriceListView/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var component = TofuHooks.mkHookComponent("CostEstimationContainer")(function (props) {
    return function __do() {
        var updateStatuses = StateHooks.useSelector(function (v) {
            return v.updateStatuses;
        })();
        var v = TofuHooks.useState(false)();
        var appDispatch = StateHooks.useDispatch();
        var printingLicenseEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Printing.value)();
        var elementsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Elements.value)();
        var newEditModalEnabled = FeatureHooks.useFeatureEnabled("project-new-edit-dialog")();
        var toggleModal = v.value1(function (old) {
            return !old;
        });
        var projectDetails = (function () {
            if (newEditModalEnabled) {
                return new Components_SubNavigation.Button({
                    onClickFn: toggleModal,
                    title: "Hankkeen tiedot"
                });
            };
            return new Components_SubNavigation.Link({
                title: "Hankkeen tiedot",
                color: TacoConstants.PrimaryBlue.value,
                active: Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.EditEstimationProjectMode.value),
                href: Types_FrontEndRoutes["fragments"]["estimation-project-details"],
                route: new Types_FrontEndRoutes.EstimationProjectDetailsFERoute({
                    projectId: props.projectId
                }),
                id: "navigation-project-details"
            });
        })();
        var printingLink = Data_Monoid.guard(Data_Monoid.monoidArray)(printingLicenseEnabled)([ new Components_SubNavigation.Link({
            title: "Tuloste",
            color: TacoConstants.componentColors.border,
            active: Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.PrintingMode.value),
            href: Types_FrontEndRoutes.fragments.printing,
            route: new Types_FrontEndRoutes.PrintingFERoute({
                projectId: props.projectId
            }),
            id: "printing"
        }) ]);
        var printingContainer = React_Basic.element($foreign.printing)({
            projectId: props.projectId
        });
        var onSaveModal = function __do() {
            appDispatch(new Actions.GetProjectRequest({
                projectId: props.projectId
            }))();
            return toggleModal();
        };
        var offerPageLink = [ new Components_SubNavigation.Link({
            title: "Tarjouslaskenta",
            color: TacoConstants.resourceColors.border,
            active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.OfferPageMode.value),
            href: Types_FrontEndRoutes.fragments.offer,
            route: new Types_FrontEndRoutes.OfferPageRoute({
                projectId: props.projectId
            }),
            id: "navigation-offer"
        }) ];
        var newProjectDetailsModal = React_Basic.element($foreign.projectDetailsModal)({
            projectId: props.projectId,
            isOpen: true,
            closeModal: toggleModal,
            newProject: false,
            onSave: onSaveModal
        });
        var fetchValue = UseFetch.useFetchValueByProjectId(props.projectId);
        var elementsLink = Data_Monoid.guard(Data_Monoid.monoidArray)(elementsEnabled)([ new Components_SubNavigation.Link({
            title: "Rakenteet",
            color: TacoConstants.elementColors.border,
            active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.BuildingElementsMode.value),
            href: Types_FrontEndRoutes["fragments"]["estimation-building-elements"],
            route: new Types_FrontEndRoutes.EstimationBuildingElementsRoute({
                projectId: props.projectId,
                initialSelectedElement: Data_Maybe.Nothing.value
            }),
            id: "navigation-elements"
        }) ]);
        var elementLibraryLink = Data_Monoid.guard(Data_Monoid.monoidArray)(elementsEnabled)([ new Components_SubNavigation.Link({
            title: "Rakennekirjasto",
            color: TacoConstants.elementColors.border,
            active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.ReferenceMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.BuildingElementsMode.value),
            href: Types_FrontEndRoutes["fragments"]["estimation-reference-elements"],
            route: new Types_FrontEndRoutes.ReferenceEstimationElementsRoute({
                projectId: props.projectId
            }),
            id: "navigation-ref-elements"
        }) ]);
        var subNavigationItems = TofuHooks.useMemo([ ReactHooksUtils.utf(props.viewMode), ReactHooksUtils.utf(props.estimationMode), ReactHooksUtils.utf(props.projectId), ReactHooksUtils.utf(elementsEnabled), ReactHooksUtils.utf(printingLicenseEnabled) ])(function (v1) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(elementsLink)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Components_SubNavigation.Link({
                title: "Suoritteet",
                color: TacoConstants.componentColors.border,
                active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.ComponentsMode.value),
                href: Types_FrontEndRoutes.fragments.estimation,
                route: new Types_FrontEndRoutes.EstimationFERoute({
                    projectId: props.projectId,
                    initialSelectedComponent: Data_Maybe.Nothing.value
                }),
                id: "navigation-components"
            }), new Components_SubNavigation.Link({
                title: "Panokset",
                color: TacoConstants.resourceColors.border,
                active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.ResourcesMode.value),
                href: Types_FrontEndRoutes["fragments"]["estimation-resources"],
                route: new Types_FrontEndRoutes.EstimationResourcesRoute({
                    projectId: props.projectId
                }),
                id: "navigation-resources"
            }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(offerPageLink)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Components_SubNavigation.Link({
                title: "M\xe4\xe4r\xe4laskenta",
                color: TacoConstants.componentColors.border,
                active: Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.DixonMode.value),
                href: Types_FrontEndRoutes.fragments.dixon,
                route: new Types_FrontEndRoutes.DixonFERoute({
                    projectId: props.projectId,
                    mFileId: Data_Maybe.Nothing.value,
                    mDrawingId: Data_Maybe.Nothing.value,
                    mMeasurementGroupId: Data_Maybe.Nothing.value
                }),
                id: "navigation-dixon"
            }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(printingLink)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Components_SubNavigation.Spacing.value ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(elementLibraryLink)([ new Components_SubNavigation.Link({
                title: "Suoritekirjasto",
                color: TacoConstants.referenceComponentColors.border,
                active: Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.ReferenceMode.value) && Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.ComponentsMode.value),
                href: Types_FrontEndRoutes["fragments"]["estimation-reference"],
                route: new Types_FrontEndRoutes.EstimationReferenceRoute({
                    projectId: props.projectId
                }),
                id: "navigation-ref-components"
            }), new Components_SubNavigation.Link({
                title: "Panoskirjasto",
                color: TacoConstants.resourcePriceListColors.border,
                active: Data_Eq.eq(CostEstimation_Types.eqECEstimationMode)(props.estimationMode)(CostEstimation_Types.ResourcePriceListMode.value),
                href: Types_FrontEndRoutes["fragments"]["estimation-resource-price-list"],
                route: new Types_FrontEndRoutes.EstimationResourcePriceListRoute({
                    projectId: props.projectId
                }),
                id: "navigation-ref-resources"
            }), Components_SubNavigation.Separator.value, projectDetails ])))))));
        })();
        var header = Header.header({
            projectId: new Data_Maybe.Just(props.projectId),
            applicationMode: Types_Project.EstimationMode.value,
            currentRoute: props.route,
            subNavigation: Data_Maybe.Just.create(Components_SubNavigation.component({
                items: subNavigationItems
            }))
        });
        var v1 = fetchValue(function (v1) {
            return v1.selectedProject;
        })(Actions.GetProjectRequest.create)();
        return React_Basic.fragment((function () {
            if (v1.value0 instanceof Data_Maybe.Nothing) {
                return [ TacoLoader.component()()({}) ];
            };
            if (v1.value0 instanceof Data_Maybe.Just) {
                return [ header, Data_Monoid.guard(React_Basic.monoidJSX)(v.value0)(newProjectDetailsModal), (function () {
                    if (props.estimationMode instanceof CostEstimation_Types.ComponentsMode) {
                        if (props.viewMode instanceof Types_CostEstimation.EditMode) {
                            return EditView.component({
                                projectId: props.projectId,
                                initialSelectedComponent: props.initialSelectedComponent,
                                project: v1.value0.value0.project,
                                updateStatuses: updateStatuses,
                                dixonOptions: Data_Maybe.Nothing.value,
                                setCheckedComponents: Data_Maybe.Nothing.value,
                                checkedComponents: Data_Maybe.Nothing.value
                            });
                        };
                        if (props.viewMode instanceof Types_CostEstimation.ReferenceMode) {
                            return ReferenceView.component({
                                projectId: props.projectId,
                                initialSelectedComponent: props.initialSelectedComponent,
                                project: v1.value0.value0.project,
                                updateStatuses: updateStatuses,
                                panelOptions: Data_Maybe.Nothing.value
                            });
                        };
                        throw new Error("Failed pattern match at CostEstimationContainer (line 217, column 13 - line 233, column 18): " + [ props.viewMode.constructor.name ]);
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.BuildingElementsMode) {
                        if (props.viewMode instanceof Types_CostEstimation.EditMode) {
                            return CostEstimation_ElementsEditView.component({
                                projectId: props.projectId,
                                project: v1.value0.value0.project,
                                initialSelectedElement: props.initialSelectedElement,
                                dixonOptions: Data_Maybe.Nothing.value,
                                panelOptions: Data_Maybe.Nothing.value,
                                setCheckedElements: Data_Maybe.Nothing.value,
                                checkedElements: Data_Maybe.Nothing.value,
                                setCheckedSpecs: Data_Maybe.Nothing.value,
                                checkedSpecs: Data_Maybe.Nothing.value
                            });
                        };
                        if (props.viewMode instanceof Types_CostEstimation.ReferenceMode) {
                            return CostEstimation_ElementsReferenceView.component({
                                projectId: props.projectId,
                                project: v1.value0.value0.project,
                                initialSelectedElement: props.initialSelectedElement,
                                panelOptions: Data_Maybe.Nothing.value
                            });
                        };
                        throw new Error("Failed pattern match at CostEstimationContainer (line 235, column 13 - line 252, column 18): " + [ props.viewMode.constructor.name ]);
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.ResourcesMode) {
                        return CostEstimation_ResourcesView.component({
                            projectId: props.projectId,
                            project: v1.value0.value0.project,
                            viewMode: props.viewMode
                        });
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.ResourcePriceListMode) {
                        return ResourcePriceListView.component({
                            projectId: props.projectId,
                            project: v1.value0.value0.project,
                            panelOptions: Data_Maybe.Nothing.value
                        });
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.OfferPageMode) {
                        return OfferPageView.offerPageView({
                            projectId: props.projectId,
                            project: v1.value0.value0.project
                        });
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.EditEstimationProjectMode) {
                        return EstimationEditProject.component({
                            projectId: props.projectId
                        });
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.DixonMode) {
                        return Containers_DixonContainer.component({
                            projectId: props.projectId,
                            mFileId: props.mFileId,
                            mDrawingId: props.mDrawingId,
                            mMeasurementGroupId: props.mMeasurementGroupId
                        });
                    };
                    if (props.estimationMode instanceof CostEstimation_Types.PrintingMode) {
                        return printingContainer;
                    };
                    throw new Error("Failed pattern match at CostEstimationContainer (line 215, column 9 - line 275, column 30): " + [ props.estimationMode.constructor.name ]);
                })() ];
            };
            throw new Error("Failed pattern match at CostEstimationContainer (line 209, column 22 - line 276, column 8): " + [ v1.value0.constructor.name ]);
        })());
    };
});
module.exports = {
    component: component
};
