import { Button, Modal } from "@tocoman/ui";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LabelFilteredCombobox } from "src/client-ts/components/LabelFilteredCombobox";
import { useAllowedCurrencyMutation } from "./useOrganizationCurrencySettings";

type AddAllowedCurrencyModalProps = {
  orgCurrencies: string[];
  onClose: () => void;
};

const addableCurrencies = ["EUR", "GBP", "DKK", "NOK", "SEK", "USD"];

export function AddAllowedCurrencyModal({
  orgCurrencies,
  onClose,
}: AddAllowedCurrencyModalProps) {
  const { t } = useTranslation("settings", { keyPrefix: "currencies" });

  const { control, handleSubmit } = useForm<{ newAllowedCurrency: string }>();

  const { mutate: addAllowedCurrency } = useAllowedCurrencyMutation();

  const selectableCurrencies = useMemo(() => {
    return addableCurrencies
      .filter((currency) => !orgCurrencies.includes(currency))
      .map((currency) => {
        return {
          label: currency,
          value: currency,
          key: currency,
        };
      });
  }, [addableCurrencies]);

  const handleSave = (data: { newAllowedCurrency: string }) => {
    addAllowedCurrency(data.newAllowedCurrency);
    onClose();
  };

  const actions = useMemo(() => {
    return (
      <>
        <Button label={t`cancel`} onClick={onClose} variant={"secondary"} />
        <Button label={t`addCurrency`} onClick={handleSubmit(handleSave)} />
      </>
    );
  }, []);

  return (
    <Modal
      title={t`currencyModalTitle`}
      closeModal={onClose}
      isOpen
      actions={actions}
    >
      <LabelFilteredCombobox
        name="newAllowedCurrency"
        control={control}
        items={selectableCurrencies}
      />
    </Modal>
  );
}
