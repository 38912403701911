// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonMeasurementGroupComponents_Styles = require("../DixonMeasurementGroupComponents.Styles/index.js");
var Effect = require("../Effect/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var getColumns = function (props) {
    var unitCell = function (v) {
        return Data_Maybe.fromMaybe("")(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.unit;
        })(v.item.component)));
    };
    var subProjectCodeCell = function (v) {
        return Data_Maybe.fromMaybe("")(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.subProjectCode;
        })(v.item.component)));
    };
    var mgroupCell = function (v) {
        return TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: v.item.measurementGroup.name,
            onClick: Data_Maybe.Just.create(function __do() {
                var mEditor = TofuHooks.readRef(props.editorRef)();
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                    return function () {
                        return editor.set_active_measurement_group(v.item.measurementGroup.id);
                    };
                })();
            })
        });
    };
    var descriptionCell = function (v) {
        return TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: Data_Maybe.fromMaybe("Linkitetty\xe4 suoritetta ei l\xf6ytynyt! Poista t\xe4m\xe4 linkki ja luo se tarpeen mukaan uudelleen.")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.description;
            })(v.item.component)),
            href: (function () {
                if (v.item.component instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create("/" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Project.ProjectId)(props.projectId)) + ("/estimation?componentId=" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Component.ComponentId)(v.item.component.value0.id)))));
                };
                if (v.item.component instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at DixonMeasurementGroupComponents (line 235, column 15 - line 237, column 29): " + [ v.item.component.constructor.name ]);
            })()
        });
    };
    var codeCell = function (v) {
        return Data_Maybe.fromMaybe("")(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.code;
        })(v.item.component)));
    };
    var checkbox = function (v) {
        return TacoCheckbox.component()()({
            className: "item-select-checkbox",
            isChecked: v.checked,
            onToggle: TacoCheckbox.WithShift.create(function (v1) {
                return props.setSelectedItemIds(function (old) {
                    return Utils.toggleInSet(Data_Ord.ordInt)(v.item.id)(old);
                });
            }),
            labelText: Data_Maybe.Nothing.value,
            testId: "item-select-checkbox" + Data_Show.show(Data_Show.showInt)(v.item.id)
        });
    };
    return [ {
        key: "checkbox",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("26px"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "mgroup",
        label: React_Basic_DOM.text("Mittausryhm\xe4"),
        width: new TacoTable_Types.FlexMinWidth(3, "120px"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], mgroupCell),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "subProjectCode",
        label: React_Basic_DOM.text("AK"),
        width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.PlainTextCell(subProjectCodeCell),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "code",
        label: React_Basic_DOM.text("Koodi"),
        width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.PlainTextCell(codeCell),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "description",
        label: React_Basic_DOM.text("Suoritteen selite"),
        width: new TacoTable_Types.FlexMinWidth(3, "120px"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], descriptionCell),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "unit",
        label: React_Basic_DOM.text("Yks."),
        width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.PlainTextCell(unitCell),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var mkRow = function (props) {
    return function (v) {
        return {
            className: (function () {
                var $42 = !v.rowData.estimationComponentsLoading && Data_Maybe.isNothing(v.rowData.item.component);
                if ($42) {
                    return v.className + "-error";
                };
                var $43 = !Data_Array.elem(Data_Eq.eqInt)(v.rowData.item.id)(props.activeLinkIds);
                if ($43) {
                    return v.className;
                };
                return v.className + "-active";
            })(),
            rowData: v.rowData,
            foldingElement: Data_Maybe.Nothing.value,
            onClick: function (v1) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            rowKey: v.rowKey,
            columns: getColumns(props)
        };
    };
};
var getRow = function (props) {
    return function (r) {
        return mkRow(props)({
            className: "DixonMeasurementGroupComponentsTableRow",
            rowData: r,
            rowKey: Data_Show.show(Data_Show.showInt)(r.item.id)
        });
    };
};
var componentsTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("MeasurementGroupComponentsTableInstance");
var component = TofuHooks.mkHookComponent("DixonMeasurementGroupComponents")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = Client_WASM.useEstimationComponents(v.projectId)();
        var v3 = TofuHooks.useState(Data_Set.empty)();
        var v4 = TofuHooks.useState(true)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v2.value1) ])(function __do() {
            Effect_Timer.setTimeout(0)(v4.value1(function (v5) {
                return v2.value1;
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var mapLinkItem = function (item) {
            if (item.component_id instanceof Data_Maybe.Just) {
                var component$prime = Data_Array.find(function (c) {
                    return Data_Eq.eq(Types_Component.eqComponentId)(c.id)(item.component_id.value0);
                })(v2.value0);
                if (component$prime instanceof Data_Maybe.Just) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(function (m) {
                        return m.id === item.measurement_group_id;
                    })(v.editorState.measurement_groups))(function (measurementGroup) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                            id: item.id,
                            measurementGroup: measurementGroup,
                            component: new Data_Maybe.Just(component$prime.value0)
                        });
                    });
                };
                if (component$prime instanceof Data_Maybe.Nothing) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(function (m) {
                        return m.id === item.measurement_group_id;
                    })(v.editorState.measurement_groups))(function (measurementGroup) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                            id: item.id,
                            measurementGroup: measurementGroup,
                            component: Data_Maybe.Nothing.value
                        });
                    });
                };
                throw new Error("Failed pattern match at DixonMeasurementGroupComponents (line 77, column 11 - line 93, column 18): " + [ component$prime.constructor.name ]);
            };
            if (item.component_id instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at DixonMeasurementGroupComponents (line 73, column 7 - line 94, column 27): " + [ item.component_id.constructor.name ]);
        };
        var isComponentLink = function (link) {
            return Data_Maybe.isJust(link.component_id);
        };
        var links = (function () {
            if (v2.value1) {
                return [  ];
            };
            return Data_Array.filter(isComponentLink)(v.editorState.measurement_group_links);
        })();
        var floatingActionButtons = React_Basic.fragment([  ]);
        var deleteLinks = function () {
            return v.editor.delete_measurement_group_links(Data_Array.fromFoldable(Data_Set.foldableSet)(v3.value0));
        };
        var closeDeleteModal = v1.value1(function (v5) {
            return false;
        });
        var deleteModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeDeleteModal),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                deleteLinks();
                return closeDeleteModal();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Poista"
        }) ];
        var deleteMeasurementGroupsModal = TacoModal.component()()({
            onClose: closeDeleteModal,
            isActive: v1.value0,
            heading: "Poista valitut linkit",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Haluatko varmasti poistaa valitut linkit ja linkitetyt m\xe4\xe4r\xe4t?") ]) ]
            }), TacoModal.modalActions({
                contents: deleteModalActions
            }) ]
        });
        var activeMeasurementGroupIds = Data_Functor.map(Data_Functor.functorArray)(function (v5) {
            return v5.id;
        })(Data_Array.filter(function (v5) {
            return v5.active;
        })(v.editorState.measurement_groups));
        var filterActiveLink = function (l) {
            return Data_Array.elem(Data_Eq.eqInt)(l.measurementGroup.id)(activeMeasurementGroupIds) || Data_Maybe.isNothing(l.component);
        };
        var linkItems = Data_Array.filter(filterActiveLink)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(mapLinkItem)(links)));
        var activeLinks = Data_Array.filter(function (l) {
            return Data_Array.elem(Data_Eq.eqInt)(l.measurement_group_id)(activeMeasurementGroupIds);
        })(v.editorState.measurement_group_links);
        var activeLinkIds = Data_Functor.map(Data_Functor.functorArray)(function (v5) {
            return v5.id;
        })(activeLinks);
        var tableProps = {
            editorRef: v.editorRef,
            selectedItemIds: v3.value0,
            setSelectedItemIds: v3.value1,
            activeLinkIds: activeLinkIds,
            projectId: v.projectId
        };
        var columns = getColumns(tableProps);
        var table = TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "item";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "measurementGroup";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "perimeter";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "num_vertices";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "linked_component_ids";
            }
        })(Data_Eq.eqArray(Types_Component.eqComponentId)))()({
            reflectSymbol: function () {
                return "linked_building_element_ids";
            }
        })(Data_Eq.eqArray(Types_Element.eqElementId)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "formula_sum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "drawing_ids";
            }
        })(Data_Eq.eqArray(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "default_location";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "default_formula";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "color";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "area";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "active";
            }
        })(Data_Eq.eqBoolean))))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "component";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "subProjectName";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "subProjectId";
            }
        })(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
            reflectSymbol: function () {
                return "subProjectCode";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "memo";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Component.eqComponentId))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
            reflectSymbol: function () {
                return "flagged";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "costGroupDescription";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "costGroupCode";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "componentType";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "classCode";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "amount";
            }
        })(Data_Eq.eqNumber)))))))()({
            reflectSymbol: function () {
                return "estimationComponentsLoading";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean)))(componentsTableInstance)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(getRow(tableProps))(function (item) {
                return {
                    item: item,
                    checked: Data_Foldable.elem(Data_Set.foldableSet)(Data_Eq.eqInt)(item.id)(v3.value0),
                    estimationComponentsLoading: v4.value0
                };
            }))(linkItems),
            showHeader: true,
            styleSet: styleSet,
            columns: columns
        });
        var componentsTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.componentColors,
            children: [ table ],
            floatingActionButtons: floatingActionButtons
        });
        var v5 = TofuHooks.useState(false)();
        var closeDeprecationNoticeModal = v5.value1(function (v6) {
            return false;
        });
        var deprecationNoticeModal = TacoModal.component()()({
            onClose: closeDeleteModal,
            isActive: v5.value0,
            heading: "Lis\xe4tietoja",
            contents: [ TacoModal.modalBody({
                contents: [ TacoText.component()()({
                    text: "asdasd"
                }) ]
            }), TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(closeDeprecationNoticeModal),
                    text: "Sulje"
                }) ]
            }) ]
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonMeasurementGroupComponents",
            css: DixonMeasurementGroupComponents_Styles.dixonMeasurementGroupComponentsContainerStyles,
            children: [ componentsTable, deleteMeasurementGroupsModal, deprecationNoticeModal ]
        });
    };
});
module.exports = {
    component: component,
    styleSet: styleSet,
    getColumns: getColumns,
    mkRow: mkRow,
    getRow: getRow,
    componentsTableInstance: componentsTableInstance
};
