import { AdditionalRow } from "./PremiumOfferPageTable";
import {
  useOfferParamsQuery,
  useSetOfferParamsQuery,
} from "./useOfferParamsQuery";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Modal, Select, SelectItem } from "@tocoman/ui";
import { Table } from "../../../components/Table";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  getBaseTotal,
  getMargin,
  getMarginPercentage,
  getOffer,
  getPinnedTotalRow,
  setMargin,
  setMarginPercentage,
} from "./PremiumMarginFunctions";

type EditMarginModalProps = {
  projectId: number;
  open: boolean;
  onClose: () => void;
  deleteRow: (code: string) => void;
  generalRow: AdditionalRow;
  currency: string;
};

export type MarginRowData = {
  code: string;
  description: string;
  baseTotal: number;
  marginPercentage: number;
  margin: number;
  totalWithMargin: number;
  visible?: boolean;
  economicBaseTotal?: number;
  economicMargin?: number;
};

export const EditMarginModal = ({
  projectId,
  open,
  onClose,
  deleteRow,
  generalRow,
  currency,
}: EditMarginModalProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });
  const gridRef = useRef<AgGridReact>(null);

  const { data: offerParams } = useOfferParamsQuery(Number(projectId));
  const editOfferValue = useSetOfferParamsQuery(projectId);

  const marginData = useMemo(() => {
    const summaryMarginPercentage = offerParams?.marginPercentages.find(
      (mp) => mp.key === "summary"
    )?.value;
    const costClasses =
      generalRow.costClasses?.map((costClass) => {
        const ccKey = "cc-" + costClass.costClassCode;
        const ccMarginPercentage =
          summaryMarginPercentage ??
          offerParams?.marginPercentages.find((mp) => mp.key === ccKey)
            ?.value ??
          0;
        const margin = costClass.baseTotal * ccMarginPercentage;
        const totalWithMargin = costClass.baseTotal + margin;
        return {
          code: ccKey,
          description: costClass.name,
          baseTotal: costClass.baseTotal,
          marginPercentage: ccMarginPercentage,
          margin: margin,
          totalWithMargin: totalWithMargin,
        };
      }) ?? [];
    const costItems =
      generalRow.costItems?.map((costItem) => {
        const ciMarginPercentage =
          summaryMarginPercentage ??
          offerParams?.marginPercentages.find((mp) => mp.key === costItem.code)
            ?.value ??
          0;
        const margin = costItem.baseTotal * ciMarginPercentage;
        const totalWithMargin = costItem.baseTotal + margin;
        return {
          code: costItem.code,
          description: costItem.description,
          baseTotal: costItem.baseTotal,
          marginPercentage: ciMarginPercentage,
          margin: margin,
          totalWithMargin: totalWithMargin,
          visible: costItem.visible,
        };
      }) ?? [];
    const visibleCostItems = costItems.filter((ci) => ci.visible);
    return [...costClasses, ...visibleCostItems];
  }, [generalRow]);

  const handleDelete = () => {
    deleteRow("margin");
    const resetValues = {
      showEconomicMargin: offerParams?.showEconomicMargin ?? true,
      values: {
        riskPercentages: offerParams?.riskPercentages ?? [],
        marginPercentages: [],
        offerOverrides: offerParams?.offerOverrides ?? [],
        rowDescriptions: offerParams?.rowDescriptions ?? [],
        generalPercentages: offerParams?.generalPercentages ?? [],
        changePercentages: offerParams?.changePercentages ?? [],
        specialPercentages: offerParams?.specialPercentages ?? [],
      },
    };
    editOfferValue.mutate(resetValues);
    onClose();
  };

  const handleSave = () => {
    const marginPercentages = marginData.map((row) => {
      const key = row.code;
      const value = row.marginPercentage;
      return { key, value };
    });
    const totalRow = gridRef.current?.api?.getPinnedBottomRow(0);
    if (totalRow) {
      marginPercentages.push({
        key: "total",
        value: totalRow.data.marginPercentage,
      });
    }
    const firstMarginPercentage = marginPercentages[0]?.value ?? 0;
    const isSummaryMargin = marginPercentages.every(
      (mp) => mp.value === firstMarginPercentage
    );
    const summaryMargin = [{ key: "summary", value: firstMarginPercentage }];
    const marginPercentageToSave = isSummaryMargin
      ? summaryMargin
      : marginPercentages;
    const newOfferParams = {
      showEconomicMargin: selectedMarginType?.value === "economicMargin",
      values: {
        riskPercentages: offerParams?.riskPercentages ?? [],
        marginPercentages: marginPercentageToSave,
        offerOverrides: offerParams?.offerOverrides ?? [],
        rowDescriptions: offerParams?.rowDescriptions ?? [],
        generalPercentages: offerParams?.generalPercentages ?? [],
        changePercentages: offerParams?.changePercentages ?? [],
        specialPercentages: offerParams?.specialPercentages ?? [],
      },
    };
    editOfferValue.mutate(newOfferParams);
    onClose();
  };

  const colDefs: (
    | ColDef<MarginRowData>
    | ColGroupDef<MarginRowData>
  )[] = useMemo(
    () => [
      {
        field: "description",
        headerName: t("costClass"),
        flex: 1,
      },
      {
        field: "baseTotal",
        headerName: t("baseTotal"),
        type: ["money"],
        flex: 1,
        valueGetter: getBaseTotal,
      },
      {
        field: "marginPercentage",
        headerName: t("columns.marginPercentage"),
        type: ["percentageWithZeroValue", "editable"],
        flex: 1,
        valueGetter: getMarginPercentage,
        valueSetter: setMarginPercentage,
      },
      {
        field: "margin",
        headerName: t("margin"),
        type: ["money", "editable"],
        flex: 1,
        valueGetter: getMargin,
        valueSetter: setMargin,
      },
      {
        field: "totalWithMargin",
        headerName: t("columns.totalWithMargin"),
        type: ["money"],
        flex: 1,
        valueGetter: getOffer,
      },
    ],
    []
  );

  const actions = [
    <div className={"w-full flex justify-between"} key={"btn-wrapper"}>
      <Button
        key={"delete"}
        label={t("delete")}
        onClick={handleDelete}
        color={"danger"}
      />
      <div>
        <Button
          key="cancel"
          label={t("cancel")}
          onClick={onClose}
          variant="text"
          color="gray"
        />
        <Button
          key="save"
          label={t("save")}
          onClick={handleSave}
          color="normal"
        />
      </div>
    </div>,
  ];

  const marginTypes: SelectItem<string>[] = [
    {
      value: "economicMargin",
      label: t`economicMargin`,
      key: "economicMargin",
    },
    {
      value: "technicalMargin",
      label: t`technicalMargin`,
      key: "technicalMargin",
    },
  ];

  const [selectedMarginType, setSelectedMarginType] = useState<
    SelectItem<string> | undefined
  >(
    offerParams?.showEconomicMargin === false ? marginTypes[1] : marginTypes[0]
  );

  const marginValueChanged = (value: SelectItem<string> | undefined) => {
    setSelectedMarginType(value);
    if (gridRef?.current) {
      gridRef.current.api.setGridOption("context", {
        currency,
        selectedMarginType: value?.value ?? "economicMargin",
      });
      updatePinnedTotalRow();
    }
  };

  // selectedMarginTypes set initialState gave always economicMargin, so needed to set it again after offerParams is loaded
  useEffect(() => {
    if (offerParams?.showEconomicMargin === false) {
      setSelectedMarginType(marginTypes[1]);
    } else {
      setSelectedMarginType(marginTypes[0]);
    }
  }, [offerParams]);

  const [pinnedTotalRow, setPinnedTotalRow] = useState<
    MarginRowData[] | undefined
  >();

  const updatePinnedTotalRow = () => {
    const updatedPinnedRow = getPinnedTotalRow(
      gridRef,
      selectedMarginType?.value ?? "economicMargin",
      t
    );
    setPinnedTotalRow(updatedPinnedRow);
  };

  useEffect(() => {
    updatePinnedTotalRow();
  }, [selectedMarginType]);

  return (
    <Modal isOpen={open} closeModal={onClose} width={900} actions={actions}>
      <>
        <Select
          onValueChange={marginValueChanged}
          items={marginTypes}
          selected={selectedMarginType}
        />
        <Table
          gridRef={gridRef}
          rowData={marginData}
          columnDefs={colDefs}
          domLayout={"autoHeight"}
          context={{
            currency,
            selectedMarginType: selectedMarginType?.value ?? "economicMargin",
          }}
          onFirstDataRendered={updatePinnedTotalRow}
          onCellValueChanged={updatePinnedTotalRow}
          pinnedBottomRowData={pinnedTotalRow}
        />
      </>
    </Modal>
  );
};
