// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var ComponentUtils = require("../ComponentUtils/index.js");
var ComponentsViewUtils = require("../ComponentsViewUtils/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_CopyComponents = require("../CostEstimation.CopyComponents/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_SelectedComponentDetails = require("../CostEstimation.SelectedComponentDetails/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var CostEstimation_ViewControls = require("../CostEstimation.ViewControls/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var EstimationComponentsTable = require("../EstimationComponentsTable/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseRefProjects = require("../UseRefProjects/index.js");
var UseSelectedComponentScrollPosition = require("../UseSelectedComponentScrollPosition/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var UseTargetCostGroup = require("../UseTargetCostGroup/index.js");
var UseWorkAchievement = require("../UseWorkAchievement/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var selectedEstimationComponentsWithResources = function (components) {
    return Data_Array.filter(ComponentsViewUtils.selected)(components);
};
var rowHeight = TacoTable_Types.RowMedium.value;
var referenceModeFollowOptionsByChoices = [ {
    text: "Koodi",
    value: "code"
} ];
var findMatchingComponentIndex = function (key) {
    return function (viewECWR) {
        return function (components) {
            var unECWR = (function () {
                var $239 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
                return function ($240) {
                    return (function (v) {
                        return v.component;
                    })($239($240));
                };
            })();
            var extract = function ($241) {
                return (function (v) {
                    return v.code;
                })(unECWR($241));
            };
            var matchCode = extract(viewECWR);
            var getScore = function (i) {
                return function (str) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(matchCode))(function (matchCode$prime) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(str))(function (str$prime) {
                            var score = Utils.scoreMatch(matchCode$prime)(str$prime);
                            var $82 = score > 0;
                            if ($82) {
                                return new Data_Maybe.Just({
                                    i: i,
                                    score: score
                                });
                            };
                            return Data_Maybe.Nothing.value;
                        });
                    });
                };
            };
            var componentsCodes = Data_Functor.map(Data_Functor.functorArray)(extract)(components);
            var exactMatch = Data_Array.elemIndex(Data_Nullable.eqNullable(Data_Eq.eqString))(matchCode)(componentsCodes);
            var closeMatch = (function () {
                var scores = Data_Array.catMaybes(Data_Array.mapWithIndex(getScore)(componentsCodes));
                var sortedScores = Data_Array.sortWith(Data_Ord.ordInt)(function (v) {
                    return v.score;
                })(scores);
                var grouped = Data_Array.groupBy(function (x) {
                    return function (y) {
                        return x.score === y.score;
                    };
                })(sortedScores);
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.last(grouped))(function (nea) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((Data_Array_NonEmpty.head(nea)).i);
                });
            })();
            return Control_Alt.alt(Data_Maybe.altMaybe)(exactMatch)(closeMatch);
        };
    };
};
var dispatchEstimationComponentAction = function (appDispatch) {
    return function (action) {
        return appDispatch(new Actions.EstimationComponentActionRequest(action));
    };
};
var component = TofuHooks.mkHookComponent("ReferenceComponentsView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var mRefPanelMode = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.panelOptions)(function (v) {
            return CostEstimationLayout_Types.ReferenceComponentPanel.value;
        });
        var isPanel = Data_Maybe.isJust(mRefPanelMode);
        var v = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v1 = UseWorkAchievement.useWorkAchievement(props.projectId)();
        var v2 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-total-price")(false)(false)();
        var v3 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-unit-price")(false)(false)();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-edit-show-cost-class-hours-per-unit")(false)(false)();
        var showCostClassColumns = v2.value0 || (v3.value0 || v4.value0);
        var v5 = UseLocalStorage.useLocalStorageJSON(TofuJSON.tofuJSONSet(Types_Newtypes.ordCostClassCode)(Types_Newtypes.tofuJSONCostClassCode))("view-controls-ref-show-cost-class-columns-selection")(Data_Set.empty)(false)();
        var v6 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-ref-show-hours-columns")(false)(false)();
        var v7 = FetchHooks.useCostClassesWithHash(props.projectId)();
        var v8 = Client_WASM.useEstimationProjects();
        var v9 = Client_WASM.useReferenceEstimationComponents(props.projectId)();
        var v10 = Client_WASM.useReferenceSubprojects(props.projectId)();
        var v11 = Client_WASM.useElementSpecs(props.projectId)();
        var v12 = Client_WASM.useSubprojects(props.projectId)();
        var v13 = Client_WASM.useEstimationElements(props.projectId)();
        var v14 = UseRefProjects.useRefProjects(props.projectId)();
        var v15 = Client_WASM.useClassificationGroups(props.projectId)();
        var v16 = Client_WASM.useProjectClassificationClasses(props.projectId)();
        var v17 = Client_WASM.usePlanningCostGroups(props.projectId)();
        var v18 = Client_WASM.useProjectLocations(props.projectId)();
        var buildingDescriptionBySpecId = ComponentsViewUtils.mkBuildingDescriptionBySpecId("ReferenceView")(v13.value0)(v11.value0)();
        var v19 = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var loading = v9.value1 || (v15.value1 || (v12.value1 || (v8.value1 || (v10.value1 || v15.value1))));
        var v20 = UseTargetCostGroup.useTargetCostGroup(props.projectId)();
        var initialSelectedComponentIdx = (function () {
            if (props.initialSelectedComponent instanceof Data_Maybe.Nothing && v19.value0.selectedComponentIdx instanceof Data_Maybe.Just) {
                return Edited.Edited.create(new Data_Maybe.Just(v19.value0.selectedComponentIdx.value0));
            };
            return Edited.Unedited.value;
        })();
        var v21 = TofuHooks.useState({
            selectedComponentIdx: initialSelectedComponentIdx,
            checkedComponents: Data_Set.empty,
            shouldScrollToSelection: true,
            referenceSortingCriteria: TacoTable_Types.SortingCriteria.create(TacoTable_Types.Ascending.value)(new Data_Maybe.Just(CostEstimation_Types.Code.value))(EstimationComponentsTable.getSortingValue(v.socialExpensePercentagesByCostClass)),
            components: Common.emptyArray,
            unsortedComponents: Common.emptyArray,
            activeFilters: v19.value0.referenceComponentFilters,
            lastCheckedItem: Data_Maybe.Nothing.value,
            addComponentModalOpen: false,
            updateSelectedComponentIdxOnTick: false
        })();
        var unselectComponent = function __do() {
            DOMUtils.blurActiveElement();
            return v21.value1(function (v22) {
                return {
                    selectedComponentIdx: Edited.Unedited.value,
                    checkedComponents: v22.checkedComponents,
                    shouldScrollToSelection: v22.shouldScrollToSelection,
                    referenceSortingCriteria: v22.referenceSortingCriteria,
                    components: v22.components,
                    unsortedComponents: v22.unsortedComponents,
                    activeFilters: v22.activeFilters,
                    lastCheckedItem: v22.lastCheckedItem,
                    addComponentModalOpen: v22.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: v22.updateSelectedComponentIdxOnTick
                };
            })();
        };
        TofuHooks.useEffect([ v.socialExpensePercentagesByCostClassWithHash.hash ])(Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))(v21.value1(function (s) {
            return {
                selectedComponentIdx: s.selectedComponentIdx,
                checkedComponents: s.checkedComponents,
                shouldScrollToSelection: s.shouldScrollToSelection,
                referenceSortingCriteria: EstimationComponentsTable.updateSortingCriteriaSEP(v.socialExpensePercentagesByCostClassWithHash.value)(s.referenceSortingCriteria),
                components: s.components,
                unsortedComponents: s.unsortedComponents,
                activeFilters: s.activeFilters,
                lastCheckedItem: s.lastCheckedItem,
                addComponentModalOpen: s.addComponentModalOpen,
                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
            };
        })))();
        var v22 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-follow-edit-component-on")(false)(false)();
        var v23 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readString)("view-controls-follow-edit-component-by-criteria")("code")(false)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v22.value0) ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v22.value0)(v21.value1(function (v24) {
                return {
                    selectedComponentIdx: Edited.Unedited.value,
                    checkedComponents: v24.checkedComponents,
                    shouldScrollToSelection: v24.shouldScrollToSelection,
                    referenceSortingCriteria: v24.referenceSortingCriteria,
                    components: v24.components,
                    unsortedComponents: v24.unsortedComponents,
                    activeFilters: v24.activeFilters,
                    lastCheckedItem: v24.lastCheckedItem,
                    addComponentModalOpen: v24.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: v24.updateSelectedComponentIdxOnTick
                };
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        Client_WASM.useSetActiveRefComponentFilters(v21.value0.activeFilters)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v22.value0), ReactHooksUtils.utf(props.panelOptions) ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v22.value0)((function () {
                var mSelectedEditViewComponent = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v24) {
                    return v24.selectedEditViewComponent;
                })(props.panelOptions);
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mSelectedEditViewComponent)(function (v24) {
                    return v21.value1(function (v25) {
                        return {
                            selectedComponentIdx: Edited.Unedited.value,
                            checkedComponents: v25.checkedComponents,
                            shouldScrollToSelection: v25.shouldScrollToSelection,
                            referenceSortingCriteria: v25.referenceSortingCriteria,
                            components: v25.components,
                            unsortedComponents: v25.unsortedComponents,
                            activeFilters: v25.activeFilters,
                            lastCheckedItem: v25.lastCheckedItem,
                            addComponentModalOpen: v25.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: v25.updateSelectedComponentIdxOnTick
                        };
                    });
                });
            })())();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var selectedComponentIdx = TofuHooks.useMemo([ ReactHooksUtils.utf(v21.value0.selectedComponentIdx), ReactHooksUtils.utf(props.initialSelectedComponent), ReactHooksUtils.utf(v21.value0.components), ReactHooksUtils.utf(props.panelOptions), ReactHooksUtils.utf(v22.value0) ])(function (v24) {
            var selectedEditViewComponent = (function () {
                if (v22.value0) {
                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v25) {
                        return v25.selectedEditViewComponent;
                    })(props.panelOptions);
                };
                return Data_Maybe.Nothing.value;
            })();
            var bySelectedEVC = function (selectedEVC) {
                return findMatchingComponentIndex(v23.value0)(selectedEVC)(v21.value0.components);
            };
            if (v21.value0.selectedComponentIdx instanceof Edited.Unedited && selectedEditViewComponent instanceof Data_Maybe.Just) {
                return bySelectedEVC(selectedEditViewComponent.value0);
            };
            if (v21.value0.selectedComponentIdx instanceof Edited.Edited && (v21.value0.selectedComponentIdx.value0 instanceof Data_Maybe.Nothing && selectedEditViewComponent instanceof Data_Maybe.Just)) {
                return bySelectedEVC(selectedEditViewComponent.value0);
            };
            if (v21.value0.selectedComponentIdx instanceof Edited.Unedited && props.initialSelectedComponent instanceof Data_Maybe.Just) {
                return Data_Array.elemIndex(Types_Component.eqComponentId)(props.initialSelectedComponent.value0)(Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $242 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
                    return function ($243) {
                        return (function (v25) {
                            return v25.component.id;
                        })($242($243));
                    };
                })())(v21.value0.components));
            };
            if (v21.value0.selectedComponentIdx instanceof Edited.Unedited) {
                return Data_Maybe.Nothing.value;
            };
            if (v21.value0.selectedComponentIdx instanceof Edited.Edited) {
                return v21.value0.selectedComponentIdx.value0;
            };
            throw new Error("Failed pattern match at ReferenceView (line 256, column 10 - line 261, column 28): " + [ v21.value0.selectedComponentIdx.constructor.name, props.initialSelectedComponent.constructor.name, selectedEditViewComponent.constructor.name ]);
        })();
        var selectedComponent = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedComponentIdx), ReactHooksUtils.utf(v21.value0.components) ])(function (v24) {
            return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(v21.value0.components))(selectedComponentIdx);
        })();
        TofuHooks.useEffect([ selectedComponentIdx ])(function __do() {
            v19.value1(function (v24) {
                return {
                    project: v24.project,
                    elementFilters: v24.elementFilters,
                    elementGroupProperty: v24.elementGroupProperty,
                    selectedElementIdx: v24.selectedElementIdx,
                    referenceElementFilters: v24.referenceElementFilters,
                    selectedReferenceElementIdx: v24.selectedReferenceElementIdx,
                    componentFilters: v24.componentFilters,
                    componentGroupProperty: v24.componentGroupProperty,
                    selectedComponentIdx: v24.selectedComponentIdx,
                    referenceComponentFilters: v24.referenceComponentFilters,
                    selectedReferenceComponentIdx: selectedComponentIdx,
                    referenceResourceFilters: v24.referenceResourceFilters,
                    selectedResourceId: v24.selectedResourceId
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectComponent = TofuHooks.useMemo([  ])(function (v24) {
            return function (shouldScrollToSelection) {
                return function (mComponent) {
                    if (mComponent instanceof Data_Maybe.Just && (Types_CostEstimation.unECWR(mComponent.value0)).component.componentType !== 3) {
                        return v21.value1(function (s) {
                            return {
                                selectedComponentIdx: (function () {
                                    var v26 = Data_Array.elemIndex(Types_CostEstimation.eqEstimationComponentWithResources)(mComponent.value0)(s.components);
                                    var v27 = Edited.flattenEdited(s.selectedComponentIdx);
                                    if (v27 instanceof Data_Maybe.Just && (v26 instanceof Data_Maybe.Just && v27.value0 === v26.value0)) {
                                        return new Edited.Edited(Data_Maybe.Nothing.value);
                                    };
                                    return new Edited.Edited(v26);
                                })(),
                                checkedComponents: s.checkedComponents,
                                shouldScrollToSelection: (function () {
                                    if (s.selectedComponentIdx instanceof Edited.Edited && s.selectedComponentIdx.value0 instanceof Data_Maybe.Nothing) {
                                        return true;
                                    };
                                    if (s.selectedComponentIdx instanceof Edited.Unedited) {
                                        return true;
                                    };
                                    return shouldScrollToSelection;
                                })(),
                                referenceSortingCriteria: s.referenceSortingCriteria,
                                components: s.components,
                                unsortedComponents: s.unsortedComponents,
                                activeFilters: s.activeFilters,
                                lastCheckedItem: s.lastCheckedItem,
                                addComponentModalOpen: s.addComponentModalOpen,
                                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                            };
                        });
                    };
                    return v21.value1(function (s) {
                        return {
                            selectedComponentIdx: new Edited.Edited(Data_Maybe.Nothing.value),
                            checkedComponents: s.checkedComponents,
                            shouldScrollToSelection: shouldScrollToSelection,
                            referenceSortingCriteria: s.referenceSortingCriteria,
                            components: s.components,
                            unsortedComponents: s.unsortedComponents,
                            activeFilters: s.activeFilters,
                            lastCheckedItem: s.lastCheckedItem,
                            addComponentModalOpen: s.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                        };
                    });
                };
            };
        })();
        var setSorting = function (s) {
            return function (newProperty) {
                var getNewSortingCriteria = function (v24) {
                    return function (mySortingCriteria) {
                        var $130 = Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECSortProperty))(mySortingCriteria.value1)(new Data_Maybe.Just(newProperty));
                        if ($130) {
                            return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(mySortingCriteria.value0), mySortingCriteria.value1, mySortingCriteria.value2);
                        };
                        return new TacoTable_Types.SortingCriteria(mySortingCriteria.value0, new Data_Maybe.Just(newProperty), mySortingCriteria.value2);
                    };
                };
                return getNewSortingCriteria(Types_CostEstimation.ReferenceMode.value)(s.referenceSortingCriteria);
            };
        };
        var setSortingCriteria = TofuHooks.useMemo([ ReactHooksUtils.utf(loading) ])(function (v24) {
            return function (newProperty) {
                return v21.value1(function (s) {
                    var newReferenceSortingCriteria = setSorting(s)(newProperty);
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceSortingCriteria: newReferenceSortingCriteria,
                        components: s.components,
                        unsortedComponents: s.unsortedComponents,
                        activeFilters: s.activeFilters,
                        lastCheckedItem: Data_Maybe.Nothing.value,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: true
                    };
                });
            };
        })();
        var selectComponentRelative = TofuHooks.useMemo([  ])(function (v24) {
            return function (diff) {
                return v21.value1(function (s) {
                    var targetIdx = diff + Data_Maybe.fromMaybe(-diff | 0)(Edited.flattenEdited(s.selectedComponentIdx)) | 0;
                    return {
                        selectedComponentIdx: Edited.Edited.create(Data_Maybe.Just.create(Data_Ord.max(Data_Ord.ordInt)(0)(Data_Ord.min(Data_Ord.ordInt)(targetIdx)(Data_Array.length(s.components) - 1 | 0)))),
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: true,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        components: s.components,
                        unsortedComponents: s.unsortedComponents,
                        activeFilters: s.activeFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                });
            };
        })();
        var setUnsortedComponents = TofuHooks.useMemo([  ])(function (v24) {
            return function (unsorted) {
                return v21.value1(function (v25) {
                    return {
                        selectedComponentIdx: v25.selectedComponentIdx,
                        checkedComponents: v25.checkedComponents,
                        shouldScrollToSelection: v25.shouldScrollToSelection,
                        referenceSortingCriteria: v25.referenceSortingCriteria,
                        components: v25.components,
                        unsortedComponents: unsorted,
                        activeFilters: v25.activeFilters,
                        lastCheckedItem: v25.lastCheckedItem,
                        addComponentModalOpen: v25.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: v25.updateSelectedComponentIdxOnTick
                    };
                });
            };
        })();
        var setComponentsList = TofuHooks.useMemo([  ])(function (v24) {
            return function (list) {
                return v21.value1(function (s) {
                    if (s.updateSelectedComponentIdxOnTick) {
                        return {
                            selectedComponentIdx: Edited.Edited.create(ComponentsViewUtils.findNewIdxOfSelectedComponent(Edited.flattenEdited(s.selectedComponentIdx))(s.components)(list)),
                            checkedComponents: s.checkedComponents,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceSortingCriteria: s.referenceSortingCriteria,
                            components: list,
                            unsortedComponents: s.unsortedComponents,
                            activeFilters: s.activeFilters,
                            lastCheckedItem: s.lastCheckedItem,
                            addComponentModalOpen: s.addComponentModalOpen,
                            updateSelectedComponentIdxOnTick: false
                        };
                    };
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        components: list,
                        unsortedComponents: s.unsortedComponents,
                        activeFilters: s.activeFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                });
            };
        })();
        var setActiveComponentFilters = function (filters) {
            return function __do() {
                v19.value1(function (v24) {
                    return {
                        project: v24.project,
                        elementFilters: v24.elementFilters,
                        elementGroupProperty: v24.elementGroupProperty,
                        selectedElementIdx: v24.selectedElementIdx,
                        referenceElementFilters: v24.referenceElementFilters,
                        selectedReferenceElementIdx: v24.selectedReferenceElementIdx,
                        componentFilters: v24.componentFilters,
                        componentGroupProperty: v24.componentGroupProperty,
                        selectedComponentIdx: v24.selectedComponentIdx,
                        referenceComponentFilters: filters,
                        selectedReferenceComponentIdx: v24.selectedReferenceComponentIdx,
                        referenceResourceFilters: v24.referenceResourceFilters,
                        selectedResourceId: v24.selectedResourceId
                    };
                })();
                return v21.value1(function (s) {
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        components: s.components,
                        unsortedComponents: s.unsortedComponents,
                        activeFilters: filters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: s.addComponentModalOpen,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                })();
            };
        };
        var toggleCheckbox$prime = TofuHooks.useMemo([  ])(function (v24) {
            return function (compId) {
                return function (s) {
                    return {
                        checkedComponents: Utils.toggleInSet(Types_Component.ordComponentId)(compId)(s.checkedComponents),
                        lastCheckedItem: new Data_Maybe.Just(compId),
                        activeFilters: s.activeFilters,
                        addComponentModalOpen: s.addComponentModalOpen,
                        components: s.components,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        selectedComponentIdx: s.selectedComponentIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        unsortedComponents: s.unsortedComponents,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                };
            };
        })();
        var toggleCheckbox = TofuHooks.useMemo([  ])(function (v24) {
            return function (compId) {
                return v21.value1(function (s) {
                    return toggleCheckbox$prime(compId)(s);
                });
            };
        })();
        var toggleAreaOfCheckboxes = TofuHooks.useMemo([  ])(function (v24) {
            return function (thisId) {
                return Control_Apply.applyFirst(Effect.applyEffect)(WindowUtils.selectionRemoveAllRanges)(v21.value1(function (s) {
                    if (s.lastCheckedItem instanceof Data_Maybe.Just) {
                        var notTopicComponent = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))((function () {
                            var $244 = Data_Eq.eq(Types_Component.eqComponentType)(Types_Component.TopicComponent.value);
                            return function ($245) {
                                return $244(Types_Component.intToComponentType((function (v25) {
                                    return v25.component.componentType;
                                })($245)));
                            };
                        })());
                        var components$prime = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources))(s.components);
                        var idToIndex = function (id) {
                            return Data_Array.findIndex(function (x) {
                                return Data_Eq.eq(Types_Component.eqComponentId)(id)(x.component.id);
                            })(components$prime);
                        };
                        var areaInIds = function (fstIdx) {
                            return function (sndIdx) {
                                return Data_Functor.map(Data_Functor.functorArray)(function (v25) {
                                    return v25.component.id;
                                })(Data_Array.filter(notTopicComponent)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Array.index(components$prime))(Data_Array.range(fstIdx)(sndIdx)))));
                            };
                        };
                        var v25 = idToIndex(s.lastCheckedItem.value0);
                        var v26 = idToIndex(thisId);
                        if (v26 instanceof Data_Maybe.Just && v25 instanceof Data_Maybe.Just) {
                            return {
                                selectedComponentIdx: s.selectedComponentIdx,
                                checkedComponents: Data_Set.union(Types_Component.ordComponentId)(s.checkedComponents)(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(areaInIds(v26.value0)(v25.value0))),
                                shouldScrollToSelection: s.shouldScrollToSelection,
                                referenceSortingCriteria: s.referenceSortingCriteria,
                                components: s.components,
                                unsortedComponents: s.unsortedComponents,
                                activeFilters: s.activeFilters,
                                lastCheckedItem: new Data_Maybe.Just(thisId),
                                addComponentModalOpen: s.addComponentModalOpen,
                                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                            };
                        };
                        return s;
                    };
                    if (s.lastCheckedItem instanceof Data_Maybe.Nothing) {
                        return toggleCheckbox$prime(thisId)(s);
                    };
                    throw new Error("Failed pattern match at ReferenceView (line 332, column 5 - line 342, column 42): " + [ s.lastCheckedItem.constructor.name ]);
                }));
            };
        })();
        var unselectAllComponents = TofuHooks.useMemo([  ])(function (v24) {
            return v21.value1(function (s) {
                return {
                    selectedComponentIdx: new Edited.Edited(Data_Maybe.Nothing.value),
                    checkedComponents: Data_Set.empty,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    referenceSortingCriteria: s.referenceSortingCriteria,
                    components: s.components,
                    unsortedComponents: s.unsortedComponents,
                    activeFilters: s.activeFilters,
                    lastCheckedItem: s.lastCheckedItem,
                    addComponentModalOpen: s.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                };
            });
        })();
        var onShowAddComponentModal = TofuHooks.useMemo([  ])(function (v24) {
            return function (show) {
                return v21.value1(function (s) {
                    return {
                        selectedComponentIdx: s.selectedComponentIdx,
                        checkedComponents: s.checkedComponents,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceSortingCriteria: s.referenceSortingCriteria,
                        components: s.components,
                        unsortedComponents: s.unsortedComponents,
                        activeFilters: s.activeFilters,
                        lastCheckedItem: s.lastCheckedItem,
                        addComponentModalOpen: show,
                        updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                    };
                });
            };
        })();
        ComponentsViewUtils.updateComponentsList({
            viewMode: Types_CostEstimation.ReferenceMode.value,
            loading: loading,
            unsortedComponents: v21.value0.unsortedComponents,
            checkedComponents: v21.value0.checkedComponents,
            setUnsortedComponents: setUnsortedComponents,
            setComponentsList: setComponentsList,
            sortingCriteria: v21.value0.referenceSortingCriteria,
            activeComponentFilters: v21.value0.activeFilters,
            projectId: props.projectId,
            socialExpensePercentagesByCostClassWithHash: v.socialExpensePercentagesByCostClassWithHash,
            costClassesWithHash: v7.value0,
            estimationProjects: v8.value0,
            referenceSubprojects: v10.value0,
            groupBy: Data_Maybe.Nothing.value,
            classificationGroups: v15.value0,
            classificationClasses: v16.value0,
            planningCostGroups: v17.value0,
            projectLocations: v18.value0,
            unselectComponent: unselectComponent,
            selectedComponentIdx: selectedComponentIdx,
            selectedComponentId: Data_Functor.map(Data_Maybe.functorMaybe)(ComponentUtils.getECWRId)(selectedComponent)
        })();
        var setRefProjectFilter = (function () {
            var isProjectFilter = function (filter) {
                if (filter instanceof CostEstimation_Filters.FilterByComponentProjectOfOrigin) {
                    return true;
                };
                return false;
            };
            var previousProjectFilter = Data_Array.find(isProjectFilter)(v21.value0.activeFilters);
            var prunedFilters = (function () {
                if (previousProjectFilter instanceof Data_Maybe.Just) {
                    return Data_Array["delete"](CostEstimation_Filters.eqEstimationComponentPropertyFilter)(previousProjectFilter.value0)(v21.value0.activeFilters);
                };
                if (previousProjectFilter instanceof Data_Maybe.Nothing) {
                    return v21.value0.activeFilters;
                };
                throw new Error("Failed pattern match at ReferenceView (line 383, column 11 - line 385, column 46): " + [ previousProjectFilter.constructor.name ]);
            })();
            return setActiveComponentFilters(Data_Array.snoc(prunedFilters)(new CostEstimation_Filters.FilterByComponentProjectOfOrigin(v14.selectedRefProjects)));
        })();
        var notTopicComponent = function (v24) {
            return Data_Eq.notEq(Types_Component.eqComponentType)(Types_Component.intToComponentType(v24.component.componentType))(Types_Component.TopicComponent.value);
        };
        TofuHooks.useEffect([ ReactHooksUtils.utf(v14.selectedRefProjects), ReactHooksUtils.utf(v9.value0) ])(function __do() {
            setRefProjectFilter();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        ComponentsViewUtils.handleCursorNavigation({
            selectComponentRelative: selectComponentRelative,
            selectComponent: selectComponent,
            isActive: !isPanel
        })();
        var tableRef = UseNullableRef.useNullableRef();
        UseSelectedComponentScrollPosition.useSelectedComponentScrollPosition(v21.value0.shouldScrollToSelection)(TacoTable_Types.rowHeightPixels(rowHeight))(selectedComponentIdx)(tableRef)();
        var v24 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        TofuHooks.useEffect([ v21.value0.components ])((function () {
            var match = Control_Bind.bind(Data_Maybe.bindMaybe)(v24.value0)(function (componentId) {
                return Data_Array.find(function (v25) {
                    return Data_Eq.eq(Types_Component.eqComponentId)(v25.component.id)(componentId);
                })(v21.value0.components);
            });
            return function __do() {
                (function () {
                    var $148 = Data_Maybe.isJust(match);
                    if ($148) {
                        selectComponent(v21.value0.shouldScrollToSelection)(match)();
                        return v24.value1(function (v25) {
                            return Data_Maybe.Nothing.value;
                        })();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var selectedRows = ReactHooksUtils.memoizeFn2(Data_Array.filter)(ComponentsViewUtils.selected)(v21.value0.components)();
        var v25 = TofuHooks.useState("")();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(v25.value0) ])(500)(function __do() {
            v21.value1(function (s) {
                var notSearch = function (x) {
                    if (x instanceof CostEstimation_Filters.FilterByComponentSearch) {
                        return false;
                    };
                    return true;
                };
                var removedSearch = Data_Array.filter(notSearch)(s.activeFilters);
                var addedSearch = (function () {
                    var $152 = Data_String_Common["null"](v25.value0);
                    if ($152) {
                        return removedSearch;
                    };
                    return Data_Array.snoc(removedSearch)(new CostEstimation_Filters.FilterByComponentSearch(v25.value0));
                })();
                return {
                    selectedComponentIdx: s.selectedComponentIdx,
                    checkedComponents: s.checkedComponents,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    referenceSortingCriteria: s.referenceSortingCriteria,
                    components: s.components,
                    unsortedComponents: s.unsortedComponents,
                    activeFilters: addedSearch,
                    lastCheckedItem: s.lastCheckedItem,
                    addComponentModalOpen: s.addComponentModalOpen,
                    updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var v26 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-ref-show-project-details")(true)(false)();
        var componentControls = Box.box("controlsWrapper")([ new Box.FlexGrow(1), new Box.Padding(TacoConstants.S.value) ])([ CostEstimation_ViewControls.controls({
            projectId: props.projectId,
            project: props.project,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            programType: props.project.programType,
            activeComponentFilters: v21.value0.activeFilters,
            components: v21.value0.components,
            showSocialExpensesInCosts: v.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: !isPanel,
            showWorkAchievement: v1.showWorkAchievement,
            showWorkAchievementToggleControl: !isPanel,
            showTargetCostGroupControl: !isPanel,
            showTargetCostGroup: !isPanel,
            addMemoToExcel: false,
            onAddMemoToExcelToggle: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            searchValue: v25.value0,
            setSearchValue: v25.value1,
            onSocialExpensesToggle: v.onSocialExpensesToggle,
            onWorkAchievementToggle: v1.onWorkAchievementToggle,
            showCostClassColumnsControl: !isPanel,
            showCostClassColumns: showCostClassColumns,
            showCostClassTotalPrice: v2.value0,
            showCostClassUnitPrice: v3.value0,
            showCostClassHoursPerUnit: v4.value0,
            setShowCostClassTotalPrice: v2.value1,
            setShowCostClassUnitPrice: v3.value1,
            setShowCostClassHoursPerUnit: v4.value1,
            costClasses: v7.value0.value,
            seletedShowCostClassCodes: v5.value0,
            setSeletedShowCostClassCodes: v5.value1,
            showHoursColumnsControl: !isPanel,
            showHoursColumns: v6.value0,
            setShowHoursColumns: v6.value1,
            onTargetCostGroupToggle: v20.onTargetCostGroupToggle,
            setActiveComponentFilters: setActiveComponentFilters,
            referenceModeFollowOptions: new Data_Maybe.Just({
                on: v22.value0,
                toggle: v22.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
                by: v23.value0,
                set: v23.value1,
                choices: referenceModeFollowOptionsByChoices,
                shortenReferenceProjectDetails: v26.value0,
                setShortenProjectDetails: v26.value1,
                refProjectsModal: v14.refProjectsModal,
                refProjectsModalButton: v14.refProjectsModalButton
            }),
            mkExport: Data_Maybe.Nothing.value,
            mkQuantityExport: Data_Maybe.Nothing.value,
            mkQuantityByLocationExport: Data_Maybe.Nothing.value,
            mkQuantityByCostClassExport: Data_Maybe.Nothing.value,
            mkComponentsExport: Data_Maybe.Nothing.value,
            mkExportPlanningCostGroupsExportParams: Data_Maybe.Nothing.value,
            groupBy: Data_Maybe.Nothing.value,
            toggleGroupBy: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showGroupByControls: false
        }) ]);
        var v27 = TacoLoader.useLoading(false)();
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.SmallMode.value,
            localStorageKey: "ComponentsReferenceView"
        })();
        var onToggleSelectAll = v21.value1(function (s) {
            var newCheckedComponents = (function () {
                var $155 = Data_Set.isEmpty(s.checkedComponents);
                if ($155) {
                    return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(Data_Functor.map(Data_Functor.functorArray)(ComponentsViewUtils.ecwrComponentId)(v21.value0.components));
                };
                return Data_Set.empty;
            })();
            return {
                selectedComponentIdx: s.selectedComponentIdx,
                checkedComponents: newCheckedComponents,
                shouldScrollToSelection: s.shouldScrollToSelection,
                referenceSortingCriteria: s.referenceSortingCriteria,
                components: s.components,
                unsortedComponents: s.unsortedComponents,
                activeFilters: s.activeFilters,
                lastCheckedItem: s.lastCheckedItem,
                addComponentModalOpen: s.addComponentModalOpen,
                updateSelectedComponentIdxOnTick: s.updateSelectedComponentIdxOnTick
            };
        });
        var isSelectionEmpty = Data_Array["null"](selectedRows);
        var initialScrollRow = (function () {
            if (props.initialSelectedComponent instanceof Data_Maybe.Nothing) {
                return v19.value0.selectedComponentIdx;
            };
            if (props.initialSelectedComponent instanceof Data_Maybe.Just) {
                var cs = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(v21.value0.components);
                var idx = Data_Array.findIndex(function (x) {
                    return Data_Eq.eq(Types_Component.eqComponentId)(props.initialSelectedComponent.value0)(x.component.id);
                })(cs);
                return idx;
            };
            throw new Error("Failed pattern match at ReferenceView (line 561, column 24 - line 567, column 12): " + [ props.initialSelectedComponent.constructor.name ]);
        })();
        var copySelectedComponentsToOtherProject = function (copyOperationType) {
            var v28 = Data_Array.head(v12.value0);
            if (v28 instanceof Data_Maybe.Just) {
                return appDispatch(CostEstimation_CopyComponents.mkCopyComponentsAction({
                    componentIds: CostEstimation_CopyComponents.selectedECWRComponentIds(v21.value0.components),
                    copyOperationType: copyOperationType,
                    targetProjectId: props.projectId,
                    targetSubprojectId: v28.value0.id,
                    resultHandler: function (v29) {
                        return unselectAllComponents;
                    }
                }));
            };
            if (v28 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ReferenceView (line 624, column 7 - line 633, column 29): " + [ v28.constructor.name ]);
        };
        var componentsTable$prime = EstimationComponentsTable.table({
            projectId: props.projectId,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            isPanel: isPanel,
            selectedEditViewElement: Control_Bind.bind(Data_Maybe.bindMaybe)(props.panelOptions)(function (v28) {
                return v28.selectedEditViewElement;
            }),
            selectedComponent: selectedComponent,
            selectedComponentIdx: selectedComponentIdx,
            sortedComponents: v21.value0.components,
            sortingCriteria: v21.value0.referenceSortingCriteria,
            rowHeight: rowHeight,
            socialExpensePercentagesByCostClass: v.socialExpensePercentagesByCostClass,
            costClasses: v7.value0.value,
            showCostClassColumns: showCostClassColumns,
            showCostClassTotalPrice: v2.value0,
            showCostClassUnitPrice: v3.value0,
            showCostClassHoursPerUnit: v4.value0,
            seletedShowCostClassCodes: v5.value0,
            showHoursColumns: v6.value0,
            showSocialExpensesInCosts: v.showSocialExpensesInCosts,
            showTargetCostGroup: v20.showTargetCostGroup,
            containerRef: tableRef,
            setSortingCriteria: setSortingCriteria,
            selectComponent: selectComponent,
            toggleAreaOfCheckboxes: toggleAreaOfCheckboxes,
            toggleCheckbox: toggleCheckbox,
            shortenReferenceProjectDetails: v26.value0,
            setNewComponentId: v24.value1,
            onShowAddComponentModal: onShowAddComponentModal,
            addComponentModalOpen: v21.value0.addComponentModalOpen,
            onDeleteSelectedComponents: function __do() {
                dispatchEstimationComponentAction(appDispatch)({
                    projectId: props.projectId,
                    params: Types_Estimation.DeleteEstimationComponents.create(CostEstimation_CopyComponents.selectedECWRComponentIds(v21.value0.components)),
                    handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                })();
                return unselectAllComponents();
            },
            selectedRows: selectedEstimationComponentsWithResources(v21.value0.components),
            projectFullName: Data_Newtype.un()(Types_Project.ProjectCode)(props.project.code) + (" " + props.project.name),
            copySelectedComponentsToOtherProject: copySelectedComponentsToOtherProject,
            selectedInputColumn: Data_Maybe.Nothing.value,
            setSelectedInputColumn: function (v28) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            selectionProps: {
                onToggleSelectAll: onToggleSelectAll,
                isSelectionEmpty: isSelectionEmpty
            },
            dixonOptions: Data_Maybe.Nothing.value,
            showSplitComponentWithMissingResourcesColumn: false,
            initialScrollRow: initialScrollRow,
            projectCurrency: props.project.currency
        });
        var componentsTable = (function () {
            var $160 = Data_Array["null"](v14.selectedRefProjects);
            if ($160) {
                return v14.emptySelectedRefProjectsButton;
            };
            return componentsTable$prime;
        })();
        var componentSplitParams = Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.panelOptions)(function (r) {
            if (r.selectedEditViewElementId instanceof Data_Maybe.Just && (r.selectedEditViewElementSpecId instanceof Data_Maybe.Just && r.selectedEditViewComponentId instanceof Data_Maybe.Just)) {
                return new Data_Maybe.Just({
                    elementId: r.selectedEditViewElementId.value0,
                    elementSpecId: r.selectedEditViewElementSpecId.value0,
                    componentId: r.selectedEditViewComponentId.value0
                });
            };
            return Data_Maybe.Nothing.value;
        }));
        var componentDetails = (function () {
            if (loading) {
                return Data_Maybe.Nothing.value;
            };
            if (selectedComponent instanceof Data_Maybe.Just && notTopicComponent(selectedComponent.value0)) {
                return Data_Maybe.Just.create(CostEstimation_SelectedComponentDetails.selectedComponentDetails({
                    updateStatuses: props.updateStatuses,
                    selectedComponent: selectedComponent.value0,
                    projectId: props.projectId,
                    project: props.project,
                    viewMode: Types_CostEstimation.ReferenceMode.value,
                    programType: props.project.programType,
                    isReportingProject: props.project.isReportingProject,
                    socialExpensePercentagesByCostClass: v.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v.showSocialExpensesInCosts,
                    showWorkAchievement: v1.showWorkAchievement,
                    buildingDescriptionBySpecId: buildingDescriptionBySpecId,
                    selectComponent: selectComponent,
                    toggleCheckbox: toggleCheckbox,
                    closeDetails: selectComponent(v21.value0.shouldScrollToSelection)(Data_Maybe.Nothing.value),
                    referencePanelOptions: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.panelOptions)(function (r) {
                        return {
                            selectedEditViewComponentId: r.selectedEditViewComponentId,
                            selectedEditViewElementSpecId: r.selectedEditViewElementSpecId,
                            selectedEditViewElementId: r.selectedEditViewElementId
                        };
                    }),
                    componentSplitParams: componentSplitParams,
                    setParentSelectedResourceIds: Data_Maybe.Nothing.value,
                    setPanelMode: panelProps.setDetailsPanelMode,
                    panelMode: panelProps.detailsPanelMode,
                    hideResources: false
                }));
            };
            return Data_Maybe.Nothing.value;
        })();
        return React_Basic.fragment([ CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(componentControls),
            controlsLoading: (function () {
                if (loading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            leftPanel: Data_Maybe.Nothing.value,
            leftSidebar: Data_Maybe.Nothing.value,
            main: componentsTable,
            rightPanel: Data_Maybe.Nothing.value,
            rightSidebar: Data_Maybe.Nothing.value,
            details: componentDetails,
            panelProps: panelProps,
            leftPanelLoading: (function () {
                if (v27.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: mRefPanelMode
        }) ]);
    };
});
module.exports = {
    component: component
};
