const currencyToLocale = {
  EUR: "fi-FI",
  SEK: "sv-SE",
  NOK: "nb-NO",
  DKK: "da-DK",
  GBP: "en-GB",
  USD: "en-US",
};

const makeCurrencyFormatter = (locale) => (currency) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const currencyFormatters = Object.fromEntries(
  Object.entries(currencyToLocale).map(([currency, locale]) => [
    currency,
    makeCurrencyFormatter(locale)(currency),
  ])
);

const makeCurrencyFormatterWithoutDecimals = (locale) => (currency) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const currencyFormattersWithoutDecimals = Object.fromEntries(
  Object.entries(currencyToLocale).map(([currency, locale]) => [
    currency,
    makeCurrencyFormatterWithoutDecimals(locale)(currency),
  ])
);

const decimal0Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const decimal1Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const decimal2Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const decimal3Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

const dateFormatter = new Intl.DateTimeFormat("fi-FI", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

const dateTimeFormatter = new Intl.DateTimeFormat("fi-FI", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

exports._formatCurrencyValue = (currency) => (value) => {
  if (currency in currencyFormatters) {
    return currencyFormatters[currency].format(value);
  } else {
    return currencyFormatters["EUR"].format(value);
  }
};

exports._formatCurrencyValueWithoutDecimals = (currency) => (value) => {
  if (currency in currencyFormatters) {
    return currencyFormattersWithoutDecimals[currency].format(value);
  } else {
    return currencyFormattersWithoutDecimals["EUR"].format(value);
  }
};

exports._formatDecimal0Value = (value) => decimal0Formatter.format(value);

exports._formatDecimal1Value = (value) => decimal1Formatter.format(value);

exports._formatDecimal2Value = (value) => decimal2Formatter.format(value);

exports._formatDecimal3Value = (value) => decimal3Formatter.format(value);

exports._formatDecimalNValue = (precision, value) => {
  switch (precision) {
    case 0:
      return decimal0Formatter.format(value);
    case 1:
      return decimal1Formatter.format(value);
    case 2:
      return decimal2Formatter.format(value);
    case 3:
      return decimal3Formatter.format(value);
    default:
      console.error("Error: could not format unknown precision", precision);
      return decimal3Formatter.format(value);
  }
};

exports._formatDateValue = (date) => {
  if (isFinite(date)) {
    return dateFormatter.format(date);
  } else {
    return "";
  }
};

exports._formatDateStringValue = (str) => {
  if (str) {
    return dateFormatter.format(new Date(str));
  } else {
    return "";
  }
};

exports._formatDateTimeValue = (date) => {
  if (isFinite(date)) {
    return dateTimeFormatter.format(date);
  } else {
    return "";
  }
};

exports._formatDateTimeStringValue = (str) => {
  if (str) {
    return dateTimeFormatter.format(new Date(str));
  } else {
    return "";
  }
};

const longDateTimeFormatter = new Intl.DateTimeFormat("fi-FI", {
  dateStyle: "full",
  timeStyle: "long",
});

exports.formatDateTime = (x) => longDateTimeFormatter.format(x);
