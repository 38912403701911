// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var LocationTreeNode = function (x) {
    return x;
};
var Flags = (function () {
    function Flags() {

    };
    Flags.value = new Flags();
    return Flags;
})();
var ProjectOfOrigin = (function () {
    function ProjectOfOrigin() {

    };
    ProjectOfOrigin.value = new ProjectOfOrigin();
    return ProjectOfOrigin;
})();
var Group = (function () {
    function Group() {

    };
    Group.value = new Group();
    return Group;
})();
var CostGroup = (function () {
    function CostGroup() {

    };
    CostGroup.value = new CostGroup();
    return CostGroup;
})();
var Class = (function () {
    function Class() {

    };
    Class.value = new Class();
    return Class;
})();
var Code = (function () {
    function Code() {

    };
    Code.value = new Code();
    return Code;
})();
var Description = (function () {
    function Description() {

    };
    Description.value = new Description();
    return Description;
})();
var Amount = (function () {
    function Amount() {

    };
    Amount.value = new Amount();
    return Amount;
})();
var Unit = (function () {
    function Unit() {

    };
    Unit.value = new Unit();
    return Unit;
})();
var SubProjectCode = (function () {
    function SubProjectCode() {

    };
    SubProjectCode.value = new SubProjectCode();
    return SubProjectCode;
})();
var UnitPrice = (function () {
    function UnitPrice() {

    };
    UnitPrice.value = new UnitPrice();
    return UnitPrice;
})();
var TotalPrice = (function () {
    function TotalPrice() {

    };
    TotalPrice.value = new TotalPrice();
    return TotalPrice;
})();
var ComponentsMode = (function () {
    function ComponentsMode() {

    };
    ComponentsMode.value = new ComponentsMode();
    return ComponentsMode;
})();
var BuildingElementsMode = (function () {
    function BuildingElementsMode() {

    };
    BuildingElementsMode.value = new BuildingElementsMode();
    return BuildingElementsMode;
})();
var ResourcesMode = (function () {
    function ResourcesMode() {

    };
    ResourcesMode.value = new ResourcesMode();
    return ResourcesMode;
})();
var EditEstimationProjectMode = (function () {
    function EditEstimationProjectMode() {

    };
    EditEstimationProjectMode.value = new EditEstimationProjectMode();
    return EditEstimationProjectMode;
})();
var ResourcePriceListMode = (function () {
    function ResourcePriceListMode() {

    };
    ResourcePriceListMode.value = new ResourcePriceListMode();
    return ResourcePriceListMode;
})();
var DixonMode = (function () {
    function DixonMode() {

    };
    DixonMode.value = new DixonMode();
    return DixonMode;
})();
var OfferPageMode = (function () {
    function OfferPageMode() {

    };
    OfferPageMode.value = new OfferPageMode();
    return OfferPageMode;
})();
var PrintingMode = (function () {
    function PrintingMode() {

    };
    PrintingMode.value = new PrintingMode();
    return PrintingMode;
})();
var ColSelectionCheckbox = (function () {
    function ColSelectionCheckbox() {

    };
    ColSelectionCheckbox.value = new ColSelectionCheckbox();
    return ColSelectionCheckbox;
})();
var ColFlags = (function () {
    function ColFlags() {

    };
    ColFlags.value = new ColFlags();
    return ColFlags;
})();
var ColProject = (function () {
    function ColProject() {

    };
    ColProject.value = new ColProject();
    return ColProject;
})();
var ColSubProjectCode = (function () {
    function ColSubProjectCode() {

    };
    ColSubProjectCode.value = new ColSubProjectCode();
    return ColSubProjectCode;
})();
var ColGroup = (function () {
    function ColGroup() {

    };
    ColGroup.value = new ColGroup();
    return ColGroup;
})();
var ColCostGroup = (function () {
    function ColCostGroup() {

    };
    ColCostGroup.value = new ColCostGroup();
    return ColCostGroup;
})();
var ColClass = (function () {
    function ColClass() {

    };
    ColClass.value = new ColClass();
    return ColClass;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColDescription = (function () {
    function ColDescription() {

    };
    ColDescription.value = new ColDescription();
    return ColDescription;
})();
var ColAmount = (function () {
    function ColAmount() {

    };
    ColAmount.value = new ColAmount();
    return ColAmount;
})();
var ColUnit = (function () {
    function ColUnit() {

    };
    ColUnit.value = new ColUnit();
    return ColUnit;
})();
var ColUnitPrice = (function () {
    function ColUnitPrice() {

    };
    ColUnitPrice.value = new ColUnitPrice();
    return ColUnitPrice;
})();
var ColUnitForUnitPrice = (function () {
    function ColUnitForUnitPrice() {

    };
    ColUnitForUnitPrice.value = new ColUnitForUnitPrice();
    return ColUnitForUnitPrice;
})();
var ColTotalPrice = (function () {
    function ColTotalPrice() {

    };
    ColTotalPrice.value = new ColTotalPrice();
    return ColTotalPrice;
})();
var ColPriceUnit = (function () {
    function ColPriceUnit() {

    };
    ColPriceUnit.value = new ColPriceUnit();
    return ColPriceUnit;
})();
var ColCostClassTotalPrice = (function () {
    function ColCostClassTotalPrice(value0) {
        this.value0 = value0;
    };
    ColCostClassTotalPrice.create = function (value0) {
        return new ColCostClassTotalPrice(value0);
    };
    return ColCostClassTotalPrice;
})();
var ColCostClassUnitPrice = (function () {
    function ColCostClassUnitPrice(value0) {
        this.value0 = value0;
    };
    ColCostClassUnitPrice.create = function (value0) {
        return new ColCostClassUnitPrice(value0);
    };
    return ColCostClassUnitPrice;
})();
var ColCostClassHoursPerUnit = (function () {
    function ColCostClassHoursPerUnit(value0) {
        this.value0 = value0;
    };
    ColCostClassHoursPerUnit.create = function (value0) {
        return new ColCostClassHoursPerUnit(value0);
    };
    return ColCostClassHoursPerUnit;
})();
var ColSplitComponentWithMissingResources = (function () {
    function ColSplitComponentWithMissingResources() {

    };
    ColSplitComponentWithMissingResources.value = new ColSplitComponentWithMissingResources();
    return ColSplitComponentWithMissingResources;
})();
var ColHours = (function () {
    function ColHours() {

    };
    ColHours.value = new ColHours();
    return ColHours;
})();
var ColHourlyRate = (function () {
    function ColHourlyRate() {

    };
    ColHourlyRate.value = new ColHourlyRate();
    return ColHourlyRate;
})();
var SelectComponent = (function () {
    function SelectComponent(value0) {
        this.value0 = value0;
    };
    SelectComponent.create = function (value0) {
        return new SelectComponent(value0);
    };
    return SelectComponent;
})();
var SelectComponentRelative = (function () {
    function SelectComponentRelative(value0) {
        this.value0 = value0;
    };
    SelectComponentRelative.create = function (value0) {
        return new SelectComponentRelative(value0);
    };
    return SelectComponentRelative;
})();
var SelectWithAClassificationCode = (function () {
    function SelectWithAClassificationCode(value0) {
        this.value0 = value0;
    };
    SelectWithAClassificationCode.create = function (value0) {
        return new SelectWithAClassificationCode(value0);
    };
    return SelectWithAClassificationCode;
})();
var SetSortingCriteria = (function () {
    function SetSortingCriteria(value0) {
        this.value0 = value0;
    };
    SetSortingCriteria.create = function (value0) {
        return new SetSortingCriteria(value0);
    };
    return SetSortingCriteria;
})();
var SetComponentsList = (function () {
    function SetComponentsList(value0) {
        this.value0 = value0;
    };
    SetComponentsList.create = function (value0) {
        return new SetComponentsList(value0);
    };
    return SetComponentsList;
})();
var SetActiveComponentFilters = (function () {
    function SetActiveComponentFilters(value0) {
        this.value0 = value0;
    };
    SetActiveComponentFilters.create = function (value0) {
        return new SetActiveComponentFilters(value0);
    };
    return SetActiveComponentFilters;
})();
var ToggleCheckbox = (function () {
    function ToggleCheckbox(value0) {
        this.value0 = value0;
    };
    ToggleCheckbox.create = function (value0) {
        return new ToggleCheckbox(value0);
    };
    return ToggleCheckbox;
})();
var ToggleAreaOfCheckboxes = (function () {
    function ToggleAreaOfCheckboxes(value0) {
        this.value0 = value0;
    };
    ToggleAreaOfCheckboxes.create = function (value0) {
        return new ToggleAreaOfCheckboxes(value0);
    };
    return ToggleAreaOfCheckboxes;
})();
var ToggleAllCheckboxes = (function () {
    function ToggleAllCheckboxes() {

    };
    ToggleAllCheckboxes.value = new ToggleAllCheckboxes();
    return ToggleAllCheckboxes;
})();
var UnselectAllComponents = (function () {
    function UnselectAllComponents() {

    };
    UnselectAllComponents.value = new UnselectAllComponents();
    return UnselectAllComponents;
})();
var ShowCopyComponentsModal = (function () {
    function ShowCopyComponentsModal(value0) {
        this.value0 = value0;
    };
    ShowCopyComponentsModal.create = function (value0) {
        return new ShowCopyComponentsModal(value0);
    };
    return ShowCopyComponentsModal;
})();
var tofuJSONLocationTreeNode = {
    writeImpl: function (x) {
        return TofuJSON.writeImpl(tofuJSONLocationTreeNode)(x);
    },
    readImpl: function (x) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(TofuJSON.readImpl(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "children";
            }
        })(TofuJSON.readArray(TofuJSON.tofuJSONForeign))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "code";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "description";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "levels";
            }
        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "parsedCode";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "socialExpensePrice";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "sortingNumber";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "totalSum";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "children";
            }
        })(TofuJSON.readArray(TofuJSON.tofuJSONForeign))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "code";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "description";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "levels";
            }
        })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "parsedCode";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "socialExpensePrice";
            }
        })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "sortingNumber";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "totalSum";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()()))(x))(function (f) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))((function () {
                var $16 = Data_Array["null"](f.children);
                if ($16) {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))([  ]);
                };
                return Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(TofuJSON.readImpl(tofuJSONLocationTreeNode))(f.children);
            })())(function (children) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
                    parsedCode: f.parsedCode,
                    code: f.code,
                    levels: f.levels,
                    description: f.description,
                    sortingNumber: f.sortingNumber,
                    totalSum: f.totalSum,
                    socialExpensePrice: f.socialExpensePrice,
                    children: children
                });
            });
        });
    }
};
var newtypeLocationTreeNode = {
    Coercible0: function () {
        return undefined;
    }
};
var getNameECSortProperty = {
    getName: function (v) {
        return "ECSortProperty";
    }
};
var eqECSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Flags && y instanceof Flags) {
                return true;
            };
            if (x instanceof ProjectOfOrigin && y instanceof ProjectOfOrigin) {
                return true;
            };
            if (x instanceof Group && y instanceof Group) {
                return true;
            };
            if (x instanceof CostGroup && y instanceof CostGroup) {
                return true;
            };
            if (x instanceof Class && y instanceof Class) {
                return true;
            };
            if (x instanceof Code && y instanceof Code) {
                return true;
            };
            if (x instanceof Description && y instanceof Description) {
                return true;
            };
            if (x instanceof Amount && y instanceof Amount) {
                return true;
            };
            if (x instanceof Unit && y instanceof Unit) {
                return true;
            };
            if (x instanceof SubProjectCode && y instanceof SubProjectCode) {
                return true;
            };
            if (x instanceof UnitPrice && y instanceof UnitPrice) {
                return true;
            };
            if (x instanceof TotalPrice && y instanceof TotalPrice) {
                return true;
            };
            return false;
        };
    }
};
var ordECSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Flags && y instanceof Flags) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Flags) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Flags) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ProjectOfOrigin && y instanceof ProjectOfOrigin) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ProjectOfOrigin) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ProjectOfOrigin) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Group && y instanceof Group) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Group) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Group) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CostGroup && y instanceof CostGroup) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CostGroup) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CostGroup) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Class && y instanceof Class) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Class) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Class) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Code && y instanceof Code) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Code) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Code) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Description && y instanceof Description) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Description) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Description) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Amount && y instanceof Amount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Amount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Amount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Unit && y instanceof Unit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Unit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Unit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof SubProjectCode && y instanceof SubProjectCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof SubProjectCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof SubProjectCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UnitPrice && y instanceof UnitPrice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof UnitPrice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof UnitPrice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TotalPrice && y instanceof TotalPrice) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.Types (line 46, column 1 - line 46, column 56): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqECSortProperty;
    }
};
var eqECEstimationMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ComponentsMode && y instanceof ComponentsMode) {
                return true;
            };
            if (x instanceof BuildingElementsMode && y instanceof BuildingElementsMode) {
                return true;
            };
            if (x instanceof ResourcesMode && y instanceof ResourcesMode) {
                return true;
            };
            if (x instanceof EditEstimationProjectMode && y instanceof EditEstimationProjectMode) {
                return true;
            };
            if (x instanceof ResourcePriceListMode && y instanceof ResourcePriceListMode) {
                return true;
            };
            if (x instanceof DixonMode && y instanceof DixonMode) {
                return true;
            };
            if (x instanceof OfferPageMode && y instanceof OfferPageMode) {
                return true;
            };
            if (x instanceof PrintingMode && y instanceof PrintingMode) {
                return true;
            };
            return false;
        };
    }
};
var eqECColumn = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ColSelectionCheckbox && y instanceof ColSelectionCheckbox) {
                return true;
            };
            if (x instanceof ColFlags && y instanceof ColFlags) {
                return true;
            };
            if (x instanceof ColProject && y instanceof ColProject) {
                return true;
            };
            if (x instanceof ColSubProjectCode && y instanceof ColSubProjectCode) {
                return true;
            };
            if (x instanceof ColGroup && y instanceof ColGroup) {
                return true;
            };
            if (x instanceof ColCostGroup && y instanceof ColCostGroup) {
                return true;
            };
            if (x instanceof ColClass && y instanceof ColClass) {
                return true;
            };
            if (x instanceof ColCode && y instanceof ColCode) {
                return true;
            };
            if (x instanceof ColDescription && y instanceof ColDescription) {
                return true;
            };
            if (x instanceof ColAmount && y instanceof ColAmount) {
                return true;
            };
            if (x instanceof ColUnit && y instanceof ColUnit) {
                return true;
            };
            if (x instanceof ColUnitPrice && y instanceof ColUnitPrice) {
                return true;
            };
            if (x instanceof ColUnitForUnitPrice && y instanceof ColUnitForUnitPrice) {
                return true;
            };
            if (x instanceof ColTotalPrice && y instanceof ColTotalPrice) {
                return true;
            };
            if (x instanceof ColPriceUnit && y instanceof ColPriceUnit) {
                return true;
            };
            if (x instanceof ColCostClassTotalPrice && y instanceof ColCostClassTotalPrice) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0)(y.value0);
            };
            if (x instanceof ColCostClassUnitPrice && y instanceof ColCostClassUnitPrice) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0)(y.value0);
            };
            if (x instanceof ColCostClassHoursPerUnit && y instanceof ColCostClassHoursPerUnit) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.value0)(y.value0);
            };
            if (x instanceof ColSplitComponentWithMissingResources && y instanceof ColSplitComponentWithMissingResources) {
                return true;
            };
            if (x instanceof ColHours && y instanceof ColHours) {
                return true;
            };
            if (x instanceof ColHourlyRate && y instanceof ColHourlyRate) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    ComponentsMode: ComponentsMode,
    BuildingElementsMode: BuildingElementsMode,
    ResourcesMode: ResourcesMode,
    EditEstimationProjectMode: EditEstimationProjectMode,
    ResourcePriceListMode: ResourcePriceListMode,
    DixonMode: DixonMode,
    OfferPageMode: OfferPageMode,
    PrintingMode: PrintingMode,
    Flags: Flags,
    ProjectOfOrigin: ProjectOfOrigin,
    Group: Group,
    CostGroup: CostGroup,
    Class: Class,
    Code: Code,
    Description: Description,
    Amount: Amount,
    Unit: Unit,
    SubProjectCode: SubProjectCode,
    UnitPrice: UnitPrice,
    TotalPrice: TotalPrice,
    ColSelectionCheckbox: ColSelectionCheckbox,
    ColFlags: ColFlags,
    ColProject: ColProject,
    ColSubProjectCode: ColSubProjectCode,
    ColGroup: ColGroup,
    ColCostGroup: ColCostGroup,
    ColClass: ColClass,
    ColCode: ColCode,
    ColDescription: ColDescription,
    ColAmount: ColAmount,
    ColUnit: ColUnit,
    ColUnitPrice: ColUnitPrice,
    ColUnitForUnitPrice: ColUnitForUnitPrice,
    ColTotalPrice: ColTotalPrice,
    ColPriceUnit: ColPriceUnit,
    ColCostClassTotalPrice: ColCostClassTotalPrice,
    ColCostClassUnitPrice: ColCostClassUnitPrice,
    ColCostClassHoursPerUnit: ColCostClassHoursPerUnit,
    ColSplitComponentWithMissingResources: ColSplitComponentWithMissingResources,
    ColHours: ColHours,
    ColHourlyRate: ColHourlyRate,
    LocationTreeNode: LocationTreeNode,
    SelectComponent: SelectComponent,
    SelectComponentRelative: SelectComponentRelative,
    SelectWithAClassificationCode: SelectWithAClassificationCode,
    SetSortingCriteria: SetSortingCriteria,
    SetComponentsList: SetComponentsList,
    SetActiveComponentFilters: SetActiveComponentFilters,
    ToggleCheckbox: ToggleCheckbox,
    ToggleAreaOfCheckboxes: ToggleAreaOfCheckboxes,
    ToggleAllCheckboxes: ToggleAllCheckboxes,
    UnselectAllComponents: UnselectAllComponents,
    ShowCopyComponentsModal: ShowCopyComponentsModal,
    eqECEstimationMode: eqECEstimationMode,
    eqECSortProperty: eqECSortProperty,
    ordECSortProperty: ordECSortProperty,
    getNameECSortProperty: getNameECSortProperty,
    eqECColumn: eqECColumn,
    newtypeLocationTreeNode: newtypeLocationTreeNode,
    tofuJSONLocationTreeNode: tofuJSONLocationTreeNode
};
