// Generated by purs version 0.14.5
"use strict";
var AddElement_Styles = require("../AddElement.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Element = require("../Types.Element/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColQuantity = (function () {
    function ColQuantity() {

    };
    ColQuantity.value = new ColQuantity();
    return ColQuantity;
})();
var ColPrice = (function () {
    function ColPrice() {

    };
    ColPrice.value = new ColPrice();
    return ColPrice;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var columns = [ {
    key: "code",
    label: React_Basic_DOM.text("Luokka"),
    width: new TacoTable_Types.Flex(1),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $19 = Data_Newtype.un()(Types_Element.ElementCode);
        var $20 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        return function ($21) {
            return $19((function (v) {
                return v.code;
            })($20($21)));
        };
    })()),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "description",
    label: React_Basic_DOM.text("Luokan nimi"),
    width: new TacoTable_Types.Fixed("14rem"),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $22 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        return function ($23) {
            return (function (v) {
                return v.description;
            })($22($23));
        };
    })()),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "rtCode",
    label: React_Basic_DOM.text("Koodi"),
    width: new TacoTable_Types.Flex(1),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $24 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        return function ($25) {
            return (function (v) {
                return v.rtCode;
            })($24($25));
        };
    })()),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "rtDescription",
    label: React_Basic_DOM.text("Selite"),
    width: new TacoTable_Types.Fixed("40rem"),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $26 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        return function ($27) {
            return (function (v) {
                return v.rtDescription;
            })($26($27));
        };
    })()),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "unit",
    label: React_Basic_DOM.text("Yksikk\xf6"),
    width: new TacoTable_Types.Flex(1),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $28 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
        return function ($29) {
            return (function (v) {
                return v.unit;
            })($28($29));
        };
    })()),
    headerJSX: Data_Maybe.Nothing.value
} ];
var mkRow = function (v) {
    return {
        className: v.className,
        rowData: v.rowData,
        foldingElement: Data_Maybe.Nothing.value,
        onClick: v.handleClick,
        rowKey: v.rowKey,
        columns: columns
    };
};
var getRow = function (selectedElementId) {
    return function (handleClick) {
        return function (v) {
            return function (r) {
                var selectionClass = function (v1) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return "";
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        var $15 = Data_Eq.eq(Types_Element.eqElementId)(v1.value0)((Data_Newtype.un()(Types_Element.WasmEstimationElement)(r)).elementId);
                        if ($15) {
                            return "selected";
                        };
                        return "";
                    };
                    throw new Error("Failed pattern match at AddBuildingElementTable (line 61, column 5 - line 61, column 32): " + [ v1.constructor.name ]);
                };
                var className = TacoTable_Cells.classNames([ "AddElementTableRow", selectionClass(selectedElementId) ]);
                return mkRow({
                    className: className,
                    rowData: r,
                    rowKey: "add-element-" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Element.ElementId)((function (v1) {
                        return v1.elementId;
                    })(Data_Newtype.un()(Types_Element.WasmEstimationElement)(r)))),
                    handleClick: handleClick
                });
            };
        };
    };
};
var addElementTableOptimized = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_Element.eqWasmElement);
var addElementTable = TofuHooks.mkHookComponent("AddElementTable")(function (props) {
    var selectedElement = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(props.elements))(props.selectedRefElementIdx);
    var selectedElementId = (function () {
        if (selectedElement instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        if (selectedElement instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just((Data_Newtype.un()(Types_Element.WasmEstimationElement)(selectedElement.value0)).elementId);
        };
        throw new Error("Failed pattern match at AddBuildingElementTable (line 84, column 27 - line 86, column 96): " + [ selectedElement.constructor.name ]);
    })();
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__AddElementTableWrapper",
            css: AddElement_Styles.addElementTableWrapperStyles,
            children: [ addElementTableOptimized({
                sortProperty: Data_Maybe.Nothing.value,
                onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                sortingOrder: TacoTable_Types.Ascending.value,
                rows: props.elements,
                rowHeight: TacoTable_Types.RowSmall.value,
                containerRef: tableRef,
                mkRow: getRow(selectedElementId)(props.addRefElement),
                showHeader: true,
                columns: columns,
                styleSet: styleSet,
                initialScrollRow: Data_Maybe.Nothing.value
            }) ]
        });
    };
});
module.exports = {
    addElementTableOptimized: addElementTableOptimized,
    styleSet: styleSet,
    mkRow: mkRow,
    ColName: ColName,
    ColQuantity: ColQuantity,
    ColPrice: ColPrice,
    getRow: getRow,
    columns: columns,
    addElementTable: addElementTable
};
