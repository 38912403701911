export const getCurrencyToLocale = (currency: string | null | undefined) => {
  if (currency !== "EUR" && currency !== "SEK") {
    console.warn("Currency not supported, defaulting to EUR");
    return getCurrencySymbol(defaultCurrency);
  }
  return getCurrencySymbol(currency);
};
const getCurrencySymbol = (currency: keyof typeof currencyToLocale) => {
  const numberFormat = new Intl.NumberFormat(currencyToLocale[currency], {
    style: "currency",
    currency,
  });
  return numberFormat.formatToParts(1).find((part) => part.type === "currency")
    ?.value;
};

export const currencyToLocale = {
  EUR: "fi-FI",
  SEK: "sv-SE",
  NOK: "nb-NO",
  DKK: "da-DK",
  GBP: "en-GB",
  USD: "en-US",
};

export const defaultCurrency: keyof typeof currencyToLocale = "EUR";
