import { Button, FileSelect, Header, Modal, TabProps, Tabs } from "@tocoman/ui";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { CostClassesTab } from "../../ProjectDetailsModal/CostClassesTab";
import { MeasurementUnitsTab } from "../../ProjectDetailsModal/MeasurementUnitsTab";
import { ProjectDataForm } from "./ProjectDataForm";
import { useFileChange } from "./useFileChange";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { CostClass } from "../../../../../../ts-bindings/CostClass";
import { Subproject } from "../../../../../../ts-bindings/Subproject";
import { useUploadTransportFile } from "./useUploadTransportFile";
import {
  ModifiedFields,
  NewCostClass,
  NewMeasurement,
  NewMeasurementValue,
  NewSubproject,
} from "src/server-ts/import-file/transportFile";
import { reportError } from "../../../../utils/error";
import { FeatureFlagContainer } from "../../../../split/FeatureFlagContainer";
import { FeatureFlags } from "../../../../split/FeatureFlags";
import { useCcFromClassification } from "./useCcFromClassification";
import { useWorkerAction } from "../../../../hooks/useWorkerAction";
import { useOnEq } from "../../../../hooks/useOnEq";
import { mkPostTransportFileAction } from "../../../../actions/project";
import { useFeatureEnabled } from "../../../../split/useFeatureEnabled";
import { useMeasurementsFromClassification } from "./useMeasurementsFromClassification";
import { useEvaluateCcDiffs } from "./useEvaluateCcDiffs";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import { useSetNextAvailableVersion } from "../../../../hooks/useSetNextAvailableVersion";

type TransportFileUploadModalProps = {
  closeModal: () => void;
  isOpen: boolean;
};

export type TransportFileFormData = {
  name: string;
  code: string;
  version: string;
  projectGroup: string;
  classification: string;
  fileStr: string;
  currency: string;
};

export type TransportFileData = TransportFileFormData & {
  newCostClasses: NewCostClass[];
  newMeasurements: NewMeasurement[];
  newMeasurementValues: NewMeasurementValue[];
  newSubprojects: NewSubproject[];
};

export function TransportFileUploadModal({
  isOpen,
  closeModal,
}: TransportFileUploadModalProps) {
  const { t } = useTranslation("costControl", {
    keyPrefix: "projectList.transportFileModal",
  });
  const [files, setFiles] = useState<File[]>([]);

  const [selectedTab, setSelectedTab] = useState("projectData");

  const methods = useForm<TransportFileFormData>({
    defaultValues: {
      version: "",
    },
  });
  const { handleSubmit, reset, watch, setValue } = methods;

  const [measurementUnits, setMeasurementUnits] = useState<Measurement[]>([]);
  useMeasurementsFromClassification(
    watch("classification"),
    setMeasurementUnits,
    measurementUnits
  );

  const [subprojects, setSubprojects] = useState<Subproject[]>([]);

  const [fileCostClasses, setFileCostClasses] = useState<CostClass[]>([]);
  const advancedImportModalEnabled = useFeatureEnabled(
    FeatureFlags.ADVANCED_IMPORT_MODAL
  );

  const [costClasses, setCostClasses] = useState<CostClass[]>([]);
  useCcFromClassification(watch("classification"), setCostClasses);

  const [criticalCcErrors, ccDiffs] = useEvaluateCcDiffs(
    fileCostClasses,
    costClasses
  );

  const [fileCurrency, setFileCurrency] = useState<string>("");

  const [transportFileStatus, handleSaveTransportFile] = useWorkerAction(
    "PostTransportFileActionState",
    (
      name: string,
      code: string,
      version: string,
      currency: string,
      file: string
    ) => {
      return mkPostTransportFileAction({
        name,
        code,
        version,
        currency,
        file,
      });
    }
  );
  useOnEq(transportFileStatus, "Resolved", () => closeModal());

  const {
    setNextAvailableVersion,
    versionLoading,
  } = useSetNextAvailableVersion(setValue);
  const createTransportFile = useUploadTransportFile();

  const onFilesChanged = useFileChange(
    setCostClasses,
    setFileCostClasses,
    setMeasurementUnits,
    setSubprojects,
    setFiles,
    reset,
    setNextAvailableVersion,
    setFileCurrency
  );

  const onSubmit = useCallback(
    async (value: TransportFileFormData) => {
      const {
        name,
        code,
        version,
        currency,
        projectGroup,
        classification,
        fileStr,
      } = value;
      const modifiedFields = mapModifiedFields(
        projectGroup,
        classification,
        costClasses,
        measurementUnits
      );
      const params = {
        name,
        code,
        version,
        currency,
        file: fileStr,
        modifiedFields,
      };

      try {
        if (advancedImportModalEnabled) {
          await createTransportFile.mutateAsync(params);
          closeModal();
        } else {
          handleSaveTransportFile(name, code, version, currency, fileStr);
        }
      } catch (e) {
        reportError(t`errors.transportFileError`, e as Error);
      }
    },
    [
      costClasses,
      measurementUnits,
      closeModal,
      advancedImportModalEnabled,
      createTransportFile,
      handleSaveTransportFile,
      t,
    ]
  );

  const actions = (
    <div className="ml-auto flex gap-2">
      {criticalCcErrors && (
        <div className={"mt-2 mr-32"}>
          <ErrorMessage errorMessage={t`errors.ccDiffError`} />
        </div>
      )}
      <Button variant="text" label={t`cancel`} onClick={closeModal} />
      <Button
        label={t`confirm`}
        onClick={handleSubmit(onSubmit)}
        loading={
          createTransportFile.status === "loading" ||
          transportFileStatus === "Pending"
        }
        disabled={
          createTransportFile.status === "loading" ||
          files.length === 0 ||
          transportFileStatus === "Pending" ||
          criticalCcErrors
        }
      />
    </div>
  );

  const tabs: TabProps<
    "projectData" | "costClasses" | "measurementUnits"
  >[] = useMemo(
    () => [
      { id: "projectData", color: "components", label: t`tabs.projectData` },
      { id: "costClasses", color: "components", label: t`tabs.costClasses` },
      {
        id: "measurementUnits",
        color: "components",
        label: t`tabs.measurementUnits`,
      },
    ],
    [t]
  );

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title={t`title`}
        actions={actions}
        width={800}
        className={"box-border p-3 max-w-[512px] "}
        loading={versionLoading}
      >
        <Header>{t`description` && <p>{t`description`}</p>}</Header>
        <FileSelect
          buttonLabel={t`browseButton`}
          dragDropLabel={t`dragDrop`}
          files={files}
          onFilesChanged={onFilesChanged}
          className="w-full box-border"
          testId={"file-select"}
        />
        <FeatureFlagContainer feature={FeatureFlags.ADVANCED_IMPORT_MODAL}>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            matchParentWidth
          />
        </FeatureFlagContainer>
        {selectedTab === "projectData" && (
          <ProjectDataForm fileCurrency={fileCurrency} />
        )}
        {selectedTab === "costClasses" && (
          <CostClassesTab
            costClasses={costClasses}
            onChange={setCostClasses}
            ccDiffs={ccDiffs}
            fileCcCount={fileCostClasses.length}
          />
        )}
        {selectedTab === "measurementUnits" && (
          <MeasurementUnitsTab
            measurementUnits={measurementUnits}
            onChange={setMeasurementUnits}
            subprojects={subprojects}
            transportFileMeasurements={true}
          />
        )}
      </Modal>
    </FormProvider>
  );
}

function mapModifiedFields(
  projectGroup: string,
  classification: string,
  costClasses: CostClass[],
  measurements: Measurement[]
): ModifiedFields {
  return {
    newClassification: classification,
    newProjectGroup: projectGroup,
    newCostClasses: costClasses,
    newMeasurements: measurements,
  };
}
