import { ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { safeDivide } from "../../../utils/safeDivide";
import { formatOfferValueToNumber } from "./OfferPageCommonFunctions";
import { TFunction } from "i18next";
import { AgGridReact } from "ag-grid-react";
import React from "react";

export function getBaseTotal(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  params.data.economicBaseTotal = safeDivide(
    params.data.baseTotal,
    1 - params.data.marginPercentage
  );
  return params.data.baseTotal;
}

export function getMarginPercentage(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  return params.data.marginPercentage;
}

export function setMarginPercentage(params: ValueSetterParams) {
  if (!params.data) {
    return false;
  }
  const newValue = formatOfferValueToNumber(params);
  if (isNaN(newValue) || params.oldValue === newValue) {
    return false;
  }
  const validNewValue = checkGivenPercentage(newValue);
  if (params.node?.isRowPinned()) {
    params.api.forEachNode((node) => {
      node.data.marginPercentage = validNewValue;
      node.data.margin = node.data.baseTotal * validNewValue;
      node.data.totalWithMargin = node.data.baseTotal + node.data.margin;
    });
    params.api.refreshCells({ force: true });
    return true;
  }

  params.data.marginPercentage = validNewValue;
  params.data.margin = params.data.baseTotal * validNewValue;
  if (params.context?.selectedMarginType === "economicMargin") {
    params.data.economicMargin = safeDivide(
      params.data.baseTotal,
      1 - validNewValue
    );
    params.data.economicMargin = params.data.economicBaseTotal * validNewValue;
    params.data.totalWithMargin =
      params.data.economicBaseTotal + params.data.economicMargin;
    return true;
  }
  params.data.totalWithMargin = params.data.baseTotal + params.data.margin;
  return true;
}

export function getMargin(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  params.data.economicMargin =
    params.data.economicBaseTotal * params.data.marginPercentage;
  params.data.margin = params.data.baseTotal * params.data.marginPercentage;
  if (params.context?.selectedMarginType === "economicMargin") {
    return params.data.economicMargin;
  }
  return params.data.margin;
}

export function setMargin(params: ValueSetterParams) {
  if (!params.data) {
    return false;
  }
  const newValue = formatOfferValueToNumber(params);
  if (isNaN(newValue) || params.oldValue === params.newValue) {
    return false;
  }
  if (params.node?.isRowPinned()) {
    params.node.data.margin = newValue;
    const newPercentage = safeDivide(newValue, params.node.data.baseTotal);
    const validNewPercentage = checkGivenPercentage(newPercentage);
    params.node.data.marginPercentage = validNewPercentage;
    params.node.data.margin = params.node.data.baseTotal * validNewPercentage;
    params.node.data.totalWithMargin =
      params.node.data.baseTotal + params.node.data.margin;
    params.api.forEachNode((node) => {
      node.data.marginPercentage = validNewPercentage;
      node.data.margin = node.data.baseTotal * validNewPercentage;
      node.data.totalWithMargin = node.data.baseTotal + node.data.margin;
      params.api.refreshCells({ rowNodes: [node], force: true });
    });
    return true;
  }
  if (params.context?.selectedMarginType === "economicMargin") {
    const newPercentage = safeDivide(
      newValue,
      params.data.baseTotal + newValue
    );
    const validNewPercentage = checkGivenPercentage(newPercentage);
    params.data.marginPercentage = validNewPercentage;
    params.data.economicMargin = safeDivide(
      params.data.baseTotal,
      1 - validNewPercentage
    );
    params.data.margin = params.data.baseTotal * validNewPercentage;
    params.data.totalWithMargin = params.data.baseTotal + params.data.margin;
    return true;
  }
  const newPercentage = safeDivide(newValue, params.data.baseTotal);
  const validNewPercentage = checkGivenPercentage(newPercentage);
  params.data.marginPercentage = validNewPercentage;
  params.data.margin = params.data.baseTotal * validNewPercentage;
  params.data.totalWithMargin = params.data.baseTotal + params.data.margin;
  return true;
}

export function getOffer(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  if (params.context?.selectedMarginType === "economicMargin") {
    return params.data.baseTotal + params.data.economicMargin;
  }
  return params.data.baseTotal + params.data.margin;
}

export function getPinnedTotalRow(
  gridRef: React.RefObject<AgGridReact>,
  marginType: string,
  t: TFunction
) {
  if (!gridRef.current) {
    return;
  }
  let baseTotal = 0;
  let economicBaseTotal = 0;
  let economicMargin = 0;
  let margin = 0;
  let totalWithMargin = 0;
  gridRef.current.api?.forEachNodeAfterFilter((node) => {
    if (node.data) {
      baseTotal += node.data.baseTotal;
      economicBaseTotal += node.data.economicBaseTotal;
      economicMargin += node.data.economicMargin;
      margin += node.data.margin;
      totalWithMargin += node.data.totalWithMargin;
    }
  });
  const marginPercentage =
    marginType === "economicMargin"
      ? safeDivide(economicMargin, economicBaseTotal)
      : safeDivide(margin, baseTotal);
  return [
    {
      code: "total",
      description: t`total`,
      baseTotal,
      economicBaseTotal,
      economicMargin,
      margin,
      totalWithMargin,
      marginPercentage,
    },
  ];
}

function checkGivenPercentage(value: number) {
  if (value < -0.9999) {
    return -0.9999;
  }
  if (value > 0.9999) {
    return 0.9999;
  }
  return value;
}
