/* eslint-disable i18next/no-literal-string */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { RequireNewPassword } from "aws-amplify-react";
import { Header, getBackBtn } from "./Utils";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";

const logger = new Logger("RequireNewPassword");

export class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
    this.getBackBtn = getBackBtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    this.change();
  }

  showComponent() {
    return (
      <div className="login login-container">
        <Header
          appState={this.props.appState}
          appDispatch={this.props.appDispatch}
        />
        <form className="login-panel">
          <h1>Vaihda salasana</h1>
          <div className="text-field">
            <label htmlFor="name">Salasana</label>
            <input
              autoFocus
              placeholder="Uusi salasana"
              key="password"
              name="password"
              type="password"
              id="force-change-password"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-actions">
            <button onClick={this.onSubmit} className="btn login-btn">
              Vaihda
            </button>
          </div>
          {this.getBackBtn()}
        </form>
      </div>
    );
  }

  change() {
    const user = this.props.authData;
    const { password } = this.inputs;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(this.inputs, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.completeNewPassword(user, password, attrs)
      .then((user) => {
        logger.debug("complete new password", user);
        this.changeState("signedIn", user);
      })
      .catch((err) => this.error(err));
  }
}

function objectWithProperties(obj, keys) {
  const target = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    target[key] = obj[key];
  }
  return target;
}
