// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_ResourcesView_Types = require("../CostEstimation.ResourcesView.Types/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_MergedEstimationResource = require("../Types.MergedEstimationResource/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var resourcesTableStyleSet = CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.resourceColors);
var resourcesTableOptimized = TacoTable.tableOptimized(CostEstimation_ResourcesView_Types.getNameResourcesTableSortProperty)(CostEstimation_ResourcesView_Types.eqResourcesTableSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentagesByCostClass";
    }
})(Data_Map_Internal.eqMap(Types_Newtypes.eqCostClassCode)(Types_Unit.eqSocialExpensePercentage)))()({
    reflectSymbol: function () {
        return "showSocialExpensesInCosts";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "selectedRowColumn";
    }
})(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn)))()({
    reflectSymbol: function () {
        return "selected";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "resource";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "key";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "estimationResources";
    }
})(Data_Array_NonEmpty_Internal.eqNonEmptyArray(Data_Tuple.eqTuple(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))))(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))))));
var resourceColumnLabelText = function (currencySymbol) {
    return function (col) {
        if (col instanceof Types_MergedEstimationResource.ColCheckbox) {
            return "Valinta";
        };
        if (col instanceof Types_MergedEstimationResource.ColCostClassCode) {
            return "PL";
        };
        if (col instanceof Types_MergedEstimationResource.ColResourceClassificationGroup) {
            return "PRyhm\xe4";
        };
        if (col instanceof Types_MergedEstimationResource.ColSortingNumber) {
            return "J\xe4rjestys";
        };
        if (col instanceof Types_MergedEstimationResource.ColName) {
            return "Nimi";
        };
        if (col instanceof Types_MergedEstimationResource.ColUnit) {
            return "Yks";
        };
        if (col instanceof Types_MergedEstimationResource.ColDiscountPercentage) {
            return "Ale-%";
        };
        if (col instanceof Types_MergedEstimationResource.ColPriceWithDiscount) {
            return "Hinta sis. ale";
        };
        if (col instanceof Types_MergedEstimationResource.ColTotalAmountSum) {
            return "Panosm\xe4\xe4r\xe4";
        };
        if (col instanceof Types_MergedEstimationResource.ColPricePerUnit) {
            return "Alentamaton hinta";
        };
        if (col instanceof Types_MergedEstimationResource.ColPricePerUnitUnit) {
            return currencySymbol + "/Yks.";
        };
        if (col instanceof Types_MergedEstimationResource.ColTotalPriceSum) {
            return "Yhteens\xe4";
        };
        if (col instanceof Types_MergedEstimationResource.ColCurrencyId) {
            return "Val";
        };
        if (col instanceof Types_MergedEstimationResource.ColContract) {
            return "Sopimus";
        };
        if (col instanceof Types_MergedEstimationResource.ColIndex) {
            return "Indeksi";
        };
        if (col instanceof Types_MergedEstimationResource.ColValidDate) {
            return "Voimassa";
        };
        if (col instanceof Types_MergedEstimationResource.ColDiscountGroupCode) {
            return "AleRyhm\xe4";
        };
        if (col instanceof Types_MergedEstimationResource.ColChangeDate) {
            return "Muutosp\xe4iv\xe4";
        };
        if (col instanceof Types_MergedEstimationResource.ColTaxRate) {
            return "Alv-%";
        };
        if (col instanceof Types_MergedEstimationResource.ColEanCode) {
            return "EAN-koodi";
        };
        if (col instanceof Types_MergedEstimationResource.ColPLPriceListId) {
            return "Hinnasto";
        };
        if (col instanceof Types_MergedEstimationResource.ColInternalCode) {
            return "Sis. Tunnus";
        };
        if (col instanceof Types_MergedEstimationResource.ColVendor) {
            return "Toimittaja";
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorCode) {
            return "Toimittajan oma";
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorUnit) {
            return "Myyntier\xe4n yksikk\xf6";
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorBatchSize) {
            return "Myyntier\xe4n nettosis\xe4lt\xf6";
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 523, column 46 - line 549, column 50): " + [ col.constructor.name ]);
    };
};
var resourceColumnKey = function (res) {
    if (res instanceof Types_MergedEstimationResource.ColCheckbox) {
        return "checkbox";
    };
    if (res instanceof Types_MergedEstimationResource.ColCostClassCode) {
        return "costClassCode";
    };
    if (res instanceof Types_MergedEstimationResource.ColResourceClassificationGroup) {
        return "resourceClassificationGroup";
    };
    if (res instanceof Types_MergedEstimationResource.ColSortingNumber) {
        return "sortingNumber";
    };
    if (res instanceof Types_MergedEstimationResource.ColName) {
        return "name";
    };
    if (res instanceof Types_MergedEstimationResource.ColUnit) {
        return "unit";
    };
    if (res instanceof Types_MergedEstimationResource.ColDiscountPercentage) {
        return "discountPercentage";
    };
    if (res instanceof Types_MergedEstimationResource.ColPriceWithDiscount) {
        return "priceWithDiscount";
    };
    if (res instanceof Types_MergedEstimationResource.ColTotalAmountSum) {
        return "totalAmountSum";
    };
    if (res instanceof Types_MergedEstimationResource.ColPricePerUnit) {
        return "pricePerUnit";
    };
    if (res instanceof Types_MergedEstimationResource.ColPricePerUnitUnit) {
        return "pricePerUnitUnit";
    };
    if (res instanceof Types_MergedEstimationResource.ColTotalPriceSum) {
        return "totalPriceSum";
    };
    if (res instanceof Types_MergedEstimationResource.ColCurrencyId) {
        return "currencyId";
    };
    if (res instanceof Types_MergedEstimationResource.ColContract) {
        return "contract";
    };
    if (res instanceof Types_MergedEstimationResource.ColIndex) {
        return "index";
    };
    if (res instanceof Types_MergedEstimationResource.ColValidDate) {
        return "validDate";
    };
    if (res instanceof Types_MergedEstimationResource.ColDiscountGroupCode) {
        return "discountGroupCode";
    };
    if (res instanceof Types_MergedEstimationResource.ColChangeDate) {
        return "changeDate";
    };
    if (res instanceof Types_MergedEstimationResource.ColTaxRate) {
        return "taxrate";
    };
    if (res instanceof Types_MergedEstimationResource.ColEanCode) {
        return "eanCode";
    };
    if (res instanceof Types_MergedEstimationResource.ColPLPriceListId) {
        return "priceListId";
    };
    if (res instanceof Types_MergedEstimationResource.ColInternalCode) {
        return "internalCode";
    };
    if (res instanceof Types_MergedEstimationResource.ColVendor) {
        return "vendor";
    };
    if (res instanceof Types_MergedEstimationResource.ColVendorCode) {
        return "vendorCode";
    };
    if (res instanceof Types_MergedEstimationResource.ColVendorUnit) {
        return "vendorUnit";
    };
    if (res instanceof Types_MergedEstimationResource.ColVendorBatchSize) {
        return "vendorBatchSize";
    };
    throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 494, column 25 - line 520, column 42): " + [ res.constructor.name ]);
};
var prepareColumns = function (props) {
    return function (fn) {
        var selectAllCheckbox = TacoCheckbox.component()()({
            className: "select-all-checkbox",
            testId: "select-all-checkbox",
            isChecked: !Data_Set.isEmpty(props.selectedResources),
            onToggle: new TacoCheckbox.NoEvent(props.toggleSelectAllResources),
            color: TacoConstants.resourceColors.border
        });
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var mkSocialExpenseLabel = function (col) {
            return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), React_Basic_DOM.text(resourceColumnLabelText(currencySymbol)(col)) ]);
        };
        var getLabel = function (col) {
            if (col instanceof Types_MergedEstimationResource.ColPricePerUnitUnit) {
                return mkSocialExpenseLabel(col);
            };
            if (col instanceof Types_MergedEstimationResource.ColTotalPriceSum) {
                return mkSocialExpenseLabel(col);
            };
            return React_Basic_DOM.text(resourceColumnLabelText(currencySymbol)(col));
        };
        var mkCol = function (v) {
            var $50 = fn(v.col);
            return {
                sortProperty: $50.sortProperty,
                key: resourceColumnKey(v.col),
                label: getLabel(v.col),
                width: v.width,
                cell: $50.cell,
                headerJSX: v.headerJSX
            };
        };
        return Data_Functor.map(Data_Functor.functorArray)(mkCol)([ {
            col: Types_MergedEstimationResource.ColCheckbox.value,
            width: new TacoTable_Types.Fixed("2rem"),
            headerJSX: new Data_Maybe.Just(selectAllCheckbox)
        }, {
            col: Types_MergedEstimationResource.ColCostClassCode.value,
            width: new TacoTable_Types.Fixed("5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColResourceClassificationGroup.value,
            width: new TacoTable_Types.Fixed("5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColSortingNumber.value,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColName.value,
            width: new TacoTable_Types.FlexMinWidth(4, "12rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColTotalAmountSum.value,
            width: new TacoTable_Types.FlexMinWidth(2, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColUnit.value,
            width: new TacoTable_Types.Fixed("4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColPricePerUnit.value,
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColPricePerUnitUnit.value,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColDiscountPercentage.value,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColPriceWithDiscount.value,
            width: new TacoTable_Types.FlexMinWidth(1, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColTotalPriceSum.value,
            width: new TacoTable_Types.FlexMinWidth(2, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColCurrencyId.value,
            width: new TacoTable_Types.Fixed("4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColContract.value,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColIndex.value,
            width: new TacoTable_Types.Fixed("5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColValidDate.value,
            width: new TacoTable_Types.Fixed("7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColDiscountGroupCode.value,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColChangeDate.value,
            width: new TacoTable_Types.Fixed("7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColTaxRate.value,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColEanCode.value,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColPLPriceListId.value,
            width: new TacoTable_Types.Fixed("8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColInternalCode.value,
            width: new TacoTable_Types.Fixed("8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColVendor.value,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColVendorCode.value,
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColVendorUnit.value,
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            col: Types_MergedEstimationResource.ColVendorBatchSize.value,
            width: new TacoTable_Types.FlexMinWidth(1, "12rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ]);
    };
};
var mkRowData = function (props) {
    return function (resource) {
        var selectedRowColumn = (function () {
            var $56 = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(props.resourceProps.selectedMergedResourceKey)(new Data_Maybe.Just(resource.key));
            if ($56) {
                return props.selectedInputColumn;
            };
            return Data_Maybe.Nothing.value;
        })();
        return {
            resource: resource,
            selectedRowColumn: selectedRowColumn,
            socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            selected: Data_Set.member(Types_Component.ordEstimationResourceId)(resource.id)(props.selectedResources)
        };
    };
};
var getTotalPrice = function (v) {
    return function (v1) {
        if (v1.value1 instanceof Data_Maybe.Just) {
            return CostEstimation_Calculations.resourceTotalPrice(v)(v1.value1.value0)(v1.value0);
        };
        if (v1.value1 instanceof Data_Maybe.Nothing) {
            return 0.0;
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 73, column 1 - line 73, column 130): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getTotalAmount = function (v) {
    if (v.value1 instanceof Data_Maybe.Just) {
        return CostEstimation_Calculations.resourceTotalAmount(v.value1.value0)(v.value0);
    };
    if (v.value1 instanceof Data_Maybe.Nothing) {
        return 0.0;
    };
    throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 77, column 1 - line 77, column 91): " + [ v.constructor.name ]);
};
var getSortingValue = function (socialExpensePercentagesByCostClass) {
    return function (sortKey) {
        return function (resource) {
            var head = Data_Tuple.fst(Data_Array_NonEmpty.head(resource.estimationResources));
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByCostClass) {
                return TacoTable_Types.StringValue.create(Utils.nullable("")(Data_Newtype.un()(Types_Newtypes.CostClassCode))(resource.costClassCode) + Data_Show.show(Types_Component.showEstimationResourceId)(resource.id));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByChangeDate) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.changeDate));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByUnit) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(resource.unit) + Data_Show.show(Types_Component.showEstimationResourceId)(resource.id));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByUnitPrice) {
                return TacoTable_Types.NumberValue.create(Utils.fromNullable(0.0)(resource.pricePerUnit));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByResourceName) {
                return new TacoTable_Types.StringValue(resource.name);
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByTotalAmountSum) {
                return TacoTable_Types.NumberValue.create(Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(getTotalAmount)(resource.estimationResources)));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByTotalPriceSum) {
                return TacoTable_Types.NumberValue.create(Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(getTotalPrice(socialExpensePercentagesByCostClass))(resource.estimationResources)));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByResourceClassificationGroup) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.resourceClassificationGroup));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.BySortingNumber) {
                return TacoTable_Types.NumberValue.create(Data_Maybe.fromMaybe(0.0)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Number.fromString)(Data_Nullable.toMaybe(head.sortingNumber))));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByName) {
                return new TacoTable_Types.StringValue(head.name);
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByDiscountPercentage) {
                return new TacoTable_Types.NumberValue(head.discountPercentage);
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByPriceListId) {
                return TacoTable_Types.StringValue.create(Data_Newtype.unwrap()(Utils.fromNullable("")(head.orgResourcePriceListId)));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByPriceWithDiscount) {
                return new TacoTable_Types.NumberValue(head.priceWithDiscount);
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByCurrencyId) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.currencyId));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByDiscountGroupCode) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.discountGroupCode));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByInternalCode) {
                return TacoTable_Types.IntValue.create(Utils.fromNullable(0)(resource.orgResourcePriceId));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByContract) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.contract));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByTaxRate) {
                return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.Taxrate)(head.taxPercentage));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByEanCode) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.eanCode));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByIndex) {
                return TacoTable_Types.NumberValue.create(Utils.fromNullable(0.0)(head.index));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByValidDate) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.validDate));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByVendor) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.vendor));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByVendorCode) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.vendorCode));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByVendorUnit) {
                return TacoTable_Types.StringValue.create(Utils.fromNullable("")(head.vendorUnit));
            };
            if (sortKey instanceof CostEstimation_ResourcesView_Types.ByVendorBatchSize) {
                return TacoTable_Types.NumberValue.create(Utils.fromNullable(0.0)(head.vendorBatchSize));
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 411, column 3 - line 436, column 83): " + [ sortKey.constructor.name ]);
        };
    };
};
var getRow = function (columns) {
    return function (props) {
        return function (v) {
            return function (v1) {
                var visibleColumns$prime = Types_MergedEstimationResource.handleExtraVisibleColumns(props.visibleColumns);
                var selected = (function () {
                    if (props.resourceProps.selectedMergedResourceKey instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    if (props.resourceProps.selectedMergedResourceKey instanceof Data_Maybe.Just) {
                        return props.resourceProps.selectedMergedResourceKey.value0 === v1.resource.key;
                    };
                    throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 401, column 16 - line 403, column 38): " + [ props.resourceProps.selectedMergedResourceKey.constructor.name ]);
                })();
                var selectedRowColumn = (function () {
                    if (selected) {
                        return props.selectedInputColumn;
                    };
                    return Data_Maybe.Nothing.value;
                })();
                var onClick = function (r) {
                    return props.resourceProps.setMergedResourceSelection(r.resource);
                };
                var columns$prime = Data_Array.filter(function (c) {
                    return Data_Array.elem(Data_Eq.eqString)((Data_Newtype.unwrap()(c)).key)(Data_Functor.map(Data_Functor.functorArray)(resourceColumnKey)(visibleColumns$prime));
                })(columns);
                return {
                    className: Data_Monoid.guard(Data_Monoid.monoidString)(selected)("selected"),
                    rowData: {
                        resource: v1.resource,
                        selectedRowColumn: selectedRowColumn,
                        socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: props.showSocialExpensesInCosts,
                        selected: Data_Set.member(Types_Component.ordEstimationResourceId)(v1.resource.id)(props.selectedResources)
                    },
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: onClick,
                    rowKey: v1.resource.key,
                    columns: columns$prime
                };
            };
        };
    };
};
var getColumns = function (props) {
    var selectionCheckbox = function (v) {
        return TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: v.selected,
            onToggle: TacoCheckbox.WithShift.create((function () {
                var $126 = props.onClickSelectResource(v.resource.id);
                var $127 = Data_Maybe.fromMaybe(false);
                return function ($128) {
                    return $126($127($128));
                };
            })()),
            labelText: Data_Maybe.Nothing.value,
            color: TacoConstants.resourceColors.border
        });
    };
    var priceWithDiscountCell = new TacoTable_Types.PlainCurrencyCell(props.projectCurrency, function (r) {
        var firstResource = Data_Tuple.fst(Data_Array_NonEmpty.head(r.resource.estimationResources));
        if (props.showSocialExpensesInCosts) {
            return CostEstimation_Calculations.resourcePriceWithDiscount(props.socialExpensePercentagesByCostClass)(firstResource);
        };
        return r.resource.priceWithDiscount;
    });
    var onInputBlur = function (v) {
        return props.setSelectedInputColumn(Data_Maybe.Nothing.value);
    };
    var mkOnFocus = function (r) {
        return function (col) {
            return function __do() {
                Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqString))(props.resourceProps.selectedMergedResourceKey)(new Data_Maybe.Just(r.key)))(props.resourceProps.setMergedResourceSelection(r))();
                return props.setSelectedInputColumn(new Data_Maybe.Just(col))();
            };
        };
    };
    var mkAction$prime = function (r) {
        return function (resourceParamsFn) {
            return function (handler) {
                return new Actions.EstimationResourceActionRequest({
                    projectId: props.projectId,
                    params: {
                        actionType: Types_Estimation.InPlaceResourceUpdate.value,
                        actionParams: Types_Estimation.EditEstimationResource.create(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (resource) {
                            return {
                                resourceId: resource.id,
                                resourceParams: resourceParamsFn(Types_Estimation.editEstimationResourceDefaultParams)
                            };
                        })(Data_Tuple.fst))(Data_Array_NonEmpty.toArray(r.estimationResources)))
                    },
                    handler: handler
                });
            };
        };
    };
    var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
    var pricePerUnitUnit = function (v) {
        return Utils.nullable("")(function (v1) {
            return currencySymbol + "/" + v1;
        })(v.resource.unit);
    };
    var currencyOption = function (currency) {
        return {
            value: Data_Newtype.unwrap()(currency),
            text: Data_Newtype.unwrap()(currency)
        };
    };
    var canEdit = EstimationUtils.canEditView(props);
    var currencyIdInput = function (v) {
        var currencyId = Utils.fromNullable("")(v.resource.currencyId);
        return TacoSelect.component()()({
            value: currencyId,
            disabled: !canEdit,
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColCurrencyId.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColCurrencyId.value)),
            disabledOptions: (function () {
                var $89 = currencyId !== Data_Newtype.unwrap()(props.projectCurrency);
                if ($89) {
                    return [ currencyId ];
                };
                return [  ];
            })(),
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(canEdit)(props.appDispatch(new Actions.EstimationResourceActionRequest({
                    projectId: props.projectId,
                    params: {
                        actionType: Types_Estimation.InPlaceResourceUpdate.value,
                        actionParams: Types_Estimation.EditEstimationResource.create(Data_Functor.map(Data_Functor.functorArray)(function ($129) {
                            return (function (resource) {
                                return {
                                    resourceId: resource.id,
                                    resourceParams: {
                                        currencyId: Data_Nullable.notNull(value),
                                        changedDate: Types_Estimation.editEstimationResourceDefaultParams.changedDate,
                                        costClassCode: Types_Estimation.editEstimationResourceDefaultParams.costClassCode,
                                        discountPercentage: Types_Estimation.editEstimationResourceDefaultParams.discountPercentage,
                                        eanCode: Types_Estimation.editEstimationResourceDefaultParams.eanCode,
                                        group: Types_Estimation.editEstimationResourceDefaultParams.group,
                                        index: Types_Estimation.editEstimationResourceDefaultParams.index,
                                        name: Types_Estimation.editEstimationResourceDefaultParams.name,
                                        orgResourcePriceId: Types_Estimation.editEstimationResourceDefaultParams.orgResourcePriceId,
                                        orgResourcePriceListId: Types_Estimation.editEstimationResourceDefaultParams.orgResourcePriceListId,
                                        pricePerUnit: Types_Estimation.editEstimationResourceDefaultParams.pricePerUnit,
                                        resourceClassificationGroup: Types_Estimation.editEstimationResourceDefaultParams.resourceClassificationGroup,
                                        sortingNumber: Types_Estimation.editEstimationResourceDefaultParams.sortingNumber,
                                        taxRate: Types_Estimation.editEstimationResourceDefaultParams.taxRate,
                                        unit: Types_Estimation.editEstimationResourceDefaultParams.unit,
                                        unitConsumptionRate: Types_Estimation.editEstimationResourceDefaultParams.unitConsumptionRate,
                                        validDate: Types_Estimation.editEstimationResourceDefaultParams.validDate,
                                        vendor: Types_Estimation.editEstimationResourceDefaultParams.vendor,
                                        vendorBatchSize: Types_Estimation.editEstimationResourceDefaultParams.vendorBatchSize,
                                        vendorCode: Types_Estimation.editEstimationResourceDefaultParams.vendorCode,
                                        vendorUnit: Types_Estimation.editEstimationResourceDefaultParams.vendorUnit,
                                        wastePercentage: Types_Estimation.editEstimationResourceDefaultParams.wastePercentage
                                    }
                                };
                            })(Data_Tuple.fst($129));
                        })(Data_Array_NonEmpty.toArray(v.resource.estimationResources)))
                    },
                    handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                })));
            }),
            items: Data_Functor.map(Data_Functor.functorArray)(currencyOption)((function () {
                var $90 = currencyId !== Data_Newtype.unwrap()(props.projectCurrency);
                if ($90) {
                    return [ currencyId, props.projectCurrency ];
                };
                return [ props.projectCurrency ];
            })()),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
            testId: "currency-select"
        });
    };
    var discountPercentageInput = function (v) {
        return TacoInput.remoteNumberField()()({
            value: v.resource.discountPercentage,
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColDiscountPercentage.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColDiscountPercentage.value)),
            precision: 2,
            align: TacoInput.RightAlign.value,
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: Data_Nullable.notNull(value),
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var eanCodeInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.eanCode),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColEanCode.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColEanCode.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: Data_Nullable.notNull(value),
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var indexInput = function (v) {
        return TacoInput.remoteNumberField()()({
            value: Utils.fromNullable(0.0)(v.resource.index),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColIndex.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColIndex.value)),
            precision: 2,
            align: TacoInput.RightAlign.value,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: Data_Nullable.notNull(value),
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            },
            disabled: !canEdit
        });
    };
    var nameInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: v.resource.name,
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColName.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColName.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: Data_Nullable.notNull(value),
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var pricePerUnitInput = function (v) {
        var firstResource = Data_Tuple.fst(Data_Array_NonEmpty.head(v.resource.estimationResources));
        var displayValue = (function () {
            if (props.showSocialExpensesInCosts) {
                return CostEstimation_Calculations.resourcePricePerUnitWithoutDiscountPercentage(props.socialExpensePercentagesByCostClass)(firstResource);
            };
            return Utils.fromNullable(0.0)(v.resource.pricePerUnit);
        })();
        return TacoInput.remoteNumberField()()({
            value: displayValue,
            precision: 2,
            align: TacoInput.RightAlign.value,
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColPricePerUnit.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColPricePerUnit.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: Data_Nullable.notNull((function () {
                            if (props.showSocialExpensesInCosts) {
                                return CostEstimation_Calculations.solveForBaseResourcePricePerUnitWithoutDiscountPercentage(props.socialExpensePercentagesByCostClass)(firstResource)(value);
                            };
                            return value;
                        })()),
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: Utils.pureNullable(0.0),
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var resourceClassificationGroupInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.resourceClassificationGroup),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColResourceClassificationGroup.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColResourceClassificationGroup.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: Data_Nullable.notNull(value),
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var sortingNumberInput = function (v) {
        var firstResource = Data_Tuple.fst(Data_Array_NonEmpty.head(v.resource.estimationResources));
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(firstResource.sortingNumber),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColSortingNumber.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColSortingNumber.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: Data_Nullable.notNull(value),
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var taxrateInput = function (v) {
        return TacoInput.remoteNumberField()()({
            value: Data_Newtype.un()(Types_Unit.Taxrate)(v.resource.taxPercentage),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColTaxRate.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColTaxRate.value)),
            precision: 2,
            align: TacoInput.RightAlign.value,
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: Data_Nullable.notNull(value),
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var unitInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.unit),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColUnit.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColUnit.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: Data_Nullable.notNull(value),
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var vendorBatchSizeInput = function (v) {
        return TacoInput.remoteNumberField()()({
            value: Utils.fromNullable(0.0)(v.resource.vendorBatchSize),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColVendorBatchSize.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColVendorBatchSize.value)),
            precision: 2,
            align: TacoInput.RightAlign.value,
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: Data_Nullable.notNull(value),
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var vendorCodeInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.vendorCode),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColVendorCode.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColVendorCode.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: Data_Nullable.notNull(value),
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var vendorInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.vendor),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColVendor.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColVendor.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: Data_Nullable.notNull(value),
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var vendorUnitInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: Utils.fromNullable("")(v.resource.vendorUnit),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColVendorUnit.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColVendorUnit.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: v1.costClassCode,
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: Data_Nullable.notNull(value),
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    var _nullableUnwrap = function (dictNewtype) {
        return function (mkValue) {
            return function (defaultValue) {
                var $130 = Utils.fromNullable(defaultValue);
                var $131 = Utils.mapNullable(Data_Newtype.un()(mkValue));
                return function ($132) {
                    return $130($131($132));
                };
            };
        };
    };
    var costClassCodeInput = function (v) {
        return TacoInput.remoteStringField()()({
            value: _nullableUnwrap()(Types_Newtypes.CostClassCode)("")(v.resource.costClassCode),
            onFocus: mkOnFocus(v.resource)(Types_MergedEstimationResource.ColCostClassCode.value),
            onBlur: onInputBlur(v.resource),
            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(Types_MergedEstimationResource.eqEstimationResourceColumn))(v.selectedRowColumn)(new Data_Maybe.Just(Types_MergedEstimationResource.ColCostClassCode.value)),
            disabled: !canEdit,
            mkAction: function (value) {
                return mkAction$prime(v.resource)(function (v1) {
                    return {
                        name: v1.name,
                        costClassCode: Data_Nullable.notNull(value),
                        pricePerUnit: v1.pricePerUnit,
                        unit: v1.unit,
                        wastePercentage: v1.wastePercentage,
                        unitConsumptionRate: v1.unitConsumptionRate,
                        group: v1.group,
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        validDate: v1.validDate,
                        changedDate: v1.changedDate,
                        discountPercentage: v1.discountPercentage,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize,
                        currencyId: v1.currencyId,
                        orgResourcePriceListId: v1.orgResourcePriceListId,
                        orgResourcePriceId: v1.orgResourcePriceId
                    };
                });
            }
        });
    };
    return prepareColumns(props)(function (col) {
        if (col instanceof Types_MergedEstimationResource.ColCheckbox) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByCostClass.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], selectionCheckbox)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColCostClassCode) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByCostClass.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], costClassCodeInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColName) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByResourceName.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], nameInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColUnit) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByUnit.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColPricePerUnit) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByUnitPrice.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], pricePerUnitInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColPricePerUnitUnit) {
            return {
                sortProperty: Data_Maybe.Nothing.value,
                cell: new TacoTable_Types.PlainTextCell(pricePerUnitUnit)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColTotalAmountSum) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByTotalAmountSum.value),
                cell: TacoTable_Types.PlainNumberCell.create(2)((function () {
                    var $133 = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber);
                    var $134 = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(getTotalAmount);
                    return function ($135) {
                        return $133($134((function (v) {
                            return v.estimationResources;
                        })((function (v) {
                            return v.resource;
                        })($135))));
                    };
                })())
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColTotalPriceSum) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByTotalPriceSum.value),
                cell: TacoTable_Types.PlainCurrencyCell.create(props.projectCurrency)((function () {
                    var $136 = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber);
                    var $137 = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(getTotalPrice(props.socialExpensePercentagesByCostClass));
                    return function ($138) {
                        return $136($137((function (v) {
                            return v.estimationResources;
                        })((function (v) {
                            return v.resource;
                        })($138))));
                    };
                })())
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColPLPriceListId) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByPriceListId.value),
                cell: TacoTable_Types.PlainTextCell.create(function (r) {
                    return Utils.fromNullable("")(r.resource.orgResourcePriceListId);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColInternalCode) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByInternalCode.value),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return Utils.nullable("")(Data_Show.show(Data_Show.showInt))(r.resource.orgResourcePriceId);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColDiscountPercentage) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByDiscountPercentage.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], discountPercentageInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColPriceWithDiscount) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByPriceWithDiscount.value),
                cell: priceWithDiscountCell
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColResourceClassificationGroup) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByResourceClassificationGroup.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], resourceClassificationGroupInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColSortingNumber) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.BySortingNumber.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], sortingNumberInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColCurrencyId) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByCurrencyId.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], currencyIdInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColDiscountGroupCode) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByDiscountGroupCode.value),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return Utils.fromNullable("")(r.resource.discountGroupCode);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColContract) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByContract.value),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return Utils.fromNullable("")(r.resource.contract);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColValidDate) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByValidDate.value),
                cell: new TacoTable_Types.PlainDateStringCell(function (r) {
                    return Utils.fromNullable("")(r.resource.validDate);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColChangeDate) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByChangeDate.value),
                cell: new TacoTable_Types.PlainDateStringCell(function (r) {
                    return Utils.fromNullable("")(r.resource.changeDate);
                })
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColEanCode) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByEanCode.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], eanCodeInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColTaxRate) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByTaxRate.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], taxrateInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColIndex) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByIndex.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], indexInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColVendor) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByVendor.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], vendorInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorCode) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByVendorCode.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], vendorCodeInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorUnit) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByVendorUnit.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], vendorUnitInput)
            };
        };
        if (col instanceof Types_MergedEstimationResource.ColVendorBatchSize) {
            return {
                sortProperty: new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByVendorBatchSize.value),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], vendorBatchSizeInput)
            };
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.Table (line 83, column 32 - line 135, column 99): " + [ col.constructor.name ]);
    });
};
var controllableColumns = [ Types_MergedEstimationResource.ColCostClassCode.value, Types_MergedEstimationResource.ColResourceClassificationGroup.value, Types_MergedEstimationResource.ColSortingNumber.value, Types_MergedEstimationResource.ColName.value, Types_MergedEstimationResource.ColTotalAmountSum.value, Types_MergedEstimationResource.ColUnit.value, Types_MergedEstimationResource.ColPricePerUnitUnit.value, Types_MergedEstimationResource.ColDiscountPercentage.value, Types_MergedEstimationResource.ColPriceWithDiscount.value, Types_MergedEstimationResource.ColTotalPriceSum.value, Types_MergedEstimationResource.ColCurrencyId.value, Types_MergedEstimationResource.ColContract.value, Types_MergedEstimationResource.ColIndex.value, Types_MergedEstimationResource.ColValidDate.value, Types_MergedEstimationResource.ColDiscountGroupCode.value, Types_MergedEstimationResource.ColChangeDate.value, Types_MergedEstimationResource.ColTaxRate.value, Types_MergedEstimationResource.ColEanCode.value, Types_MergedEstimationResource.ColPLPriceListId.value, Types_MergedEstimationResource.ColInternalCode.value, Types_MergedEstimationResource.ColVendor.value, Types_MergedEstimationResource.ColVendorCode.value, Types_MergedEstimationResource.ColVendorUnit.value, Types_MergedEstimationResource.ColVendorBatchSize.value ];
var boldTextCell = function (property) {
    return function (v) {
        return React_Basic_DOM_Generated.span()({
            className: "bold",
            children: [ React_Basic_DOM.text(property(v.resource)) ]
        });
    };
};
module.exports = {
    boldTextCell: boldTextCell,
    getTotalPrice: getTotalPrice,
    getTotalAmount: getTotalAmount,
    getColumns: getColumns,
    getRow: getRow,
    getSortingValue: getSortingValue,
    resourcesTableOptimized: resourcesTableOptimized,
    resourcesTableStyleSet: resourcesTableStyleSet,
    controllableColumns: controllableColumns,
    resourceColumnKey: resourceColumnKey,
    resourceColumnLabelText: resourceColumnLabelText,
    prepareColumns: prepareColumns,
    mkRowData: mkRowData
};
