// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Effect = require("../Effect/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var Relative = (function () {
    function Relative() {

    };
    Relative.value = new Relative();
    return Relative;
})();
var Absolute = (function () {
    function Absolute() {

    };
    Absolute.value = new Absolute();
    return Absolute;
})();
var Fixed = (function () {
    function Fixed() {

    };
    Fixed.value = new Fixed();
    return Fixed;
})();
var Sticky = (function () {
    function Sticky() {

    };
    Sticky.value = new Sticky();
    return Sticky;
})();
var OverflowX = (function () {
    function OverflowX() {

    };
    OverflowX.value = new OverflowX();
    return OverflowX;
})();
var OverflowY = (function () {
    function OverflowY() {

    };
    OverflowY.value = new OverflowY();
    return OverflowY;
})();
var Auto = (function () {
    function Auto() {

    };
    Auto.value = new Auto();
    return Auto;
})();
var Hidden = (function () {
    function Hidden() {

    };
    Hidden.value = new Hidden();
    return Hidden;
})();
var Scroll = (function () {
    function Scroll() {

    };
    Scroll.value = new Scroll();
    return Scroll;
})();
var Visible = (function () {
    function Visible() {

    };
    Visible.value = new Visible();
    return Visible;
})();
var Flex = (function () {
    function Flex() {

    };
    Flex.value = new Flex();
    return Flex;
})();
var InlineFlex = (function () {
    function InlineFlex() {

    };
    InlineFlex.value = new InlineFlex();
    return InlineFlex;
})();
var Block = (function () {
    function Block() {

    };
    Block.value = new Block();
    return Block;
})();
var Inline = (function () {
    function Inline() {

    };
    Inline.value = new Inline();
    return Inline;
})();
var InlineBlock = (function () {
    function InlineBlock() {

    };
    InlineBlock.value = new InlineBlock();
    return InlineBlock;
})();
var Grid = (function () {
    function Grid() {

    };
    Grid.value = new Grid();
    return Grid;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var NotAllowed = (function () {
    function NotAllowed() {

    };
    NotAllowed.value = new NotAllowed();
    return NotAllowed;
})();
var Pointer = (function () {
    function Pointer() {

    };
    Pointer.value = new Pointer();
    return Pointer;
})();
var PhoneM = (function () {
    function PhoneM() {

    };
    PhoneM.value = new PhoneM();
    return PhoneM;
})();
var PhoneL = (function () {
    function PhoneL() {

    };
    PhoneL.value = new PhoneL();
    return PhoneL;
})();
var TabletM = (function () {
    function TabletM() {

    };
    TabletM.value = new TabletM();
    return TabletM;
})();
var TabletL = (function () {
    function TabletL() {

    };
    TabletL.value = new TabletL();
    return TabletL;
})();
var DesktopM = (function () {
    function DesktopM() {

    };
    DesktopM.value = new DesktopM();
    return DesktopM;
})();
var DesktopL = (function () {
    function DesktopL() {

    };
    DesktopL.value = new DesktopL();
    return DesktopL;
})();
var Margin = (function () {
    function Margin(value0) {
        this.value0 = value0;
    };
    Margin.create = function (value0) {
        return new Margin(value0);
    };
    return Margin;
})();
var MarginLeft = (function () {
    function MarginLeft(value0) {
        this.value0 = value0;
    };
    MarginLeft.create = function (value0) {
        return new MarginLeft(value0);
    };
    return MarginLeft;
})();
var MarginRight = (function () {
    function MarginRight(value0) {
        this.value0 = value0;
    };
    MarginRight.create = function (value0) {
        return new MarginRight(value0);
    };
    return MarginRight;
})();
var MarginTop = (function () {
    function MarginTop(value0) {
        this.value0 = value0;
    };
    MarginTop.create = function (value0) {
        return new MarginTop(value0);
    };
    return MarginTop;
})();
var MarginBottom = (function () {
    function MarginBottom(value0) {
        this.value0 = value0;
    };
    MarginBottom.create = function (value0) {
        return new MarginBottom(value0);
    };
    return MarginBottom;
})();
var MarginX = (function () {
    function MarginX(value0) {
        this.value0 = value0;
    };
    MarginX.create = function (value0) {
        return new MarginX(value0);
    };
    return MarginX;
})();
var MarginY = (function () {
    function MarginY(value0) {
        this.value0 = value0;
    };
    MarginY.create = function (value0) {
        return new MarginY(value0);
    };
    return MarginY;
})();
var Padding = (function () {
    function Padding(value0) {
        this.value0 = value0;
    };
    Padding.create = function (value0) {
        return new Padding(value0);
    };
    return Padding;
})();
var PaddingLeft = (function () {
    function PaddingLeft(value0) {
        this.value0 = value0;
    };
    PaddingLeft.create = function (value0) {
        return new PaddingLeft(value0);
    };
    return PaddingLeft;
})();
var PaddingRight = (function () {
    function PaddingRight(value0) {
        this.value0 = value0;
    };
    PaddingRight.create = function (value0) {
        return new PaddingRight(value0);
    };
    return PaddingRight;
})();
var PaddingTop = (function () {
    function PaddingTop(value0) {
        this.value0 = value0;
    };
    PaddingTop.create = function (value0) {
        return new PaddingTop(value0);
    };
    return PaddingTop;
})();
var PaddingBottom = (function () {
    function PaddingBottom(value0) {
        this.value0 = value0;
    };
    PaddingBottom.create = function (value0) {
        return new PaddingBottom(value0);
    };
    return PaddingBottom;
})();
var PaddingX = (function () {
    function PaddingX(value0) {
        this.value0 = value0;
    };
    PaddingX.create = function (value0) {
        return new PaddingX(value0);
    };
    return PaddingX;
})();
var PaddingY = (function () {
    function PaddingY(value0) {
        this.value0 = value0;
    };
    PaddingY.create = function (value0) {
        return new PaddingY(value0);
    };
    return PaddingY;
})();
var PosLeft = (function () {
    function PosLeft(value0) {
        this.value0 = value0;
    };
    PosLeft.create = function (value0) {
        return new PosLeft(value0);
    };
    return PosLeft;
})();
var PosRight = (function () {
    function PosRight(value0) {
        this.value0 = value0;
    };
    PosRight.create = function (value0) {
        return new PosRight(value0);
    };
    return PosRight;
})();
var PosTop = (function () {
    function PosTop(value0) {
        this.value0 = value0;
    };
    PosTop.create = function (value0) {
        return new PosTop(value0);
    };
    return PosTop;
})();
var PosBottom = (function () {
    function PosBottom(value0) {
        this.value0 = value0;
    };
    PosBottom.create = function (value0) {
        return new PosBottom(value0);
    };
    return PosBottom;
})();
var Width = (function () {
    function Width(value0) {
        this.value0 = value0;
    };
    Width.create = function (value0) {
        return new Width(value0);
    };
    return Width;
})();
var MaxWidth = (function () {
    function MaxWidth(value0) {
        this.value0 = value0;
    };
    MaxWidth.create = function (value0) {
        return new MaxWidth(value0);
    };
    return MaxWidth;
})();
var MinWidth = (function () {
    function MinWidth(value0) {
        this.value0 = value0;
    };
    MinWidth.create = function (value0) {
        return new MinWidth(value0);
    };
    return MinWidth;
})();
var Height = (function () {
    function Height(value0) {
        this.value0 = value0;
    };
    Height.create = function (value0) {
        return new Height(value0);
    };
    return Height;
})();
var MaxHeight = (function () {
    function MaxHeight(value0) {
        this.value0 = value0;
    };
    MaxHeight.create = function (value0) {
        return new MaxHeight(value0);
    };
    return MaxHeight;
})();
var MinHeight = (function () {
    function MinHeight(value0) {
        this.value0 = value0;
    };
    MinHeight.create = function (value0) {
        return new MinHeight(value0);
    };
    return MinHeight;
})();
var BackgroundColor = (function () {
    function BackgroundColor(value0) {
        this.value0 = value0;
    };
    BackgroundColor.create = function (value0) {
        return new BackgroundColor(value0);
    };
    return BackgroundColor;
})();
var ForegroundColor = (function () {
    function ForegroundColor(value0) {
        this.value0 = value0;
    };
    ForegroundColor.create = function (value0) {
        return new ForegroundColor(value0);
    };
    return ForegroundColor;
})();
var BoxShadow = (function () {
    function BoxShadow(value0) {
        this.value0 = value0;
    };
    BoxShadow.create = function (value0) {
        return new BoxShadow(value0);
    };
    return BoxShadow;
})();
var Opacity = (function () {
    function Opacity(value0) {
        this.value0 = value0;
    };
    Opacity.create = function (value0) {
        return new Opacity(value0);
    };
    return Opacity;
})();
var FontWeight = (function () {
    function FontWeight(value0) {
        this.value0 = value0;
    };
    FontWeight.create = function (value0) {
        return new FontWeight(value0);
    };
    return FontWeight;
})();
var Display = (function () {
    function Display(value0) {
        this.value0 = value0;
    };
    Display.create = function (value0) {
        return new Display(value0);
    };
    return Display;
})();
var Position = (function () {
    function Position(value0) {
        this.value0 = value0;
    };
    Position.create = function (value0) {
        return new Position(value0);
    };
    return Position;
})();
var Overflow = (function () {
    function Overflow(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Overflow.create = function (value0) {
        return function (value1) {
            return new Overflow(value0, value1);
        };
    };
    return Overflow;
})();
var Visibility = (function () {
    function Visibility(value0) {
        this.value0 = value0;
    };
    Visibility.create = function (value0) {
        return new Visibility(value0);
    };
    return Visibility;
})();
var FlexColumn = (function () {
    function FlexColumn() {

    };
    FlexColumn.value = new FlexColumn();
    return FlexColumn;
})();
var FlexRow = (function () {
    function FlexRow() {

    };
    FlexRow.value = new FlexRow();
    return FlexRow;
})();
var FlexWrap = (function () {
    function FlexWrap() {

    };
    FlexWrap.value = new FlexWrap();
    return FlexWrap;
})();
var FlexGrow = (function () {
    function FlexGrow(value0) {
        this.value0 = value0;
    };
    FlexGrow.create = function (value0) {
        return new FlexGrow(value0);
    };
    return FlexGrow;
})();
var FlexShrink = (function () {
    function FlexShrink(value0) {
        this.value0 = value0;
    };
    FlexShrink.create = function (value0) {
        return new FlexShrink(value0);
    };
    return FlexShrink;
})();
var FlexBasis = (function () {
    function FlexBasis(value0) {
        this.value0 = value0;
    };
    FlexBasis.create = function (value0) {
        return new FlexBasis(value0);
    };
    return FlexBasis;
})();
var GridColumn = (function () {
    function GridColumn() {

    };
    GridColumn.value = new GridColumn();
    return GridColumn;
})();
var GridRow = (function () {
    function GridRow() {

    };
    GridRow.value = new GridRow();
    return GridRow;
})();
var Gap = (function () {
    function Gap(value0) {
        this.value0 = value0;
    };
    Gap.create = function (value0) {
        return new Gap(value0);
    };
    return Gap;
})();
var AlignStart = (function () {
    function AlignStart() {

    };
    AlignStart.value = new AlignStart();
    return AlignStart;
})();
var AlignCenter = (function () {
    function AlignCenter() {

    };
    AlignCenter.value = new AlignCenter();
    return AlignCenter;
})();
var AlignBaseline = (function () {
    function AlignBaseline() {

    };
    AlignBaseline.value = new AlignBaseline();
    return AlignBaseline;
})();
var AlignEnd = (function () {
    function AlignEnd() {

    };
    AlignEnd.value = new AlignEnd();
    return AlignEnd;
})();
var AlignStretch = (function () {
    function AlignStretch() {

    };
    AlignStretch.value = new AlignStretch();
    return AlignStretch;
})();
var JustifyStart = (function () {
    function JustifyStart() {

    };
    JustifyStart.value = new JustifyStart();
    return JustifyStart;
})();
var JustifyCenter = (function () {
    function JustifyCenter() {

    };
    JustifyCenter.value = new JustifyCenter();
    return JustifyCenter;
})();
var JustifyEnd = (function () {
    function JustifyEnd() {

    };
    JustifyEnd.value = new JustifyEnd();
    return JustifyEnd;
})();
var JustifySpaceBetween = (function () {
    function JustifySpaceBetween() {

    };
    JustifySpaceBetween.value = new JustifySpaceBetween();
    return JustifySpaceBetween;
})();
var JustifySpaceAround = (function () {
    function JustifySpaceAround() {

    };
    JustifySpaceAround.value = new JustifySpaceAround();
    return JustifySpaceAround;
})();
var JustifySpaceEvenly = (function () {
    function JustifySpaceEvenly() {

    };
    JustifySpaceEvenly.value = new JustifySpaceEvenly();
    return JustifySpaceEvenly;
})();
var PlaceCenter = (function () {
    function PlaceCenter() {

    };
    PlaceCenter.value = new PlaceCenter();
    return PlaceCenter;
})();
var Border = (function () {
    function Border(value0) {
        this.value0 = value0;
    };
    Border.create = function (value0) {
        return new Border(value0);
    };
    return Border;
})();
var BorderRadius = (function () {
    function BorderRadius(value0) {
        this.value0 = value0;
    };
    BorderRadius.create = function (value0) {
        return new BorderRadius(value0);
    };
    return BorderRadius;
})();
var ColorSet = (function () {
    function ColorSet(value0) {
        this.value0 = value0;
    };
    ColorSet.create = function (value0) {
        return new ColorSet(value0);
    };
    return ColorSet;
})();
var Transition = (function () {
    function Transition(value0) {
        this.value0 = value0;
    };
    Transition.create = function (value0) {
        return new Transition(value0);
    };
    return Transition;
})();
var ZIndex = (function () {
    function ZIndex(value0) {
        this.value0 = value0;
    };
    ZIndex.create = function (value0) {
        return new ZIndex(value0);
    };
    return ZIndex;
})();
var Hover = (function () {
    function Hover(value0) {
        this.value0 = value0;
    };
    Hover.create = function (value0) {
        return new Hover(value0);
    };
    return Hover;
})();
var Focus = (function () {
    function Focus(value0) {
        this.value0 = value0;
    };
    Focus.create = function (value0) {
        return new Focus(value0);
    };
    return Focus;
})();
var Disabled = (function () {
    function Disabled(value0) {
        this.value0 = value0;
    };
    Disabled.create = function (value0) {
        return new Disabled(value0);
    };
    return Disabled;
})();
var Selector = (function () {
    function Selector(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Selector.create = function (value0) {
        return function (value1) {
            return new Selector(value0, value1);
        };
    };
    return Selector;
})();
var Media = (function () {
    function Media(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Media.create = function (value0) {
        return function (value1) {
            return new Media(value0, value1);
        };
    };
    return Media;
})();
var Cursor = (function () {
    function Cursor(value0) {
        this.value0 = value0;
    };
    Cursor.create = function (value0) {
        return new Cursor(value0);
    };
    return Cursor;
})();
var Style = (function () {
    function Style(value0) {
        this.value0 = value0;
    };
    Style.create = function (value0) {
        return new Style(value0);
    };
    return Style;
})();
var Empty = (function () {
    function Empty() {

    };
    Empty.value = new Empty();
    return Empty;
})();
var visibilityStyle = (function () {
    var mkStyle = function (value) {
        return React_Basic_Emotion.css()({
            visibility: React_Basic_Emotion.str(value)
        });
    };
    return function (v) {
        if (v) {
            return mkStyle("visible");
        };
        if (!v) {
            return mkStyle("hidden");
        };
        throw new Error("Failed pattern match at Box (line 85, column 19 - line 87, column 28): " + [ v.constructor.name ]);
    };
})();
var positionStyle = (function () {
    var mkStyle = function (value) {
        return React_Basic_Emotion.css()({
            position: React_Basic_Emotion.str(value)
        });
    };
    return function (v) {
        if (v instanceof Relative) {
            return mkStyle("relative");
        };
        if (v instanceof Absolute) {
            return mkStyle("absolute");
        };
        if (v instanceof Fixed) {
            return mkStyle("fixed");
        };
        if (v instanceof Sticky) {
            return mkStyle("sticky");
        };
        throw new Error("Failed pattern match at Box (line 23, column 17 - line 27, column 32): " + [ v.constructor.name ]);
    };
})();
var overflowStyle = function (dir) {
    var mkStyle = function (value) {
        if (dir instanceof OverflowX) {
            return React_Basic_Emotion.css()({
                overflowX: React_Basic_Emotion.str(value)
            });
        };
        if (dir instanceof OverflowY) {
            return React_Basic_Emotion.css()({
                overflowY: React_Basic_Emotion.str(value)
            });
        };
        throw new Error("Failed pattern match at Box (line 69, column 21 - line 71, column 53): " + [ dir.constructor.name ]);
    };
    return function (v) {
        if (v instanceof Auto) {
            return mkStyle("auto");
        };
        if (v instanceof Hidden) {
            return mkStyle("hidden");
        };
        if (v instanceof Scroll) {
            return mkStyle("scroll");
        };
        if (v instanceof Visible) {
            return mkStyle("visible");
        };
        throw new Error("Failed pattern match at Box (line 63, column 21 - line 67, column 31): " + [ v.constructor.name ]);
    };
};
var getBreakpointSize = function (breakpoint) {
    if (breakpoint instanceof PhoneM) {
        return 320.0;
    };
    if (breakpoint instanceof PhoneL) {
        return 375.0;
    };
    if (breakpoint instanceof TabletM) {
        return 768.0;
    };
    if (breakpoint instanceof TabletL) {
        return 1024.0;
    };
    if (breakpoint instanceof DesktopM) {
        return 1440.0;
    };
    if (breakpoint instanceof DesktopL) {
        return 1920.0;
    };
    throw new Error("Failed pattern match at Box (line 116, column 32 - line 122, column 21): " + [ breakpoint.constructor.name ]);
};
var getMediaQueryStr = function (breakpoint) {
    var breakpointStr = Data_Number_Format.toString(getBreakpointSize(breakpoint));
    return "@media (min-width:" + (breakpointStr + "px)");
};
var displayStyle = (function () {
    var mkStyle = function (value) {
        return React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str(value)
        });
    };
    return function (v) {
        if (v instanceof Flex) {
            return mkStyle("flex");
        };
        if (v instanceof InlineFlex) {
            return mkStyle("inline-flex");
        };
        if (v instanceof Block) {
            return mkStyle("block");
        };
        if (v instanceof Inline) {
            return mkStyle("inline");
        };
        if (v instanceof InlineBlock) {
            return mkStyle("block");
        };
        if (v instanceof Grid) {
            return mkStyle("grid");
        };
        if (v instanceof None) {
            return mkStyle("none");
        };
        throw new Error("Failed pattern match at Box (line 41, column 16 - line 48, column 32): " + [ v.constructor.name ]);
    };
})();
var cursorAppearanceStyle = (function () {
    var mkStyle = function (value) {
        return React_Basic_Emotion.css()({
            cursor: React_Basic_Emotion.str(value)
        });
    };
    return function (v) {
        if (v instanceof NotAllowed) {
            return mkStyle("not-allowed");
        };
        if (v instanceof Pointer) {
            return mkStyle("pointer");
        };
        throw new Error("Failed pattern match at Box (line 78, column 25 - line 80, column 31): " + [ v.constructor.name ]);
    };
})();
var css$prime = Types_ReasonableCoerce.reasonableCoerce("E.Style is represented as an FO of E.StyleProperty");
var boxShadowStyle = function (size) {
    var mkStyle = function (value) {
        return React_Basic_Emotion.css()({
            boxShadow: React_Basic_Emotion.str("0 0 " + (value + " 1px rgba(0, 0, 0, 0.2)"))
        });
    };
    if (size instanceof TacoConstants.S) {
        return mkStyle("3px");
    };
    if (size instanceof TacoConstants.M) {
        return mkStyle("6px");
    };
    if (size instanceof TacoConstants.L) {
        return mkStyle("9px");
    };
    if (size instanceof TacoConstants.CustomSize) {
        return mkStyle(size.value0);
    };
    return mkStyle("6px");
};
var boxStyle = function (boxProps) {
    return React_Basic_Emotion.merge(Data_Functor.map(Data_Functor.functorArray)(boxPropStyle)(boxProps));
};
var boxPropStyle = function (v) {
    if (v instanceof Margin) {
        return React_Basic_Emotion.css()({
            margin: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginLeft) {
        return React_Basic_Emotion.css()({
            marginLeft: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginRight) {
        return React_Basic_Emotion.css()({
            marginRight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginTop) {
        return React_Basic_Emotion.css()({
            marginTop: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginBottom) {
        return React_Basic_Emotion.css()({
            marginBottom: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginX) {
        return React_Basic_Emotion.css()({
            marginLeft: StyleProperties.size(v.value0),
            marginRight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MarginY) {
        return React_Basic_Emotion.css()({
            marginTop: StyleProperties.size(v.value0),
            marginBottom: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof Padding) {
        return React_Basic_Emotion.css()({
            padding: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingLeft) {
        return React_Basic_Emotion.css()({
            paddingLeft: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingRight) {
        return React_Basic_Emotion.css()({
            paddingRight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingTop) {
        return React_Basic_Emotion.css()({
            paddingTop: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingBottom) {
        return React_Basic_Emotion.css()({
            paddingBottom: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingX) {
        return React_Basic_Emotion.css()({
            paddingLeft: StyleProperties.size(v.value0),
            paddingRight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PaddingY) {
        return React_Basic_Emotion.css()({
            paddingTop: StyleProperties.size(v.value0),
            paddingBottom: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PosLeft) {
        return React_Basic_Emotion.css()({
            left: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PosRight) {
        return React_Basic_Emotion.css()({
            right: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PosTop) {
        return React_Basic_Emotion.css()({
            top: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof PosBottom) {
        return React_Basic_Emotion.css()({
            bottom: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof Width) {
        return React_Basic_Emotion.css()({
            width: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MaxWidth) {
        return React_Basic_Emotion.css()({
            maxWidth: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MinWidth) {
        return React_Basic_Emotion.css()({
            minWidth: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof Height) {
        return React_Basic_Emotion.css()({
            height: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MaxHeight) {
        return React_Basic_Emotion.css()({
            maxHeight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof MinHeight) {
        return React_Basic_Emotion.css()({
            minHeight: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof BackgroundColor) {
        return React_Basic_Emotion.css()({
            backgroundColor: StyleProperties.colorScheme(v.value0)
        });
    };
    if (v instanceof ForegroundColor) {
        return React_Basic_Emotion.css()({
            color: StyleProperties.colorScheme(v.value0)
        });
    };
    if (v instanceof BoxShadow) {
        return boxShadowStyle(v.value0);
    };
    if (v instanceof Opacity) {
        return React_Basic_Emotion.css()({
            opacity: React_Basic_Emotion.num(v.value0)
        });
    };
    if (v instanceof FontWeight) {
        return React_Basic_Emotion.css()({
            fontWeight: React_Basic_Emotion.str(v.value0)
        });
    };
    if (v instanceof Display) {
        return displayStyle(v.value0);
    };
    if (v instanceof Position) {
        return positionStyle(v.value0);
    };
    if (v instanceof Overflow) {
        return React_Basic_Emotion.merge([ overflowStyle(OverflowX.value)(v.value0), overflowStyle(OverflowY.value)(v.value1) ]);
    };
    if (v instanceof Visibility) {
        return visibilityStyle(v.value0);
    };
    if (v instanceof FlexColumn) {
        return React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("flex"),
            flexDirection: React_Basic_Emotion.str("column")
        });
    };
    if (v instanceof FlexRow) {
        return React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("flex"),
            flexDirection: React_Basic_Emotion.str("row")
        });
    };
    if (v instanceof FlexWrap) {
        return React_Basic_Emotion.css()({
            flexWrap: React_Basic_Emotion.str("wrap")
        });
    };
    if (v instanceof FlexGrow) {
        return React_Basic_Emotion.css()({
            flex: React_Basic_Emotion["int"](v.value0)
        });
    };
    if (v instanceof FlexShrink) {
        return React_Basic_Emotion.css()({
            flexShrink: React_Basic_Emotion["int"](v.value0)
        });
    };
    if (v instanceof FlexBasis) {
        return React_Basic_Emotion.css()({
            flexBasis: React_Basic_Emotion.str(v.value0)
        });
    };
    if (v instanceof GridColumn) {
        return React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("grid"),
            gridAutoFlow: React_Basic_Emotion.str("column")
        });
    };
    if (v instanceof GridRow) {
        return React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("grid"),
            gridAutoFlow: React_Basic_Emotion.str("row")
        });
    };
    if (v instanceof Gap) {
        return React_Basic_Emotion.css()({
            gap: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof AlignStart) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("flex-start")
        });
    };
    if (v instanceof AlignCenter) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("center")
        });
    };
    if (v instanceof AlignBaseline) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("baseline")
        });
    };
    if (v instanceof AlignEnd) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("flex-end")
        });
    };
    if (v instanceof AlignStretch) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("stretch")
        });
    };
    if (v instanceof JustifyStart) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("flex-start")
        });
    };
    if (v instanceof JustifyCenter) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("center")
        });
    };
    if (v instanceof JustifyEnd) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("flex-end")
        });
    };
    if (v instanceof JustifySpaceBetween) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("space-between")
        });
    };
    if (v instanceof JustifySpaceAround) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("space-around")
        });
    };
    if (v instanceof JustifySpaceEvenly) {
        return React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("space-evenly")
        });
    };
    if (v instanceof PlaceCenter) {
        return React_Basic_Emotion.css()({
            alignItems: React_Basic_Emotion.str("center"),
            justifyContent: React_Basic_Emotion.str("center")
        });
    };
    if (v instanceof Border) {
        return React_Basic_Emotion.css()({
            border: React_Basic_Emotion.str(TacoConstants.borderStr(v.value0))
        });
    };
    if (v instanceof BorderRadius) {
        return React_Basic_Emotion.css()({
            borderRadius: StyleProperties.size(v.value0)
        });
    };
    if (v instanceof ColorSet) {
        return React_Basic_Emotion.css()({
            color: StyleProperties.colorScheme(v.value0.foreground),
            backgroundColor: StyleProperties.colorScheme(v.value0.background),
            border: React_Basic_Emotion.str(TacoConstants.borderStr(new TacoConstants.BorderCustom(v.value0.border)))
        });
    };
    if (v instanceof Transition) {
        return React_Basic_Emotion.css()({
            transitionDuration: React_Basic_Emotion.str("150ms"),
            transitionTimingFunction: React_Basic_Emotion.str("ease"),
            transitionProperty: React_Basic_Emotion.str(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(", ")(v.value0))
        });
    };
    if (v instanceof ZIndex) {
        return React_Basic_Emotion.css()({
            zIndex: React_Basic_Emotion["int"](v.value0)
        });
    };
    if (v instanceof Hover) {
        return React_Basic_Emotion.css()({
            "&:hover": React_Basic_Emotion.nested(boxStyle(v.value0))
        });
    };
    if (v instanceof Focus) {
        return React_Basic_Emotion.css()({
            "&:focus": React_Basic_Emotion.nested(boxStyle(v.value0))
        });
    };
    if (v instanceof Disabled) {
        return React_Basic_Emotion.css()({
            "&:disabled": React_Basic_Emotion.nested(boxStyle(v.value0))
        });
    };
    if (v instanceof Selector) {
        return css$prime(Foreign_Object.singleton(v.value0)(React_Basic_Emotion.nested(boxStyle(v.value1))));
    };
    if (v instanceof Media) {
        return css$prime(Foreign_Object.singleton(getMediaQueryStr(v.value0))(React_Basic_Emotion.nested(boxStyle(v.value1))));
    };
    if (v instanceof Cursor) {
        return cursorAppearanceStyle(v.value0);
    };
    if (v instanceof Style) {
        return v.value0;
    };
    if (v instanceof Empty) {
        return React_Basic_Emotion.css()({});
    };
    throw new Error("Failed pattern match at Box (line 216, column 16 - line 306, column 38): " + [ v.constructor.name ]);
};
var semigroupBoxProp = {
    append: function (x) {
        return function (y) {
            return Style.create(React_Basic_Emotion.merge([ boxPropStyle(x), boxPropStyle(y) ]));
        };
    }
};
var monoidBoxProp = {
    mempty: Empty.value,
    Semigroup0: function () {
        return semigroupBoxProp;
    }
};
var box$prime = TofuHooks.mkHookComponent("Box")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        children: props.children,
        className: "__Box " + props.name,
        css: React_Basic_Emotion.merge([ React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("flex"),
            flexWrap: React_Basic_Emotion.str("wrap")
        }), boxStyle(props.boxProps) ])
    }));
});
var box = function (name) {
    return function (boxProps) {
        return function (children) {
            return box$prime({
                name: name,
                boxProps: boxProps,
                children: children
            });
        };
    };
};
module.exports = {
    Relative: Relative,
    Absolute: Absolute,
    Fixed: Fixed,
    Sticky: Sticky,
    positionStyle: positionStyle,
    Flex: Flex,
    InlineFlex: InlineFlex,
    Block: Block,
    Inline: Inline,
    InlineBlock: InlineBlock,
    Grid: Grid,
    None: None,
    displayStyle: displayStyle,
    Auto: Auto,
    Hidden: Hidden,
    Scroll: Scroll,
    Visible: Visible,
    OverflowX: OverflowX,
    OverflowY: OverflowY,
    overflowStyle: overflowStyle,
    NotAllowed: NotAllowed,
    Pointer: Pointer,
    cursorAppearanceStyle: cursorAppearanceStyle,
    visibilityStyle: visibilityStyle,
    boxShadowStyle: boxShadowStyle,
    PhoneM: PhoneM,
    PhoneL: PhoneL,
    TabletM: TabletM,
    TabletL: TabletL,
    DesktopM: DesktopM,
    DesktopL: DesktopL,
    getMediaQueryStr: getMediaQueryStr,
    getBreakpointSize: getBreakpointSize,
    Margin: Margin,
    MarginLeft: MarginLeft,
    MarginRight: MarginRight,
    MarginTop: MarginTop,
    MarginBottom: MarginBottom,
    MarginX: MarginX,
    MarginY: MarginY,
    Padding: Padding,
    PaddingLeft: PaddingLeft,
    PaddingRight: PaddingRight,
    PaddingTop: PaddingTop,
    PaddingBottom: PaddingBottom,
    PaddingX: PaddingX,
    PaddingY: PaddingY,
    PosLeft: PosLeft,
    PosRight: PosRight,
    PosTop: PosTop,
    PosBottom: PosBottom,
    Width: Width,
    MaxWidth: MaxWidth,
    MinWidth: MinWidth,
    Height: Height,
    MaxHeight: MaxHeight,
    MinHeight: MinHeight,
    BackgroundColor: BackgroundColor,
    ForegroundColor: ForegroundColor,
    BoxShadow: BoxShadow,
    Opacity: Opacity,
    FontWeight: FontWeight,
    Display: Display,
    Position: Position,
    Overflow: Overflow,
    Visibility: Visibility,
    FlexColumn: FlexColumn,
    FlexRow: FlexRow,
    FlexWrap: FlexWrap,
    FlexGrow: FlexGrow,
    FlexShrink: FlexShrink,
    FlexBasis: FlexBasis,
    GridColumn: GridColumn,
    GridRow: GridRow,
    Gap: Gap,
    AlignStart: AlignStart,
    AlignCenter: AlignCenter,
    AlignBaseline: AlignBaseline,
    AlignEnd: AlignEnd,
    AlignStretch: AlignStretch,
    JustifyStart: JustifyStart,
    JustifyCenter: JustifyCenter,
    JustifyEnd: JustifyEnd,
    JustifySpaceBetween: JustifySpaceBetween,
    JustifySpaceAround: JustifySpaceAround,
    JustifySpaceEvenly: JustifySpaceEvenly,
    PlaceCenter: PlaceCenter,
    Border: Border,
    BorderRadius: BorderRadius,
    ColorSet: ColorSet,
    Transition: Transition,
    ZIndex: ZIndex,
    Hover: Hover,
    Focus: Focus,
    Disabled: Disabled,
    Selector: Selector,
    Media: Media,
    Cursor: Cursor,
    Style: Style,
    Empty: Empty,
    boxPropStyle: boxPropStyle,
    "css'": css$prime,
    boxStyle: boxStyle,
    "box'": box$prime,
    box: box,
    semigroupBoxProp: semigroupBoxProp,
    monoidBoxProp: monoidBoxProp
};
