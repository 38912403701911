// The compiled purescript line does not have type annotations
// @ts-expect-error
import { useNavigateToRoute } from "../../../output/FrontEndRouteHelper";

type RouteName =
  | "RootRoute"
  | "TsRoute"
  | "ReportingProjectListRoute"
  | "EstimationProjectsRoute"
  | "EstimationProjectDetailsFERoute"
  | "PortfolioReportFERoute"
  | "AddCostControlProjectFERoute"
  | "AddEstimationProjectFERoute"
  | "ImportEstimationProjectFERoute"
  | "ReportsFERoute"
  | "IncomeFERoute"
  | "CostControlFERoute"
  | "InvoicesFERoute"
  | "MonthlyReportFERoute"
  | "CostControlPrintingFERoute"
  | "RenameMainCostGroupsFERoute"
  | "ProjectDetailsFERoute"
  | "PrintingFERoute"
  | "OrganizationFERoute"
  | "EstimationFERoute"
  | "EstimationReferenceRoute"
  | "ReferenceEstimationElementsRoute"
  | "EstimationBuildingElementsRoute"
  | "EstimationResourcesRoute"
  | "NewEstimationResourcesRoute"
  | "EstimationResourcePriceListRoute"
  | "OfferPageRoute"
  | "LogsFERoute"
  | "NotificationsFERoute"
  | "AdminFERoute"
  | "SuperAdminFERoute"
  | "IntegrationFERoute"
  | "ReleaseNotesFERoute"
  | "UIDemoFERoute"
  | "DixonFERoute"
  | "OauthCode";

type RouteOptions = {
  projectId?: number;
  initialSelectedComponent?: number;
  initialSelectedElement?: number;
  mFileId?: number;
  mDrawingId?: number;
  mMeasurementGroupId?: number;
};

// Returns a function that takes a route name and options
// (e.g. project id). The possible route names and options can be
// found in FrontEndRouteHelper.purs
export function useFrontEndNavigation() {
  const navigate = useNavigateToRoute();
  return function (route: RouteName, options: RouteOptions = {}) {
    navigate(route)(options)();
  };
}
