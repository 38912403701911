import { CellValueChangedEvent } from "ag-grid-community";
import { OfferParams, OfferParamsWithValue } from "./useOfferParamsQuery";
import { OfferClassificationSummary } from "./OfferPage";
import { AgGridReact } from "ag-grid-react";
import { safeDivide } from "../../../utils/safeDivide";

export function saveRegularRowEdit(
  event: CellValueChangedEvent,
  currentOfferValues: OfferParams,
  marginType: string | undefined
): OfferParamsWithValue {
  const newMarginPercentages: { value: number; key: string }[] = [];
  event.api.forEachNodeAfterFilter((node) => {
    if (node.data.code !== "grandTotal") {
      newMarginPercentages.push({
        value: node.data.marginPercentage,
        key: node.data.code,
      });
    }
  });
  const editOfferData = {
    showEconomicMargin: marginType === "economicMargin",
    values: {
      riskPercentages: currentOfferValues.riskPercentages,
      offerOverrides: currentOfferValues.offerOverrides,
      marginPercentages: newMarginPercentages,
      rowDescriptions: currentOfferValues.rowDescriptions,
      generalPercentages: currentOfferValues.generalPercentages,
      changePercentages: currentOfferValues.changePercentages,
      specialPercentages: currentOfferValues.specialPercentages,
    },
  };
  return editOfferData;
}

export function savePinnedRowEdit(
  event: CellValueChangedEvent<OfferClassificationSummary>,
  marginType: string | undefined,
  offerParams: OfferParams | undefined
) {
  let grandTotal = 0;
  let margin = 0;
  event.api.forEachNodeAfterFilter((node) => {
    if (node.data) {
      grandTotal += node.data.total;
      if (marginType === "economicMargin") {
        margin += node.data.economicMargin;
      } else {
        margin += node.data.technicalMargin;
      }
    }
  });
  const marginPercentage =
    marginType === "economicMargin"
      ? safeDivide(margin, grandTotal + margin)
      : safeDivide(margin, grandTotal);
  const newGeneralPercentage = [
    {
      value: marginPercentage,
      key: "summary",
    },
  ];
  const editOfferData = {
    showEconomicMargin: marginType === "economicMargin",
    values: {
      riskPercentages: offerParams?.riskPercentages ?? [],
      marginPercentages: newGeneralPercentage,
      offerOverrides: offerParams?.offerOverrides ?? [],
      rowDescriptions: offerParams?.rowDescriptions ?? [],
      generalPercentages: offerParams?.generalPercentages ?? [],
      changePercentages: offerParams?.changePercentages ?? [],
      specialPercentages: offerParams?.specialPercentages ?? [],
    },
  };
  return editOfferData;
}

export function getAllOfferParams(
  gridRef: React.RefObject<AgGridReact<OfferClassificationSummary>>,
  marginType: string | undefined,
  offerParams: OfferParams | undefined
) {
  const newPercentages: {
    value: number;
    key: string;
  }[] = [];
  gridRef.current?.api.forEachNode((node) => {
    if (node.data) {
      newPercentages.push({
        value: node.data.marginPercentage,
        key: node.data.code,
      });
    }
  });
  const firstPercentage = newPercentages[0].value;
  const allHaveSamePercentage = newPercentages.every(
    (value) => value.value === firstPercentage
  );
  const generalPercentage = allHaveSamePercentage
    ? [{ value: firstPercentage, key: "summary" }]
    : undefined;
  return {
    showEconomicMargin: marginType === "economicMargin",
    values: {
      riskPercentages: offerParams?.riskPercentages ?? [],
      marginPercentages: generalPercentage ?? newPercentages,
      offerOverrides: offerParams?.offerOverrides ?? [],
      rowDescriptions: offerParams?.rowDescriptions ?? [],
      generalPercentages: offerParams?.generalPercentages ?? [],
      changePercentages: offerParams?.changePercentages ?? [],
      specialPercentages: offerParams?.specialPercentages ?? [],
    },
  };
}
