// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var Utils = require("../Utils/index.js");
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColQuantity = (function () {
    function ColQuantity() {

    };
    ColQuantity.value = new ColQuantity();
    return ColQuantity;
})();
var ColPrice = (function () {
    function ColPrice() {

    };
    ColPrice.value = new ColPrice();
    return ColPrice;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var mkRow = function (v) {
    return {
        className: v.className,
        rowData: v.rowData,
        foldingElement: Data_Maybe.Nothing.value,
        onClick: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        rowKey: v.rowKey,
        columns: v.columns
    };
};
var mkAddComponentTableColumns = function (props) {
    var addComponentButton = function (r) {
        return TacoButton.component()()({
            text: "+ Lis\xe4\xe4 suorite",
            buttonStyle: TacoButton_Types.Link.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(props.addRefComponent(r)),
            testId: "add-component-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Component.ComponentId)(r.id)) + "-button")
        });
    };
    return [ {
        key: "code",
        label: React_Basic_DOM.text("Koodi"),
        width: new TacoTable_Types.Fixed("10rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $17 = Utils.fromNullable("");
            return function ($18) {
                return $17((function (v) {
                    return v.code;
                })($18));
            };
        })()),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "description",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.FlexMinWidth(1, "20rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.description;
        }),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "amount",
        label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
        width: new TacoTable_Types.Fixed("10rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.PlainNumberCell(2, function (v) {
            return v.amount;
        }),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "unit",
        label: React_Basic_DOM.text("Yksikk\xf6"),
        width: new TacoTable_Types.Fixed("7rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $19 = Utils.fromNullable("");
            return function ($20) {
                return $19((function (v) {
                    return v.unit;
                })($20));
            };
        })()),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "button",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("8rem"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], addComponentButton),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var getRow = function (selectedComponentId) {
    return function (columns) {
        return function (v) {
            return function (r) {
                var selectionClass = function (v1) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return "";
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        var $13 = Data_Eq.eq(Types_Component.eqComponentId)(v1.value0)(r.id);
                        if ($13) {
                            return "selected";
                        };
                        return "";
                    };
                    throw new Error("Failed pattern match at AddComponentTable (line 65, column 5 - line 65, column 32): " + [ v1.constructor.name ]);
                };
                var className = TacoTable_Cells.classNames([ "AddComponentTableRow", selectionClass(selectedComponentId) ]);
                return mkRow({
                    className: className,
                    rowData: r,
                    rowKey: "add-component-" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Component.ComponentId)(r.id)),
                    columns: columns
                });
            };
        };
    };
};
var addComponentOptimizedTable = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)));
var addComponentTable = TofuHooks.mkHookComponent("AddComponentTable")(function (props) {
    var selectedComponent = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(props.components))(props.selectedRefComponentIdx);
    var selectedComponentId = (function () {
        if (selectedComponent instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        if (selectedComponent instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(selectedComponent.value0.id);
        };
        throw new Error("Failed pattern match at AddComponentTable (line 104, column 29 - line 106, column 66): " + [ selectedComponent.constructor.name ]);
    })();
    var columns = mkAddComponentTableColumns({
        addRefComponent: props.addRefComponent
    });
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        return Box.box("__AddComponentTable")([ new Box.Border(TacoConstants.BorderLight.value), new Box.FlexGrow(1), Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Selector(".__TacoTable", [ Box.MinHeight.create(new TacoConstants.CustomSize("auto")) ]), new Box.Position(Box.Relative.value), new Box.Height(new TacoConstants.CustomSize("0")) ])([ addComponentOptimizedTable({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: props.components,
            mkRow: getRow(selectedComponentId)(columns),
            showHeader: true,
            rowHeight: TacoTable_Types.RowMedium.value,
            columns: columns,
            styleSet: styleSet,
            containerRef: tableRef,
            initialScrollRow: Data_Maybe.Nothing.value
        }) ]);
    };
});
module.exports = {
    addComponentOptimizedTable: addComponentOptimizedTable,
    styleSet: styleSet,
    mkRow: mkRow,
    ColName: ColName,
    ColQuantity: ColQuantity,
    ColPrice: ColPrice,
    getRow: getRow,
    mkAddComponentTableColumns: mkAddComponentTableColumns,
    addComponentTable: addComponentTable
};
