// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_AddComponent = require("../CostEstimation.AddComponent/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_EstimationFunctions = require("../CostEstimation.EstimationFunctions/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var EstimationComponentsTable_Internal_EditMultiplePrices = require("../EstimationComponentsTable.Internal.EditMultiplePrices/index.js");
var EstimationComponentsTableHelpers = require("../EstimationComponentsTableHelpers/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Planning = require("../Types.Planning/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var ContainerContext = (function () {
    function ContainerContext() {

    };
    ContainerContext.value = new ContainerContext();
    return ContainerContext;
})();
var DetailsContext = (function () {
    function DetailsContext() {

    };
    DetailsContext.value = new DetailsContext();
    return DetailsContext;
})();
var QtyCanEdit = (function () {
    function QtyCanEdit() {

    };
    QtyCanEdit.value = new QtyCanEdit();
    return QtyCanEdit;
})();
var QtyNotEditable = (function () {
    function QtyNotEditable() {

    };
    QtyNotEditable.value = new QtyNotEditable();
    return QtyNotEditable;
})();
var unEC = (function () {
    var $415 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
    return function ($416) {
        return $415((function (v) {
            return v.estimationComponentWithResources;
        })($416));
    };
})();
var styleSet = CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.componentColors);
var prepareECColumns = function (classColumnEnabled) {
    return function (viewMode) {
        return function (rowProps) {
            return function (v) {
                return function (f) {
                    var selectedCostClasses = Data_Function.flip(Data_Array.filter)(rowProps.costClasses)(function (cc) {
                        return Data_Set.member(Types_Newtypes.ordCostClassCode)(cc.costClassCode)(rowProps.seletedShowCostClassCodes);
                    });
                    var selectAllCheckbox = TacoCheckbox.component()()({
                        className: "select-all-checkbox",
                        isChecked: !v.isSelectionEmpty,
                        onToggle: TacoCheckbox.NoEvent.create(v.onToggleSelectAll),
                        testId: "select-all-checkbox"
                    });
                    var prepare = function (v1) {
                        var v2 = f(v1.col);
                        return {
                            key: v1.key,
                            label: v1.label,
                            width: v1.width,
                            cell: v2.cell,
                            sortProperty: v2.sortProperty,
                            headerJSX: v1.headerJSX
                        };
                    };
                    var splitComponentWithMissingResourcesColumns = (function () {
                        if (rowProps.showSplitComponentWithMissingResourcesColumn) {
                            return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
                                col: CostEstimation_Types.ColSplitComponentWithMissingResources.value,
                                key: "split-info",
                                label: React_Basic_DOM.text(""),
                                width: new TacoTable_Types.Fixed("2rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            } ]);
                        };
                        return Common.emptyArray;
                    })();
                    var mkSocialExpenseLabel = function (text) {
                        return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(rowProps.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), React_Basic_DOM.text(text) ]);
                    };
                    var totalPriceLabel = mkSocialExpenseLabel("Yhteens\xe4");
                    var mkCostClassHoursPerUnitColumn = function (cc) {
                        return {
                            key: cc.name + "-cc-hours-per-unit",
                            col: new CostEstimation_Types.ColCostClassHoursPerUnit(cc.costClassCode),
                            label: (function () {
                                var text = Data_Newtype.unwrap()(cc.costClassCode) + (": " + (cc.name + " h/Yks."));
                                return React_Basic_DOM.text(text);
                            })(),
                            width: new TacoTable_Types.Fixed("9rem"),
                            headerJSX: Data_Maybe.Nothing.value
                        };
                    };
                    var hoursColumns = (function () {
                        if (rowProps.showHoursColumns) {
                            return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
                                col: CostEstimation_Types.ColHours.value,
                                key: "hours",
                                label: React_Basic_DOM.text("Tunnit"),
                                width: new TacoTable_Types.Fixed("9rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            }, {
                                col: CostEstimation_Types.ColHourlyRate.value,
                                key: "hourly_rate",
                                label: mkSocialExpenseLabel("Keskituntiansio"),
                                width: new TacoTable_Types.Fixed("9rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            } ]);
                        };
                        return Common.emptyArray;
                    })();
                    var currencySymbol = EstimationUtils.getCurrencySymbol(rowProps.projectCurrency);
                    var mkCostClassTotalPriceColumn = function (cc) {
                        return {
                            key: cc.name + "-cc-total-price",
                            col: new CostEstimation_Types.ColCostClassTotalPrice(cc.costClassCode),
                            label: (function () {
                                var text = Data_Newtype.unwrap()(cc.costClassCode) + (": " + (cc.name + (" " + currencySymbol)));
                                var v1 = Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(cc.costClassCode)(rowProps.socialExpensePercentagesByCostClass);
                                if (v1 instanceof Data_Maybe.Just && Data_Newtype.unwrap()(Data_Newtype.unwrap()(v1.value0)) !== 0.0) {
                                    return mkSocialExpenseLabel(text);
                                };
                                return React_Basic_DOM.text(text);
                            })(),
                            width: new TacoTable_Types.Fixed("9rem"),
                            headerJSX: Data_Maybe.Nothing.value
                        };
                    };
                    var mkCostClassUnitPriceColumn = function (cc) {
                        return {
                            key: cc.name + "-cc-unit-price",
                            col: new CostEstimation_Types.ColCostClassUnitPrice(cc.costClassCode),
                            label: (function () {
                                var text = Data_Newtype.unwrap()(cc.costClassCode) + (": " + (cc.name + (" " + (currencySymbol + "/Yks."))));
                                var v1 = Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(cc.costClassCode)(rowProps.socialExpensePercentagesByCostClass);
                                if (v1 instanceof Data_Maybe.Just && Data_Newtype.unwrap()(Data_Newtype.unwrap()(v1.value0)) !== 0.0) {
                                    return mkSocialExpenseLabel(text);
                                };
                                return React_Basic_DOM.text(text);
                            })(),
                            width: new TacoTable_Types.Fixed("9rem"),
                            headerJSX: Data_Maybe.Nothing.value
                        };
                    };
                    var prepareColumnsForCostClass = function (costClass) {
                        var costClassUnitPrice = (function () {
                            if (rowProps.showCostClassUnitPrice) {
                                return Data_Maybe.Just.create(prepare(mkCostClassUnitPriceColumn(costClass)));
                            };
                            return Data_Maybe.Nothing.value;
                        })();
                        var costClassTotalPrice = (function () {
                            if (rowProps.showCostClassTotalPrice) {
                                return Data_Maybe.Just.create(prepare(mkCostClassTotalPriceColumn(costClass)));
                            };
                            return Data_Maybe.Nothing.value;
                        })();
                        var costClassHoursPerUnit = (function () {
                            var $183 = rowProps.showCostClassHoursPerUnit && costClass.hourlyPricing;
                            if ($183) {
                                return Data_Maybe.Just.create(prepare(mkCostClassHoursPerUnitColumn(costClass)));
                            };
                            return Data_Maybe.Nothing.value;
                        })();
                        return Data_Array.catMaybes([ costClassHoursPerUnit, costClassUnitPrice, costClassTotalPrice ]);
                    };
                    var unitForUnitPriceLabel = mkSocialExpenseLabel(currencySymbol + "/Yks.");
                    var costClassColumns = (function () {
                        if (rowProps.showCostClassColumns) {
                            return Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(prepareColumnsForCostClass)(selectedCostClasses));
                        };
                        return Common.emptyArray;
                    })();
                    var conditionalColumns = (function () {
                        if (viewMode instanceof Types_CostEstimation.ReferenceMode) {
                            if (rowProps.shortenReferenceProjectDetails) {
                                return [ {
                                    col: CostEstimation_Types.ColProject.value,
                                    key: "project",
                                    label: React_Basic_DOM.text("Hanke"),
                                    width: new TacoTable_Types.Fixed("5rem"),
                                    headerJSX: Data_Maybe.Nothing.value
                                } ];
                            };
                            return [ {
                                col: CostEstimation_Types.ColProject.value,
                                key: "project",
                                label: React_Basic_DOM.text("Hanke"),
                                width: new TacoTable_Types.Fixed("24rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            } ];
                        };
                        if (viewMode instanceof Types_CostEstimation.EditMode) {
                            var subprojectColumn = (function () {
                                if (rowProps.showSubprojects) {
                                    return [ {
                                        col: CostEstimation_Types.ColSubProjectCode.value,
                                        key: "subProjectCode",
                                        label: React_Basic_DOM.text("AK"),
                                        width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                                        headerJSX: Data_Maybe.Nothing.value
                                    } ];
                                };
                                return Common.emptyArray;
                            })();
                            var groupColumn = (function () {
                                if (rowProps.showGroups) {
                                    return [ {
                                        col: CostEstimation_Types.ColGroup.value,
                                        key: "group",
                                        label: React_Basic_DOM.text("Ryhm\xe4"),
                                        width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                                        headerJSX: Data_Maybe.Nothing.value
                                    } ];
                                };
                                return Common.emptyArray;
                            })();
                            var costGroupColumn = (function () {
                                if (rowProps.showTargetCostGroup) {
                                    return [ {
                                        col: CostEstimation_Types.ColCostGroup.value,
                                        key: "costGroup",
                                        label: React_Basic_DOM.text("Tavoitelittera"),
                                        width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                                        headerJSX: Data_Maybe.Nothing.value
                                    } ];
                                };
                                return Common.emptyArray;
                            })();
                            return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                                col: CostEstimation_Types.ColFlags.value,
                                key: "flags",
                                label: React_Basic_DOM.text("Merkinn\xe4t"),
                                width: new TacoTable_Types.Fixed("12rem"),
                                headerJSX: Data_Maybe.Nothing.value
                            } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(subprojectColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(groupColumn)(costGroupColumn)));
                        };
                        throw new Error("Failed pattern match at EstimationComponentsTable (line 118, column 26 - line 136, column 167): " + [ viewMode.constructor.name ]);
                    })();
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
                        col: CostEstimation_Types.ColSelectionCheckbox.value,
                        key: "selectionCheckbox",
                        label: React_Basic_DOM.text(""),
                        width: new TacoTable_Types.Fixed("2rem"),
                        headerJSX: new Data_Maybe.Just(selectAllCheckbox)
                    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)(conditionalColumns))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Monoid.guard(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Data_Monoid.monoidArray)))(classColumnEnabled)(Data_Functor.map(Data_Functor.functorArray))(prepare)([ {
                        col: CostEstimation_Types.ColClass.value,
                        key: "class",
                        label: React_Basic_DOM.text("Luokka"),
                        width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
                        col: CostEstimation_Types.ColCode.value,
                        key: "code",
                        label: React_Basic_DOM.text("Koodi"),
                        width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColDescription.value,
                        key: "description",
                        label: React_Basic_DOM.text("Selite"),
                        width: new TacoTable_Types.FlexMinWidth(10, "16rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColAmount.value,
                        key: "amount",
                        label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
                        width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColUnit.value,
                        key: "unit",
                        label: React_Basic_DOM.text("Yks."),
                        width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColUnitPrice.value,
                        key: "unitPrice",
                        label: React_Basic_DOM.text(""),
                        width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColUnitForUnitPrice.value,
                        key: "unitForUnitPrice",
                        label: unitForUnitPriceLabel,
                        width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColTotalPrice.value,
                        key: "totalPrice",
                        label: totalPriceLabel,
                        width: new TacoTable_Types.FlexMinWidth(1, "9rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        col: CostEstimation_Types.ColPriceUnit.value,
                        key: "totalPriceUnit",
                        label: React_Basic_DOM.text(""),
                        width: new TacoTable_Types.Fixed("2rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(splitComponentWithMissingResourcesColumns)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(hoursColumns)(costClassColumns))))));
                };
            };
        };
    };
};
var mkIsComponentQuantityEditable = function (allLocations) {
    var locationsByComponentId = Utils.groupMap(Types_Component.eqComponentId)(Types_Component.ordComponentId)(function (v) {
        return v.estimationComponentId;
    })(allLocations);
    return function (componentId) {
        var v = Data_Map_Internal.lookup(Types_Component.ordComponentId)(componentId)(locationsByComponentId);
        if (v instanceof Data_Maybe.Just && Data_Array["null"](v.value0)) {
            return QtyCanEdit.value;
        };
        var v1 = function (v2) {
            return QtyNotEditable.value;
        };
        if (v instanceof Data_Maybe.Just) {
            var $195 = Data_Array.length(v.value0) === 1;
            if ($195) {
                var $196 = Data_Array.head(v.value0);
                if ($196 instanceof Data_Maybe.Just) {
                    var $197 = Data_Maybe.isNothing(Data_Nullable.toMaybe($196.value0.elementSpecId));
                    if ($197) {
                        var $198 = $196.value0.locationCode === "*";
                        if ($198) {
                            return QtyCanEdit.value;
                        };
                        return v1(true);
                    };
                    return v1(true);
                };
                return v1(true);
            };
            return v1(true);
        };
        return v1(true);
    };
};
var mkContainsElementSpecLocations = function (allLocations) {
    var locationsByComponentId = Utils.groupMap(Types_Component.eqComponentId)(Types_Component.ordComponentId)(function (v) {
        return v.estimationComponentId;
    })(allLocations);
    return function (componentId) {
        var v = Data_Map_Internal.lookup(Types_Component.ordComponentId)(componentId)(locationsByComponentId);
        var v1 = function (v2) {
            return false;
        };
        if (v instanceof Data_Maybe.Just) {
            var $202 = Data_Array.find(function ($417) {
                return Data_Maybe.isJust(Data_Nullable.toMaybe((function (v2) {
                    return v2.elementSpecId;
                })($417)));
            })(v.value0);
            if ($202 instanceof Data_Maybe.Just) {
                return true;
            };
            return v1(true);
        };
        return v1(true);
    };
};
var getSortingValue = function (v) {
    return function (v1) {
        if (v1 instanceof CostEstimation_Types.Flags) {
            var f = function (r) {
                return r.component.checked || r.component.flagged;
            };
            var $418 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($419) {
                return TacoTable_Types.BooleanValue.create(f($418($419)));
            };
        };
        if (v1 instanceof CostEstimation_Types.ProjectOfOrigin) {
            var $420 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($421) {
                return TacoTable_Types.StringValue.create(CostEstimation_EstimationFunctions.projectCodeAndName((function (v2) {
                    return v2.projectOfOrigin;
                })($420($421))));
            };
        };
        if (v1 instanceof CostEstimation_Types.Group) {
            var $422 = Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode));
            var $423 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($424) {
                return TacoTable_Types.StringValue.create($422((function (v2) {
                    return v2.component.group;
                })($423($424))));
            };
        };
        if (v1 instanceof CostEstimation_Types.CostGroup) {
            var $425 = Utils.fromNullable("");
            var $426 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($427) {
                return TacoTable_Types.StringValue.create($425((function (v2) {
                    return v2.component.costGroupCode;
                })($426($427))));
            };
        };
        if (v1 instanceof CostEstimation_Types.Class) {
            var $428 = Utils.fromNullable("");
            var $429 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($430) {
                return TacoTable_Types.StringValue.create($428((function (v2) {
                    return v2.component.classCode;
                })($429($430))));
            };
        };
        if (v1 instanceof CostEstimation_Types.Code) {
            var byCode = (function () {
                var $431 = Utils.fromNullable("");
                var $432 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
                return function ($433) {
                    return TacoTable_Types.StringValue.create($431((function (v2) {
                        return v2.component.code;
                    })($432($433))));
                };
            })();
            return function (r) {
                return TacoTable_Types.TupleValue.create(new Data_Tuple.Tuple(byCode(r), getSortingValue(v)(CostEstimation_Types.SubProjectCode.value)(r)));
            };
        };
        if (v1 instanceof CostEstimation_Types.Description) {
            var $434 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($435) {
                return TacoTable_Types.StringValue.create((function (v2) {
                    return v2.component.description;
                })($434($435)));
            };
        };
        if (v1 instanceof CostEstimation_Types.Amount) {
            var $436 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($437) {
                return TacoTable_Types.NumberValue.create((function (v2) {
                    return v2.component.amount;
                })($436($437)));
            };
        };
        if (v1 instanceof CostEstimation_Types.Unit) {
            var $438 = Utils.fromNullable("");
            var $439 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($440) {
                return TacoTable_Types.StringValue.create($438((function (v2) {
                    return v2.component.unit;
                })($439($440))));
            };
        };
        if (v1 instanceof CostEstimation_Types.SubProjectCode) {
            var $441 = Utils.fromNullable("");
            var $442 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($443) {
                return TacoTable_Types.StringValue.create($441((function (v2) {
                    return v2.component.subProjectCode;
                })($442($443))));
            };
        };
        if (v1 instanceof CostEstimation_Types.UnitPrice) {
            var $444 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($445) {
                return TacoTable_Types.NumberValue.create((function (c) {
                    return CostEstimation_Calculations.componentUnitPrice(v)(c.resources);
                })($444($445)));
            };
        };
        if (v1 instanceof CostEstimation_Types.TotalPrice) {
            var $446 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
            return function ($447) {
                return TacoTable_Types.NumberValue.create((function (c) {
                    return CostEstimation_Calculations.componentTotalPrice(v)(c.component)(c.resources);
                })($446($447)));
            };
        };
        throw new Error("Failed pattern match at EstimationComponentsTable (line 217, column 1 - line 217, column 128): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var updateSortingCriteriaSEP = function (sep) {
    return function (v) {
        return new TacoTable_Types.SortingCriteria(v.value0, v.value1, getSortingValue(sep));
    };
};
var getRow = function (columnsByComponentType) {
    return function (props) {
        return function (idx) {
            return function (v) {
                var selected = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(props.selectedComponentIdx)(new Data_Maybe.Just(idx));
                var dixonLinks = (function () {
                    if (props.dixonOptions instanceof Data_Maybe.Just) {
                        return Data_Map_Internal.lookup(Types_Component.ordComponentId)(v.estimationComponentWithResources.component.id)(props.dixonOptions.value0.measurement_group_links_by_component);
                    };
                    if (props.dixonOptions instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    throw new Error("Failed pattern match at EstimationComponentsTable (line 740, column 18 - line 742, column 25): " + [ props.dixonOptions.constructor.name ]);
                })();
                var isActiveMgLink = (function () {
                    if (props.dixonOptions instanceof Data_Maybe.Just && dixonLinks instanceof Data_Maybe.Just) {
                        var matching_mg_ids = Data_Array.intersect(Data_Eq.eqInt)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.measurement_group_id;
                        })(dixonLinks.value0))(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.id;
                        })(props.dixonOptions.value0.active_measurement_groups));
                        return Data_Array.length(matching_mg_ids) > 0;
                    };
                    return false;
                })();
                var columns = Data_Maybe.fromMaybe(Common.emptyArray)(Data_Map_Internal.lookup(Types_Component.ordComponentType)(Types_Component.intToComponentType(v.estimationComponentWithResources.component.componentType))(columnsByComponentType));
                var className = TacoTable_Cells.classNames([ (function () {
                    if (selected) {
                        return "selected";
                    };
                    return "";
                })(), (function () {
                    var v1 = Types_Component.intToComponentType(v.estimationComponentWithResources.component.componentType);
                    if (v1 instanceof Types_Component.TopicComponent) {
                        return "topic-component";
                    };
                    return "";
                })(), Data_Monoid.guard(Data_Monoid.monoidString)(v.estimationComponentWithResources.checkboxState)("checked"), Data_Monoid.guard(Data_Monoid.monoidString)(isActiveMgLink)("dixon-link") ]);
                var activeDixonLinks = (function () {
                    if (props.dixonOptions instanceof Data_Maybe.Just && dixonLinks instanceof Data_Maybe.Just) {
                        return Data_Maybe.Just.create(Data_Array.filter(function (mgl) {
                            return Data_Array.elem(Data_Eq.eqInt)(mgl.measurement_group_id)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.id;
                            })(props.dixonOptions.value0.active_measurement_groups));
                        })(dixonLinks.value0));
                    };
                    return Data_Maybe.Nothing.value;
                })();
                return {
                    rowData: {
                        estimationComponentWithResources: v.estimationComponentWithResources,
                        socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                        shortenReferenceProjectDetails: props.shortenReferenceProjectDetails,
                        selectedRowColumn: (function () {
                            if (selected) {
                                return props.selectedInputColumn;
                            };
                            return Data_Maybe.Nothing.value;
                        })(),
                        dixonLinks: dixonLinks,
                        activeDixonLinks: activeDixonLinks
                    },
                    rowKey: Data_Show.show(Data_Show.showInt)(idx),
                    onClick: props.onRowClick,
                    className: className,
                    foldingElement: Data_Maybe.Nothing.value,
                    columns: columns
                };
            };
        };
    };
};
var getComponentsToRender = function (v) {
    return function (wasmComponentWithResources) {
        return function (v1) {
            return function (v2) {
                return function (checkedComponents) {
                    if (v.loading) {
                        return Common.emptyArray;
                    };
                    var fn = function (r) {
                        return {
                            resources: r.resources,
                            component: r.component,
                            locations: r.locations,
                            checkboxState: Data_Set.member(Types_Component.ordComponentId)(r.component.id)(checkedComponents),
                            totalPrice: r.totalPrice,
                            totalPriceWithoutSocialExpenses: r.totalPriceWithoutSocialExpenses,
                            totalPriceByCostClasses: r.totalPriceByCostClasses,
                            hours: r.hours,
                            hourlyRate: r.hourlyRate,
                            projectOfOrigin: Data_Nullable.toMaybe(r.projectOfOrigin),
                            isSplitComponentWithMissingResources: false
                        };
                    };
                    return Data_Functor.map(Data_Functor.functorArray)(fn)(wasmComponentWithResources);
                };
            };
        };
    };
};
var eqQuantityEditable = {
    eq: function (x) {
        return function (y) {
            if (x instanceof QtyCanEdit && y instanceof QtyCanEdit) {
                return true;
            };
            if (x instanceof QtyNotEditable && y instanceof QtyNotEditable) {
                return true;
            };
            return false;
        };
    }
};
var emptyCell = function (v) {
    return React_Basic.empty;
};
var componentInfoTableRowHeight = TacoTable_Types.RowMedium.value;
var componentInfoTableCellHeight = TacoTable_Types.RowSmall.value;
var boldTextCell = function (property) {
    return function (r) {
        var text = property(r);
        var style = React_Basic_DOM_Internal.css({
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        });
        return React_Basic_DOM_Generated.span()({
            className: "bold",
            style: style,
            title: text,
            children: [ React_Basic_DOM.text(text) ]
        });
    };
};
var getEstimationComponentColumns = function (componentType) {
    return function (props) {
        return function (selectionProps) {
            return function (classColumnEnabled) {
                var unitPriceCell = function (v) {
                    return CostEstimation_Calculations.componentUnitPrice(props.socialExpensePercentagesByCostClass)(v.estimationComponentWithResources.resources);
                };
                var unitPrice = function (r) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(2)(unitPriceCell(r)),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("unit-price")
                    });
                };
                var totalPriceMaybeCell = new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                    var $245 = r.estimationComponentWithResources.totalPrice !== 0.0;
                    if ($245) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(r.estimationComponentWithResources.totalPrice),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("total-price")
                        });
                    };
                    return React_Basic.empty;
                });
                var totalPriceCell = function (v) {
                    return v.estimationComponentWithResources.totalPrice;
                };
                var totalPrice = function (r) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(2)(totalPriceCell(r)),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("total-price")
                    });
                };
                var topicComponentDescription = function (r) {
                    return TacoText.component()()({
                        text: (function (v) {
                            return v.component.description;
                        })(unEC(r)),
                        weight: TacoText_Types.Bold.value,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("topic-description")
                    });
                };
                var topicComponentCode = function (r) {
                    return TacoText.component()()({
                        text: Utils.fromNullable("")((function (v) {
                            return v.component.code;
                        })(unEC(r))),
                        weight: TacoText_Types.Bold.value,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("topic-code")
                    });
                };
                var splitComponentWithMissingResourcesCell = new TacoTable_Types.JSXCell([  ], function (r) {
                    if (r.estimationComponentWithResources.isSplitComponentWithMissingResources) {
                        return TacoIcon.component()()()()({
                            icon: TacoIcon_Icons_Types.IconInfoWhite.value,
                            size: TacoConstants.XS.value,
                            title: "Suoritteen panostasolle on merkitty useita ryhmi\xe4. Suorite esiintyy kaikissa n\xe4iss\xe4 ryhmiss\xe4, mutta kustannukset ovat vain niilt\xe4 panosriveilt\xe4 mihin on merkitty otsikon mukainen ryhm\xe4."
                        });
                    };
                    return React_Basic.empty;
                });
                var project = function (r) {
                    return TacoText.component()()({
                        text: CostEstimation_EstimationFunctions.projectCodeAndName((function (v) {
                            return v.projectOfOrigin;
                        })(unEC(r))),
                        title: CostEstimation_EstimationFunctions.projectCodeAndName((function (v) {
                            return v.projectOfOrigin;
                        })(unEC(r))),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("project")
                    });
                };
                var onInputBlur = props.setSelectedInputColumn(Data_Maybe.Nothing.value);
                var mkOnFocus = function (col) {
                    return function (row) {
                        return function __do() {
                            Data_Monoid.guard(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))))(Data_Eq.notEq(Data_Maybe.eqMaybe(Types_CostEstimation.eqEstimationComponentWithResources))(props.selectedComponent)(new Data_Maybe.Just(row.estimationComponentWithResources)))(props.setSelectedComponent)(false)(new Data_Maybe.Just(row.estimationComponentWithResources))();
                            return props.setSelectedInputColumn(new Data_Maybe.Just(col))();
                        };
                    };
                };
                var mkFlagIcon = function (icon) {
                    return function (color) {
                        return function (condition) {
                            return function (title) {
                                return TacoIcon.component()()()()({
                                    icon: icon,
                                    size: TacoConstants.XS.value,
                                    color: (function () {
                                        if (condition) {
                                            return color;
                                        };
                                        return TacoConstants.Transparent.value;
                                    })(),
                                    title: title
                                });
                            };
                        };
                    };
                };
                var mkAction = function (componentId) {
                    return function (mkEditAction$prime) {
                        return function (value) {
                            return function (handler) {
                                return new Actions.EstimationComponentActionRequest({
                                    projectId: props.projectId,
                                    params: new Types_Estimation.EditEstimationComponent({
                                        componentId: componentId,
                                        editAction: mkEditAction$prime(value)
                                    }),
                                    handler: handler
                                });
                            };
                        };
                    };
                };
                var mkQuantityInput = function (v) {
                    return function (testId) {
                        return function (flexGrow) {
                            return function (minWidth) {
                                return function (value) {
                                    return function (handler) {
                                        return function (row) {
                                            var component = (unEC(row)).component;
                                            var input = Box.box("amountInput")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
                                                flexWrap: React_Basic_Emotion.str("nowrap")
                                            })) ])([ (function () {
                                                if (row.activeDixonLinks instanceof Data_Maybe.Just) {
                                                    var linkTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v1) {
                                                        return v1.from_type;
                                                    }))(Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                                                        return v1.from_type;
                                                    })(row.activeDixonLinks.value0));
                                                    return Box.box("linkTypeWrapper")([ Box.PaddingTop.create(new TacoConstants.CustomSize("3px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                                                        wrap: TacoText_Types.NoWrap.value,
                                                        text: (function () {
                                                            var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v3) {
                                                                return v3.from_type;
                                                            })(Data_Array_NonEmpty.head))(Data_Array.head(linkTypes));
                                                            var v2 = Data_Array.length(linkTypes);
                                                            if (v2 === 0) {
                                                                return "";
                                                            };
                                                            if (v2 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Formula")) {
                                                                return "(\u0192)";
                                                            };
                                                            if (v2 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Area")) {
                                                                return "(A)";
                                                            };
                                                            if (v2 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Perimeter")) {
                                                                return "(P)";
                                                            };
                                                            if (v2 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "NumVertices")) {
                                                                return "(N)";
                                                            };
                                                            return "(...)";
                                                        })(),
                                                        testId: testId + "-link-type"
                                                    })));
                                                };
                                                if (row.activeDixonLinks instanceof Data_Maybe.Nothing) {
                                                    return React_Basic.empty;
                                                };
                                                throw new Error("Failed pattern match at EstimationComponentsTable (line 512, column 13 - line 528, column 34): " + [ row.activeDixonLinks.constructor.name ]);
                                            })(), TacoInput.remoteNumberField()()({
                                                value: value,
                                                onFocus: mkOnFocus(CostEstimation_Types.ColAmount.value)(row),
                                                onBlur: onInputBlur,
                                                autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn))(row.selectedRowColumn)(new Data_Maybe.Just(CostEstimation_Types.ColAmount.value)),
                                                mkAction: mkAction(component.id)(handler),
                                                validator: function (v1) {
                                                    return TacoInput.Valid.value;
                                                },
                                                stateKey: Data_Show.show(Types_Component.showComponentId)(component.id),
                                                align: TacoInput.RightAlign.value,
                                                disabled: (function () {
                                                    var v1 = props.isComponentQuantityEditable(component.id);
                                                    if (v1 instanceof QtyCanEdit) {
                                                        return false;
                                                    };
                                                    if (v1 instanceof QtyNotEditable) {
                                                        return true;
                                                    };
                                                    throw new Error("Failed pattern match at EstimationComponentsTable (line 539, column 27 - line 541, column 41): " + [ v1.constructor.name ]);
                                                })(),
                                                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                                                testId: testId
                                            }) ]);
                                            return EstimationComponentsTableHelpers.inputWrapper(flexGrow)(minWidth)(input);
                                        };
                                    };
                                };
                            };
                        };
                    };
                };
                var quantityCellInput = function (row) {
                    var component = (unEC(row)).component;
                    var calculatedQuantity = CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.componentCalculationAmount(component));
                    return mkQuantityInput("M\xe4\xe4r\xe4")(EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("quantity-input"))(1)("5rem")(calculatedQuantity)(function (quantity) {
                        return new Types_Estimation.EditEstimationComponentQuantity({
                            quantity: quantity
                        });
                    })(row);
                };
                var mkStringInput = function (col) {
                    return function (v) {
                        return function (testId) {
                            return function (flexGrow) {
                                return function (minWidth) {
                                    return function (maxLength) {
                                        return function (value) {
                                            return function (handler) {
                                                return function (row) {
                                                    var component = (unEC(row)).component;
                                                    var input = TacoInput.remoteStringField()()({
                                                        value: value,
                                                        onFocus: mkOnFocus(col)(row),
                                                        onBlur: onInputBlur,
                                                        autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn))(row.selectedRowColumn)(new Data_Maybe.Just(col)),
                                                        mkAction: mkAction(component.id)(handler),
                                                        validator: LengthValidator.lengthValidator(0)(maxLength),
                                                        stateKey: Data_Show.show(Types_Component.showComponentId)(component.id),
                                                        rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                                                        testId: testId
                                                    });
                                                    return EstimationComponentsTableHelpers.inputWrapper(flexGrow)(minWidth)(input);
                                                };
                                            };
                                        };
                                    };
                                };
                            };
                        };
                    };
                };
                var unitCellInput = function (row) {
                    var component = (unEC(row)).component;
                    return mkStringInput(CostEstimation_Types.ColUnit.value)("Yks.")(EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("unit-input"))(1)("4rem")(20)(Utils.fromNullable("")(component.unit))(function (unit) {
                        return new Types_Estimation.EditEstimationComponentUnit({
                            unit: unit
                        });
                    })(row);
                };
                var memoIndicator = function (row) {
                    var r = unEC(row);
                    return TacoButton.actionToggleButton({
                        value: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(r.component.memo),
                        icon: TacoIcon_Icons_Types.IconMemoIndicatorV1.value,
                        colors: {
                            active: TacoConstants.ComponentBlueDark.value,
                            inactive: TacoConstants.Transparent.value
                        },
                        action: Data_Maybe.Nothing.value,
                        title: r.component.memo,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("memo-indicator-button")
                    });
                };
                var hours = function (r) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(1)((function (v) {
                            return v.hours;
                        })(unEC(r))),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("hours")
                    });
                };
                var hourlyRate = function (r) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(1)((function (v) {
                            return v.hourlyRate;
                        })(unEC(r))),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("hourly-rate")
                    });
                };
                var hasMemoElement = function (v) {
                    return mkFlagIcon(TacoIcon_Icons_Types.IconMemoIndicatorV1.value)(TacoConstants.PrimaryBlue.value)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(v.estimationComponentWithResources.component.memo))(v.estimationComponentWithResources.component.memo);
                };
                var groupCellInput = function (row) {
                    var component = (unEC(row)).component;
                    return mkStringInput(CostEstimation_Types.ColGroup.value)("Ryhm\xe4")(EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("group-input"))(1)("6rem")(40)(Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(component.group))(function (group) {
                        return new Types_Estimation.EditEstimationComponentGroup({
                            group: group
                        });
                    })(row);
                };
                var flagElement = function (v) {
                    return mkFlagIcon(TacoIcon_Icons_Types.IconFlagV1.value)(TacoConstants.PrimaryBlue.value)(v.estimationComponentWithResources.component.flagged)("");
                };
                var descriptionCellInput = function (row) {
                    var component = (unEC(row)).component;
                    return TacoInput.remoteStringField()()({
                        value: component.description,
                        title: component.description,
                        mkAction: mkAction(component.id)(function (description) {
                            return new Types_Estimation.EditEstimationComponentDescription({
                                description: description
                            });
                        }),
                        onFocus: mkOnFocus(CostEstimation_Types.ColDescription.value)(row),
                        onBlur: onInputBlur,
                        autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn))(row.selectedRowColumn)(new Data_Maybe.Just(CostEstimation_Types.ColDescription.value)),
                        stateKey: Data_Show.show(Types_Component.showComponentId)(component.id),
                        rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("description-input")
                    });
                };
                var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
                var priceUnit = function (r) {
                    return TacoText.component()()({
                        text: currencySymbol,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("price-unit")
                    });
                };
                var totalPriceUnitMaybeCell = new TacoTable_Types.JSXCell([  ], function (r) {
                    var text = (function () {
                        var $268 = r.estimationComponentWithResources.totalPrice !== 0.0;
                        if ($268) {
                            return currencySymbol;
                        };
                        return "";
                    })();
                    return TacoText.component()()({
                        text: text,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("total-price-unit")
                    });
                });
                var unitForUnitPrice = function (r) {
                    return TacoText.component()()({
                        text: Utils.fromNullable("")(Utils.mapNullable(Data_Semigroup.append(Data_Semigroup.semigroupString)(currencySymbol + "/"))((function (v) {
                            return v.component.unit;
                        })(unEC(r)))),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("unit-for-unit-price")
                    });
                };
                var costClassUnitPrice = function (code) {
                    return function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(EstimationComponentsTableHelpers.getUnitPriceOfCostClass(code)(r)),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("cost-class-unit-price")
                        });
                    };
                };
                var costClassTotalPrice = function (code) {
                    return function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(EstimationComponentsTableHelpers.getTotalPriceOfCostClass(code)(r)),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("cost-class-total-price")
                        });
                    };
                };
                var costClassHoursPerUnit = function (code) {
                    return function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(EstimationComponentsTableHelpers.getHoursPerUnitOfCostClass(code)(r)),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("cost-class-hours-per-unit")
                        });
                    };
                };
                var codeCellInput = function (row) {
                    var component = (unEC(row)).component;
                    return mkStringInput(CostEstimation_Types.ColCode.value)("Koodi")(EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("code-input"))(1)("7rem")(80)(Utils.fromNullable("")(component.code))(function (code) {
                        return new Types_Estimation.EditEstimationComponentCode({
                            code: code,
                            subprojectId: Data_Nullable.toMaybe(component.subProjectId)
                        });
                    })(row);
                };
                var classCell = new TacoTable_Types.JSXCell([  ], function (r) {
                    return TacoText.component()()({
                        text: Utils.fromNullable("")((function (v) {
                            return v.component.classCode;
                        })(unEC(r))),
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("class")
                    });
                });
                var checkedElement = function (v) {
                    return mkFlagIcon(TacoIcon_Icons_Types.IconCheckCircleV1.value)(TacoConstants.GreenLight.value)(v.estimationComponentWithResources.component.checked)("");
                };
                var flags = function (x) {
                    return Gap.gapX(TacoConstants.M.value)(Data_Functor.flap(Data_Functor.functorArray)([ checkedElement, flagElement, hasMemoElement ])(x));
                };
                var canEdit = EstimationUtils.canEditView(props);
                var checkedBtn = function (row) {
                    var component = (unEC(row)).component;
                    return TacoButton.actionToggleButton({
                        value: component.checked,
                        icon: TacoIcon_Icons_Types.IconCheckCircleV1.value,
                        colors: {
                            active: TacoConstants.GreenLight.value,
                            inactive: TacoConstants.Gray.value
                        },
                        action: Data_Monoid.guard(Data_Maybe.monoidMaybe(Effect.semigroupEffect(Data_Semigroup.semigroupUnit)))(canEdit)(Data_Maybe.Just.create(props.appDispatch(mkAction(component.id)(function (wasChecked) {
                            return new Types_Estimation.EditEstimationComponentChecked({
                                checked: !wasChecked
                            });
                        })(component.checked)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))))))),
                        title: "",
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("action-toggle-button")
                    });
                };
                var codeCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], codeCellInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.fromNullable("")((function (v) {
                                return v.component.code;
                            })(unEC(r))),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("code")
                        });
                    });
                })();
                var costClassHoursPerUnitMaybeInput = function (code) {
                    return function (r) {
                        if (canEdit) {
                            return EstimationComponentsTableHelpers.mkCostClassHoursPerUnitInput({
                                flexGrow: 1,
                                minWidth: "4rem",
                                column: new CostEstimation_Types.ColCostClassUnitPrice(code),
                                mkOnFocus: mkOnFocus,
                                onInputBlur: onInputBlur,
                                projectId: props.projectId,
                                socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                                viewMode: props.viewMode
                            })(code)(r);
                        };
                        return costClassHoursPerUnit(code)(r);
                    };
                };
                var costClassTotalPriceMaybeInput = function (code) {
                    return function (r) {
                        if (canEdit) {
                            return EstimationComponentsTableHelpers.mkCostClassTotalPriceInput({
                                flexGrow: 1,
                                minWidth: "4rem",
                                column: new CostEstimation_Types.ColCostClassTotalPrice(code),
                                mkOnFocus: mkOnFocus,
                                onInputBlur: onInputBlur,
                                projectId: props.projectId,
                                socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                                viewMode: props.viewMode
                            })(code)(r);
                        };
                        return costClassTotalPrice(code)(r);
                    };
                };
                var costClassUnitPriceMaybeInput = function (code) {
                    return function (r) {
                        if (canEdit) {
                            return EstimationComponentsTableHelpers.mkCostClassUnitPriceInput({
                                flexGrow: 1,
                                minWidth: "4rem",
                                column: new CostEstimation_Types.ColCostClassUnitPrice(code),
                                mkOnFocus: mkOnFocus,
                                onInputBlur: onInputBlur,
                                projectId: props.projectId,
                                socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                                viewMode: props.viewMode
                            })(code)(r);
                        };
                        return costClassUnitPrice(code)(r);
                    };
                };
                var descriptionCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], descriptionCellInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: (function (row) {
                                return (unEC(row)).component.description;
                            })(r),
                            title: (function (row) {
                                return (unEC(row)).component.description;
                            })(r),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("description")
                        });
                    });
                })();
                var flaggedBtn = function (row) {
                    var component = (unEC(row)).component;
                    return TacoButton.actionToggleButton({
                        value: component.flagged,
                        icon: TacoIcon_Icons_Types.IconFlagV1.value,
                        colors: {
                            active: TacoConstants.PrimaryBlue.value,
                            inactive: TacoConstants.Gray.value
                        },
                        action: Data_Monoid.guard(Data_Maybe.monoidMaybe(Effect.semigroupEffect(Data_Semigroup.semigroupUnit)))(canEdit)(Data_Maybe.Just.create(props.appDispatch(mkAction(component.id)(function (wasFlagged) {
                            return new Types_Estimation.EditEstimationComponentFlagged({
                                flagged: !wasFlagged
                            });
                        })(component.flagged)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))))))),
                        title: "",
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("flagged-button")
                    });
                };
                var flagButtons = function (row) {
                    return Box.box("flagButtonsWrapper")([ Box.FlexRow.value, Box.JustifyCenter.value, Box.MarginTop.create(new TacoConstants.CustomSize("1px")) ])([ checkedBtn(row), flaggedBtn(row), memoIndicator(row) ]);
                };
                var flagInputs = function (row) {
                    return Box.box("flagInputs")([ Box.FlexColumn.value ])([ flagButtons(row) ]);
                };
                var groupCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], groupCellInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))((function (v) {
                                return v.component.group;
                            })(unEC(r))),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("group")
                        });
                    });
                })();
                var quantityCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], quantityCellInput);
                    };
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(1)((function (row) {
                                return CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.componentCalculationAmount((unEC(row)).component));
                            })(r)),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("quantity")
                        });
                    });
                })();
                var subProjectInput = function (row) {
                    var component = (unEC(row)).component;
                    var locations = Data_Array.filter((function () {
                        var $448 = Data_Eq.eq(Types_Component.eqComponentId)(component.id);
                        return function ($449) {
                            return $448((function (v) {
                                return v.estimationComponentId;
                            })($449));
                        };
                    })())(props.estimationLocations);
                    var elementLinkedLocations = Data_Array.filter((function () {
                        var $450 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                        return function ($451) {
                            return $450(Utils.isNull($451));
                        };
                    })())(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.elementSpecId;
                    })(locations));
                    var isLinkedWithElement = !Data_Array["null"](elementLinkedLocations);
                    return CostEstimation_AddComponent.mkSubprojectInput({
                        subprojects: props.subprojects,
                        currentId: Data_Nullable.toMaybe(component.subProjectId),
                        label: Data_Maybe.Nothing.value,
                        disabled: !canEdit || isLinkedWithElement,
                        onFocus: mkOnFocus(CostEstimation_Types.ColSubProjectCode.value)(row),
                        onBlur: onInputBlur,
                        autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn))(row.selectedRowColumn)(new Data_Maybe.Just(CostEstimation_Types.ColSubProjectCode.value)),
                        onChange: function (subprojectId) {
                            var code = Data_Nullable.toMaybe((function (v) {
                                return v.component.code;
                            })(Data_Newtype.unwrap()(row.estimationComponentWithResources)));
                            var editParams = new Types_Estimation.EditEstimationComponent({
                                componentId: component.id,
                                editAction: new Types_Estimation.EditEstimationComponentSubprojectId({
                                    subprojectId: subprojectId,
                                    code: code
                                })
                            });
                            return props.appDispatch(new Actions.EstimationComponentActionRequest({
                                projectId: props.projectId,
                                params: editParams,
                                handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                            }));
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                        testId: "component-" + Data_Show.show(Types_Component.showComponentId)(component.id)
                    })(new Data_Maybe.Just({
                        enabledTooltip: "Vaihda alakohde.",
                        disabledTooltip: "Suoritteen alakohde m\xe4\xe4r\xe4ytyy rakenteelta, jolla suorite on k\xe4yt\xf6ss\xe4. Alakohde tulee vaihtaa rakenteelle. Sijainnit v\xe4lilehdelt\xe4 on linkki rakenteeseen, jolla t\xe4m\xe4 suorite on k\xe4yt\xf6ss\xe4."
                    }));
                };
                var unitCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitCellInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.fromNullable("")((function (v) {
                                return v.component.unit;
                            })(unEC(r))),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("unit")
                        });
                    });
                })();
                var actionCheckbox = function (v) {
                    return TacoCheckbox.component()()({
                        className: "component-action-checkbox no-margin",
                        isChecked: v.estimationComponentWithResources.checkboxState,
                        onToggle: TacoCheckbox.WithShift.create(props.toggleCheckbox(v.estimationComponentWithResources.component.id)),
                        labelText: Data_Maybe.Nothing.value,
                        testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(v)("checkbox")
                    });
                };
                var _component = function (row) {
                    return (unEC(row)).component;
                };
                var costGroupCellInput = function (row) {
                    return mkStringInput(CostEstimation_Types.ColCostGroup.value)("Tavoitelittera")(EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(row)("cost-group-input"))(1)("6rem")(40)(Utils.fromNullable("")((_component(row)).costGroupCode))(function (costGroupCode) {
                        return new Types_Estimation.EditEstimationComponentCostGroup({
                            costGroupCode: costGroupCode
                        });
                    })(row);
                };
                var costGroupCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], costGroupCellInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.fromNullable("")((function (v) {
                                return v.component.costGroupCode;
                            })(unEC(r))),
                            testId: EstimationComponentsTableHelpers.testIdPrefix(props.viewMode)(r)("cost-group")
                        });
                    });
                })();
                return prepareECColumns(classColumnEnabled)(props.viewMode)(props)(selectionProps)(function (v) {
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColFlags) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], flags),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Flags.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColSubProjectCode) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([  ])(boldTextCell((function () {
                                var $452 = Utils.fromNullable("");
                                return function ($453) {
                                    return $452((function (v1) {
                                        return v1.component.subProjectCode;
                                    })(unEC($453)));
                                };
                            })())),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.SubProjectCode.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColGroup) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([  ])(boldTextCell((function () {
                                var $454 = Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode));
                                return function ($455) {
                                    return $454((function (v1) {
                                        return v1.component.group;
                                    })(unEC($455)));
                                };
                            })())),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Group.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColCostGroup) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([  ])(boldTextCell((function () {
                                var $456 = Utils.fromNullable("");
                                return function ($457) {
                                    return $456((function (v1) {
                                        return v1.component.costGroupCode;
                                    })(unEC($457)));
                                };
                            })())),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Group.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColCode) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], topicComponentCode),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Code.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColDescription) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], topicComponentDescription),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Description.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColAmount) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], emptyCell),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Amount.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColUnit) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], emptyCell),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Unit.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColUnitPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], emptyCell),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.UnitPrice.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColUnitForUnitPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], emptyCell),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.UnitPrice.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColTotalPrice) {
                        return {
                            cell: totalPriceMaybeCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.TotalPrice.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColPriceUnit) {
                        return {
                            cell: totalPriceUnitMaybeCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.TotalPrice.value)
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColCostClassTotalPrice) {
                        return {
                            cell: EstimationComponentsTableHelpers.costClassTotalPriceMaybeCell(props.viewMode)(v.value0),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColCostClassUnitPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], emptyCell),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (componentType instanceof Types_Component.TopicComponent && v instanceof CostEstimation_Types.ColCostClassHoursPerUnit) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], emptyCell),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColSelectionCheckbox) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], actionCheckbox),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColFlags) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], flagInputs),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Flags.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColSubProjectCode) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], subProjectInput),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.SubProjectCode.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColGroup) {
                        return {
                            cell: groupCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Group.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColCostGroup) {
                        return {
                            cell: costGroupCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.CostGroup.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColClass) {
                        return {
                            cell: classCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Class.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColCode) {
                        return {
                            cell: codeCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Code.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColDescription) {
                        return {
                            cell: descriptionCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Description.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColAmount) {
                        return {
                            cell: quantityCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Amount.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColUnit) {
                        return {
                            cell: unitCell,
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.Unit.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColUnitPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], unitPrice),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.UnitPrice.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColUnitForUnitPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], unitForUnitPrice),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.UnitPrice.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColTotalPrice) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], totalPrice),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.TotalPrice.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColPriceUnit) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], priceUnit),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.TotalPrice.value)
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColCostClassTotalPrice) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(costClassTotalPriceMaybeInput(v.value0)),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColCostClassUnitPrice) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(costClassUnitPriceMaybeInput(v.value0)),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColCostClassHoursPerUnit) {
                        return {
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(costClassHoursPerUnitMaybeInput(v.value0)),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColSplitComponentWithMissingResources) {
                        return {
                            cell: splitComponentWithMissingResourcesCell,
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColHours) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], hours),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColHourlyRate) {
                        return {
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], hourlyRate),
                            sortProperty: Data_Maybe.Nothing.value
                        };
                    };
                    if (v instanceof CostEstimation_Types.ColProject) {
                        return {
                            cell: new TacoTable_Types.JSXCell([  ], project),
                            sortProperty: new Data_Maybe.Just(CostEstimation_Types.ProjectOfOrigin.value)
                        };
                    };
                    throw new Error("Failed pattern match at EstimationComponentsTable (line 305, column 75 - line 358, column 89): " + [ componentType.constructor.name, props.viewMode.constructor.name, v.constructor.name ]);
                });
            };
        };
    };
};
var _tableOptimized = TacoTable.tableOptimized(CostEstimation_Types.getNameECSortProperty)(CostEstimation_Types.eqECSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentagesByCostClass";
    }
})(Data_Map_Internal.eqMap(Types_Newtypes.eqCostClassCode)(Types_Unit.eqSocialExpensePercentage)))()({
    reflectSymbol: function () {
        return "shortenReferenceProjectDetails";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "selectedRowColumn";
    }
})(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn)))()({
    reflectSymbol: function () {
        return "estimationComponentWithResources";
    }
})(Types_CostEstimation.eqEstimationComponentWithResources))()({
    reflectSymbol: function () {
        return "dixonLinks";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target_field";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "measurement_group_id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "from_type";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "element_spec_id";
    }
})(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
    reflectSymbol: function () {
        return "component_id";
    }
})(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "building_element_id";
    }
})(Data_Maybe.eqMaybe(Types_Element.eqElementId)))))))()({
    reflectSymbol: function () {
        return "activeDixonLinks";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target_field";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "measurement_group_id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "from_type";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "element_spec_id";
    }
})(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
    reflectSymbol: function () {
        return "component_id";
    }
})(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "building_element_id";
    }
})(Data_Maybe.eqMaybe(Types_Element.eqElementId))))))));
var _newEditMultipleComponentsButton = TofuHooks.mkHookComponent("NewEditMultipleComponentsButton")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var selectedComponentIds = Data_Functor.map(Data_Functor.functorArray)(function (c) {
            return {
                componentId: (Types_CostEstimation.unECWR(c)).component.id,
                totalPrice: (Types_CostEstimation.unECWR(c)).totalPrice
            };
        })(props.selectedComponentsECWR);
        var onSaveModal = function __do() {
            Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshClassificationGroups({
                request_params: props.projectId
            })))();
            Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                request_params: props.projectId
            })))();
            return v.value1(function (v1) {
                return false;
            })();
        };
        var closeModal = v.value1(function (v1) {
            return false;
        });
        var newMultiEditModal = React_Basic.element($foreign.multiEditModal)({
            projectId: props.projectId,
            selectedComponents: selectedComponentIds,
            onClose: closeModal,
            isOpen: v.value0,
            onSave: onSaveModal
        });
        var button = TacoButton.component()()({
            text: "Muokkaa suoritteita",
            onClick: Data_Maybe.Just.create(v.value1(function (v1) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        });
        return React_Basic.fragment([ button, Data_Monoid.guard(React_Basic.monoidJSX)(v.value0)(newMultiEditModal) ]);
    };
});
var _editMultipleComponentsButton = TofuHooks.mkHookComponent("EditMultipleComponentsButton")(function (props) {
    return function __do() {
        var topicComponentEditingEnabled = FeatureHooks.useFeatureEnabled("topic-component-editing")();
        var groupsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        var v = TofuHooks.useState(false)();
        var dispatch = StateHooks.useDispatch();
        var v1 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.selectedComponentsECWR) ])(function (v1) {
            return Data_Array.nub(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)((function () {
                var $458 = Utils.fromNullable("");
                var $459 = Data_Newtype.unwrap();
                return function ($460) {
                    return $458((function (v2) {
                        return v2.component.costGroupCode;
                    })($459($460)));
                };
            })())(props.selectedComponentsECWR));
        })();
        var v2 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.selectedComponentsECWR) ])(function (v2) {
            return Data_Array.nub(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)((function () {
                var $461 = Utils.nullable("")(Data_Newtype.unwrap());
                var $462 = Data_Newtype.unwrap();
                return function ($463) {
                    return $461((function (v3) {
                        return v3.component.group;
                    })($462($463)));
                };
            })())(props.selectedComponentsECWR));
        })();
        var defaultCostGroupCodeValue = TofuHooks.useMemo([ ReactHooksUtils.utf(v1) ])(function (v3) {
            var v4 = Data_Array.head(v1);
            var v5 = Data_Array.length(v1);
            if (v5 === 1 && v4 instanceof Data_Maybe.Just) {
                return v4.value0;
            };
            if (v4 instanceof Data_Maybe.Just) {
                return "";
            };
            return "";
        })();
        var defaultGroupCodeValue = TofuHooks.useMemo([ ReactHooksUtils.utf(v2) ])(function (v3) {
            var v4 = Data_Array.head(v2);
            var v5 = Data_Array.length(v2);
            if (v5 === 1 && v4 instanceof Data_Maybe.Just) {
                return v4.value0;
            };
            if (v4 instanceof Data_Maybe.Just) {
                return "";
            };
            return "";
        })();
        var quantityEditable = TofuHooks.useMemo([ ReactHooksUtils.utf(props.selectedComponentsECWR), ReactHooksUtils.utf(props.isComponentQuantityEditable) ])(function (v3) {
            var isComponentQuantityEditable$prime = (function () {
                var $464 = Data_Eq.eq(eqQuantityEditable)(QtyCanEdit.value);
                return function ($465) {
                    return $464(props.isComponentQuantityEditable($465));
                };
            })();
            return Data_Array.all(isComponentQuantityEditable$prime)(Data_Functor.map(Data_Functor.functorArray)(function ($466) {
                return (function (v4) {
                    return v4.component.id;
                })(Types_CostEstimation.unECWR($466));
            })(props.selectedComponentsECWR));
        })();
        var containsElementSpecLocations1 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.selectedComponentsECWR), ReactHooksUtils.utf(props.containsElementSpecLocations) ])(function (v3) {
            return Data_Array.any(props.containsElementSpecLocations)(Data_Functor.map(Data_Functor.functorArray)(function ($467) {
                return (function (v4) {
                    return v4.component.id;
                })(Types_CostEstimation.unECWR($467));
            })(props.selectedComponentsECWR));
        })();
        var defaultQuantityValue = TofuHooks.useMemo([ ReactHooksUtils.utf(props.selectedComponentsECWR) ])(function (v3) {
            var amounts = Data_Array.nub(Data_Ord.ordNumber)(Data_Functor.map(Data_Functor.functorArray)(function ($468) {
                return (function (v4) {
                    return v4.component.amount;
                })(Types_CostEstimation.unECWR($468));
            })(props.selectedComponentsECWR));
            var v4 = Data_Array.head(amounts);
            var v5 = Data_Array.length(amounts);
            if (v5 === 1 && v4 instanceof Data_Maybe.Just) {
                return Data_Show.show(Data_Show.showNumber)(CostEstimation_Calculations.toAmountPrecision(v4.value0));
            };
            if (v4 instanceof Data_Maybe.Just) {
                return "";
            };
            return "";
        })();
        var v3 = TofuHooks.useState(Edited.Unedited.value)();
        var v4 = TofuHooks.useState(Edited.Unedited.value)();
        var v5 = TofuHooks.useState(Edited.Unedited.value)();
        var v6 = TofuHooks.useState(false)();
        var v7 = TofuHooks.useState(false)();
        var v8 = TofuHooks.useState(false)();
        var resetInputFields = function __do() {
            v3.value1(function (v9) {
                return Edited.Unedited.value;
            })();
            v4.value1(function (v9) {
                return Edited.Unedited.value;
            })();
            v5.value1(function (v9) {
                return Edited.Unedited.value;
            })();
            v6.value1(function (v9) {
                return false;
            })();
            v7.value1(function (v9) {
                return false;
            })();
            return v8.value1(function (v9) {
                return false;
            })();
        };
        TofuHooks.useEffect([ ReactHooksUtils.utf(props.selectedComponentsECWR) ])(function __do() {
            resetInputFields();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var isConvertibleToTopicComponent = (function () {
            var resources = (function () {
                var v9 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v10) {
                    return v10.resources;
                })(Data_Newtype.unwrap()))(Data_Array.head(props.selectedComponentsECWR));
                if (v9 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v9 instanceof Data_Maybe.Just) {
                    return Data_Array.filter(function (r) {
                        return !r.isCostClassPrice;
                    })(v9.value0);
                };
                throw new Error("Failed pattern match at EstimationComponentsTable (line 1191, column 21 - line 1193, column 80): " + [ v9.constructor.name ]);
            })();
            var quantity = (function () {
                var v9 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v10) {
                    return v10.locations;
                })(Data_Newtype.unwrap()))(Data_Array.head(props.selectedComponentsECWR));
                if (v9 instanceof Data_Maybe.Nothing) {
                    return 0.0;
                };
                if (v9 instanceof Data_Maybe.Just) {
                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v10) {
                        return v10.amount;
                    })(v9.value0));
                };
                throw new Error("Failed pattern match at EstimationComponentsTable (line 1188, column 20 - line 1190, column 58): " + [ v9.constructor.name ]);
            })();
            var component = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                return v9.component;
            })(Data_Newtype.unwrap()))(Data_Array.head(props.selectedComponentsECWR));
            return Data_Array.length(props.selectedComponentsECWR) === 1 && (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v9) {
                return v9.componentType;
            })(component))(new Data_Maybe.Just(0)) && (quantity === 0.0 && Data_Array["null"](resources)));
        })();
        var isConvertibleToRegularComponent = (function () {
            var component = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                return v9.component;
            })(Data_Newtype.unwrap()))(Data_Array.head(props.selectedComponentsECWR));
            return Data_Array.length(props.selectedComponentsECWR) === 1 && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v9) {
                return v9.componentType;
            })(component))(new Data_Maybe.Just(3));
        })();
        var resetQuantitiesInput = TacoCheckbox.component()()({
            isChecked: v6.value0,
            onToggle: TacoCheckbox.NoEvent.create(v6.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Nollaa")),
            color: TacoConstants.DangerRedDark.value,
            title: "Varoitus: kaikki valittujen suoritteiden m\xe4\xe4r\xe4t poistetaan.",
            testId: "multi-edit-reset-quantities-control"
        });
        var resetQuantitiesContents = [ TacoText.component()()({
            text: "Nollaa m\xe4\xe4r\xe4t",
            weight: TacoText_Types.Bold.value
        }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(containsElementSpecLocations1)(TacoText.component()())({
            text: "Vain suoritteiden sijaintien m\xe4\xe4r\xe4t nollataan. Rakenteiden m\xe4\xe4r\xe4t on nollattava erikseen.",
            weight: TacoText_Types.Bold.value
        }), resetQuantitiesInput ];
        var quantityInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Edited.fromEdited(defaultQuantityValue)(v5.value0),
            validator: NumberValidator.numberValidator,
            onChange: function (f) {
                return v5.value1(function (v9) {
                    return new Edited.Edited(f(""));
                });
            },
            disabled: !quantityEditable || v6.value0,
            title: (function () {
                var $326 = !quantityEditable;
                if ($326) {
                    return "Valittujen suoritteiden m\xe4\xe4r\xe4\xe4 ei voi muokata. Tarkista ett\xe4 kaikkein suoritteiden m\xe4\xe4r\xe4 on muokattavissa ja vain oletussijainnilla.";
                };
                if (v6.value0) {
                    return "Olet nollaamassa suoritteiden m\xe4\xe4ri\xe4. M\xe4\xe4rien muutoksia ei tallenneta vaan kaikki nollataan.";
                };
                return "";
            })(),
            testId: "multi-edit-quantity-input"
        });
        var quantityContents = [ TacoText.component()()({
            text: "M\xe4\xe4r\xe4" + Data_Monoid.guard(Data_Monoid.monoidString)(Edited.isEdited(v5.value0))("*"),
            weight: TacoText_Types.Bold.value
        }), quantityInput ];
        var groupInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Edited.fromEdited(defaultGroupCodeValue)(v4.value0),
            validator: LengthValidator.lengthValidator(0)(20),
            onChange: function (f) {
                return v4.value1(function (v9) {
                    return new Edited.Edited(f(""));
                });
            },
            testId: "multi-edit-group-input"
        });
        var costGroupInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Edited.fromEdited(defaultCostGroupCodeValue)(v3.value0),
            validator: LengthValidator.lengthValidator(0)(10),
            onChange: function (f) {
                return v3.value1(function (v9) {
                    return new Edited.Edited(f(""));
                });
            },
            testId: "multi-edit-cost-group-input"
        });
        var costGroupInputs = Data_Monoid.guard(Data_Monoid.monoidArray)(props.showTargetCostGroup)([ TacoText.component()()({
            text: "Littera" + Data_Monoid.guard(Data_Monoid.monoidString)(Edited.isEdited(v3.value0))("*"),
            weight: TacoText_Types.Bold.value
        }), costGroupInput ]);
        var convertToTopicComponentInput = TacoCheckbox.component()()({
            isChecked: v7.value0,
            onToggle: TacoCheckbox.NoEvent.create(v7.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Muunna")),
            color: TacoConstants.DangerRedDark.value,
            testId: "multi-edit-convert-to-topic-component-control"
        });
        var convertToTopicComponentField = [ TacoText.component()()({
            text: "Muunna otsikkoriviksi",
            weight: TacoText_Types.Bold.value
        }), Data_Monoid.guard(React_Basic.monoidJSX)(isConvertibleToTopicComponent)(convertToTopicComponentInput) ];
        var convertToRegularComponentInput = TacoCheckbox.component()()({
            isChecked: v8.value0,
            onToggle: TacoCheckbox.NoEvent.create(v8.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Muunna")),
            color: TacoConstants.DangerRedDark.value,
            testId: "multi-edit-convert-to-regular-component-control"
        });
        var convertToRegularComponentField = [ TacoText.component()()({
            text: "Muunna suoriteriviksi",
            weight: TacoText_Types.Bold.value
        }), Data_Monoid.guard(React_Basic.monoidJSX)(isConvertibleToRegularComponent)(convertToRegularComponentInput) ];
        var componentTypeConversionContents = (function () {
            if (!isConvertibleToTopicComponent && !isConvertibleToRegularComponent) {
                return [  ];
            };
            if (isConvertibleToTopicComponent && !isConvertibleToRegularComponent) {
                return Data_Monoid.guard(Data_Monoid.monoidArray)(topicComponentEditingEnabled)(convertToTopicComponentField);
            };
            if (!isConvertibleToTopicComponent && isConvertibleToRegularComponent) {
                return Data_Monoid.guard(Data_Monoid.monoidArray)(topicComponentEditingEnabled)(convertToRegularComponentField);
            };
            if (isConvertibleToTopicComponent && isConvertibleToRegularComponent) {
                return [  ];
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1397, column 39 - line 1401, column 23): " + [ isConvertibleToTopicComponent.constructor.name, isConvertibleToRegularComponent.constructor.name ]);
        })();
        var componentGroupInputs = Data_Monoid.guard(Data_Monoid.monoidArray)(groupsEnabled)([ TacoText.component()()({
            text: "Ryhm\xe4" + Data_Monoid.guard(Data_Monoid.monoidString)(Edited.isEdited(v4.value0))("*"),
            weight: TacoText_Types.Bold.value
        }), groupInput ]);
        var modalBody = TacoModal.modalBody({
            contents: Data_Semigroup.append(Data_Semigroup.semigroupArray)(costGroupInputs)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(componentGroupInputs)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(quantityContents)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(resetQuantitiesContents)(componentTypeConversionContents))))
        });
        var closeModal = function __do() {
            resetInputFields();
            return v.value1(function (v9) {
                return false;
            })();
        };
        var saveConvertToRegularComponent = (function () {
            var v9 = Data_Array.head(props.selectedComponentsECWR);
            if (v9 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            if (v9 instanceof Data_Maybe.Just) {
                return dispatch(new Actions.ConvertToRegularComponentRequest({
                    handler: function (v10) {
                        return function __do() {
                            WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                                request_params: props.projectId
                            }))();
                            v3.value1(function (v11) {
                                return Edited.Unedited.value;
                            })();
                            return closeModal();
                        };
                    },
                    projectId: props.projectId,
                    params: new Types_Planning.ConvertToRegularComponentAction({
                        componentId: v9.value0.component.id
                    })
                }));
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1213, column 7 - line 1224, column 16): " + [ v9.constructor.name ]);
        })();
        var saveConvertToTopicComponent = (function () {
            var v9 = Data_Array.head(props.selectedComponentsECWR);
            if (v9 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            if (v9 instanceof Data_Maybe.Just) {
                return dispatch(new Actions.ConvertToTopicComponentRequest({
                    handler: function (v10) {
                        return function __do() {
                            WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                                request_params: props.projectId
                            }))();
                            v3.value1(function (v11) {
                                return Edited.Unedited.value;
                            })();
                            return closeModal();
                        };
                    },
                    projectId: props.projectId,
                    params: new Types_Planning.ConvertToTopicComponentAction({
                        componentId: v9.value0.component.id
                    })
                }));
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1227, column 7 - line 1238, column 16): " + [ v9.constructor.name ]);
        })();
        var saveCostGroupChanges = Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Edited.editedToMaybe(v3.value0))(function (costGroupCodeValue) {
            return dispatch(new Actions.PlanningActionRequest({
                handler: function (v9) {
                    return function __do() {
                        WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                            request_params: props.projectId
                        }))();
                        v3.value1(function (v10) {
                            return Edited.Unedited.value;
                        })();
                        return closeModal();
                    };
                },
                projectId: props.projectId,
                params: Types_Planning.PlanningSetComponentCostGroups.create(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                    return {
                        componentId: v9,
                        costGroupCode: costGroupCodeValue
                    };
                })((function () {
                    var $469 = Data_Newtype.unwrap();
                    return function ($470) {
                        return (function (v9) {
                            return v9.component.id;
                        })($469($470));
                    };
                })()))(props.selectedComponentsECWR))
            }));
        });
        var saveGroupChanges = Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Edited.editedToMaybe(v4.value0))(function (groupCodeValue) {
            return dispatch(new Actions.EstimationComponentActionRequest({
                handler: function (v9) {
                    return function __do() {
                        WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                            request_params: props.projectId
                        }))();
                        v4.value1(function (v10) {
                            return Edited.Unedited.value;
                        })();
                        return closeModal();
                    };
                },
                projectId: props.projectId,
                params: Types_Estimation.EditEstimationComponents.create(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                    return {
                        componentId: v9,
                        editAction: new Types_Estimation.EditEstimationComponentGroup({
                            group: groupCodeValue
                        })
                    };
                })((function () {
                    var $471 = Data_Newtype.unwrap();
                    return function ($472) {
                        return (function (v9) {
                            return v9.component.id;
                        })($471($472));
                    };
                })()))(props.selectedComponentsECWR))
            }));
        });
        var saveQuantityChanges = Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToNumber)(Edited.editedToMaybe(v5.value0)))(function (quantityValue) {
            return dispatch(new Actions.EstimationComponentActionRequest({
                handler: function (v9) {
                    return function __do() {
                        WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                            request_params: props.projectId
                        }))();
                        v5.value1(function (v10) {
                            return Edited.Unedited.value;
                        })();
                        return closeModal();
                    };
                },
                projectId: props.projectId,
                params: Types_Estimation.EditEstimationComponents.create(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                    return {
                        componentId: v9,
                        editAction: new Types_Estimation.EditEstimationComponentQuantity({
                            quantity: quantityValue
                        })
                    };
                })((function () {
                    var $473 = Data_Newtype.unwrap();
                    return function ($474) {
                        return (function (v9) {
                            return v9.component.id;
                        })($473($474));
                    };
                })()))(props.selectedComponentsECWR))
            }));
        });
        var saveResetQuantities = dispatch(new Actions.EstimationComponentActionRequest({
            handler: function (v9) {
                return function __do() {
                    WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                        request_params: props.projectId
                    }))();
                    v6.value1(function (v10) {
                        return false;
                    })();
                    return closeModal();
                };
            },
            projectId: props.projectId,
            params: Types_Estimation.ResetEstimationLocations.create(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v9) {
                return {
                    componentId: v9
                };
            })((function () {
                var $475 = Data_Newtype.unwrap();
                return function ($476) {
                    return (function (v9) {
                        return v9.component.id;
                    })($475($476));
                };
            })()))(props.selectedComponentsECWR))
        }));
        var saveAndCloseModal = function __do() {
            saveCostGroupChanges();
            saveGroupChanges();
            (function () {
                if (v6.value0) {
                    return saveResetQuantities();
                };
                return saveQuantityChanges();
            })();
            if (!v8.value0 && !v7.value0) {
                return Data_Unit.unit;
            };
            if (v8.value0 && !v7.value0) {
                Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)("Calling convert to regular component"))();
                return saveConvertToRegularComponent();
            };
            if (!v8.value0 && v7.value0) {
                Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Class_Console.log(Effect_Class.monadEffectEffect)("Calling convert to topic component"))();
                return saveConvertToTopicComponent();
            };
            if (v8.value0 && v7.value0) {
                return Data_Unit.unit;
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1202, column 7 - line 1210, column 32): " + [ v8.value0.constructor.name, v7.value0.constructor.name ]);
        };
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                testId: "cancel-multi-component-edit"
            }), TacoButton.component()()({
                onClick: new Data_Maybe.Just(saveAndCloseModal),
                text: "Muokkaa",
                testId: "submit-multi-component-edit"
            }) ]
        });
        var modal = TacoModal.component()()({
            isActive: v.value0,
            onClose: closeModal,
            heading: "Muokkaa montaa suoritetta",
            contents: [ modalBody, modalActions ]
        });
        var button = TacoButton.component()()({
            text: "Muokkaa montaa suoritetta",
            onClick: Data_Maybe.Just.create(v.value1(function (v9) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "edit-multiple-components-button"
        });
        return React_Basic.fragment([ button, modal ]);
    };
});
var _deleteSelectedComponentsBtn = TofuHooks.mkHookComponent("DeleteSelectedComponentsBtn")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var suffix = (function () {
            var $356 = props.countOfSelectedComponents === 1;
            if ($356) {
                return " suoritteen?";
            };
            return " suoritetta?";
        })();
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Haluatko varmasti poistaa ",
                children: [ React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(props.countOfSelectedComponents)) ]), React_Basic_DOM.text(suffix) ]
            }) ]
        });
        var closeDeleteSelectedComponentsModal = v.value1(function (v1) {
            return false;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeDeleteSelectedComponentsModal),
                text: "Peruuta",
                testId: "cancel-delete-selected-components"
            }), TacoButton.component()()({
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
                onClick: new Data_Maybe.Just(function __do() {
                    props.onDeleteComponents();
                    return closeDeleteSelectedComponentsModal();
                }),
                testId: "submit-delete-selected-components"
            }) ]
        });
        var deleteSelectedComponentsModal = (function () {
            if (!v.value0) {
                return React_Basic.empty;
            };
            if (v.value0) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteSelectedComponentsModal,
                    heading: "Poista valittu suorite",
                    contents: [ modalBody, modalActions ]
                });
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1604, column 37 - line 1614, column 10): " + [ v.value0.constructor.name ]);
        })();
        var button = TacoButton.component()()({
            text: "Poista valitut suoritteet",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            onClick: Data_Maybe.Just.create(v.value1(function (v1) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "delete-selected-components-button"
        });
        return React_Basic_DOM_Generated.span_([ deleteSelectedComponentsModal, button ]);
    };
});
var _copyComponentsToOtherProjectBtn = TofuHooks.mkHookComponent("CopyComponentsToOtherProjectBtn")(function (props) {
    var btnLabelText = (function () {
        if (props.estimationViewMode instanceof Types_CostEstimation.EditMode) {
            return "Kopioi valitut...";
        };
        if (props.estimationViewMode instanceof Types_CostEstimation.ReferenceMode) {
            return "Lis\xe4\xe4 suoritteet";
        };
        throw new Error("Failed pattern match at EstimationComponentsTable (line 1500, column 7 - line 1502, column 44): " + [ props.estimationViewMode.constructor.name ]);
    })();
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        onClick: new Data_Maybe.Just(props.copySelectedComponentsToOtherProject),
        text: btnLabelText,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
        buttonStyle: TacoButton_Types.Filled.value,
        size: TacoConstants.L.value,
        testId: "copy-selected-components-to-other-project-button"
    }));
});
var _copyComponentToElementBtn = TofuHooks.mkHookComponent("CopyComponentToElementBtn")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var onClick = (function () {
            var params = Control_Bind.bind(Data_Maybe.bindMaybe)(props.selectedEditViewElement)(function (ed) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(props.selectedComponentId)(function (selectedComponentId) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(ed.element.subprojectId))(function (subprojectId) {
                        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                            selectedComponentId: selectedComponentId,
                            elementId: ed.element.elementId,
                            subprojectId: subprojectId
                        });
                    });
                });
            });
            if (params instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            if (params instanceof Data_Maybe.Just) {
                return dispatch(new Actions.EstimationElementActionRequest({
                    handler: function (v) {
                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                    },
                    projectId: props.projectId,
                    params: new Types_Estimation.AddReferenceProjectComponentsToElementWithoutDuplicates({
                        componentIds: [ params.value0.selectedComponentId ],
                        targetElementId: params.value0.elementId,
                        targetProjectId: props.projectId,
                        targetSubprojectId: params.value0.subprojectId,
                        copyResources: true
                    })
                }));
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 1534, column 7 - line 1546, column 12): " + [ params.constructor.name ]);
        })();
        return TacoButton.component()()({
            onClick: new Data_Maybe.Just(onClick),
            text: "Lis\xe4\xe4 valittu suorite rakenteelle",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        });
    };
});
var _copyComponentOnlyBtn = TofuHooks.mkHookComponent("CopyComponentOnlyToOtherProjectBtn")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        onClick: Data_Maybe.Just.create(props.copyComponentsToOtherProject(Types_Estimation.CopyComponentOnly.value)),
        text: "Lis\xe4\xe4 nimikkeet",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
        buttonStyle: TacoButton_Types.Filled.value,
        size: TacoConstants.L.value,
        testId: "copy-component-only-to-other-project-button"
    }));
});
var table = TofuHooks.mkHookComponent("EstimationComponentsTable")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = Client_WASM.useSubprojects(props.projectId)();
        var v1 = Client_WASM.useEstimationLocations(props.projectId)();
        var classColumnEnabled = FeatureHooks.useFeatureEnabled("component-class-column")();
        var rows = TofuHooks.useMemo([ ReactHooksUtils.utf(props.sortedComponents), ReactHooksUtils.utf(props.socialExpensePercentagesByCostClass), ReactHooksUtils.utf(props.shortenReferenceProjectDetails) ])(function (v2) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                return {
                    estimationComponentWithResources: v3,
                    socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                    shortenReferenceProjectDetails: props.shortenReferenceProjectDetails,
                    selectedRowColumn: Data_Maybe.Nothing.value,
                    dixonLinks: Data_Maybe.Nothing.value,
                    activeDixonLinks: Data_Maybe.Nothing.value
                };
            })(props.sortedComponents);
        })();
        var selectedComponentsECWR = TofuHooks.useMemo([ ReactHooksUtils.utf(props.sortedComponents) ])(function (v2) {
            return Data_Array.filter((function () {
                var $477 = Data_Newtype.unwrap();
                return function ($478) {
                    return (function (v3) {
                        return v3.checkboxState;
                    })($477($478));
                };
            })())(props.sortedComponents);
        })();
        var isComponentQuantityEditable = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0) ])(function (v2) {
            return mkIsComponentQuantityEditable(v1.value0);
        })();
        var containsElementSpecLocations = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0) ])(function (v2) {
            return mkContainsElementSpecLocations(v1.value0);
        })();
        var subprojectsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Subprojects.value)();
        var groupsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        var selectedComponentIds = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v2) {
            return v2.component.id;
        })(Data_Newtype.unwrap()))(props.selectedRows);
        var rowProps = {
            onRowClick: (function () {
                var $479 = props.selectComponent(false);
                return function ($480) {
                    return $479(Data_Maybe.Just.create((function (v2) {
                        return v2.estimationComponentWithResources;
                    })($480)));
                };
            })(),
            selectedComponent: props.selectedComponent,
            selectedComponentIdx: props.selectedComponentIdx,
            setSelectedComponent: props.selectComponent,
            toggleCheckbox: function (cId) {
                return function (mShift) {
                    if (mShift instanceof Data_Maybe.Just) {
                        if (mShift.value0) {
                            return props.toggleAreaOfCheckboxes(cId);
                        };
                        return props.toggleCheckbox(cId);
                    };
                    if (mShift instanceof Data_Maybe.Nothing) {
                        return props.toggleCheckbox(cId);
                    };
                    throw new Error("Failed pattern match at EstimationComponentsTable (line 939, column 11 - line 944, column 42): " + [ mShift.constructor.name ]);
                };
            },
            projectId: props.projectId,
            appDispatch: appDispatch,
            rowContext: ContainerContext.value,
            subprojects: v.value0,
            viewMode: props.viewMode,
            programType: props.programType,
            isReportingProject: props.isReportingProject,
            socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            showTargetCostGroup: props.showTargetCostGroup,
            shortenReferenceProjectDetails: props.shortenReferenceProjectDetails,
            estimationLocations: v1.value0,
            isComponentQuantityEditable: isComponentQuantityEditable,
            selectedInputColumn: props.selectedInputColumn,
            setSelectedInputColumn: props.setSelectedInputColumn,
            costClasses: props.costClasses,
            showCostClassColumns: props.showCostClassColumns,
            showCostClassTotalPrice: props.showCostClassTotalPrice,
            showCostClassUnitPrice: props.showCostClassUnitPrice,
            showCostClassHoursPerUnit: props.showCostClassHoursPerUnit,
            showSubprojects: subprojectsEnabled,
            showGroups: groupsEnabled,
            seletedShowCostClassCodes: props.seletedShowCostClassCodes,
            showHoursColumns: props.showHoursColumns,
            showSplitComponentWithMissingResourcesColumn: props.showSplitComponentWithMissingResourcesColumn,
            dixonOptions: props.dixonOptions,
            projectCurrency: props.projectCurrency
        };
        var topicColumns = getEstimationComponentColumns(Types_Component.TopicComponent.value)(rowProps)(props.selectionProps)(classColumnEnabled);
        var regularColumns = getEstimationComponentColumns(Types_Component.RegularComponent.value)(rowProps)(props.selectionProps)(classColumnEnabled);
        var newEditMultipleComponentsButton = _newEditMultipleComponentsButton({
            selectedComponentsECWR: selectedComponentsECWR,
            projectId: props.projectId
        });
        var isSelectionEmpty = Data_Array["null"](props.selectedRows);
        var isPublished = EstimationUtils.isProductionPlanning(props) || EstimationUtils.isReporting(props);
        var editMultiplePricesButton = EstimationComponentsTable_Internal_EditMultiplePrices.editMultiplePricesButton({
            selectedComponentsECWR: selectedComponentsECWR,
            projectId: props.projectId
        });
        var editMultipleComponentsButton = _editMultipleComponentsButton({
            selectedComponentsECWR: selectedComponentsECWR,
            projectId: props.projectId,
            showTargetCostGroup: props.showTargetCostGroup,
            isComponentQuantityEditable: isComponentQuantityEditable,
            containsElementSpecLocations: containsElementSpecLocations
        });
        var duplicateComponent = (function () {
            if (props.selectedComponent instanceof Data_Maybe.Just) {
                var copyOperationAction = function (targetProjectId) {
                    return function (targetSubprojectId) {
                        var params = Types_Estimation.CopyEstimationComponentsToProject.create({
                            estimationComponentIds: [ props.selectedComponent.value0.component.id ],
                            targetSubprojectId: targetSubprojectId,
                            targetProjectId: targetProjectId,
                            copyOperationType: Types_Estimation.CopyComponentWithResourcesAndLocations.value,
                            allowDuplicates: true
                        });
                        return new Actions.EstimationComponentActionRequest({
                            projectId: props.projectId,
                            params: params,
                            handler: function (v2) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            }
                        });
                    };
                };
                var v2 = Data_Nullable.toMaybe(props.selectedComponent.value0.component.subProjectId);
                if (v2 instanceof Data_Maybe.Just) {
                    return appDispatch(copyOperationAction(props.projectId)(v2.value0));
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at EstimationComponentsTable (line 999, column 64 - line 1003, column 20): " + [ v2.constructor.name ]);
            };
            if (props.selectedComponent instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at EstimationComponentsTable (line 998, column 26 - line 1018, column 27): " + [ props.selectedComponent.constructor.name ]);
        })();
        var duplicateComponentButton = Data_Monoid.guard(React_Basic.monoidJSX)(props.isPanel)(TacoButton.component()()({
            text: "Monista suorite",
            onClick: new Data_Maybe.Just(duplicateComponent),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }));
        var countOfSelectedRows = Data_Array.length(props.selectedRows);
        var deleteSelectedComponentsBtn = _deleteSelectedComponentsBtn({
            onDeleteComponents: props.onDeleteSelectedComponents,
            countOfSelectedComponents: countOfSelectedRows
        });
        var singleComponentSelected = countOfSelectedRows === 1;
        var copyComponentsToOtherProjectBtn = _copyComponentsToOtherProjectBtn({
            copySelectedComponentsToOtherProject: props.copySelectedComponentsToOtherProject(Types_Estimation.CopyComponentWithResources.value),
            projectFullName: props.projectFullName,
            estimationViewMode: props.viewMode
        });
        var copyComponentToElementButton = _copyComponentToElementBtn({
            selectedEditViewElement: props.selectedEditViewElement,
            projectId: props.projectId,
            selectedComponentId: Data_Array.head(selectedComponentIds)
        });
        var copyComponentOnlyBtn = _copyComponentOnlyBtn({
            copyComponentsToOtherProject: props.copySelectedComponentsToOtherProject
        });
        var columnsByType = Data_Map_Internal.fromFoldable(Types_Component.ordComponentType)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(Types_Component.RegularComponent.value, regularColumns), new Data_Tuple.Tuple(Types_Component.TopicComponent.value, topicColumns) ]);
        var estimationComponentsTable = Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](rows))(_tableOptimized({
            rows: rows,
            columns: regularColumns,
            mkRow: getRow(columnsByType)(rowProps),
            sortProperty: props.sortingCriteria.value1,
            sortingOrder: props.sortingCriteria.value0,
            rowHeight: props.rowHeight,
            onSort: props.setSortingCriteria,
            containerRef: props.containerRef,
            showHeader: true,
            styleSet: styleSet,
            initialScrollRow: props.initialScrollRow
        }));
        var canEdit = EstimationUtils.canEditView(props);
        var addNewComponent = CostEstimation_AddComponent.component({
            projectId: props.projectId,
            setNewComponentId: props.setNewComponentId,
            onShowAddComponentModal: props.onShowAddComponentModal,
            addComponentModalOpen: props.addComponentModalOpen,
            detailsElement: Data_Maybe.Nothing.value
        });
        var newMultiComponentEditModalEnabled = FeatureHooks.useFeatureEnabled("multi-component-edit-modal-new")();
        var editMultiplePricesEnabled = FeatureHooks.useFeatureEnabled("component-costclass-price-adjustment")();
        return CostEstimationTableWrapper.component({
            colorSet: TacoConstants.componentColors,
            children: [ estimationComponentsTable ],
            floatingActionButtons: React_Basic.fragment([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(addNewComponent), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && (!isSelectionEmpty && !newMultiComponentEditModalEnabled))(editMultipleComponentsButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && (!isSelectionEmpty && newMultiComponentEditModalEnabled))(newEditMultipleComponentsButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && (!isSelectionEmpty && editMultiplePricesEnabled))(editMultiplePricesButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && (Data_Maybe.isJust(props.selectedComponent) && isSelectionEmpty))(duplicateComponentButton), Data_Monoid.guard(React_Basic.monoidJSX)(!isPublished && (!isSelectionEmpty && Data_Maybe.isNothing(props.selectedEditViewElement)))(copyComponentsToOtherProjectBtn), Data_Monoid.guard(React_Basic.monoidJSX)(!isPublished && (Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.ReferenceMode.value) && (!isSelectionEmpty && Data_Maybe.isNothing(props.selectedEditViewElement))))(copyComponentOnlyBtn), Data_Monoid.guard(React_Basic.monoidJSX)(!isPublished && (Data_Maybe.isJust(props.selectedEditViewElement) && singleComponentSelected))(copyComponentToElementButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !isSelectionEmpty)(deleteSelectedComponentsBtn) ])
        });
    };
});
module.exports = {
    prepareECColumns: prepareECColumns,
    getSortingValue: getSortingValue,
    updateSortingCriteriaSEP: updateSortingCriteriaSEP,
    ContainerContext: ContainerContext,
    DetailsContext: DetailsContext,
    unEC: unEC,
    boldTextCell: boldTextCell,
    emptyCell: emptyCell,
    componentInfoTableRowHeight: componentInfoTableRowHeight,
    componentInfoTableCellHeight: componentInfoTableCellHeight,
    getEstimationComponentColumns: getEstimationComponentColumns,
    getRow: getRow,
    getComponentsToRender: getComponentsToRender,
    table: table,
    "_tableOptimized": _tableOptimized,
    styleSet: styleSet,
    "_editMultipleComponentsButton": _editMultipleComponentsButton,
    "_newEditMultipleComponentsButton": _newEditMultipleComponentsButton,
    "_copyComponentsToOtherProjectBtn": _copyComponentsToOtherProjectBtn,
    "_copyComponentToElementBtn": _copyComponentToElementBtn,
    "_copyComponentOnlyBtn": _copyComponentOnlyBtn,
    "_deleteSelectedComponentsBtn": _deleteSelectedComponentsBtn,
    QtyCanEdit: QtyCanEdit,
    QtyNotEditable: QtyNotEditable,
    mkIsComponentQuantityEditable: mkIsComponentQuantityEditable,
    mkContainsElementSpecLocations: mkContainsElementSpecLocations,
    eqQuantityEditable: eqQuantityEditable,
    multiEditModal: $foreign.multiEditModal
};
