// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

export const Header = () => {
  return (
    <div className="heading-panel">
      <div className="logo">
        <img src="https://dev.tocoman.fi/tocoman_logo_nega.png" />
      </div>
    </div>
  );
};

export function getBackBtn() {
  return (
    <button
      type="button"
      className="back-button"
      onClick={() => this.changeState("signIn")}
    >
      <span>{"< palaa kirjautumiseen"}</span>
    </button>
  );
}
