// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonMeasurements_Styles = require("../DixonMeasurements.Styles/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var QuantityFormula_Interpreter = require("../QuantityFormula.Interpreter/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Wasm_FFI = require("../Wasm.FFI/index.js");
var NoneActive = (function () {
    function NoneActive() {

    };
    NoneActive.value = new NoneActive();
    return NoneActive;
})();
var SingleActive = (function () {
    function SingleActive(value0) {
        this.value0 = value0;
    };
    SingleActive.create = function (value0) {
        return new SingleActive(value0);
    };
    return SingleActive;
})();
var MultipleActive = (function () {
    function MultipleActive() {

    };
    MultipleActive.value = new MultipleActive();
    return MultipleActive;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var measurementsTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("MeasurementsTableInstance");
var getColumns = function (locationsEnabled) {
    return function (props) {
        var unitInput = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    onSave: function (value) {
                        return function __do() {
                            var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                return function () {
                                    return editor.set_measurement_group_unit(v.item.value0.value0.measurement_group.id, value);
                                };
                            })();
                        };
                    },
                    value: v.item.value0.value0.measurement_group.unit,
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            return React_Basic.empty;
        };
        var total = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.value0.value0.formula_result));
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.value0.formula_result));
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 427, column 22 - line 430, column 93): " + [ v.item.constructor.name ]);
        };
        var selectAllCheckbox = TacoCheckbox.component()()({
            className: "select-all-checkbox",
            isChecked: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.selectedItemIds),
            onToggle: TacoCheckbox.WithShift.create(function (v) {
                return function __do() {
                    var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                        return function () {
                            return editor.toggle_all_measurements(Data_Unit.unit);
                        };
                    })();
                };
            }),
            testId: "select-all-checkbox"
        });
        var perimeter = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.value0.value0.perimeter) + " m");
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.value0.perimeter) + " m");
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 385, column 26 - line 388, column 96): " + [ v.item.constructor.name ]);
        };
        var numVertices = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(0)(v.item.value0.value0.num_vertices) + " kpl");
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(0)(v.item.value0.num_vertices) + " kpl");
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 381, column 28 - line 384, column 101): " + [ v.item.constructor.name ]);
        };
        var nameInput = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    value: v.item.value0.value0.measurement_group.name,
                    onSave: function (value) {
                        return function __do() {
                            var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                return function () {
                                    return editor.set_measurement_group_name(v.item.value0.value0.measurement_group.id, value);
                                };
                            })();
                        };
                    },
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof NoneActive) {
                return React_Basic_DOM.text("(Ei valittua mittausryhm\xe4\xe4)");
            };
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof MultipleActive) {
                return React_Basic_DOM.text("(Monta valittua mittausryhm\xe4\xe4)");
            };
            if (v.item instanceof Data_Either.Right) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    onSave: props.setValue(v.item.value0)("name"),
                    value: v.item.value0.name,
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 351, column 26 - line 366, column 10): " + [ v.item.constructor.name ]);
        };
        var mkAutocompleteItem = function (location) {
            return {
                text: location,
                id: location
            };
        };
        var locationSelectChoices = Data_Functor.mapFlipped(Data_Functor.functorArray)(props.projectLocations)(function (v) {
            return {
                text: v.code,
                id: v.code
            };
        });
        var locationCodeSelect = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: locationSelectChoices
                    } ],
                    clearButton: true,
                    placeholder: "",
                    onSelect: function (item$prime$prime) {
                        if (item$prime$prime instanceof Data_Maybe.Just) {
                            return function __do() {
                                var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                    return function () {
                                        return editor.set_measurement_group_defaults(v.item.value0.value0.measurement_group.id, item$prime$prime.value0.id, v.item.value0.value0.measurement_group.default_formula);
                                    };
                                })();
                            };
                        };
                        if (item$prime$prime instanceof Data_Maybe.Nothing) {
                            return function __do() {
                                var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                    return function () {
                                        return editor.set_measurement_group_defaults(v.item.value0.value0.measurement_group.id, "", v.item.value0.value0.measurement_group.default_formula);
                                    };
                                })();
                            };
                        };
                        throw new Error("Failed pattern match at DixonMeasurements (line 323, column 13 - line 331, column 130): " + [ item$prime$prime.constructor.name ]);
                    },
                    selected: (function () {
                        var $84 = v.item.value0.value0.measurement_group.default_location === "";
                        if ($84) {
                            return Data_Maybe.Nothing.value;
                        };
                        return Data_Maybe.Just.create(mkAutocompleteItem(v.item.value0.value0.measurement_group.default_location));
                    })(),
                    testId: "default-location-code-select",
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: locationSelectChoices
                    } ],
                    clearButton: false,
                    placeholder: "",
                    onSelect: function (item$prime$prime) {
                        if (item$prime$prime instanceof Data_Maybe.Just) {
                            return props.setValue(v.item.value0)("location")(item$prime$prime.value0.id);
                        };
                        if (item$prime$prime instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at DixonMeasurements (line 342, column 13 - line 345, column 35): " + [ item$prime$prime.constructor.name ]);
                    },
                    selected: Data_Maybe.Just.create(mkAutocompleteItem(v.item.value0.location)),
                    testId: "location-code-select",
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 317, column 35 - line 349, column 10): " + [ v.item.constructor.name ]);
        };
        var defaultFormulaValidator = function (formula) {
            var validatorContext = Wasm_FFI.dixonFormulaContext({
                numVertices: 0.0,
                perimeter: 0.0,
                area: 0.0,
                formula: formula
            });
            var parsedFormula = QuantityFormula_Parser.convertToInternalFormula(formula);
            var v = QuantityFormula_Interpreter.evalQuantityFormula(validatorContext)(parsedFormula);
            if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QNum) {
                return TacoInput.Valid.value;
            };
            if (v instanceof Data_Either.Right) {
                return new TacoInput.Invalid("");
            };
            if (v instanceof Data_Either.Left) {
                return new TacoInput.Invalid("");
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 399, column 10 - line 403, column 29): " + [ v.constructor.name ]);
        };
        var formulaInput = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    value: Data_Newtype.un()(QuantityFormula_Types.UserFormula)(QuantityFormula_Parser.convertToUserFormula(v.item.value0.value0.measurement_group.default_formula)),
                    align: TacoInput.RightAlign.value,
                    validator: function (formula) {
                        var $101 = formula === "" || Data_Eq.eq(TacoInput.eqValueValidity)(defaultFormulaValidator(formula))(TacoInput.Valid.value);
                        if ($101) {
                            return TacoInput.Valid.value;
                        };
                        return new TacoInput.Invalid("Virheellinen kaava");
                    },
                    onSave: function (defaultFormula) {
                        var parsedFormula = QuantityFormula_Parser.convertToInternalFormula(defaultFormula);
                        var formula = Data_Newtype.un()(QuantityFormula_Types.InternalFormula)(parsedFormula);
                        return function __do() {
                            var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                return function () {
                                    return editor.set_measurement_group_defaults(v.item.value0.value0.measurement_group.id, v.item.value0.value0.measurement_group.default_location, formula);
                                };
                            })();
                        };
                    },
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    onSave: props.setValue(v.item.value0)("formula"),
                    value: Data_Newtype.un()(QuantityFormula_Types.UserFormula)(QuantityFormula_Parser.convertToUserFormula(v.item.value0.formula)),
                    align: TacoInput.RightAlign.value,
                    validator: function (formula) {
                        var $106 = Data_Eq.eq(TacoInput.eqValueValidity)(defaultFormulaValidator(formula))(TacoInput.Valid.value);
                        if ($106) {
                            return TacoInput.Valid.value;
                        };
                        return new TacoInput.Invalid("Virheellinen kaava");
                    },
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 405, column 29 - line 425, column 10): " + [ v.item.constructor.name ]);
        };
        var codeInput = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    value: v.item.value0.value0.measurement_group.code,
                    onSave: function (value) {
                        return function __do() {
                            var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                return function () {
                                    return editor.set_measurement_group_code(v.item.value0.value0.measurement_group.id, value);
                                };
                            })();
                        };
                    },
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
                });
            };
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof NoneActive) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof MultipleActive) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 368, column 26 - line 379, column 27): " + [ v.item.constructor.name ]);
        };
        var checkbox = function (v) {
            if (v.item instanceof Data_Either.Left) {
                return TacoButton.component()()({
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
                    color: TacoConstants.Gray.value,
                    onClick: Data_Maybe.Just.create(props.setActiveMeasurementsTabId(function (v1) {
                        return new Data_Maybe.Just("measurement-groups");
                    }))
                });
            };
            if (v.item instanceof Data_Either.Right) {
                return TacoCheckbox.component()()({
                    className: "item-select-checkbox",
                    isChecked: v.checked,
                    onToggle: TacoCheckbox.WithShift.create(function (v1) {
                        return function __do() {
                            var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                                return function () {
                                    return editor.toggle_measurement(v.item.value0.id);
                                };
                            })();
                        };
                    }),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "item-select-checkbox" + Data_Show.show(Data_Show.showInt)(v.item.value0.id)
                });
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 279, column 34 - line 294, column 10): " + [ v.item.constructor.name ]);
        };
        var area = function (v) {
            if (v.item instanceof Data_Either.Left && v.item.value0 instanceof SingleActive) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(2)(v.item.value0.value0.area) + " m\xb2");
            };
            if (v.item instanceof Data_Either.Left) {
                return React_Basic.empty;
            };
            if (v.item instanceof Data_Either.Right) {
                return React_Basic_DOM.text(Formatters.formatDecimalValue(2)(v.item.value0.area) + " m\xb2");
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 389, column 21 - line 392, column 92): " + [ v.item.constructor.name ]);
        };
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Fixed("24px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
            headerJSX: new Data_Maybe.Just(selectAllCheckbox)
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Monoid.guard(Data_Monoid.monoidArray)(locationsEnabled)([ {
            key: "location",
            label: React_Basic_DOM.text("Sijainti"),
            width: new TacoTable_Types.FlexMinWidth(1, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], locationCodeSelect),
            headerJSX: Data_Maybe.Nothing.value
        } ]))([ {
            key: "code",
            label: React_Basic_DOM.text("Luokka"),
            width: new TacoTable_Types.FlexMinWidth(2, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], codeInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text("Selite"),
            width: new TacoTable_Types.FlexMinWidth(3, "140px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], nameInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "num_vertices",
            label: React_Basic_DOM.text("Kpl (N)"),
            width: new TacoTable_Types.FlexMinWidth(1, "60px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], numVertices),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "perimeter",
            label: React_Basic_DOM.text("Pituus / Piiri (P)"),
            width: new TacoTable_Types.FlexMinWidth(2, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], perimeter),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "area",
            label: React_Basic_DOM.text("Pinta-ala (A)"),
            width: new TacoTable_Types.FlexMinWidth(2, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], area),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "formula",
            label: React_Basic_DOM.text("Kaava (\u0192)"),
            width: new TacoTable_Types.FlexMinWidth(1, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], formulaInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "total",
            label: React_Basic_DOM.text("Summa (\u0192)"),
            width: new TacoTable_Types.FlexMinWidth(2, "100px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], total),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text("Yksikk\xf6"),
            width: new TacoTable_Types.FlexMinWidth(1, "80px"),
            sortProperty: TacoTable_Types.noSortProperty,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitInput),
            headerJSX: Data_Maybe.Nothing.value
        } ]));
    };
};
var mkRow = function (locationsEnabled) {
    return function (props) {
        return function (v) {
            return {
                className: v.className + (function () {
                    if (v.rowData.item instanceof Data_Either.Right && v.rowData.item.value0.highlighted === true) {
                        return "-highlighted";
                    };
                    return "";
                })(),
                rowData: v.rowData,
                foldingElement: Data_Maybe.Nothing.value,
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                rowKey: v.rowKey,
                columns: getColumns(locationsEnabled)(props)
            };
        };
    };
};
var getRow = function (locationsEnabled) {
    return function (props) {
        return function (r) {
            return mkRow(locationsEnabled)(props)({
                className: (function () {
                    if (r.item instanceof Data_Either.Left) {
                        return "DixonMeasurementsTableMGroupRow";
                    };
                    if (r.item instanceof Data_Either.Right) {
                        return "DixonMeasurementsTableRow";
                    };
                    throw new Error("Failed pattern match at DixonMeasurements (line 466, column 16 - line 468, column 45): " + [ r.item.constructor.name ]);
                })(),
                rowData: r,
                rowKey: Data_Show.show(Data_Show.showString)((function () {
                    if (r.item instanceof Data_Either.Left) {
                        return "measurement_group";
                    };
                    if (r.item instanceof Data_Either.Right) {
                        return Data_Show.show(Data_Show.showInt)(r.item.value0.id);
                    };
                    throw new Error("Failed pattern match at DixonMeasurements (line 470, column 18 - line 472, column 33): " + [ r.item.constructor.name ]);
                })())
            });
        };
    };
};
var eqMeasurementGroupSummary = {
    eq: function (x) {
        return function (y) {
            if (x instanceof NoneActive && y instanceof NoneActive) {
                return true;
            };
            if (x instanceof SingleActive && y instanceof SingleActive) {
                return x.value0.area === y.value0.area && x.value0.formula_result === y.value0.formula_result && (x.value0.measurement_group.active === y.value0.measurement_group.active && x.value0.measurement_group.area === y.value0.measurement_group.area && x.value0.measurement_group.code === y.value0.measurement_group.code && x.value0.measurement_group.color === y.value0.measurement_group.color && x.value0.measurement_group.default_formula === y.value0.measurement_group.default_formula && x.value0.measurement_group.default_location === y.value0.measurement_group.default_location && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.value0.measurement_group.drawing_ids)(y.value0.measurement_group.drawing_ids) && x.value0.measurement_group.formula_sum === y.value0.measurement_group.formula_sum && x.value0.measurement_group.id === y.value0.measurement_group.id && Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementId))(x.value0.measurement_group.linked_building_element_ids)(y.value0.measurement_group.linked_building_element_ids) && Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqComponentId))(x.value0.measurement_group.linked_component_ids)(y.value0.measurement_group.linked_component_ids) && x.value0.measurement_group.name === y.value0.measurement_group.name && x.value0.measurement_group.num_vertices === y.value0.measurement_group.num_vertices && x.value0.measurement_group.perimeter === y.value0.measurement_group.perimeter && x.value0.measurement_group.unit === y.value0.measurement_group.unit) && x.value0.num_vertices === y.value0.num_vertices && x.value0.perimeter === y.value0.perimeter;
            };
            if (x instanceof MultipleActive && y instanceof MultipleActive) {
                return true;
            };
            return false;
        };
    }
};
var component = TofuHooks.mkHookComponent("DixonMeasurements")(function (v) {
    return function __do() {
        var v1 = Client_WASM.useProjectLocations(v.projectId)();
        var v2 = TofuHooks.useState(false)();
        var locationsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Locations.value)();
        var setValue = function (item) {
            return function (id) {
                return function (value) {
                    return function __do() {
                        var mEditor = React_Basic_Hooks.readRef(v.editorRef)();
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor$prime) {
                            if (id === "name") {
                                return function () {
                                    return editor$prime.set_measurement_name(item.id, value);
                                };
                            };
                            if (id === "location") {
                                return function () {
                                    return editor$prime.set_measurement_location(item.id, value);
                                };
                            };
                            if (id === "formula") {
                                return function () {
                                    return editor$prime.set_measurement_formula(item.id, Data_Newtype.un()(QuantityFormula_Types.InternalFormula)(QuantityFormula_Parser.convertToInternalFormula(value)));
                                };
                            };
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        })();
                    };
                };
            };
        };
        var openDeleteModal = v2.value1(function (v3) {
            return true;
        });
        var num_vertices = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
            return v3.num_vertices;
        })(v.editorState.measurements));
        var perimeter = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
            return v3.perimeter;
        })(v.editorState.measurements));
        var formula_result = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
            return v3.formula_result;
        })(v.editorState.measurements));
        var deleteMeasurementsButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Poista valitut mittaukset",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(openDeleteModal),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var closeDeleteModal = v2.value1(function (v3) {
            return false;
        });
        var area = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
            return v3.area;
        })(v.editorState.measurements));
        var activeMeasurementGroups = Data_Array.filter(function (v3) {
            return v3.active;
        })(v.editorState.measurement_groups);
        var measurementGroupItem = (function () {
            var $154 = Data_Array["null"](activeMeasurementGroups);
            if ($154) {
                return NoneActive.value;
            };
            var $155 = Data_Array.length(activeMeasurementGroups) === 1;
            if ($155) {
                var first = Data_Array.index(activeMeasurementGroups)(0);
                if (first instanceof Data_Maybe.Just) {
                    return new SingleActive({
                        measurement_group: first.value0,
                        num_vertices: num_vertices,
                        perimeter: perimeter,
                        area: area,
                        formula_result: formula_result
                    });
                };
                if (first instanceof Data_Maybe.Nothing) {
                    return NoneActive.value;
                };
                throw new Error("Failed pattern match at DixonMeasurements (line 200, column 11 - line 202, column 34): " + [ first.constructor.name ]);
            };
            return MultipleActive.value;
        })();
        var activeMeasurementGroup = (function () {
            var $158 = Data_Array.length(activeMeasurementGroups) > 1;
            if ($158) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeMeasurementGroups);
        })();
        var activeItems = Data_Array.filter(function (v3) {
            return v3.active;
        })(v.editorState.measurements);
        var selectedItemIds = Data_Functor.map(Data_Functor.functorArray)(function (v3) {
            return v3.id;
        })(activeItems);
        var deleteMeasurements = function () {
            return v.editor.delete_measurements(Data_Array.fromFoldable(Data_Foldable.foldableArray)(selectedItemIds));
        };
        var deleteModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeDeleteModal),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                deleteMeasurements();
                return closeDeleteModal();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Poista"
        }) ];
        var deleteMeasurementGroupsModal = TacoModal.component()()({
            onClose: closeDeleteModal,
            isActive: v2.value0,
            heading: "Poista valitut mittaukset",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Haluatko varmasti poistaa valitut mittaukset sek\xe4 kaikki n\xe4ist\xe4 linkitetyt m\xe4\xe4r\xe4t?") ]) ]
            }), TacoModal.modalActions({
                contents: deleteModalActions
            }) ]
        });
        var measurementItems = Data_Functor.map(Data_Functor.functorArray)(function (item) {
            return {
                item: new Data_Either.Right(item),
                checked: Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqInt)(item.id)(selectedItemIds)
            };
        })(v.editorState.measurements);
        var items = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            item: new Data_Either.Left(measurementGroupItem),
            checked: false
        } ])(measurementItems);
        var tableProps = {
            selectedItemIds: selectedItemIds,
            setValue: setValue,
            editorRef: v.editorRef,
            projectLocations: v1.value0,
            setActiveMeasurementsTabId: v.setActiveMeasurementsTabId,
            maybeLockedProject: v.maybeLockedProject
        };
        var columns = getColumns(locationsEnabled)(tableProps);
        var table = TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "item";
            }
        })(Data_Either.eqEither(eqMeasurementGroupSummary)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "state";
            }
        })(DixonMeasurements_Types.eqMeasurementState))()({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.eqShape))()({
            reflectSymbol: function () {
                return "perimeter";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "num_vertices";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "measurement_group_id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "location";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "highlighted";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "formula_result";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "formula";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "drawing_id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "created_at";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "area";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "active";
            }
        })(Data_Eq.eqBoolean)))))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean)))(measurementsTableInstance)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Functor.map(Data_Functor.functorArray)(getRow(locationsEnabled)(tableProps))(items),
            showHeader: true,
            styleSet: styleSet,
            columns: columns
        });
        var activeDrawings = Data_Array.filter(function (v3) {
            return v3.active;
        })(Data_Array.concatMap(function (v3) {
            return v3.drawings;
        })(Data_Array.filter(function (v3) {
            return v3.active;
        })(v.editorState.files)));
        var activeDrawing = (function () {
            var $159 = Data_Array.length(activeDrawings) > 1;
            if ($159) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Array.head(activeDrawings);
        })();
        var copiedFromActiveDrawing = (function () {
            if (activeDrawing instanceof Data_Maybe.Just) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(new Data_Maybe.Just(activeDrawing.value0.id))(v.copiedMeasurementsDrawingId);
            };
            if (activeDrawing instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at DixonMeasurements (line 80, column 31 - line 82, column 23): " + [ activeDrawing.constructor.name ]);
        })();
        var copyMeasurements = React_Basic.fragment([ TacoButton.component()()({
            text: "Kopioi mittaukset",
            title: "Jos mittauksia kopioidaan piirustuksesta toiseen, pit\xe4\xe4 mittakaava asettaa eri piirustuksiin samaan kohtaan ja mitattuna saman suuntaisesti esim vasemmalta oikealla tai ylh\xe4\xe4lt\xe4 alas. Kopioitavat mittaukset liitet\xe4\xe4n uuteen piirustukseen n\xe4iden mittausten perusteella. ",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: Data_Maybe.Just.create(function __do() {
                v.setCopiedMeasurements(function (v3) {
                    return activeItems;
                })();
                return v.setCopiedMeasurementsDrawingId(function (v3) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v4) {
                        return v4.id;
                    })(activeDrawing);
                })();
            }),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var pasteCopiedMeasurements = function __do() {
            var mEditor = React_Basic_Hooks.readRef(v.editorRef)();
            if (mEditor instanceof Data_Maybe.Just && activeDrawing instanceof Data_Maybe.Just) {
                var wasmMeasurements = TofuJSON.write(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "active";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "area";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "created_at";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "drawing_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "formula_result";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "highlighted";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "location";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "measurement_group_id";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "num_vertices";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "perimeter";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "shape";
                    }
                })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(DixonMeasurements_Types.tofuJSONMeasurementState)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(v.copiedMeasurements);
                return mEditor.value0.paste_measurements(activeDrawing.value0.id, wasmMeasurements);
            };
            return Data_Unit.unit;
        };
        var pasteMeasurements = React_Basic.fragment([ TacoButton.component()()({
            text: "Liit\xe4 mittaukset",
            title: "Jos mittauksia kopioidaan piirustuksesta toiseen, pit\xe4\xe4 mittakaava asettaa eri piirustuksiin samaan kohtaan ja mitattuna saman suuntaisesti esim vasemmalta oikealla tai ylh\xe4\xe4lt\xe4 alas. Kopioitavat mittaukset liitet\xe4\xe4n uuteen piirustukseen n\xe4iden mittausten perusteella. ",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(pasteCopiedMeasurements),
            disabled: (!Data_Maybe.isJust(activeDrawing) || !Data_Maybe.isJust(activeMeasurementGroup)) && copiedFromActiveDrawing,
            size: TacoConstants.L.value
        }) ]);
        var floatingActionButtons = React_Basic.fragment([ Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](selectedItemIds))(deleteMeasurementsButton), Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](selectedItemIds))(copyMeasurements), Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](v.copiedMeasurements))(pasteMeasurements) ]);
        var measurementsTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.componentColors,
            children: [ table ],
            floatingActionButtons: floatingActionButtons
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonMeasurements",
            css: DixonMeasurements_Styles.dixonMeasurementsContainerStyles({
                highlightColor: TacoConstants.colorSchemeToStr(TacoConstants.GrayLightest.value)
            }),
            children: [ measurementsTable, deleteMeasurementGroupsModal ]
        });
    };
});
module.exports = {
    component: component,
    NoneActive: NoneActive,
    SingleActive: SingleActive,
    MultipleActive: MultipleActive,
    styleSet: styleSet,
    getColumns: getColumns,
    mkRow: mkRow,
    getRow: getRow,
    measurementsTableInstance: measurementsTableInstance,
    eqMeasurementGroupSummary: eqMeasurementGroupSummary
};
