import {
  mapToOfferClassificationSummary,
  OfferClassificationSummary,
  OfferPageProps,
} from "./OfferPage";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { useMemo } from "react";
import { useOfferParamsQuery } from "./useOfferParamsQuery";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { Loader } from "@tocoman/ui";
import { PremiumOfferPageTable } from "./PremiumOfferPageTable";

export type PremiumOfferData = OfferClassificationSummary & {
  costClasses: PremiumOfferCostClass[];
};

export type PremiumOfferCostClass = {
  total: number;
} & CostClass;

export const PremiumOfferPage = ({ project }: OfferPageProps) => {
  const projectId = project.id;

  const measurementUnits = useWorkerState("MeasurementsState", projectId);

  const classificationData = useWorkerState(
    "ProjectClassificationDataWithoutSocialExpensesState",
    projectId
  );

  const {
    data: offerParams,
    isLoading: offerParamsLoading,
    refetch: refetchOfferParams,
  } = useOfferParamsQuery(projectId);

  const emptyOfferParams = {
    rowDescriptions: [],
    offerOverrides: [],
    changePercentages: [],
    riskPercentages: [],
    generalPercentages: [],
    additionalCosts: [],
    marginPercentages: [],
    specialPercentages: [],
    showEconomicMargin: true,
  };
  const tableRowData: PremiumOfferData[] | null = useMemo(() => {
    if (!classificationData) {
      return null;
    }
    const classificationSummary = mapToOfferClassificationSummary(
      project,
      classificationData,
      offerParams ?? emptyOfferParams
    );

    return classificationSummary.map((data) => {
      const costClasses = data.totalPriceByCostClasses.map((costClass) => {
        const total = costClass.totalPrice;
        return {
          ...costClass.costClass,
          total: total,
        };
      });
      return {
        ...data,
        costClasses,
      };
    });
  }, [classificationData, offerParams, project]);

  return (
    <div className={"h-full w-full"}>
      {offerParamsLoading || (!classificationData && <Loader />)}
      <PremiumOfferPageTable
        projectId={projectId}
        tableRowData={tableRowData}
        measurementUnits={measurementUnits}
        offerParams={offerParams ?? emptyOfferParams}
        refetchOfferParams={refetchOfferParams}
      />
    </div>
  );
};
