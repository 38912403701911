import { ProjectDetailsData } from "../../../../../ts-bindings/ProjectDetailsData";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { ProjectClassificationData } from "../../../../../ts-bindings/ProjectClassificationData";
import { useEffect, useMemo } from "react";
import { OfferParams, useProOfferParamsQuery } from "./useOfferParamsQuery";
import { Loader, SelectItem } from "@tocoman/ui";
import { OfferPageTable } from "./OfferPageTable";
import { useState } from "react";
import { Button } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { OfferPdfSelection } from "./OfferPdfSelection";
import { useLocalStorage } from "usehooks-ts";

export type OfferProject = Omit<
  ProjectDetailsData,
  "productionPlanning" | "costGroupClassification"
>;
export type OfferPageProps = {
  project: OfferProject;
};

export type OfferClassificationSummary = Omit<
  ProjectClassificationData,
  "children"
> & {
  marginPercentage: number;
  generalMarginPercentage: number | undefined;
  economicTotal: number;
  grandTotal: number;
  economicMargin: number;
  technicalMargin: number;
  totalWithEconomicMargin: number;
  totalWithTechnicalMargin: number;
  marginPercentageOfTotal: number;
  VAT: number;
  totalWithVAT: number;
  VATPercentage: number;
};

export function mapToOfferClassificationSummary(
  project: OfferProject,
  classifications: ProjectClassificationData[],
  offerParams: OfferParams
): OfferClassificationSummary[] {
  return classifications.map((classification) => {
    const { children, ...rest } = classification;
    const generalMargin = offerParams.marginPercentages.find(
      (param) => param.key === "summary"
    );
    const customMarginPercentage = offerParams.marginPercentages.find(
      (param) => param.key === classification.code
    );
    const marginInUse = customMarginPercentage
      ? customMarginPercentage
      : generalMargin;
    const marginPercentage = marginInUse ? marginInUse.value : 0;
    return {
      ...rest,
      marginPercentage,
      economicMargin: 0,
      technicalMargin: 0,
      economicTotal: 0,
      grandTotal: 0,
      totalWithEconomicMargin: 0,
      totalWithTechnicalMargin: 0,
      marginPercentageOfTotal: 0,
      VAT: 0,
      totalWithVAT: 0,
      VATPercentage: project.taxRate ?? 24,
      generalMarginPercentage: generalMargin?.value,
    };
  });
}

export function OfferPage({ project }: OfferPageProps) {
  const projectId = project.id;
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const [pdfPrintModalOpen, setPdfPrintModalOpen] = useState<boolean>(false);

  const measurementUnits = useWorkerState("MeasurementsState", projectId);

  const classificationData = useWorkerState(
    "UnfilteredSepProjectClassificationDataState",
    projectId
  );

  const [marginType, setMarginType] = useLocalStorage<
    SelectItem<string> | undefined
  >("offerMarginType", {
    value: "economicMargin",
    label: t`economicMargin`,
    key: "economicMargin",
  });

  const {
    data: offerParams,
    isLoading: offerParamsLoading,
  } = useProOfferParamsQuery(projectId);

  const tableRowData = useMemo(() => {
    if (!offerParams || !classificationData) {
      return null;
    }
    return mapToOfferClassificationSummary(
      project,
      classificationData,
      offerParams
    );
  }, [classificationData, offerParams, project]);

  const [rowData, setRowData] = useState<OfferClassificationSummary[] | null>(
    null
  );

  useEffect(() => {
    if (rowData === null) {
      // Make a copy of the data to make AG Grids undo/redo work
      setRowData(tableRowData);
    }
  }, [rowData, tableRowData]);

  return (
    <div className={"h-full"}>
      <OfferPdfSelection
        isOpen={pdfPrintModalOpen}
        closeModal={() => setPdfPrintModalOpen(false)}
        data={rowData}
        projectData={project}
        marginType={marginType?.value ?? "economicMargin"}
      />
      {offerParamsLoading && <Loader />}
      <OfferPageTable
        rowData={rowData}
        measurementUnits={measurementUnits ?? []}
        projectData={{
          projectId,
          taxRate: project.taxRate,
          currency: project.currency ?? "EUR",
        }}
        marginType={marginType}
        onMarginTypeChange={setMarginType}
      />
      <Button
        className={"float-right mr-3"}
        onClick={() => setPdfPrintModalOpen(true)}
      >{t`print`}</Button>
    </div>
  );
}
