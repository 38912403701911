// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    addMouseClickEventListenerToDocument: $foreign.addMouseClickEventListenerToDocument,
    addMouseClickEventListenerToDocument_: $foreign.addMouseClickEventListenerToDocument_,
    removeMouseClickEventListenerFromDocument: $foreign.removeMouseClickEventListenerFromDocument,
    addWindowResizeListener: $foreign.addWindowResizeListener,
    removeWindowResizeListener: $foreign.removeWindowResizeListener,
    addScrollEventListenerToElement: $foreign.addScrollEventListenerToElement,
    removeScrollEventListenerFromElement: $foreign.removeScrollEventListenerFromElement,
    asDistinctStream: $foreign.asDistinctStream
};
