// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ResourcesView_Styles = require("../ResourcesView.Styles/index.js");
var ScrollContainer = require("../ScrollContainer/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Location = require("../Types.Location/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var Utils = require("../Utils/index.js");
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByUnit = (function () {
    function ByUnit() {

    };
    ByUnit.value = new ByUnit();
    return ByUnit;
})();
var ByQuantity = (function () {
    function ByQuantity() {

    };
    ByQuantity.value = new ByQuantity();
    return ByQuantity;
})();
var ByDescription = (function () {
    function ByDescription() {

    };
    ByDescription.value = new ByDescription();
    return ByDescription;
})();
var ByWastePercentage = (function () {
    function ByWastePercentage() {

    };
    ByWastePercentage.value = new ByWastePercentage();
    return ByWastePercentage;
})();
var ByTotalAmount = (function () {
    function ByTotalAmount() {

    };
    ByTotalAmount.value = new ByTotalAmount();
    return ByTotalAmount;
})();
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColUnit = (function () {
    function ColUnit() {

    };
    ColUnit.value = new ColUnit();
    return ColUnit;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColDescription = (function () {
    function ColDescription() {

    };
    ColDescription.value = new ColDescription();
    return ColDescription;
})();
var ColQuantity = (function () {
    function ColQuantity() {

    };
    ColQuantity.value = new ColQuantity();
    return ColQuantity;
})();
var ColWastePercentage = (function () {
    function ColWastePercentage() {

    };
    ColWastePercentage.value = new ColWastePercentage();
    return ColWastePercentage;
})();
var ColEfficiency = (function () {
    function ColEfficiency() {

    };
    ColEfficiency.value = new ColEfficiency();
    return ColEfficiency;
})();
var ColTotalAmount = (function () {
    function ColTotalAmount() {

    };
    ColTotalAmount.value = new ColTotalAmount();
    return ColTotalAmount;
})();
var tableRowHeight = TacoTable_Types.RowSmall.value;
var sortElements = function (criteria) {
    if (criteria.value1 instanceof Data_Maybe.Just) {
        var withOrder = (function () {
            var $46 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(criteria.value0)(TacoTable_Types.Descending.value);
            if ($46) {
                return Data_Function.flip;
            };
            return Control_Category.identity(Control_Category.categoryFn);
        })();
        var sortableValue = function (x) {
            return criteria.value2(criteria.value1.value0)(x);
        };
        return Data_Array.sortBy(function (a) {
            return function (b) {
                return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
            };
        });
    };
    return Control_Category.identity(Control_Category.categoryFn);
};
var resourceLocationsTable = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "totalCost";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "quantity";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "locationDescription";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "locationCode";
    }
})(Data_Eq.eqString)));
var resourceDetailsTableStyleSet = TacoTable_Styles.optimizedTableStyleSet;
var prepareColumns = function (fn) {
    var mkCol = function (v) {
        var $52 = fn(v.col);
        return {
            sortProperty: $52.sortProperty,
            key: v.key,
            label: v.label,
            width: v.width,
            cell: $52.cell,
            headerJSX: Data_Maybe.Nothing.value
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(mkCol)([ {
        col: ColCode.value,
        key: "code",
        label: React_Basic_DOM.text("Koodi"),
        width: new TacoTable_Types.Fixed("10rem")
    }, {
        col: ColDescription.value,
        key: "description",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(1)
    }, {
        col: ColQuantity.value,
        key: "quantity",
        label: React_Basic_DOM.text("Menekki"),
        width: new TacoTable_Types.Fixed("7rem")
    }, {
        col: ColWastePercentage.value,
        key: "waste",
        label: React_Basic_DOM.text("Lis\xe4-%"),
        width: new TacoTable_Types.Fixed("7rem")
    }, {
        col: ColTotalAmount.value,
        key: "totalAmount",
        label: React_Basic_DOM.text("Panosm\xe4\xe4r\xe4"),
        width: new TacoTable_Types.Fixed("7rem")
    }, {
        col: ColUnit.value,
        key: "unit",
        label: React_Basic_DOM.text("Yksikk\xf6"),
        width: new TacoTable_Types.Fixed("7rem")
    } ]);
};
var mkResourceLocations = function (selectedMergedResource) {
    return function (locations) {
        return function (allEstimationLocations) {
            return function (sep) {
                var unProjectLocation = Data_Newtype.un()(Types_Location.ProjectLocation);
                var resourceUnit = Utils.fromNullable("")((function (v) {
                    return v.unit;
                })(Data_Tuple.fst(Data_Array_NonEmpty.head(selectedMergedResource.estimationResources))));
                var getResourcesForComponent = function (componentId) {
                    var matchComponentId = function (v) {
                        if (v.value1 instanceof Data_Maybe.Just && Data_Eq.eq(Types_Component.eqComponentId)(v.value1.value0.id)(componentId)) {
                            return new Data_Maybe.Just(v.value0);
                        };
                        return Data_Maybe.Nothing.value;
                    };
                    return Data_Array_NonEmpty.mapMaybe(matchComponentId)(selectedMergedResource.estimationResources);
                };
                var quantityForLocation = function (estimationLocation) {
                    var resources = getResourcesForComponent(estimationLocation.estimationComponentId);
                    var getAmount = function (res) {
                        return CostEstimation_Calculations.resourceTotalAmount(estimationLocation)(res);
                    };
                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(getAmount)(resources));
                };
                var totalCostForLocation = function (estimationLocation) {
                    var resources = getResourcesForComponent(estimationLocation.estimationComponentId);
                    var getAmount = function (res) {
                        return CostEstimation_Calculations.resourceTotalPrice(sep)(estimationLocation)(res);
                    };
                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(getAmount)(resources));
                };
                var mkResourceLocation = function (locationCode) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(unProjectLocation)(Data_Array.find((function () {
                        var $122 = Data_Eq.eq(Data_Eq.eqString)(locationCode);
                        return function ($123) {
                            return $122((function (v) {
                                return v.code;
                            })(unProjectLocation($123)));
                        };
                    })())(locations)))(function (location) {
                        var estimationLocations = Data_Array.filter(function (x) {
                            return x.locationCode === locationCode;
                        })(allEstimationLocations);
                        var quantity = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(quantityForLocation)(estimationLocations));
                        var totalCost = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(totalCostForLocation)(estimationLocations));
                        return new Data_Maybe.Just({
                            locationCode: location.code,
                            locationDescription: location.description,
                            quantity: quantity,
                            unit: resourceUnit,
                            totalCost: totalCost
                        });
                    });
                };
                var componentIds$prime = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.id;
                })(Data_Array_NonEmpty.catMaybes(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Tuple.snd)(selectedMergedResource.estimationResources))));
                var findMatchingComponentId = function (l) {
                    return Data_Set.member(Types_Component.ordComponentId)(l.estimationComponentId)(componentIds$prime);
                };
                var relevantLocationCodes = Data_Array.nub(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.locationCode;
                })(Data_Array.filter(findMatchingComponentId)(allEstimationLocations)));
                var resourceLocations = Data_Array.mapMaybe(mkResourceLocation)(relevantLocationCodes);
                return Data_Array.sortWith(Data_Ord.ordString)(function (v) {
                    return v.locationCode;
                })(resourceLocations);
            };
        };
    };
};
var getRow = function (columns) {
    return function (v) {
        return function (v1) {
            return function (v2) {
                var onClick = function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                return {
                    className: Data_Monoid.guard(Data_Monoid.monoidString)(false)("selected"),
                    rowData: v2,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: onClick,
                    rowKey: Data_Show.show(Types_Component.showEstimationResourceId)(v2.resourceWithComponent.value0.id),
                    columns: columns
                };
            };
        };
    };
};
var getResourceLocationRow = function (columns) {
    return function (v) {
        return function (r) {
            return {
                className: "resource-location-row",
                rowData: r,
                foldingElement: Data_Maybe.Nothing.value,
                onClick: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                rowKey: r.locationCode,
                columns: columns
            };
        };
    };
};
var getResourceLocationColumns = function (v) {
    var mkCol = function (v1) {
        return {
            sortProperty: TacoTable_Types.noSortProperty,
            key: v1.key,
            label: v1.label,
            width: v1.width,
            cell: v1.cell,
            headerJSX: Data_Maybe.Nothing.value
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(mkCol)([ {
        key: "locationCode",
        label: React_Basic_DOM.text("Sijainti"),
        width: new TacoTable_Types.Fixed("10rem"),
        cell: new TacoTable_Types.PlainTextCell(function (v1) {
            return v1.locationCode;
        })
    }, {
        key: "locationDescription",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(1),
        cell: new TacoTable_Types.PlainTextCell(function (v1) {
            return v1.locationDescription;
        })
    }, {
        key: "quantity",
        label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
        width: new TacoTable_Types.Fixed("10rem"),
        cell: new TacoTable_Types.PlainNumberCell(2, function (v1) {
            return v1.quantity;
        })
    }, {
        key: "unit",
        label: React_Basic_DOM.text("Yks"),
        width: new TacoTable_Types.Fixed("10rem"),
        cell: new TacoTable_Types.PlainTextCell(function (v1) {
            return v1.unit;
        })
    }, {
        key: "totalPrice",
        label: React_Basic_DOM.text("Hinta"),
        width: new TacoTable_Types.Fixed("10rem"),
        cell: new TacoTable_Types.PlainEuroCell(function (v1) {
            return v1.totalCost;
        })
    } ]);
};
var getNameResourceDetailsTableSortProperty = {
    getName: function (v) {
        return "ResourceDetailsTableSortProperty";
    }
};
var eqResourceDetailsTableSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return true;
            };
            if (x instanceof ByQuantity && y instanceof ByQuantity) {
                return true;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return true;
            };
            if (x instanceof ByWastePercentage && y instanceof ByWastePercentage) {
                return true;
            };
            if (x instanceof ByTotalAmount && y instanceof ByTotalAmount) {
                return true;
            };
            return false;
        };
    }
};
var ordResourceDetailsTableSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByUnit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByUnit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByQuantity && y instanceof ByQuantity) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByQuantity) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByQuantity) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByWastePercentage && y instanceof ByWastePercentage) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByWastePercentage) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByWastePercentage) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTotalAmount && y instanceof ByTotalAmount) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView.SelectedResourceDetails (line 67, column 1 - line 67, column 92): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqResourceDetailsTableSortProperty;
    }
};
var resourceDetailsTableOptimized = TacoTable.tableOptimized(getNameResourceDetailsTableSortProperty)(eqResourceDetailsTableSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "resourceWithComponent";
    }
})(Data_Tuple.eqTuple(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))))(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))));
var boldTextCell = function (property) {
    return function (r) {
        return React_Basic_DOM_Generated.span()({
            className: "bold",
            children: [ React_Basic_DOM.text(property(r)) ]
        });
    };
};
var _unRes = function (v) {
    return {
        resource: v.resourceWithComponent.value0,
        component: v.resourceWithComponent.value1
    };
};
var getTotalAmount = function (res) {
    var v = _unRes(res);
    if (v.component instanceof Data_Maybe.Just) {
        return CostEstimation_Calculations.resourceTotalAmount(v.component.value0)(v.resource);
    };
    if (v.component instanceof Data_Maybe.Nothing) {
        return 0.0;
    };
    throw new Error("Failed pattern match at CostEstimation.ResourcesView.SelectedResourceDetails (line 122, column 6 - line 124, column 19): " + [ v.component.constructor.name ]);
};
var getColumns = function (props) {
    var mkComponentLink = function (textFn) {
        return function (r) {
            var v = _unRes(r);
            var initialSelectedComponent = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.id;
            })(v.component);
            return TacoButton.component()()({
                text: textFn(r),
                buttonStyle: TacoButton_Types.Link.value,
                onClick: new Data_Maybe.Just(props.dispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationFERoute({
                    projectId: props.projectId,
                    initialSelectedComponent: initialSelectedComponent
                }))))
            });
        };
    };
    return prepareColumns(function (col) {
        if (col instanceof ColName) {
            return {
                sortProperty: new Data_Maybe.Just(ByName.value),
                cell: TacoTable_Types.JSXCell.create([  ])(boldTextCell(function ($124) {
                    return (function (v) {
                        return v.resource.name;
                    })(_unRes($124));
                }))
            };
        };
        if (col instanceof ColUnit) {
            return {
                sortProperty: new Data_Maybe.Just(ByUnit.value),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $125 = Utils.fromNullable("");
                    return function ($126) {
                        return $125((function (v) {
                            return v.resource.unit;
                        })(_unRes($126)));
                    };
                })())
            };
        };
        if (col instanceof ColCode) {
            return {
                sortProperty: new Data_Maybe.Just(ByCode.value),
                cell: TacoTable_Types.JSXCell.create([  ])(mkComponentLink((function () {
                    var $127 = Data_Maybe.fromMaybe("");
                    var $128 = Data_Maybe.fromMaybe(Data_Maybe.Nothing.value);
                    var $129 = Data_Functor.map(Data_Maybe.functorMaybe)(function ($131) {
                        return Data_Nullable.toMaybe((function (v) {
                            return v.code;
                        })($131));
                    });
                    return function ($130) {
                        return $127($128($129((function (v) {
                            return v.component;
                        })(_unRes($130)))));
                    };
                })()))
            };
        };
        if (col instanceof ColDescription) {
            return {
                sortProperty: new Data_Maybe.Just(ByDescription.value),
                cell: TacoTable_Types.JSXCell.create([  ])(mkComponentLink((function () {
                    var $132 = Data_Maybe.fromMaybe("");
                    var $133 = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                        return v.description;
                    });
                    return function ($134) {
                        return $132($133((function (v) {
                            return v.component;
                        })(_unRes($134))));
                    };
                })()))
            };
        };
        if (col instanceof ColQuantity) {
            return {
                sortProperty: new Data_Maybe.Just(ByQuantity.value),
                cell: TacoTable_Types.PlainNumberCell.create(2)(function ($135) {
                    return (function (v) {
                        return v.resource.unitConsumptionRate;
                    })(_unRes($135));
                })
            };
        };
        if (col instanceof ColWastePercentage) {
            return {
                sortProperty: new Data_Maybe.Just(ByWastePercentage.value),
                cell: TacoTable_Types.PlainNumberCell.create(2)(function ($136) {
                    return (function (v) {
                        return v.resource.wastePercentage;
                    })(_unRes($136));
                })
            };
        };
        if (col instanceof ColEfficiency) {
            return {
                sortProperty: Data_Maybe.Nothing.value,
                cell: TacoTable_Types.PlainNumberCell.create(2)(function ($137) {
                    return (function (x) {
                        return 1.0 / x.resource.unitConsumptionRate;
                    })(_unRes($137));
                })
            };
        };
        if (col instanceof ColTotalAmount) {
            return {
                sortProperty: new Data_Maybe.Just(ByTotalAmount.value),
                cell: TacoTable_Types.PlainNumberCell.create(2)(getTotalAmount)
            };
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.SelectedResourceDetails (line 128, column 26 - line 144, column 104): " + [ col.constructor.name ]);
    });
};
var getSortingValue = function (sortKey) {
    return function (v) {
        if (sortKey instanceof ByName) {
            return new TacoTable_Types.StringValue(v.resourceWithComponent.value0.name);
        };
        if (sortKey instanceof ByCode) {
            return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function ($138) {
                return Data_Nullable.toMaybe((function (v1) {
                    return v1.code;
                })($138));
            })(v.resourceWithComponent.value1))));
        };
        if (sortKey instanceof ByUnit) {
            return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function ($139) {
                return Data_Nullable.toMaybe((function (v1) {
                    return v1.unit;
                })($139));
            })(v.resourceWithComponent.value1))));
        };
        if (sortKey instanceof ByQuantity) {
            return TacoTable_Types.NumberValue.create((function (v1) {
                return v1.unitConsumptionRate;
            })(v.resourceWithComponent.value0));
        };
        if (sortKey instanceof ByDescription) {
            return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.description;
            })(v.resourceWithComponent.value1)));
        };
        if (sortKey instanceof ByWastePercentage) {
            return new TacoTable_Types.NumberValue(v.resourceWithComponent.value0.wastePercentage);
        };
        if (sortKey instanceof ByTotalAmount) {
            return TacoTable_Types.NumberValue.create(getTotalAmount(v));
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.SelectedResourceDetails (line 74, column 6 - line 81, column 58): " + [ sortKey.constructor.name ]);
    };
};
var selectedResourceDetails = TofuHooks.mkHookComponent("SelectedResourceDetails")(function (v) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var resourceLocations = TofuHooks.useMemo([ ReactHooksUtils.utf(v.selectedMergedResource), ReactHooksUtils.utf(v.projectLocations), ReactHooksUtils.utf(v.estimationLocations), ReactHooksUtils.utf(v.socialExpensePercentages) ])(function (v1) {
            return mkResourceLocations(v.selectedMergedResource)(v.projectLocations)(v.estimationLocations)(v.socialExpensePercentages);
        })();
        var v1 = TofuHooks.useState(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByName.value), getSortingValue))();
        var tableRef = UseNullableRef.useNullableRef();
        var resourceLocationsTableRef = UseNullableRef.useNullableRef();
        var tableProps = {
            dispatch: dispatch,
            projectId: v.projectId
        };
        var resourceLocationColumns = getResourceLocationColumns(tableProps);
        var mkRowData = function (res) {
            return {
                resourceWithComponent: res
            };
        };
        var rows = sortElements(v1.value0)(Data_Array_NonEmpty.toArray(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(mkRowData)(v.selectedMergedResource.estimationResources)));
        var locationsList = resourceLocationsTable({
            containerRef: resourceLocationsTableRef,
            rows: resourceLocations,
            columns: resourceLocationColumns,
            mkRow: getResourceLocationRow(resourceLocationColumns),
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            rowHeight: tableRowHeight,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showHeader: true,
            styleSet: resourceDetailsTableStyleSet,
            initialScrollRow: Data_Maybe.Nothing.value
        });
        var columns = getColumns(tableProps);
        var componentList = function (v2) {
            return resourceDetailsTableOptimized({
                containerRef: tableRef,
                rows: rows,
                columns: columns,
                mkRow: getRow(columns)(tableProps),
                sortProperty: v2.value1,
                sortingOrder: v2.value0,
                rowHeight: tableRowHeight,
                onSort: function (newProperty) {
                    return v1.value1(function (prevCriteria) {
                        if (prevCriteria.value1 instanceof Data_Maybe.Just && Data_Eq.eq(eqResourceDetailsTableSortProperty)(prevCriteria.value1.value0)(newProperty)) {
                            return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(v2.value0), new Data_Maybe.Just(prevCriteria.value1.value0), prevCriteria.value2);
                        };
                        if (prevCriteria.value1 instanceof Data_Maybe.Just) {
                            return new TacoTable_Types.SortingCriteria(v2.value0, new Data_Maybe.Just(newProperty), prevCriteria.value2);
                        };
                        return new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByName.value), getSortingValue);
                    });
                },
                showHeader: true,
                styleSet: resourceDetailsTableStyleSet,
                initialScrollRow: Data_Maybe.Nothing.value
            });
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__SelectedResourceDetails",
            css: ResourcesView_Styles.detailsStyles,
            children: [ TacoTabs.component({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(v.panelMode)(CostEstimationLayout_Types.MinimizedMode.value),
                tabs: [ new TacoTabs_Types.NormalTab({
                    children: [ ScrollContainer.component({
                        children: [ componentList(v1.value0) ]
                    }) ],
                    quantity: Data_Maybe.Just.create(Data_Array_NonEmpty.length(v.selectedMergedResource.estimationResources)),
                    title: "Suoritteet",
                    id: "components",
                    color: TacoConstants.componentColors.border
                }), new TacoTabs_Types.NormalTab({
                    children: [ ScrollContainer.component({
                        children: [ locationsList ]
                    }) ],
                    quantity: Data_Maybe.Nothing.value,
                    title: "Sijainti",
                    id: "locations",
                    color: TacoConstants.resourceColors.border
                }), TacoTabs_Types.SeparatorTab.value, TacoTabs_Types.JsxTab.create(CostEstimation_Layout.panelModeButtons({
                    panelMode: v.panelMode,
                    setPanelMode: v.setPanelMode,
                    vertical: false,
                    smallMode: true,
                    testId: "resource-details-panel-size"
                })) ]
            }) ]
        });
    };
});
module.exports = {
    tableRowHeight: tableRowHeight,
    ByName: ByName,
    ByCode: ByCode,
    ByUnit: ByUnit,
    ByQuantity: ByQuantity,
    ByDescription: ByDescription,
    ByWastePercentage: ByWastePercentage,
    ByTotalAmount: ByTotalAmount,
    getSortingValue: getSortingValue,
    ColName: ColName,
    ColUnit: ColUnit,
    ColCode: ColCode,
    ColDescription: ColDescription,
    ColQuantity: ColQuantity,
    ColWastePercentage: ColWastePercentage,
    ColEfficiency: ColEfficiency,
    ColTotalAmount: ColTotalAmount,
    prepareColumns: prepareColumns,
    boldTextCell: boldTextCell,
    "_unRes": _unRes,
    getTotalAmount: getTotalAmount,
    getColumns: getColumns,
    resourceDetailsTableOptimized: resourceDetailsTableOptimized,
    resourceDetailsTableStyleSet: resourceDetailsTableStyleSet,
    getRow: getRow,
    sortElements: sortElements,
    resourceLocationsTable: resourceLocationsTable,
    getResourceLocationColumns: getResourceLocationColumns,
    getResourceLocationRow: getResourceLocationRow,
    mkResourceLocations: mkResourceLocations,
    selectedResourceDetails: selectedResourceDetails,
    eqResourceDetailsTableSortProperty: eqResourceDetailsTableSortProperty,
    ordResourceDetailsTableSortProperty: ordResourceDetailsTableSortProperty,
    getNameResourceDetailsTableSortProperty: getNameResourceDetailsTableSortProperty
};
