// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var hourlyPricingToggle = TofuHooks.mkHookComponent("EditCostClassesHourlyPricing")(function (v) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var toggleHourlyPricing = dispatch(new Actions.CostClassActionRequest({
            projectId: v.projectId,
            params: new Types_CostClass.EditCostClass([ {
                id: v.costClass.id,
                editAction: new Types_CostClass.EditCostClassHourlyPricing({
                    hourlyPricing: !v.costClass.hourlyPricing
                })
            } ]),
            handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
        }));
        return TacoCheckbox.component()()({
            isChecked: v.costClass.hourlyPricing,
            onToggle: new TacoCheckbox.NoEvent(toggleHourlyPricing),
            testId: "cost-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(v.costClass.id)) + "-hourly-pricing-toggle")
        });
    };
});
var editCostClassesTable = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("EditCostClassesTable");
var deleteButton = TofuHooks.mkHookComponent("EditCostClassesDeleteBtn")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var dispatch = StateHooks.useDispatch();
        var openModal = v1.value1(function (v2) {
            return true;
        });
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Haluatko varmasti poistaa panoslajin " + (Data_Newtype.un()(Types_Newtypes.CostClassCode)(v.costClass.costClassCode) + (": " + (v.costClass.name + "?")))
            }) ]
        });
        var closeModal = v1.value1(function (v2) {
            return false;
        });
        var deleteCostClass = function __do() {
            dispatch(new Actions.CostClassActionRequest({
                projectId: v.projectId,
                params: new Types_CostClass.DeleteCostClass([ {
                    id: v.costClass.id
                } ]),
                handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
            }))();
            return closeModal();
        };
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                testId: "cancel-delete-cost-class-button"
            }), TacoButton.component()()({
                onClick: new Data_Maybe.Just(deleteCostClass),
                buttonStyle: TacoButton_Types.Filled.value,
                color: TacoConstants.SecondaryRed.value,
                text: "Poista panoslaji",
                testId: "submit-delete-cost-class-button"
            }) ]
        });
        return React_Basic.fragment([ TacoModal.component()()({
            onClose: closeModal,
            isActive: v1.value0,
            heading: "Oletko varma?",
            contents: [ modalBody, modalActions ]
        }), TacoButton.component()()({
            text: "Poista",
            onClick: new Data_Maybe.Just(openModal),
            color: TacoConstants.SecondaryRed.value,
            testId: "cost-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(v.costClass.id)) + "-delete-button")
        }) ]);
    };
});
var columns = function (projectId) {
    var mkActionNum = function (r) {
        return function (mkEditAction) {
            return function (value) {
                return function (handler) {
                    return new Actions.CostClassActionRequest({
                        projectId: projectId,
                        params: new Types_CostClass.EditCostClass([ {
                            id: r.id,
                            editAction: mkEditAction(value)
                        } ]),
                        handler: handler
                    });
                };
            };
        };
    };
    var multiplierInput = function (r) {
        return TacoInput.remoteNumberField()()({
            value: r.multiplier,
            mkAction: mkActionNum(r)(function (v) {
                return new Types_CostClass.EditCostClassMultiplier({
                    multiplier: v
                });
            }),
            stateKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_CostClass.CostClassId)(r.id)),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            precision: 3,
            disabled: true,
            testId: "cost-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.id)) + "-multiplier-input")
        });
    };
    var socialExpensePercentageInput = function (r) {
        return TacoInput.remoteNumberField()()({
            value: 100.0 * Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne)(Utils.nullable(0.0)(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentageInCostEstimation)),
            mkAction: mkActionNum(r)(function (v) {
                return new Types_CostClass.EditCostClassSocialExpensePercentageInCostEstimation({
                    socialExpensePercentageInCostEstimation: v
                });
            }),
            stateKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_CostClass.CostClassId)(r.id)),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            precision: 2,
            testId: "cost-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.id)) + "-social-expense-percentage-input")
        });
    };
    var mkAction = function (r) {
        return function (mkEditAction) {
            return function (value) {
                return function (handler) {
                    return new Actions.CostClassActionRequest({
                        projectId: projectId,
                        params: new Types_CostClass.EditCostClass([ {
                            id: r.id,
                            editAction: mkEditAction(value)
                        } ]),
                        handler: handler
                    });
                };
            };
        };
    };
    var nameInput = function (r) {
        return TacoInput.remoteStringField()()({
            value: r.name,
            mkAction: mkAction(r)(function (s) {
                return new Types_CostClass.EditCostClassName({
                    name: s
                });
            }),
            validator: LengthValidator.lengthValidator(0)(20),
            stateKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_CostClass.CostClassId)(r.id)),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            testId: "cost-class-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.id)) + "-name-input")
        });
    };
    var hourlyPricingCell = function (r) {
        return hourlyPricingToggle({
            projectId: projectId,
            costClass: r
        });
    };
    var deleteButtonCell = function (r) {
        return deleteButton({
            projectId: projectId,
            costClass: r
        });
    };
    return [ {
        key: "code",
        label: React_Basic_DOM.text("Tunnus"),
        width: new TacoTable_Types.Flex(1),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $33 = Data_Newtype.un()(Types_Newtypes.CostClassCode);
            return function ($34) {
                return $33((function (v) {
                    return v.costClassCode;
                })($34));
            };
        })()),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "name",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(3),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], nameInput),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "socialExpensePercentage",
        label: React_Basic_DOM.text("Sosiaalikulu-%"),
        width: new TacoTable_Types.Flex(1),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], socialExpensePercentageInput),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "multiplier",
        label: React_Basic_DOM.text("Hintakerroin"),
        width: new TacoTable_Types.Flex(1),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], multiplierInput),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "hourlyPricing",
        label: React_Basic_DOM.text("Tuntihinnoittelu"),
        width: new TacoTable_Types.Flex(1),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], hourlyPricingCell),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "delete",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Flex(1),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], deleteButtonCell),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var mkRow = function (v) {
    return {
        className: v.className,
        rowData: v.rowData,
        foldingElement: Data_Maybe.Nothing.value,
        onClick: function (v1) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        },
        rowKey: v.rowKey,
        columns: columns(v.projectId)
    };
};
var getRow = function (projectId) {
    return function (r) {
        return mkRow({
            className: "EditCostClassesRow",
            rowData: r,
            rowKey: Data_Newtype.un()(Types_Newtypes.CostClassCode)(r.costClassCode),
            projectId: projectId
        });
    };
};
var component = TofuHooks.mkHookComponent("Project.EditCostClasses")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState("")();
        var v2 = Client_WASM.useCostClasses(v.projectId)();
        var dispatch = StateHooks.useDispatch();
        var newCostClass = dispatch(new Actions.CostClassActionRequest({
            projectId: v.projectId,
            params: new Types_CostClass.AddCostClass([ {
                code: v1.value0,
                name: "Uusi panoslaji",
                socialExpensePercentageInCostEstimation: 0.0,
                multiplier: 1.0,
                hourlyPricing: false
            } ]),
            handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
        }));
        return React_Basic.fragment([ TacoText.component()()({
            text: "Panoslajit",
            weight: TacoText_Types.Bold.value
        }), TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostEstimation";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForTargets";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForComponents";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_CostClass.eqCostClassId))()({
            reflectSymbol: function () {
                return "hourlyPricing";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode)))(editCostClassesTable)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Functor.map(Data_Functor.functorArray)(getRow(v.projectId))(v2.value0),
            columns: columns(v.projectId),
            showHeader: true,
            styleSet: styleSet
        }), TacoText.component()()({
            text: "Uusi panoslaji"
        }), Gap.gapX(TacoConstants.M.value)([ TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0,
            onChange: v1.value1,
            label: "Tunnus",
            testId: "new-cost-class-code-input"
        }), TacoButton.component()()({
            text: "Lis\xe4\xe4",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
            onClick: new Data_Maybe.Just(newCostClass),
            testId: "new-cost-class-code-submit-button"
        }) ]) ]);
    };
});
module.exports = {
    editCostClassesTable: editCostClassesTable,
    deleteButton: deleteButton,
    hourlyPricingToggle: hourlyPricingToggle,
    columns: columns,
    mkRow: mkRow,
    getRow: getRow,
    styleSet: styleSet,
    component: component
};
