import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import fiApp from "./fi/app.json";
import enApp from "./en/app.json";
import svApp from "./sv/app.json";
import zuApp from "./zu/app.json";

import fiAdmin from "./fi/admin.json";
import enAdmin from "./en/admin.json";
import svAdmin from "./sv/admin.json";
import zuAdmin from "./zu/admin.json";

import fiCostControl from "./fi/costControl.json";
import enCostControl from "./en/costControl.json";
import svCostControl from "./sv/costControl.json";
import zuCostControl from "./zu/costControl.json";

import fiEstimation from "./fi/estimation.json";
import enEstimation from "./en/estimation.json";
import svEstimation from "./sv/estimation.json";
import zuEstimation from "./zu/estimation.json";

import fiIntegration from "./fi/integration.json";
import enIntegration from "./en/integration.json";
import svIntegration from "./sv/integration.json";
import zuIntegration from "./zu/integration.json";

import fiProjects from "./fi/projects.json";
import enProjects from "./en/projects.json";
import svProjects from "./sv/projects.json";
import zuProjects from "./zu/projects.json";

import fiSuperAdmin from "./fi/superAdmin.json";
import enSuperAdmin from "./en/superAdmin.json";
import svSuperAdmin from "./sv/superAdmin.json";
import zuSuperAdmin from "./zu/superAdmin.json";

import fiAgGrid from "./fi/agGrid.json";
import enAgGrid from "./en/agGrid.json";
import svAgGrid from "./sv/agGrid.json";
import zuAgGrid from "./zu/agGrid.json";

import fiComponents from "./fi/component.json";
import enComponents from "./en/component.json";
import svComponents from "./sv/component.json";
import zuComponents from "./zu/component.json";

import fiNavigation from "./fi/navigation.json";
import enNavigation from "./en/navigation.json";
import svNavigation from "./sv/navigation.json";
import zuNavigation from "./zu/navigation.json";

import fiSettings from "./fi/settings.json";
import enSettings from "./en/settings.json";
import svSettings from "./sv/settings.json";
import zuSettings from "./zu/settings.json";

import fiTest from "./fi/test.json";
import enTest from "./en/test.json";
import svTest from "./sv/test.json";
import zuTest from "./zu/test.json";

const resources = {
  fi: {
    app: fiApp,
    admin: fiAdmin,
    costControl: fiCostControl,
    estimation: fiEstimation,
    integration: fiIntegration,
    projects: fiProjects,
    superAdmin: fiSuperAdmin,
    agGrid: fiAgGrid,
    component: fiComponents,
    navigation: fiNavigation,
    settings: fiSettings,
    test: fiTest,
  },
  en: {
    app: enApp,
    admin: enAdmin,
    costControl: enCostControl,
    estimation: enEstimation,
    integration: enIntegration,
    projects: enProjects,
    superAdmin: enSuperAdmin,
    agGrid: enAgGrid,
    component: enComponents,
    navigation: enNavigation,
    settings: enSettings,
    test: enTest,
  },
  sv: {
    app: svApp,
    admin: svAdmin,
    costControl: svCostControl,
    estimation: svEstimation,
    integration: svIntegration,
    projects: svProjects,
    superAdmin: svSuperAdmin,
    agGrid: svAgGrid,
    component: svComponents,
    navigation: svNavigation,
    settings: svSettings,
    test: svTest,
  },
  zu: {
    app: zuApp,
    admin: zuAdmin,
    costControl: zuCostControl,
    estimation: zuEstimation,
    integration: zuIntegration,
    projects: zuProjects,
    superAdmin: zuSuperAdmin,
    agGrid: zuAgGrid,
    component: zuComponents,
    navigation: zuNavigation,
    settings: zuSettings,
    test: zuTest,
  },
};

i18n.use(initReactI18next).init({
  lng: "fi",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
