exports.addMouseClickEventListenerToDocument = function (id) {
  return function (callback) {
    return function () {
      var listener = function (event) {
        var div = document.getElementById(id);
        if (
          // don't close when clicking on download links
          !event.target.download &&
          document.contains(event.target) &&
          !div.contains(event.target)
        ) {
          // outside click
          callback();
        }
      };
      document.addEventListener("click", listener);
      return listener;
    };
  };
};

exports.addMouseClickEventListenerToDocument_ = function (element) {
  return function (callback) {
    return function () {
      var listener = function (event) {
        if (
          // don't close when clicking on download links
          !event.target.download &&
          document.body.contains(event.target) &&
          !element.contains(event.target)
        ) {
          // outside click
          callback();
        }
      };
      // React 17 has a bug relating to event listeners and portals.
      // https://github.com/facebook/react/issues/20074
      // Issue is that the listener is fired on the same event where it is being added.
      // This causes dialogs to close immediately after opening.
      // Fix: The listener is not added to the document until the next tick using setTimeout.
      // This is similar to ClickAwayListener in react mui.
      setTimeout(() => {
        document.addEventListener("click", listener);
      }, 0);
      return listener;
    };
  };
};

exports.removeMouseClickEventListenerFromDocument = function (handler) {
  return function () {
    document.removeEventListener("click", handler);
  };
};

exports.addWindowResizeListener = (handler) => () => {
  const listener = throttleTrailing(
    (event) => handler({ innerHeight: event.srcElement.innerHeight })(),
    200
  );
  window.addEventListener("resize", listener);
  return listener;
};

exports.removeWindowResizeListener = (listener) => () => {
  window.removeEventListener("resize", listener);
};

exports.addScrollEventListenerToElement = (element) => (handler) => () => {
  const listener = throttleTrailing(
    (event) => handler({ scrollTop: event.srcElement.scrollTop })(),
    100
  );
  element.addEventListener("scroll", listener);
  return listener;
};

exports.removeScrollEventListenerFromElement = (element) => (
  listener
) => () => {
  element.removeEventListener("scroll", listener);
};

exports.asDistinctStream = (compareEvents) => (handlerF) => () => {
  var lastDistinctEvent = null;
  return (event) => () => {
    if (!lastDistinctEvent || compareEvents(lastDistinctEvent)(event)) {
      lastDistinctEvent = event;
      handlerF(event)();
    }
  };
};

const throttleTrailing = (cb, t) => {
  let prevInvocationTime = 0;
  let activeTimeout = null;
  let invocationNum = 0;

  return (...args) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let invocation = invocationNum++;

    const dt = Date.now() - prevInvocationTime;
    const shouldThrottleInvocation = dt < t;

    const apply = () => {
      prevInvocationTime = Date.now();

      clearTimeout(activeTimeout);
      activeTimeout = null;

      cb(...args);
    };

    const setNextTimeout = () => {
      if (activeTimeout !== null) {
        clearTimeout(activeTimeout);
        activeTimeout = null;
      }

      activeTimeout = setTimeout(apply, t - dt);
    };

    if (shouldThrottleInvocation) {
      setNextTimeout();
    } else {
      apply();
    }
  };
};
