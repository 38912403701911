// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var ComponentGroupsTree = require("../ComponentGroupsTree/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_BuildingElementsControls = require("../CostEstimation.BuildingElementsControls/index.js");
var CostEstimation_BuildingElementsView = require("../CostEstimation.BuildingElementsView/index.js");
var CostEstimation_BuildingElementsView_ElementDetailsContainer = require("../CostEstimation.BuildingElementsView.ElementDetailsContainer/index.js");
var CostEstimation_BuildingElementsView_ElementSummaries = require("../CostEstimation.BuildingElementsView.ElementSummaries/index.js");
var CostEstimation_BuildingElementsView_Table = require("../CostEstimation.BuildingElementsView.Table/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_SummariesPanel = require("../CostEstimation.SummariesPanel/index.js");
var CostEstimation_SummariesSidebar = require("../CostEstimation.SummariesSidebar/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var EstimationComponentFilter = require("../EstimationComponentFilter/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Kishimen = require("../Kishimen/index.js");
var ProjectClassificationTable = require("../ProjectClassificationTable/index.js");
var ProjectLocationsTree = require("../ProjectLocationsTree/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ReferenceLibrarySidebar = require("../ReferenceLibrarySidebar/index.js");
var ReferenceLibrarySidepanel = require("../ReferenceLibrarySidepanel/index.js");
var StateHooks = require("../StateHooks/index.js");
var SubprojectsTree = require("../SubprojectsTree/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseSelectedComponentScrollPosition = require("../UseSelectedComponentScrollPosition/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var UseWorkAchievement = require("../UseWorkAchievement/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var component = TofuHooks.mkMemoHookComponent("ElementsEditView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = Client_WASM.useEstimationElements(props.projectId)();
        var v1 = Client_WASM.useEstimationComponents(props.projectId)();
        var v2 = Client_WASM.useEstimationResources(props.projectId)();
        var v3 = FetchHooks.useElementMeasures(props.projectId)();
        var v4 = Client_WASM.useEstimationProjects();
        var v5 = Client_WASM.useSubprojects(props.projectId)();
        var v6 = Client_WASM.useEstimationLocations(props.projectId)();
        var v7 = Client_WASM.useProjectLocations(props.projectId)();
        var v8 = FetchHooks.useCostClassesWithHash(props.projectId)();
        var v9 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v10 = UseWorkAchievement.useWorkAchievement(props.projectId)();
        var loading = v.value1 || (v1.value1 || (v2.value1 || (v3.value1 || (v4.value1 || (v5.value1 || (v6.value1 || (v7.value1 || v8.value1)))))));
        var v11 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("element-view-controls-edit-add-memo-to-excel")(false)(false)();
        var v12 = Client_WASM.useComponentWithResources(props.projectId)();
        var v13 = Client_WASM.useFilteredElementIds(props.projectId)();
        var v14 = TofuHooks.useMemo([ ReactHooksUtils.utf(v13.value0) ])(function (v14) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(v13.value0);
        })();
        var v15 = Client_WASM.useElementsData(props.projectId)();
        var v16 = Client_WASM.useClassificationGroups(props.projectId)();
        var v17 = Client_WASM.useProjectClassificationClasses(props.projectId)();
        var v18 = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var initialSelectedElementIdx = (function () {
            if (props.initialSelectedElement instanceof Data_Maybe.Nothing && v18.value0.selectedElementIdx instanceof Data_Maybe.Just) {
                return Edited.Edited.create(new Data_Maybe.Just(v18.value0.selectedElementIdx.value0));
            };
            return Edited.Unedited.value;
        })();
        var v19 = TofuHooks.useState({
            sortingCriteria: new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(CostEstimation_BuildingElementsView_Table.ByCode.value), CostEstimation_BuildingElementsView_Table.getSortingValue),
            checkedElements: Data_Set.empty,
            lastCheckedItem: Data_Maybe.Nothing.value,
            checkedSpecs: Data_Set.empty,
            detailsElementIdx: initialSelectedElementIdx,
            shouldScrollToSelection: true,
            editActiveFilters: v18.value0.elementFilters,
            selectedInputColumn: Data_Maybe.Nothing.value,
            selectedResourceIds: Data_Set.empty,
            groupBy: v18.value0.elementGroupProperty
        })();
        var toggleGroupBy = function (property) {
            return function __do() {
                v18.value1(UseEstimationUiState.toggleUpdateGrouping(Types_BuildingElementsView.eqElementsTableGroupBy)({
                    reflectSymbol: function () {
                        return "elementGroupProperty";
                    }
                })()(Type_Proxy["Proxy"].value)(property))();
                return v19.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editActiveFilters: s.editActiveFilters,
                        selectedInputColumn: s.selectedInputColumn,
                        selectedResourceIds: s.selectedResourceIds,
                        groupBy: (function () {
                            var $109 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_BuildingElementsView.eqElementsTableGroupBy))(new Data_Maybe.Just(property))(s.groupBy);
                            if ($109) {
                                return Data_Maybe.Nothing.value;
                            };
                            return new Data_Maybe.Just(property);
                        })()
                    };
                })();
            };
        };
        TofuHooks.useEffect([ props.checkedElements ])(function __do() {
            (function () {
                if (props.checkedElements instanceof Data_Maybe.Just) {
                    return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(Data_Set.eqSet(Types_Element.eqElementId))(v19.value0.checkedElements)(props.checkedElements.value0))(v19.value1(function (state$prime) {
                        return {
                            sortingCriteria: state$prime.sortingCriteria,
                            checkedElements: props.checkedElements.value0,
                            lastCheckedItem: state$prime.lastCheckedItem,
                            checkedSpecs: state$prime.checkedSpecs,
                            detailsElementIdx: state$prime.detailsElementIdx,
                            shouldScrollToSelection: state$prime.shouldScrollToSelection,
                            editActiveFilters: state$prime.editActiveFilters,
                            selectedInputColumn: state$prime.selectedInputColumn,
                            selectedResourceIds: state$prime.selectedResourceIds,
                            groupBy: state$prime.groupBy
                        };
                    }))();
                };
                if (props.checkedElements instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 169, column 5 - line 175, column 27): " + [ props.checkedElements.constructor.name ]);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useEffect([ v19.value0.checkedElements ])((function () {
            if (props.checkedElements instanceof Data_Maybe.Just && props.setCheckedElements instanceof Data_Maybe.Just) {
                return function __do() {
                    Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Eq.notEq(Data_Set.eqSet(Types_Element.eqElementId))(v19.value0.checkedElements)(props.checkedElements.value0))(props.setCheckedElements.value0)(function (v20) {
                        return v19.value0.checkedElements;
                    })();
                    return props.setCheckedElements.value0(function (v20) {
                        return Data_Set.empty;
                    });
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
        })())();
        TofuHooks.useEffect([ props.checkedSpecs ])(function __do() {
            (function () {
                if (props.checkedSpecs instanceof Data_Maybe.Just) {
                    return Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Eq.notEq(Data_Set.eqSet(Types_Newtypes.eqElementSpecId))(v19.value0.checkedSpecs)(props.checkedSpecs.value0))(v19.value1)(function (state$prime) {
                        return {
                            sortingCriteria: state$prime.sortingCriteria,
                            checkedElements: state$prime.checkedElements,
                            lastCheckedItem: state$prime.lastCheckedItem,
                            checkedSpecs: props.checkedSpecs.value0,
                            detailsElementIdx: state$prime.detailsElementIdx,
                            shouldScrollToSelection: state$prime.shouldScrollToSelection,
                            editActiveFilters: state$prime.editActiveFilters,
                            selectedInputColumn: state$prime.selectedInputColumn,
                            selectedResourceIds: state$prime.selectedResourceIds,
                            groupBy: state$prime.groupBy
                        };
                    })();
                };
                if (props.checkedSpecs instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 190, column 5 - line 193, column 27): " + [ props.checkedSpecs.constructor.name ]);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useEffect([ v19.value0.checkedSpecs ])((function () {
            if (props.checkedSpecs instanceof Data_Maybe.Just && props.setCheckedSpecs instanceof Data_Maybe.Just) {
                return function __do() {
                    Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Eq.notEq(Data_Set.eqSet(Types_Newtypes.eqElementSpecId))(v19.value0.checkedSpecs)(props.checkedSpecs.value0))(props.setCheckedSpecs.value0)(function (v20) {
                        return v19.value0.checkedSpecs;
                    })();
                    return props.setCheckedSpecs.value0(function (v20) {
                        return Data_Set.empty;
                    });
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
        })())();
        var v20 = TofuHooks.useState("")();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(v20.value0) ])(500)(function __do() {
            v19.value1(function (s) {
                var notSearch = function (x) {
                    if (x instanceof CostEstimation_Filters.FilterByElementsDataSearch) {
                        return false;
                    };
                    return true;
                };
                var removedSearch = Data_Array.filter(notSearch)(s.editActiveFilters);
                var addedSearch = (function () {
                    var $125 = Data_String_Common["null"](v20.value0);
                    if ($125) {
                        return removedSearch;
                    };
                    return Data_Array.snoc(removedSearch)(new CostEstimation_Filters.FilterByElementsDataSearch(v20.value0));
                })();
                return {
                    sortingCriteria: s.sortingCriteria,
                    checkedElements: s.checkedElements,
                    lastCheckedItem: s.lastCheckedItem,
                    checkedSpecs: s.checkedSpecs,
                    detailsElementIdx: s.detailsElementIdx,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    editActiveFilters: addedSearch,
                    selectedInputColumn: s.selectedInputColumn,
                    selectedResourceIds: s.selectedResourceIds,
                    groupBy: s.groupBy
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        Client_WASM.useSetActiveElementFilters(v19.value0.editActiveFilters)();
        var setActiveElementsFilters = TofuHooks.useCallback([ ReactHooksUtils.utf(Types_CostEstimation.EditMode.value) ])(function (filters) {
            return function __do() {
                v18.value1(function (v21) {
                    return {
                        project: v21.project,
                        elementFilters: filters,
                        elementGroupProperty: v21.elementGroupProperty,
                        selectedElementIdx: v21.selectedElementIdx,
                        referenceElementFilters: v21.referenceElementFilters,
                        selectedReferenceElementIdx: v21.selectedReferenceElementIdx,
                        componentFilters: v21.componentFilters,
                        componentGroupProperty: v21.componentGroupProperty,
                        selectedComponentIdx: v21.selectedComponentIdx,
                        referenceComponentFilters: v21.referenceComponentFilters,
                        selectedReferenceComponentIdx: v21.selectedReferenceComponentIdx,
                        referenceResourceFilters: v21.referenceResourceFilters,
                        selectedResourceId: v21.selectedResourceId
                    };
                })();
                return v19.value1(function (v21) {
                    return {
                        sortingCriteria: v21.sortingCriteria,
                        checkedElements: v21.checkedElements,
                        lastCheckedItem: v21.lastCheckedItem,
                        checkedSpecs: v21.checkedSpecs,
                        detailsElementIdx: v21.detailsElementIdx,
                        shouldScrollToSelection: v21.shouldScrollToSelection,
                        editActiveFilters: filters,
                        selectedInputColumn: v21.selectedInputColumn,
                        selectedResourceIds: v21.selectedResourceIds,
                        groupBy: v21.groupBy
                    };
                })();
            };
        })();
        var setDetailsElementIdx$prime = TofuHooks.useCallback([ v19.value1 ])(function (shouldScrollToSelection) {
            return function (update) {
                return v19.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        detailsElementIdx: update(s.detailsElementIdx),
                        shouldScrollToSelection: (function () {
                            if (s.detailsElementIdx instanceof Edited.Edited && s.detailsElementIdx.value0 instanceof Data_Maybe.Nothing) {
                                return true;
                            };
                            if (s.detailsElementIdx instanceof Edited.Unedited) {
                                return true;
                            };
                            return shouldScrollToSelection;
                        })(),
                        editActiveFilters: s.editActiveFilters,
                        selectedInputColumn: s.selectedInputColumn,
                        selectedResourceIds: s.selectedResourceIds,
                        groupBy: s.groupBy
                    };
                });
            };
        })();
        var withClearCheckedSpecs = TofuHooks.useCallback([ v19.value1 ])(function (eff) {
            return Control_Apply.applyFirst(Effect.applyEffect)(eff)(v19.value1(function (v21) {
                return {
                    sortingCriteria: v21.sortingCriteria,
                    checkedElements: v21.checkedElements,
                    lastCheckedItem: Data_Maybe.Nothing.value,
                    checkedSpecs: Data_Set.empty,
                    detailsElementIdx: v21.detailsElementIdx,
                    shouldScrollToSelection: v21.shouldScrollToSelection,
                    editActiveFilters: v21.editActiveFilters,
                    selectedInputColumn: v21.selectedInputColumn,
                    selectedResourceIds: v21.selectedResourceIds,
                    groupBy: v21.groupBy
                };
            }));
        })();
        var toggleElementChecked = TofuHooks.useCallback([ v19.value1 ])(function (elementId) {
            return v19.value1(function (s) {
                return {
                    sortingCriteria: s.sortingCriteria,
                    checkedElements: Utils.toggleInSet(Types_Element.ordElementId)(elementId)(s.checkedElements),
                    lastCheckedItem: new Data_Maybe.Just(elementId),
                    checkedSpecs: s.checkedSpecs,
                    detailsElementIdx: s.detailsElementIdx,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    editActiveFilters: s.editActiveFilters,
                    selectedInputColumn: s.selectedInputColumn,
                    selectedResourceIds: s.selectedResourceIds,
                    groupBy: s.groupBy
                };
            });
        })();
        var v21 = TofuHooks.useMemo([ ReactHooksUtils.utf(Types_CostEstimation.EditMode.value), ReactHooksUtils.utf(v1.value0) ])(function (v21) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(Utils.arrayMapNullable(function (v22) {
                return v22.code;
            })(v1.value0));
        })();
        var components = ReactHooksUtils.usePersistMemo("ElementsEditView_mkComponentWithResources")([ ReactHooksUtils.utf(v1.value0), ReactHooksUtils.utf(v2.value0) ])(function (v22) {
            return CostEstimation_BuildingElementsView.mkComponentWithResources(v1.value0)(v2.value0);
        })();
        var filteredElementData = TofuHooks.useMemo([ ReactHooksUtils.utf(v15.value0), ReactHooksUtils.utf(v14) ])(function (v22) {
            return Data_Array.filter(function (ed) {
                return Data_Set.member(Types_Element.ordElementId)((Data_Newtype.unwrap()((Data_Newtype.unwrap()(ed)).element)).elementId)(v14);
            })(v15.value0);
        })();
        var elementsData = TofuHooks.useMemo([ ReactHooksUtils.utf(v19.value0.sortingCriteria), ReactHooksUtils.utf(filteredElementData) ])(function (v22) {
            return CostEstimation_BuildingElementsView.sortElements(v19.value0.sortingCriteria)(filteredElementData);
        })();
        var groupedElementsData = TofuHooks.useMemo([ ReactHooksUtils.utf(elementsData), ReactHooksUtils.utf(v19.value0.sortingCriteria), ReactHooksUtils.utf(v19.value0.groupBy), ReactHooksUtils.utf(v16.value0) ])(function (v22) {
            if (v19.value0.groupBy instanceof Data_Maybe.Nothing) {
                return elementsData;
            };
            if (v19.value0.groupBy instanceof Data_Maybe.Just) {
                return CostEstimation_BuildingElementsView.groupElements(v16.value0)(v17.value0)(v7.value0)(v8.value0)(v19.value0.sortingCriteria)(v19.value0.groupBy.value0)(elementsData);
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 268, column 7 - line 277, column 23): " + [ v19.value0.groupBy.constructor.name ]);
        })();
        var selectedElementIdx = TofuHooks.useMemo([ ReactHooksUtils.utf(v19.value0.detailsElementIdx), ReactHooksUtils.utf(props.initialSelectedElement), ReactHooksUtils.utf(groupedElementsData) ])(function (v22) {
            if (v19.value0.detailsElementIdx instanceof Edited.Unedited && (props.initialSelectedElement instanceof Data_Maybe.Just && Data_Eq.notEq(Types_Element.eqElementId)(props.initialSelectedElement.value0)(-1 | 0))) {
                return Data_Array.elemIndex(Types_Element.eqElementId)(props.initialSelectedElement.value0)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_BuildingElementsView_Types["_elementId"])(groupedElementsData));
            };
            if (v19.value0.detailsElementIdx instanceof Edited.Unedited) {
                return Data_Maybe.Nothing.value;
            };
            if (v19.value0.detailsElementIdx instanceof Edited.Edited) {
                return v19.value0.detailsElementIdx.value0;
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 281, column 11 - line 285, column 23): " + [ v19.value0.detailsElementIdx.constructor.name, props.initialSelectedElement.constructor.name ]);
        })();
        TofuHooks.useEffect([ selectedElementIdx ])(function __do() {
            v18.value1(function (v22) {
                return {
                    project: v22.project,
                    elementFilters: v22.elementFilters,
                    elementGroupProperty: v22.elementGroupProperty,
                    selectedElementIdx: selectedElementIdx,
                    referenceElementFilters: v22.referenceElementFilters,
                    selectedReferenceElementIdx: v22.selectedReferenceElementIdx,
                    componentFilters: v22.componentFilters,
                    componentGroupProperty: v22.componentGroupProperty,
                    selectedComponentIdx: v22.selectedComponentIdx,
                    referenceComponentFilters: v22.referenceComponentFilters,
                    selectedReferenceComponentIdx: v22.selectedReferenceComponentIdx,
                    referenceResourceFilters: v22.referenceResourceFilters,
                    selectedResourceId: v22.selectedResourceId
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectedElement = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElementIdx), ReactHooksUtils.utf(groupedElementsData) ])(function (v22) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(groupedElementsData))(selectedElementIdx))(function (e) {
                var $135 = Data_Eq.notEq(Types_Element.eqElementId)(CostEstimation_BuildingElementsView_Types["_elementId"](e))(-1 | 0);
                if ($135) {
                    return new Data_Maybe.Just(e);
                };
                return Data_Maybe.Nothing.value;
            });
        })();
        var detailsElementId = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElement) ])(function (v22) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(CostEstimation_BuildingElementsView_Types["_elementId"])(selectedElement);
        })();
        var selectedElementMeasures = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElement) ])(function (v22) {
            var elementMeasuresFilter = function (v23) {
                if (selectedElement instanceof Data_Maybe.Nothing) {
                    return false;
                };
                if (selectedElement instanceof Data_Maybe.Just) {
                    var element = (Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(selectedElement.value0)).element;
                    return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(v23.elementId)(new Data_Maybe.Just((Data_Newtype.un()(Types_Element.WasmEstimationElement)(element)).elementId));
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 307, column 53 - line 311, column 81): " + [ selectedElement.constructor.name ]);
            };
            return Data_Array.filter(elementMeasuresFilter)(v3.value0);
        })();
        var rows = TofuHooks.useMemo([ ReactHooksUtils.utf(v4.value0), ReactHooksUtils.utf(v19.value0.checkedElements), ReactHooksUtils.utf(groupedElementsData), ReactHooksUtils.utf(detailsElementId), ReactHooksUtils.utf(v19.value0.selectedInputColumn), ReactHooksUtils.utf(props.dixonOptions) ])(function (v22) {
            return Data_Function.flip(Data_Array.mapWithIndex)(groupedElementsData)(CostEstimation_BuildingElementsView.mkElementRow(v4.value0)(v19.value0.checkedElements)(false)(detailsElementId)(v19.value0.selectedInputColumn)(props.dixonOptions));
        })();
        var toggleAreaOfCheckboxes = TofuHooks.useCallback([ ReactHooksUtils.utf(v19.value1), ReactHooksUtils.utf(rows) ])(function (elementId) {
            return Control_Apply.applyFirst(Effect.applyEffect)(WindowUtils.selectionRemoveAllRanges)(v19.value1(function (s) {
                if (s.lastCheckedItem instanceof Data_Maybe.Just) {
                    var notTopicElement = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))((function () {
                        var $264 = Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData);
                        return function ($265) {
                            return (function (v22) {
                                return v22.isTopicRow;
                            })($264((function (v22) {
                                return v22.elementsData;
                            })($265)));
                        };
                    })());
                    var _elementId = function ($266) {
                        return CostEstimation_BuildingElementsView_Types["_elementId"]((function (v22) {
                            return v22.elementsData;
                        })($266));
                    };
                    var areaInIds = function (fstIdx) {
                        return function (sndIdx) {
                            return Data_Functor.map(Data_Functor.functorArray)(_elementId)(Data_Array.filter(notTopicElement)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Array.index(rows))(Data_Array.range(fstIdx)(sndIdx)))));
                        };
                    };
                    var idToIndex = function (id) {
                        return Data_Array.findIndex(function (row) {
                            return Data_Eq.eq(Types_Element.eqElementId)(id)(_elementId(row));
                        })(rows);
                    };
                    var v22 = idToIndex(s.lastCheckedItem.value0);
                    var v23 = idToIndex(elementId);
                    if (v23 instanceof Data_Maybe.Just && v22 instanceof Data_Maybe.Just) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: Data_Set.union(Types_Element.ordElementId)(s.checkedElements)(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(areaInIds(v23.value0)(v22.value0))),
                            lastCheckedItem: new Data_Maybe.Just(elementId),
                            checkedSpecs: s.checkedSpecs,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            editActiveFilters: s.editActiveFilters,
                            selectedInputColumn: s.selectedInputColumn,
                            selectedResourceIds: s.selectedResourceIds,
                            groupBy: s.groupBy
                        };
                    };
                    return s;
                };
                if (s.lastCheckedItem instanceof Data_Maybe.Nothing) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: Utils.toggleInSet(Types_Element.ordElementId)(elementId)(s.checkedElements),
                        lastCheckedItem: new Data_Maybe.Just(elementId),
                        checkedSpecs: s.checkedSpecs,
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editActiveFilters: s.editActiveFilters,
                        selectedInputColumn: s.selectedInputColumn,
                        selectedResourceIds: s.selectedResourceIds,
                        groupBy: s.groupBy
                    };
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 333, column 5 - line 349, column 12): " + [ s.lastCheckedItem.constructor.name ]);
            }));
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v19.value0.detailsElementIdx), ReactHooksUtils.utf(props.initialSelectedElement), ReactHooksUtils.utf(detailsElementId), ReactHooksUtils.utf(Types_CostEstimation.EditMode.value) ])((function () {
            var test = Data_Maybe.isNothing(props.panelOptions) && (function () {
                if (v19.value0.detailsElementIdx instanceof Edited.Edited && props.initialSelectedElement instanceof Data_Maybe.Nothing) {
                    return true;
                };
                if (v19.value0.detailsElementIdx instanceof Edited.Edited) {
                    return Data_Eq.notEq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(detailsElementId)(props.initialSelectedElement);
                };
                if (v19.value0.detailsElementIdx instanceof Edited.Unedited) {
                    return false;
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 353, column 40 - line 356, column 29): " + [ v19.value0.detailsElementIdx.constructor.name, props.initialSelectedElement.constructor.name ]);
            })();
            return function __do() {
                Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(test)(appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationBuildingElementsRoute({
                    projectId: props.projectId,
                    initialSelectedElement: detailsElementId
                }))))();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var checkedElementsData = TofuHooks.useMemo([ ReactHooksUtils.utf(v19.value0.checkedElements), ReactHooksUtils.utf(groupedElementsData) ])(function (v22) {
            return CostEstimation_BuildingElementsView_Types.mkCheckedElementsData(v19.value0.checkedElements)(groupedElementsData);
        })();
        Client_WASM.useSetSelectedElements(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(v19.value0.checkedElements))();
        var setDetailsElementIdx = TofuHooks.useCallback([ ReactHooksUtils.utf(withClearCheckedSpecs), ReactHooksUtils.utf(setDetailsElementIdx$prime) ])(function (shouldScrollToSelection) {
            return function (idx) {
                return withClearCheckedSpecs(setDetailsElementIdx$prime(shouldScrollToSelection)(function (oldIdx) {
                    var $149 = Data_Eq.eq(Edited.eqEdited(Data_Maybe.eqMaybe(Data_Eq.eqInt)))(oldIdx)(idx);
                    if ($149) {
                        return new Edited.Edited(Data_Maybe.Nothing.value);
                    };
                    return idx;
                }));
            };
        })();
        var setDetailsElementId = TofuHooks.useCallback([ ReactHooksUtils.utf(rows), ReactHooksUtils.utf(setDetailsElementIdx) ])(function (shouldScrollToSelection) {
            return function (id) {
                var newIdx = Data_Array.findIndex(function (r) {
                    return Data_Eq.eq(Types_Element.eqElementId)(CostEstimation_BuildingElementsView.elementIdOfRow(r))(id);
                })(rows);
                return setDetailsElementIdx(shouldScrollToSelection)(new Edited.Edited(newIdx));
            };
        })();
        var closeDetails = TofuHooks.useCallback([ ReactHooksUtils.utf(withClearCheckedSpecs), ReactHooksUtils.utf(setDetailsElementIdx) ])(withClearCheckedSpecs(setDetailsElementIdx(true)(new Edited.Edited(Data_Maybe.Nothing.value))))();
        var elementProps = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElementIdx), ReactHooksUtils.utf(setDetailsElementIdx), ReactHooksUtils.utf(detailsElementId), ReactHooksUtils.utf(closeDetails) ])(function (v22) {
            return {
                setDetailsElementIdx: setDetailsElementIdx,
                detailsElementId: detailsElementId,
                closeDetails: closeDetails,
                selectedElementIdx: selectedElementIdx
            };
        })();
        CostEstimation_BuildingElementsView.handleCursorNavigation({
            selectElementRelative: function (change) {
                return withClearCheckedSpecs(v19.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        detailsElementIdx: (function () {
                            if (s.detailsElementIdx instanceof Edited.Edited && s.detailsElementIdx.value0 instanceof Data_Maybe.Just) {
                                return new Edited.Edited(Data_Maybe.Just.create(s.detailsElementIdx.value0.value0 + change | 0));
                            };
                            return s.detailsElementIdx;
                        })(),
                        shouldScrollToSelection: true,
                        editActiveFilters: s.editActiveFilters,
                        selectedInputColumn: s.selectedInputColumn,
                        selectedResourceIds: s.selectedResourceIds,
                        groupBy: s.groupBy
                    };
                }));
            },
            closeDetails: closeDetails,
            isActive: true
        })(Data_Array.length(v.value0))(v.value0)();
        var tableRef = UseNullableRef.useNullableRef();
        UseSelectedComponentScrollPosition.useSelectedComponentScrollPosition(v19.value0.shouldScrollToSelection)(TacoTable_Types.rowHeightPixels(CostEstimation_BuildingElementsView.tableRowHeight))(selectedElementIdx)(tableRef)();
        var v22 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v23 = TofuHooks.useState(Data_Set.empty)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(groupedElementsData), ReactHooksUtils.utf(v22.value0) ])((function () {
            var mNewIdx = Control_Bind.bind(Data_Maybe.bindMaybe)(v22.value0)(function (elementId) {
                return Data_Array.findIndex(function (r) {
                    return Data_Eq.eq(Types_Element.eqElementId)(CostEstimation_BuildingElementsView.elementIdOfRow(r))(elementId);
                })(rows);
            });
            return function __do() {
                (function () {
                    if (mNewIdx instanceof Data_Maybe.Just) {
                        withClearCheckedSpecs(setDetailsElementIdx$prime(v19.value0.shouldScrollToSelection)(function (v24) {
                            return new Edited.Edited(new Data_Maybe.Just(mNewIdx.value0));
                        }))();
                        return v22.value1(function (v24) {
                            return Data_Maybe.Nothing.value;
                        })();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var onToggleCheckAll = TofuHooks.useCallback([ ReactHooksUtils.utf(v19.value1), ReactHooksUtils.utf(groupedElementsData) ])(v19.value1(function (s) {
            var newCheckedElements = (function () {
                var $157 = Data_Set.isEmpty(s.checkedElements);
                if ($157) {
                    return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_BuildingElementsView_Types["_elementId"])(groupedElementsData));
                };
                return Data_Set.empty;
            })();
            return {
                sortingCriteria: s.sortingCriteria,
                checkedElements: newCheckedElements,
                lastCheckedItem: s.lastCheckedItem,
                checkedSpecs: s.checkedSpecs,
                detailsElementIdx: s.detailsElementIdx,
                shouldScrollToSelection: s.shouldScrollToSelection,
                editActiveFilters: s.editActiveFilters,
                selectedInputColumn: s.selectedInputColumn,
                selectedResourceIds: s.selectedResourceIds,
                groupBy: s.groupBy
            };
        }))();
        var v24 = CostEstimation_BuildingElementsView.useSelectedComponentIds({
            components: components,
            selectedElement: selectedElement
        })();
        var tableProps = {
            elementProps: elementProps,
            checkedElements: v19.value0.checkedElements,
            toggleElementChecked: toggleElementChecked,
            toggleAreaOfCheckboxes: toggleAreaOfCheckboxes,
            viewMode: Types_CostEstimation.EditMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            shortenReferenceProjectDetails: false,
            projectId: props.projectId,
            projectCurrency: props.project.currency,
            appDispatch: appDispatch,
            subprojects: v5.value0,
            selectedInputColumn: v19.value0.selectedInputColumn,
            setSelectedInputColumn: function (mCol) {
                return v19.value1(function (v25) {
                    return {
                        sortingCriteria: v25.sortingCriteria,
                        checkedElements: v25.checkedElements,
                        lastCheckedItem: v25.lastCheckedItem,
                        checkedSpecs: v25.checkedSpecs,
                        detailsElementIdx: v25.detailsElementIdx,
                        shouldScrollToSelection: v25.shouldScrollToSelection,
                        editActiveFilters: v25.editActiveFilters,
                        selectedInputColumn: mCol,
                        selectedResourceIds: v25.selectedResourceIds,
                        groupBy: v25.groupBy
                    };
                });
            },
            showSocialExpensesInCosts: v9.showSocialExpensesInCosts
        };
        var selectedElementSpecId = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.fst)(v24.selectedComponentIds);
        var isSelectionEmpty = Data_Set.isEmpty(v19.value0.checkedElements);
        var selectionProps = {
            onToggleSelectAll: onToggleCheckAll,
            isSelectionEmpty: isSelectionEmpty
        };
        var columns = CostEstimation_BuildingElementsView_Table.getColumns(tableProps)(selectionProps);
        var panelProps$prime = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: (function () {
                var $159 = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v25) {
                    return v25.hideComponents;
                })(props.dixonOptions));
                if ($159) {
                    return CostEstimationLayout_Types.MinimizedMode.value;
                };
                return CostEstimationLayout_Types.NormalMode.value;
            })(),
            localStorageKey: "ElementsEditView"
        })();
        var panelProps = (function () {
            if (props.dixonOptions instanceof Data_Maybe.Just) {
                return props.dixonOptions.value0.panelProps;
            };
            if (props.dixonOptions instanceof Data_Maybe.Nothing) {
                return panelProps$prime;
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 476, column 18 - line 478, column 29): " + [ props.dixonOptions.constructor.name ]);
        })();
        var elementDetails = Data_Functor.voidLeft(Data_Maybe.functorMaybe)(selectedElement)(CostEstimation_BuildingElementsView_ElementDetailsContainer.mkElementDetails({
            elementProps: elementProps,
            selectedElement: selectedElement,
            elementMeasures: v3.value0,
            projectId: props.projectId,
            checkedSpecs: v19.value0.checkedSpecs,
            setCheckedSpecs: function (update) {
                return v19.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: update(s.checkedSpecs),
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        editActiveFilters: s.editActiveFilters,
                        selectedInputColumn: s.selectedInputColumn,
                        selectedResourceIds: s.selectedResourceIds,
                        groupBy: s.groupBy
                    };
                });
            },
            selectedComponentIds: v24.selectedComponentIds,
            setSelectedComponentIds: v24.setSelectedComponentIds,
            setParentSelectedResourceIds: new Data_Maybe.Just(v23.value1),
            socialExpensePercentagesByCostClass: v9.socialExpensePercentagesByCostClass,
            showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
            showWorkAchievement: v10.showWorkAchievement,
            viewMode: Types_CostEstimation.EditMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            isPanel: false,
            projectLocations: (function () {
                if (v7.value1) {
                    return [  ];
                };
                return v7.value0;
            })(),
            controls: React_Basic.empty,
            extraResourceControls: [  ],
            extraComponentControls: [  ],
            panelMode: panelProps.detailsPanelMode,
            setPanelMode: panelProps.setDetailsPanelMode,
            dixonOptions: props.dixonOptions,
            existingComponentCodesForEdit: v21,
            projectCurrency: props.project.currency
        }));
        var mkFilterStrings = function (filters) {
            return Data_Function.flip(Data_Array.mapMaybe)(filters)(function (filter) {
                var listing = function (xs) {
                    return Data_Array.intercalate(Data_Monoid.monoidString)(", ")(xs);
                };
                var getProjectCodesOfIds = function (xs) {
                    return Data_Function.flip(Data_Array.mapMaybe)(xs)(function (id) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Array.find)(v4.value0)(function (p) {
                            return Data_Eq.eq(Types_Project.eqProjectId)(p.id)(id);
                        }))(function (found) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(found.name + (" " + (Data_Newtype.unwrap()(found.code) + (" " + found.version))));
                        });
                    });
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataSubProjectCode) {
                    return Data_Maybe.Just.create("Alakohde: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataLocationCode) {
                    return Data_Maybe.Just.create("Sijainti: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataGroup) {
                    return Data_Maybe.Just.create("Ryhm\xe4: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataElementCode) {
                    return Data_Maybe.Just.create("Luokka: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsWithoutDataGroup) {
                    return Data_Maybe.Just.create("Ryhmittelem\xe4tt\xf6m\xe4t");
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataElementNoCodeMatch) {
                    return Data_Maybe.Just.create("Muu koodi");
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataElementRtCode) {
                    return Data_Maybe.Just.create("Koodi: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataDescription) {
                    return Data_Maybe.Just.create("Luokan nimi: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataRtDescription) {
                    return Data_Maybe.Just.create("Selite: " + listing(filter.value0.values));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataProjectOfOrigin) {
                    return Data_Maybe.Just.create("Projekti: " + Data_Array.intercalate(Data_Monoid.monoidString)(", ")(getProjectCodesOfIds(filter.value0)));
                };
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataSearch) {
                    return Data_Maybe.Just.create("Hae: " + filter.value0);
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 560, column 7 - line 571, column 60): " + [ filter.constructor.name ]);
            });
        };
        var initialScrollRow = (function () {
            if (props.initialSelectedElement instanceof Data_Maybe.Nothing) {
                return v18.value0.selectedElementIdx;
            };
            if (props.initialSelectedElement instanceof Data_Maybe.Just) {
                var es = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(v.value0);
                var idx = Data_Array.findIndex(function (e) {
                    return Data_Eq.eq(Types_Element.eqElementId)(props.initialSelectedElement.value0)(e.elementId);
                })(es);
                return idx;
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsEditView (line 519, column 24 - line 525, column 12): " + [ props.initialSelectedElement.constructor.name ]);
        })();
        var tableWhenRowsLoaded = Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](rows))(CostEstimation_BuildingElementsView_Table.elementsTableOptimized({
            rows: rows,
            columns: columns,
            mkRow: CostEstimation_BuildingElementsView_Table.getRow(columns)(tableProps),
            sortProperty: v19.value0.sortingCriteria.value1,
            sortingOrder: v19.value0.sortingCriteria.value0,
            rowHeight: CostEstimation_BuildingElementsView.tableRowHeight,
            onSort: function (newProperty) {
                return v19.value1(function (v25) {
                    var newSortingCriteria = (function () {
                        if (v25.sortingCriteria.value1 instanceof Data_Maybe.Just && Data_Eq.eq(CostEstimation_BuildingElementsView_Table.eqElementsTableSortProperty)(v25.sortingCriteria.value1.value0)(newProperty)) {
                            return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(v19.value0.sortingCriteria.value0), new Data_Maybe.Just(v25.sortingCriteria.value1.value0), v25.sortingCriteria.value2);
                        };
                        if (v25.sortingCriteria.value1 instanceof Data_Maybe.Just) {
                            return new TacoTable_Types.SortingCriteria(v19.value0.sortingCriteria.value0, new Data_Maybe.Just(newProperty), v25.sortingCriteria.value2);
                        };
                        return new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(CostEstimation_BuildingElementsView_Table.ByCode.value), CostEstimation_BuildingElementsView_Table.getSortingValue);
                    })();
                    return {
                        sortingCriteria: newSortingCriteria,
                        checkedElements: v25.checkedElements,
                        lastCheckedItem: Data_Maybe.Nothing.value,
                        checkedSpecs: v25.checkedSpecs,
                        detailsElementIdx: v25.detailsElementIdx,
                        shouldScrollToSelection: v25.shouldScrollToSelection,
                        editActiveFilters: v25.editActiveFilters,
                        selectedInputColumn: v25.selectedInputColumn,
                        selectedResourceIds: v25.selectedResourceIds,
                        groupBy: v25.groupBy
                    };
                });
            },
            containerRef: tableRef,
            showHeader: true,
            styleSet: CostEstimation_BuildingElementsView_Table.elementsTableStyleSet,
            initialScrollRow: initialScrollRow
        }));
        var floatingElementsControls$prime = CostEstimation_BuildingElementsControls.mkFloatingElementsControls({
            checkedElements: v19.value0.checkedElements,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            onToggleCheckAll: onToggleCheckAll,
            checkedElementsData: checkedElementsData,
            isSocialExpensesToggled: v9.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: true,
            onSocialExpensesToggle: v9.onSocialExpensesToggle,
            isWorkAchievementToggled: v10.showWorkAchievement,
            showWorkAchievementToggleControl: true,
            onWorkAchievementToggle: v10.onWorkAchievementToggle,
            addMemoToExcel: v11.value0,
            onAddMemoToExcelToggle: v11.value1,
            activeElementsFilters: v19.value0.editActiveFilters,
            setActiveElementsFilters: setActiveElementsFilters,
            viewMode: Types_CostEstimation.EditMode.value,
            searchValue: v20.value0,
            setSearchValue: v20.value1,
            setNewElementId: v22.value1,
            projectId: props.projectId,
            referenceModeFollowOptions: Data_Maybe.Nothing.value,
            extraElementsTableControls: Data_Monoid.mempty(Data_Monoid.monoidArray),
            mkExport: Data_Maybe.Nothing.value,
            mkElementsExport: Data_Maybe.Nothing.value,
            groupBy: v19.value0.groupBy,
            toggleGroupBy: toggleGroupBy,
            showGroupByControls: false
        });
        var elementsTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.elementColors,
            children: [ tableWhenRowsLoaded ],
            floatingActionButtons: floatingElementsControls$prime
        });
        var elementsControls$prime = CostEstimation_BuildingElementsControls.mkElementsControls({
            checkedElements: v19.value0.checkedElements,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            onToggleCheckAll: onToggleCheckAll,
            checkedElementsData: checkedElementsData,
            isSocialExpensesToggled: v9.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: true,
            onSocialExpensesToggle: v9.onSocialExpensesToggle,
            isWorkAchievementToggled: v10.showWorkAchievement,
            showWorkAchievementToggleControl: true,
            onWorkAchievementToggle: v10.onWorkAchievementToggle,
            addMemoToExcel: v11.value0,
            onAddMemoToExcelToggle: v11.value1,
            activeElementsFilters: v19.value0.editActiveFilters,
            setActiveElementsFilters: setActiveElementsFilters,
            viewMode: Types_CostEstimation.EditMode.value,
            searchValue: v20.value0,
            setSearchValue: v20.value1,
            setNewElementId: v22.value1,
            projectId: props.projectId,
            referenceModeFollowOptions: Data_Maybe.Nothing.value,
            extraElementsTableControls: Data_Monoid.mempty(Data_Monoid.monoidArray),
            mkExport: new Data_Maybe.Just(function (v25) {
                return {
                    project: props.project,
                    elementsData: new Data_Maybe.Just(elementsData),
                    elementMeasures: new Data_Maybe.Just(v3.value0),
                    componentsWithResources: Data_Maybe.Nothing.value,
                    socialExpensePercentagesByCostClass: v9.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                    showCostClassColumns: false,
                    showHoursColumns: false,
                    showWorkAchievementColumns: false,
                    costClasses: v8.value0.value,
                    addMemoToExcel: v11.value0,
                    showTargetCostGroup: false,
                    showSubprojects: true,
                    showGroups: true
                };
            }),
            mkElementsExport: new Data_Maybe.Just(function (v25) {
                return {
                    project: props.project,
                    dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                        var jsdate = Data_JSDate.now();
                        return Formatters.formatDateValue(jsdate);
                    }),
                    elementsData: groupedElementsData,
                    filterStrings: mkFilterStrings(v19.value0.editActiveFilters),
                    socialExpensePercentagesByCostClass: v9.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                    groupBy: v19.value0.groupBy,
                    addMemoToExcel: v11.value0
                };
            }),
            groupBy: v19.value0.groupBy,
            toggleGroupBy: toggleGroupBy,
            showGroupByControls: true
        });
        var elementsControls = Box.box("controlsWrapper")([ new Box.FlexGrow(1), new Box.Padding(TacoConstants.S.value) ])([ elementsControls$prime ]);
        var v25 = TacoLoader.useLoading(false)();
        var toggleFilter = EstimationComponentFilter.mkToggleFilter(CostEstimation_Filters.genericElementsDataPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataSubProjectCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataLocationCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsWithoutDataGroup";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsWithoutDataGroup";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataGroup";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsWithoutDataGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataLocationCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsWithoutDataGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsDataSubProjectCode";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "FilterByElementsWithoutDataGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataSearch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataRtDescription";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataProjectOfOrigin";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataLocationCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataGroup";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementRtCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementNoCodeMatch";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataElementCode";
            }
        }))({
            reflectSymbol: function () {
                return "FilterByElementsDataDescription";
            }
        })))(v19.value0.editActiveFilters)(setActiveElementsFilters);
        var toggleCodeFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (classificationCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsDataElementCode";
                    }
                })()(classificationCode)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleNoCodeMatchFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (v27) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsDataElementNoCodeMatch";
                    }
                })()("no-code-match")(Data_Symbol.SProxy.value);
            };
        })();
        var selectWithAClassificationCode = TofuHooks.useCallback([ ReactHooksUtils.utf(setDetailsElementId), ReactHooksUtils.utf(v.value0) ])(function (clCode) {
            return Data_Maybe.maybe(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))(setDetailsElementId(true))(CostEstimation_BuildingElementsView.getElementId(v.value0)(clCode));
        })();
        var toggleSubprojectFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (subprojectCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsDataSubProjectCode";
                    }
                })()(subprojectCode)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleLocationFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (locationCode) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsDataLocationCode";
                    }
                })()(locationCode)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleGroupFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (v27) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsDataGroup";
                    }
                })()(v27)(Data_Symbol.SProxy.value);
            };
        })();
        var toggleWithoutGroupFilter = TofuHooks.useMemo([ v19.value0.editActiveFilters ])(function (v26) {
            return function (v27) {
                return toggleFilter({
                    reflectSymbol: function () {
                        return "FilterByElementsWithoutDataGroup";
                    }
                })()("no-group")(Data_Symbol.SProxy.value);
            };
        })();
        var v26 = ReferenceLibrarySidepanel.useActiveRefMode();
        return CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(elementsControls),
            controlsLoading: (function () {
                if (loading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            leftPanel: Data_Maybe.Just.create(CostEstimation_SummariesPanel.component({
                panelMode: panelProps.leftPanelMode,
                getContents: function (v27) {
                    var subprojectsList = SubprojectsTree.subprojectsTree({
                        socialExpensePercentagesByCostClassWithHash: v9.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v9.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                        subprojects: v5.value0,
                        computedComponentWithResources: v12.value0,
                        toggleSubprojectFilter: toggleSubprojectFilter,
                        projectId: props.projectId,
                        estimationElements: v.value0,
                        estimationComponents: v1.value0,
                        setLoading: v25.setLoading,
                        projectCurrency: props.project.currency
                    });
                    var locationsList = ProjectLocationsTree.projectLocationsTree({
                        projectId: props.projectId,
                        projectCurrency: props.project.currency,
                        socialExpensePercentagesByCostClassWithHash: v9.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v9.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                        estimationLocations: v6.value0,
                        computedComponentWithResources: v12.value0,
                        toggleLocationFilter: toggleLocationFilter,
                        setLoading: v25.setLoading
                    });
                    var groupsTree = ComponentGroupsTree.componentGroupsTree({
                        socialExpensePercentagesByCostClassWithHash: v9.socialExpensePercentagesByCostClassWithHash,
                        unfilteredSocialExpensePercentagesByCostClass: v9.unfilteredSocialExpensePercentagesByCostClass,
                        showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                        projectId: props.projectId,
                        toggleGroupFilter: toggleGroupFilter,
                        toggleWithoutGroupFilter: toggleWithoutGroupFilter,
                        computedComponentWithResources: v12.value0,
                        projectCurrency: props.project.currency,
                        setLoading: v25.setLoading
                    });
                    var elementSummaries = CostEstimation_BuildingElementsView_ElementSummaries.component({
                        showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                        projectId: props.projectId,
                        setLoading: v25.setLoading,
                        projectCurrency: props.project.currency
                    });
                    var classificationTable = ProjectClassificationTable.projectClassificationTable({
                        selectWithAClassificationCode: selectWithAClassificationCode,
                        projectId: props.projectId,
                        project: props.project,
                        showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
                        toggleCodeFilter: toggleCodeFilter,
                        toggleNoCodeMatchFilter: toggleNoCodeMatchFilter,
                        setLoading: v25.setLoading
                    });
                    return React_Basic.fragment([ elementSummaries, classificationTable, subprojectsList, locationsList, groupsTree ]);
                }
            })),
            leftSidebar: Data_Maybe.Just.create(CostEstimation_SummariesSidebar.component({
                panelMode: panelProps.leftPanelMode,
                setPanelMode: panelProps.setLeftPanelMode
            })),
            main: elementsTable,
            rightPanel: (function () {
                var $196 = Data_Maybe.isNothing(props.panelOptions);
                if ($196) {
                    return Data_Maybe.Just.create(ReferenceLibrarySidepanel.component({
                        selectedEditViewElement: selectedElement,
                        selectedEditViewElementMeasures: new Data_Maybe.Just(selectedElementMeasures),
                        selectedEditViewElementSpecId: selectedElementSpecId,
                        selectedEditViewComponent: v24.selectedDetailsComponent,
                        selectedEditViewResource: Data_Maybe.Nothing.value,
                        selectedEditViewResourceIds: v23.value0,
                        projectId: props.projectId,
                        project: props.project,
                        activeRefMode: v26.activeRefMode
                    }));
                };
                return Data_Maybe.Nothing.value;
            })(),
            rightSidebar: (function () {
                var $197 = Data_Maybe.isNothing(props.panelOptions);
                if ($197) {
                    return Data_Maybe.Just.create(ReferenceLibrarySidebar.component({
                        activeRefMode: v26.activeRefMode,
                        setActiveRefMode: v26.setActiveRefMode
                    }));
                };
                return Data_Maybe.Nothing.value;
            })(),
            details: elementDetails,
            panelProps: {
                leftPanelMode: panelProps.leftPanelMode,
                setLeftPanelMode: panelProps.setLeftPanelMode,
                rightPanelMode: (function () {
                    if (v26.hasActiveRefMode) {
                        return CostEstimationLayout_Types.NormalMode.value;
                    };
                    return CostEstimationLayout_Types.MinimizedMode.value;
                })(),
                setRightPanelMode: panelProps.setRightPanelMode,
                detailsPanelMode: panelProps.detailsPanelMode,
                setDetailsPanelMode: panelProps.setDetailsPanelMode
            },
            leftPanelLoading: (function () {
                if (v25.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: Data_Maybe.Nothing.value
        });
    };
});
module.exports = {
    component: component
};
