// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var ElementDetailsContainer_Styles = require("../ElementDetailsContainer.Styles/index.js");
var ElementMeasuresTable = require("../ElementMeasuresTable/index.js");
var ElementUtils = require("../ElementUtils/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var component = TofuHooks.mkHookComponent("ElementMeasures")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var dispatch = StateHooks.useDispatch();
        var varEmpty = function (name) {
            var $6 = name === "";
            if ($6) {
                return TacoInput.Valid.value;
            };
            return new TacoInput.Invalid("");
        };
        var mkNewElementMeasure = function (code) {
            var $7 = code === "";
            if ($7) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
            };
            return function __do() {
                dispatch(new Actions.ElementMeasureActionRequest({
                    projectId: props.projectId,
                    handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                    params: new Types_Element.AddElementMeasure({
                        code: code,
                        elementId: (Data_Newtype.un()(Types_Element.WasmEstimationElement)(props.element)).elementId
                    })
                }))();
                return v.value1(function (v1) {
                    return "";
                })();
            };
        };
        var elementMeasuresFilter = function (v1) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(v1.elementId)(new Data_Maybe.Just((Data_Newtype.un()(Types_Element.WasmEstimationElement)(props.element)).elementId));
        };
        var filteredElementMeasures = Data_Array.filter(elementMeasuresFilter)(props.elementMeasures);
        var existingMeasureCodes = Data_Functor.map(Data_Functor.functorArray)((function () {
            var $16 = Data_Newtype.un()(Types_Element.ElementMeasure);
            return function ($17) {
                return Data_String_Common.toLower((function (v1) {
                    return v1.code;
                })($16($17)));
            };
        })())(filteredElementMeasures);
        var notExistingVariable = function (name) {
            var $9 = Data_Array.elem(Data_Eq.eqString)(Data_String_Common.toLower(name))(existingMeasureCodes);
            if ($9) {
                return new TacoInput.Invalid("Existing code");
            };
            return TacoInput.Valid.value;
        };
        var withDummyRow = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            elementMeasureId: 0,
            elementId: Data_Maybe.Nothing.value,
            code: "x",
            description: "rakenteen m\xe4\xe4r\xe4",
            value: props.amount
        } ])(filteredElementMeasures);
        var elementMeasuresList = TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "viewMode";
            }
        })(Types_CostEstimation.eqECViewMode))()({
            reflectSymbol: function () {
                return "elementMeasure";
            }
        })(Types_Element.eqElementMeasure))()({
            reflectSymbol: function () {
                return "dummyRow";
            }
        })(Data_Eq.eqBoolean)))(ElementMeasuresTable.elementMeasuresTable)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Array.mapWithIndex(function (index) {
                return function (em) {
                    return ElementMeasuresTable.getRow({
                        dispatch: dispatch,
                        projectId: props.projectId,
                        dummyRow: index === 0,
                        viewMode: props.viewMode
                    })(em);
                };
            })(withDummyRow),
            showHeader: true,
            styleSet: ElementMeasuresTable.styleSet,
            columns: ElementMeasuresTable.columns({
                dispatch: dispatch,
                projectId: props.projectId
            })
        });
        var canEdit = EstimationUtils.canEditView(props);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__ElementMeasures",
            css: ElementDetailsContainer_Styles.elementDetailsSidepanelStyles,
            children: [ elementMeasuresList, Box.box("NewElementMeasureContainer")([ Box.Width.create(new TacoConstants.CustomSize("21.5%")), new Box.MarginX(TacoConstants.XXS.value) ])([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(TacoInput.stringInput()()(Data_Eq.eqString)({
                placeholder: "symboli",
                value: v.value0,
                onChange: v.value1,
                onSave: mkNewElementMeasure,
                validator: TacoInput.orValueValidity(varEmpty)(TacoInput.andValueValidity(ElementUtils.varNameValidator)(notExistingVariable)),
                testId: "new-element-measure-code-input"
            })) ]) ]
        });
    };
});
module.exports = {
    component: component
};
