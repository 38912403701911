// Generated by purs version 0.14.5
"use strict";
var Admin_Styles = require("../Admin.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var BySelected = (function () {
    function BySelected() {

    };
    BySelected.value = new BySelected();
    return BySelected;
})();
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByVersion = (function () {
    function ByVersion() {

    };
    ByVersion.value = new ByVersion();
    return ByVersion;
})();
var selectCheckbox = function (isChecked) {
    return function (onToggle) {
        return TacoCheckbox.component()()({
            className: "",
            isChecked: isChecked,
            onToggle: TacoCheckbox.NoEvent.create(onToggle)
        });
    };
};
var searchField = TofuHooks.mkHookComponent("ProjectsSearchField")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(ReactBasicUtils["div'"]("__ProjectsSearchField")([ TacoInput.stringInput()()(Data_Eq.eqString)({
        onChange: v.setFilter,
        value: v.filter,
        placeholder: "Etsi hanketta...",
        testId: "admin-roles-filter-projects"
    }) ]));
});
var getSortingValue = function (v) {
    return function (r) {
        if (v instanceof BySelected) {
            return new TacoTable_Types.BooleanValue(r.selected);
        };
        if (v instanceof ByCode) {
            return TacoTable_Types.StringValue.create(Data_Newtype.un()(Types_Project.ProjectCode)(r.project.code));
        };
        if (v instanceof ByName) {
            return new TacoTable_Types.StringValue(r.project.name);
        };
        if (v instanceof ByVersion) {
            return new TacoTable_Types.StringValue(r.project.version);
        };
        throw new Error("Failed pattern match at Admin.ProjectSelector (line 182, column 1 - line 182, column 62): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var sortRows = function (sortProperty) {
    return function (sortingOrder) {
        return function (rows) {
            var withOrder = (function () {
                var $22 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(sortingOrder)(TacoTable_Types.Descending.value);
                if ($22) {
                    return Data_Function.flip;
                };
                return Control_Category.identity(Control_Category.categoryFn);
            })();
            var sortableValue = function (x) {
                return getSortingValue(sortProperty)(x);
            };
            return Data_Array.sortBy(function (a) {
                return function (b) {
                    return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
                };
            })(rows);
        };
    };
};
var getSortProperty = {
    getName: function (v) {
        return "SortProperty";
    }
};
var eqSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof BySelected && y instanceof BySelected) {
                return true;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByVersion && y instanceof ByVersion) {
                return true;
            };
            return false;
        };
    }
};
var optimizedTableInstanceForProjectRights = TacoTable.tableOptimized(getSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "selected";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "project";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "version";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "subprojects";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqSubprojectId))()({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "programType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "currency";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "createdDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.eqProjectCode))()({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(Data_Eq.eqString)))));
var table = TofuHooks.mkHookComponent("RoleProjectRightsTable")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(ByCode.value)();
        var v2 = TofuHooks.useState(TacoTable_Types.Ascending.value)();
        var tableRef = UseNullableRef.useNullableRef();
        var toggleSelected = function (selected) {
            return function (projectId) {
                if (selected) {
                    return v.updateSelectedProjects(Data_Array["delete"](Types_Project.eqProjectId)(projectId));
                };
                return v.updateSelectedProjects(Data_Array.cons(projectId));
            };
        };
        var mkRowData = function (project) {
            return {
                selected: Data_Array.elem(Types_Project.eqProjectId)(project.id)(v.selectedProjects),
                project: project
            };
        };
        var columns = [ {
            key: "selected",
            label: React_Basic_DOM.text("Valitse"),
            sortProperty: Data_Maybe.Nothing.value,
            headerJSX: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(10),
            cell: new TacoTable_Types.JSXCell([  ], function (p) {
                return selectCheckbox(p.selected)(toggleSelected(p.selected)(p.project.id));
            })
        }, {
            key: "code",
            label: React_Basic_DOM.text("Tunnus"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $44 = Data_Newtype.un()(Types_Project.ProjectCode);
                return function ($45) {
                    return $44((function (v3) {
                        return v3.project.code;
                    })($45));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByCode.value),
            width: new TacoTable_Types.Flex(15),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "version",
            label: React_Basic_DOM.text("Versio"),
            cell: TacoTable_Types.PlainTextCell.create(function (v3) {
                return v3.project.version;
            }),
            sortProperty: new Data_Maybe.Just(ByVersion.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text("Nimi"),
            cell: TacoTable_Types.PlainTextCell.create(function (v3) {
                return v3.project.name;
            }),
            sortProperty: new Data_Maybe.Just(ByName.value),
            width: new TacoTable_Types.Flex(45),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var mkRow = function (v3) {
            return function (r) {
                return {
                    rowData: r,
                    rowKey: Data_Show.show(Types_Project.showProjectId)(r.project.id),
                    onClick: function (v4) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    className: "",
                    foldingElement: Data_Maybe.Nothing.value,
                    columns: columns
                };
            };
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__RoleProjectRightsTable",
            css: Admin_Styles.roleProjectRightsTableStyles,
            children: [ optimizedTableInstanceForProjectRights({
                rows: sortRows(v1.value0)(v2.value0)(Data_Functor.map(Data_Functor.functorArray)(mkRowData)(v.projects)),
                columns: columns,
                mkRow: mkRow,
                rowHeight: TacoTable_Types.RowMedium.value,
                sortProperty: new Data_Maybe.Just(v1.value0),
                onSort: function (property) {
                    return function __do() {
                        Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(eqSortProperty)(property)(v1.value0))(v2.value1(function (v3) {
                            return TacoTable_Types.reverse(v2.value0);
                        }))();
                        return v1.value1(function (v3) {
                            return property;
                        })();
                    };
                },
                sortingOrder: v2.value0,
                containerRef: tableRef,
                showHeader: true,
                styleSet: TacoTable_Styles.optimizedTableStyleSet,
                initialScrollRow: Data_Maybe.Nothing.value
            }) ]
        });
    };
});
var component = TofuHooks.mkHookComponent("ProjectSelector")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState("")();
        var matchingProjects = (function () {
            var matchesFilterStr = function (project) {
                return Data_Array.any(Data_Eq.eq(Data_Eq.eqBoolean)(true))(Data_Functor.map(Data_Functor.functorArray)(Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(v1.value0)))(Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.toLower)([ Data_Newtype.un()(Types_Project.ProjectCode)(project.code), project.name ])));
            };
            return Data_Array.filter(matchesFilterStr)(v.projects);
        })();
        var projectRightsTable = table({
            projects: matchingProjects,
            selectedProjects: v.selectedProjects,
            updateSelectedProjects: v.updateSelectedProjects
        });
        var filterSummary = (function () {
            var totalProjectsCount = Data_Show.show(Data_Show.showInt)(Data_Array.length(v.projects));
            var listedProjectsCount = Data_Show.show(Data_Show.showInt)(Data_Array.length(matchingProjects));
            var summaryText = (function () {
                var $38 = Data_String_Common["null"](v1.value0);
                if ($38) {
                    return "Listattu kaikki " + (listedProjectsCount + " hanketta");
                };
                return "Listattu " + (listedProjectsCount + ("/" + (totalProjectsCount + " hanketta")));
            })();
            return ReactBasicUtils["div'"]("filter-summary")([ TacoText.component()()({
                text: summaryText,
                variant: TacoText_Types.Paragraph.value,
                gutterBottom: true
            }) ]);
        })();
        var projectFilter = ReactBasicUtils["div'"]("project-filter")([ searchField({
            filter: v1.value0,
            setFilter: v1.value1
        }), filterSummary ]);
        return ReactBasicUtils["div'"]("__RoleProjectRights")([ projectFilter, projectRightsTable ]);
    };
});
module.exports = {
    component: component
};
