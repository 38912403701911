// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoList = require("../TacoList/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var ColSubprojectName = (function () {
    function ColSubprojectName() {

    };
    ColSubprojectName.value = new ColSubprojectName();
    return ColSubprojectName;
})();
var ColSubprojectCode = (function () {
    function ColSubprojectCode() {

    };
    ColSubprojectCode.value = new ColSubprojectCode();
    return ColSubprojectCode;
})();
var ColSubprojectCountInMeasurementTotal = (function () {
    function ColSubprojectCountInMeasurementTotal() {

    };
    ColSubprojectCountInMeasurementTotal.value = new ColSubprojectCountInMeasurementTotal();
    return ColSubprojectCountInMeasurementTotal;
})();
var ColSubprojectActionButton = (function () {
    function ColSubprojectActionButton() {

    };
    ColSubprojectActionButton.value = new ColSubprojectActionButton();
    return ColSubprojectActionButton;
})();
var subprojectTreeModalStyleSet = TacoTable_Styles.foldingTableStyleSetWithoutSticky;
var subprojectTreeModalColumns = function (fn) {
    return [ {
        key: "code",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Koodi"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColSubprojectCode.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "name",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Nimi"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColSubprojectName.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "countInMeasurementTotal",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Mukana laajuustiedoissa"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColSubprojectCountInMeasurementTotal.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "actionButton",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text(""),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColSubprojectActionButton.value),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var subprojectsTreeModal = TofuHooks.mkMemoHookComponent("SubprojectsTreeModal")(function (props) {
    var defaultNewSubprojectInputs = {
        name: "",
        code: "",
        countInMeasurementTotal: false
    };
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(defaultNewSubprojectInputs)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var newSubprojectSubmitButton = TacoButton.component()()({
            text: "Luo uusi alakohde",
            disabled: Data_String_Common["null"](v.value0.code) || Data_String_Common["null"](v.value0.name),
            onClick: new Data_Maybe.Just(dispatch(new Actions.SubprojectActionRequest({
                handler: function (v2) {
                    return v.value1(function (v3) {
                        return defaultNewSubprojectInputs;
                    });
                },
                projectId: props.projectId,
                params: new Types_Project.AddSubproject({
                    code: v.value0.code,
                    name: new Data_Maybe.Just(v.value0.name),
                    countInMeasurementTotal: v.value0.countInMeasurementTotal,
                    projectId: props.projectId
                })
            }))),
            testId: "create-new-subproject-button"
        });
        var newSubprojectName = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.name,
            onChange: function (fn) {
                return v.value1(function (r) {
                    return {
                        name: fn(r.name),
                        code: r.code,
                        countInMeasurementTotal: r.countInMeasurementTotal
                    };
                });
            },
            validator: function (s) {
                var $44 = Data_String_CodePoints.length(s) <= 30;
                if ($44) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            },
            testId: "new-subproject-name-input"
        });
        var newSubprojectCountInMeasurementTotal = TacoCheckbox.component()()({
            isChecked: v.value0.countInMeasurementTotal,
            onToggle: TacoCheckbox.NoEvent.create(v.value1(function (r) {
                return {
                    name: r.name,
                    code: r.code,
                    countInMeasurementTotal: !r.countInMeasurementTotal
                };
            })),
            testId: "new-subproject-in-total-control"
        });
        var newSubprojectCode = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.code,
            onChange: function (fn) {
                return v.value1(function (r) {
                    return {
                        name: r.name,
                        code: fn(r.code),
                        countInMeasurementTotal: r.countInMeasurementTotal
                    };
                });
            },
            validator: function (s) {
                var $45 = Data_String_CodePoints.length(s) <= 5;
                if ($45) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            },
            testId: "new-subproject-code-input"
        });
        var newSubproject = React_Basic.keyed("new-subproject-row")(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))({
            rowData: v.value0,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            styleSet: subprojectTreeModalStyleSet.rowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            columns: subprojectTreeModalColumns(function (v2) {
                if (v2 instanceof ColSubprojectCode) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newSubprojectCode;
                    });
                };
                if (v2 instanceof ColSubprojectName) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newSubprojectName;
                    });
                };
                if (v2 instanceof ColSubprojectCountInMeasurementTotal) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newSubprojectCountInMeasurementTotal;
                    });
                };
                if (v2 instanceof ColSubprojectActionButton) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newSubprojectSubmitButton;
                    });
                };
                throw new Error("Failed pattern match at SubprojectsTree (line 346, column 45 - line 350, column 89): " + [ v2.constructor.name ]);
            }),
            rowKey: "new-subproject-row"
        }));
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.onClose),
                text: "Peruuta",
                testId: "subprojects-table-cancel-button"
            }) ]
        });
        var mkAction$prime = function (fn) {
            return function (value) {
                return function (handler) {
                    return new Actions.SubprojectActionRequest({
                        handler: handler,
                        projectId: props.projectId,
                        params: Types_Project.EditSubproject.create(fn(value))
                    });
                };
            };
        };
        var subprojectColumns = subprojectTreeModalColumns(function (v2) {
            if (v2 instanceof ColSubprojectCode) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoInput.remoteStringField()()({
                        value: r.subproject.code,
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: r.subproject.id,
                                name: r.subproject.name,
                                code: value,
                                countInMeasurementTotal: r.subproject.countInMeasurementTotal,
                                projectId: r.subproject.projectId
                            };
                        }),
                        validator: function (s) {
                            var $48 = Data_String_CodePoints.length(s) <= 5 && Data_String_CodePoints.length(s) > 0;
                            if ($48) {
                                return TacoInput.Valid.value;
                            };
                            return new TacoInput.Invalid("");
                        },
                        testId: "subprojects-tree-code-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.subproject.id)) + "-input")
                    });
                });
            };
            if (v2 instanceof ColSubprojectName) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoInput.remoteStringField()()({
                        value: Utils.fromNullable("")(r.subproject.name),
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: r.subproject.id,
                                name: Utils.pureNullable(value),
                                code: r.subproject.code,
                                countInMeasurementTotal: r.subproject.countInMeasurementTotal,
                                projectId: r.subproject.projectId
                            };
                        }),
                        validator: function (s) {
                            var $49 = Data_String_CodePoints.length(s) <= 30;
                            if ($49) {
                                return TacoInput.Valid.value;
                            };
                            return new TacoInput.Invalid("");
                        },
                        testId: "subprojects-tree-name-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.subproject.id)) + "-input")
                    });
                });
            };
            if (v2 instanceof ColSubprojectCountInMeasurementTotal) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoCheckbox.component()()({
                        isChecked: r.subproject.countInMeasurementTotal,
                        onToggle: new TacoCheckbox.NoEvent(dispatch(new Actions.SubprojectActionRequest({
                            handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                            projectId: props.projectId,
                            params: Types_Project.EditSubproject.create({
                                id: r.subproject.id,
                                name: r.subproject.name,
                                code: r.subproject.code,
                                countInMeasurementTotal: !r.subproject.countInMeasurementTotal,
                                projectId: r.subproject.projectId
                            })
                        }))),
                        testId: "subprojects-tree-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.subproject.id)) + "-in-total-control")
                    });
                });
            };
            if (v2 instanceof ColSubprojectActionButton) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                    return TacoButton.component()()({
                        text: "Poista",
                        color: TacoConstants.SecondaryRed.value,
                        disabled: r.isNonEmpty,
                        title: "Vain tyhj\xe4n alakohteen voi poistaa.",
                        onClick: new Data_Maybe.Just(v1.value1(function (v3) {
                            return new Data_Maybe.Just(r);
                        })),
                        testId: "subprojects-tree-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.subproject.id)) + "-action-button")
                    });
                });
            };
            throw new Error("Failed pattern match at SubprojectsTree (line 241, column 52 - line 275, column 12): " + [ v2.constructor.name ]);
        });
        var mkSubproject = function (subproject) {
            var rowKey = "subproject-" + Data_Show.show(Types_Project.showSubprojectId)(subproject.id);
            return React_Basic.keyed(rowKey)(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "subproject";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqSubprojectId))()({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString))))()({
                reflectSymbol: function () {
                    return "isNonEmpty";
                }
            })(Data_Eq.eqBoolean)))({
                rowData: {
                    subproject: subproject,
                    isNonEmpty: Data_Set.member(Types_Project.ordSubprojectId)(subproject.id)(props.nonEmptySubprojects)
                },
                css: React_Basic_Emotion.css()({}),
                rowHeight: TacoTable_Types.RowLarge.value,
                sortProperty: TacoTable_Types.noSortProperty,
                onClick: function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                styleSet: subprojectTreeModalStyleSet.rowStyleSet,
                style: React_Basic_DOM_Internal.css({}),
                columns: subprojectColumns,
                rowKey: rowKey
            }));
        };
        var subprojects = Data_Functor.map(Data_Functor.functorArray)(mkSubproject)(props.subprojects);
        var newHeader = TacoTable_Header.header(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "subproject";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqSubprojectId))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))))()({
            reflectSymbol: function () {
                return "isNonEmpty";
            }
        })(Data_Eq.eqBoolean)))({
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            styleSet: subprojectTreeModalStyleSet.headerStyleSet,
            onSort: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            foldingElement: Data_Maybe.Nothing.value,
            columns: subprojectColumns
        });
        var deletePromptModalBody = TacoModal.modalBody({
            contents: (function () {
                if (v1.value0 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v1.value0 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        text: "Haluatko varmasti poistaa alakohteen " + (v1.value0.value0.subproject.code + Utils.nullable("")(function (x) {
                            return " \"" + (x + "\"");
                        })(v1.value0.value0.subproject.name))
                    }));
                };
                throw new Error("Failed pattern match at SubprojectsTree (line 191, column 19 - line 194, column 143): " + [ v1.value0.constructor.name ]);
            })()
        });
        var deletePromptModalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                    return Data_Maybe.Nothing.value;
                })),
                text: "Peruuta",
                testId: "cancel-delete-subproject-button"
            }), TacoButton.component()()({
                onClick: (function () {
                    if (v1.value0 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v1.value0 instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just(dispatch(new Actions.SubprojectActionRequest({
                            handler: function (v2) {
                                return v1.value1(function (v3) {
                                    return Data_Maybe.Nothing.value;
                                });
                            },
                            projectId: props.projectId,
                            params: new Types_Project.DeleteSubproject({
                                subprojectId: v1.value0.value0.subproject.id
                            })
                        })));
                    };
                    throw new Error("Failed pattern match at SubprojectsTree (line 205, column 26 - line 214, column 26): " + [ v1.value0.constructor.name ]);
                })(),
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                testId: "submit-delete-subproject-button"
            }) ]
        });
        var deletePromptModal = TacoModal.component()()({
            onClose: v1.value1(function (v2) {
                return Data_Maybe.Nothing.value;
            }),
            isActive: Data_Maybe.isJust(v1.value0),
            heading: "Poista alakohde",
            contents: [ deletePromptModalBody, deletePromptModalActions ]
        });
        var modalBody = TacoModal.modalBody({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("__TacoTable")([ Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "table-rows",
                css: Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]),
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ newHeader ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(subprojects)([ newSubproject ]))
            }), deletePromptModal ]))
        });
        return TacoModal.component()()({
            onClose: props.onClose,
            isActive: props.isActive,
            heading: "Muokkaa alakohteita",
            contents: [ modalBody, modalActions ]
        });
    };
});
var prepareSubprojectsData = function (subprojects) {
    return function (cwrs) {
        return function (socialExpensePercentagesByCostClass) {
            var prepareSubprojectData = function (subproject) {
                var socialExpenseCost = function (v) {
                    return CostEstimation_Calculations.componentSocialExpensePrice(socialExpensePercentagesByCostClass)(v.component)(v.resources);
                };
                var componentTotal = function (v) {
                    return CostEstimation_Calculations.componentTotalPrice(socialExpensePercentagesByCostClass)(v.component)(v.resources);
                };
                var componentSubprojectId = function (v) {
                    return v.component.subProjectId;
                };
                var componentsForSubproject = Data_Array.filter((function () {
                    var $83 = Utils.foldNullable(false)(Data_Eq.eq(Types_Project.eqSubprojectId)(subproject.id));
                    return function ($84) {
                        return $83(componentSubprojectId($84));
                    };
                })())(cwrs);
                var socialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(socialExpenseCost)(componentsForSubproject));
                var total = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(componentTotal)(componentsForSubproject));
                return {
                    subprojectCode: subproject.code,
                    subprojectName: Utils.fromNullable("")(subproject.name),
                    total: total,
                    socialExpensePrice: socialExpensePrice
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(prepareSubprojectData)(subprojects);
        };
    };
};
var subprojectsTree = TofuHooks.mkMemoHookComponent("SubprojectsTree")(function (props) {
    return function __do() {
        var subprojectsData = ReactHooksUtils.usePersistMemo("SubprojectsTree_subprojectsData")([ ReactHooksUtils.utf(props.computedComponentWithResources), ReactHooksUtils.utf(props.subprojects), ReactHooksUtils.utf(props.socialExpensePercentagesByCostClassWithHash.hash), ReactHooksUtils.utf(props.unfilteredSocialExpensePercentagesByCostClass.hash) ])(function (v) {
            var unfilteredSEPSubprojectsData = prepareSubprojectsData(props.subprojects)(props.computedComponentWithResources)(props.unfilteredSocialExpensePercentagesByCostClass.value);
            var subprojectsData = prepareSubprojectsData(props.subprojects)(props.computedComponentWithResources)(props.socialExpensePercentagesByCostClassWithHash.value);
            return {
                subprojectsData: subprojectsData,
                unfilteredSEPSubprojectsData: unfilteredSEPSubprojectsData
            };
        })();
        var v = ReactHooksUtils.usePersistMemo("subprojects-tree-non-empty-subprojects")([ ReactHooksUtils.utf(props.estimationElements), ReactHooksUtils.utf(props.estimationComponents) ])(function (v) {
            var v1 = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordSubprojectId)(Data_Array.mapMaybe((function () {
                var $85 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
                return function ($86) {
                    return Data_Nullable.toMaybe((function (v2) {
                        return v2.subprojectId;
                    })($85($86)));
                };
            })())(props.estimationElements));
            var v2 = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordSubprojectId)(Data_Array.mapMaybe(function ($87) {
                return Data_Nullable.toMaybe((function (v3) {
                    return v3.subProjectId;
                })($87));
            })(props.estimationComponents));
            return Data_Semigroup.append(Data_Set.semigroupSet(Types_Project.ordSubprojectId))(v1)(v2);
        })();
        var v1 = TofuHooks.useState(false)();
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var prepareItem = TofuHooks.useMemo([ ReactHooksUtils.utf(props.toggleSubprojectFilter) ])(function (v2) {
            return function (sp) {
                return {
                    title: "",
                    titleJSX: Data_Maybe.Just.create(React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(sp.subprojectCode + ("  " + sp.subprojectName)) ])),
                    id: sp.subprojectCode,
                    value: TacoList.TextValue.create(Formatters.formatDecimalValue(2)(sp.total) + (" " + currencySymbol)),
                    onClick: new Data_Maybe.Just(props.toggleSubprojectFilter(sp.subprojectCode)),
                    initExpanded: false,
                    disabled: sp.total === 0.0,
                    getItems: function (v3) {
                        return [  ];
                    },
                    hasItems: false
                };
            };
        })();
        var subprojectItems = TofuHooks.useMemo([ ReactHooksUtils.utf(prepareItem), ReactHooksUtils.utf(subprojectsData) ])(function (v2) {
            var totalSocialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                return v3.socialExpensePrice;
            })(subprojectsData.unfilteredSEPSubprojectsData));
            var subprojects1 = Data_Functor.map(Data_Functor.functorArray)(prepareItem)(subprojectsData.subprojectsData);
            var socialExpenseText = function (text) {
                return TacoText.component()()({
                    text: text,
                    color: TacoConstants.GrayDark.value,
                    testId: "subprojects-total-social-expense-price"
                });
            };
            var socialExpenseRowItem = {
                title: "Sosiaalikulut (ei mukana summissa)",
                titleJSX: Data_Maybe.Just.create(socialExpenseText("Sosiaalikulut (ei mukana summissa)")),
                value: TacoList.JSXValue.create(socialExpenseText(Formatters.formatCurrencyValue(props.projectCurrency)(totalSocialExpensePrice))),
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                getItems: function (v3) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false,
                id: "Sosiaalikulut (ei mukana summissa)"
            };
            var socialExpenseRow = (function () {
                if (props.showSocialExpensesInCosts) {
                    return Common.emptyArray;
                };
                return [ socialExpenseRowItem ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(subprojects1)(socialExpenseRow);
        })();
        return ReactBasicUtils["div'"]("__SubprojectsTree")([ Gap.gapX(TacoConstants.M.value)([ React_Basic_DOM_Generated.h3()({
            className: "heading",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Alakohteet"))
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa",
            onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                return true;
            })),
            testId: "edit-subprojects-button"
        }) ]), TacoList.component({
            items: subprojectItems
        }), subprojectsTreeModal({
            isActive: v1.value0,
            onClose: v1.value1(function (v2) {
                return false;
            }),
            subprojects: props.subprojects,
            projectId: props.projectId,
            nonEmptySubprojects: v
        }) ]);
    };
});
module.exports = {
    subprojectsTree: subprojectsTree
};
