// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var $$Math = require("../Math/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var RemoteDataUtils = require("../RemoteDataUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ByOldName = (function () {
    function ByOldName() {

    };
    ByOldName.value = new ByOldName();
    return ByOldName;
})();
var ByNewName = (function () {
    function ByNewName() {

    };
    ByNewName.value = new ByNewName();
    return ByNewName;
})();
var ByOldPrice = (function () {
    function ByOldPrice() {

    };
    ByOldPrice.value = new ByOldPrice();
    return ByOldPrice;
})();
var ByNewPrice = (function () {
    function ByNewPrice() {

    };
    ByNewPrice.value = new ByNewPrice();
    return ByNewPrice;
})();
var ByChangePercentage = (function () {
    function ByChangePercentage() {

    };
    ByChangePercentage.value = new ByChangePercentage();
    return ByChangePercentage;
})();
var mkRowData = function (selectedResources) {
    return function (matchMap) {
        return function (resource) {
            var newRes = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.head)(Data_Map_Internal.lookup(Types_Component.ordEstimationResourceId)(resource.id)(matchMap));
            var newPrice = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return v.priceWithDiscount;
            })(newRes);
            var newName = Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Nullable.toMaybe)(function (v) {
                return v.name;
            }))(newRes));
            var change = (function () {
                if (newPrice instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(Types_Unit.PercentageOfHundred(((newPrice.value0 - resource.priceWithDiscount) / resource.priceWithDiscount) * 100.0));
                };
                return Data_Maybe.Nothing.value;
            })();
            return {
                resourceId: resource.id,
                name: resource.name,
                newName: newName,
                oldPrice: resource.priceWithDiscount,
                newPrice: newPrice,
                change: change,
                selected: Data_Set.member(Types_Component.ordEstimationResourceId)(resource.id)(selectedResources)
            };
        };
    };
};
var getSortingValue = function (sortKey) {
    if (sortKey instanceof ByOldName) {
        return function ($81) {
            return TacoTable_Types.StringValue.create((function (v) {
                return v.name;
            })($81));
        };
    };
    if (sortKey instanceof ByNewName) {
        var $82 = Data_Maybe.fromMaybe("");
        return function ($83) {
            return TacoTable_Types.StringValue.create($82((function (v) {
                return v.newName;
            })($83)));
        };
    };
    if (sortKey instanceof ByOldPrice) {
        return function ($84) {
            return TacoTable_Types.NumberValue.create((function (v) {
                return v.oldPrice;
            })($84));
        };
    };
    if (sortKey instanceof ByNewPrice) {
        var $85 = Data_Maybe.fromMaybe(0.0);
        return function ($86) {
            return TacoTable_Types.NumberValue.create($85((function (v) {
                return v.newPrice;
            })($86)));
        };
    };
    if (sortKey instanceof ByChangePercentage) {
        var $87 = Data_Newtype.unwrap();
        var $88 = Data_Maybe.fromMaybe(0.0);
        return function ($89) {
            return TacoTable_Types.NumberValue.create($87($88((function (v) {
                return v.change;
            })($89))));
        };
    };
    throw new Error("Failed pattern match at CostEstimation.ResourcesView.MatchResourcesModal (line 248, column 3 - line 253, column 102): " + [ sortKey.constructor.name ]);
};
var getNameSortProperty = {
    getName: function (v) {
        return "UpdateResourcesTableSortProperty";
    }
};
var eqSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByOldName && y instanceof ByOldName) {
                return true;
            };
            if (x instanceof ByNewName && y instanceof ByNewName) {
                return true;
            };
            if (x instanceof ByOldPrice && y instanceof ByOldPrice) {
                return true;
            };
            if (x instanceof ByNewPrice && y instanceof ByNewPrice) {
                return true;
            };
            if (x instanceof ByChangePercentage && y instanceof ByChangePercentage) {
                return true;
            };
            return false;
        };
    }
};
var ordSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByOldName && y instanceof ByOldName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByOldName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByOldName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByNewName && y instanceof ByNewName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByNewName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByNewName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByOldPrice && y instanceof ByOldPrice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByOldPrice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByOldPrice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByNewPrice && y instanceof ByNewPrice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByNewPrice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByNewPrice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByChangePercentage && y instanceof ByChangePercentage) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView.MatchResourcesModal (line 242, column 1 - line 242, column 52): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqSortProperty;
    }
};
var resourceTable = TacoTable.tableOptimized(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "selected";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "resourceId";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "oldPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "newPrice";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "newName";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "change";
    }
})(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfHundred))));
var columns = function (setSelectedResources) {
    var selectionCheckbox = function (v) {
        return TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: v.selected,
            onToggle: (function () {
                if (v.newName instanceof Data_Maybe.Nothing) {
                    return new TacoCheckbox.Disabled("Hinnastosta ei l\xf6ydety vastaavaa panosta");
                };
                return TacoCheckbox.NoEvent.create(setSelectedResources(Data_Set.toggle(Types_Component.ordEstimationResourceId)(v.resourceId)));
            })(),
            labelText: Data_Maybe.Nothing.value
        });
    };
    var percentageJSX = function (v) {
        var color = (function () {
            if (v.change instanceof Data_Maybe.Nothing) {
                return TacoConstants.Black.value;
            };
            if (v.change instanceof Data_Maybe.Just) {
                var $47 = $$Math.abs(Data_Newtype.unwrap()(v.change.value0)) > 20.0;
                if ($47) {
                    return TacoConstants.Red.value;
                };
                return TacoConstants.Black.value;
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView.MatchResourcesModal (line 216, column 17 - line 218, column 65): " + [ v.change.constructor.name ]);
        })();
        var children = [ React_Basic_DOM.text(Data_Maybe.fromMaybe("-")(Data_Functor.map(Data_Maybe.functorMaybe)(Formatters.formatPercentageOfHundredAsStringWithPercent)(v.change))) ];
        return TacoText.component()()({
            children: children,
            color: color
        });
    };
    var mkNewPriceCell = new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v) {
        if (v.newPrice instanceof Data_Maybe.Nothing) {
            return React_Basic_DOM.text("-");
        };
        if (v.newPrice instanceof Data_Maybe.Just) {
            return React_Basic_DOM.text(Formatters.formatCurrencyValue("EUR")(v.newPrice.value0));
        };
        throw new Error("Failed pattern match at CostEstimation.ResourcesView.MatchResourcesModal (line 202, column 7 - line 204, column 71): " + [ v.newPrice.constructor.name ]);
    });
    var mkColumn = function (v) {
        return {
            key: v.key,
            label: v.label,
            sortProperty: v.sortProperty,
            width: v.width,
            cell: v.cell,
            headerJSX: Data_Maybe.Nothing.value
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(mkColumn)([ {
        key: "matchSelected",
        sortProperty: Data_Maybe.Nothing.value,
        width: new TacoTable_Types.Fixed("2rem"),
        label: React_Basic_DOM.text(""),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], selectionCheckbox)
    }, {
        key: "name",
        sortProperty: new Data_Maybe.Just(ByOldName.value),
        label: React_Basic_DOM.text("Nimi"),
        width: new TacoTable_Types.Flex(7),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.name;
        })
    }, {
        key: "newName",
        sortProperty: new Data_Maybe.Just(ByNewName.value),
        label: React_Basic_DOM.text("Uusi nimi"),
        width: new TacoTable_Types.Flex(7),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $90 = Data_Maybe.fromMaybe("-");
            return function ($91) {
                return $90((function (v) {
                    return v.newName;
                })($91));
            };
        })())
    }, {
        key: "oldPrice",
        sortProperty: new Data_Maybe.Just(ByOldPrice.value),
        label: React_Basic_DOM.text("Vanha hinta (sis. ale)"),
        width: new TacoTable_Types.Flex(2),
        cell: new TacoTable_Types.PlainEuroCell(function (v) {
            return v.oldPrice;
        })
    }, {
        key: "newPrice",
        sortProperty: new Data_Maybe.Just(ByNewPrice.value),
        label: React_Basic_DOM.text("Uusi hinta (sis. ale)"),
        width: new TacoTable_Types.Flex(2),
        cell: mkNewPriceCell
    }, {
        key: "priceChange%",
        sortProperty: new Data_Maybe.Just(ByChangePercentage.value),
        label: React_Basic_DOM.text("Muutos%"),
        width: new TacoTable_Types.Flex(3),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], percentageJSX)
    } ]);
};
var mkRow = function (setSelectedResources) {
    return function (v) {
        return function (rowData) {
            return {
                className: "select-resource-row",
                rowData: rowData,
                foldingElement: Data_Maybe.Nothing.value,
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                rowKey: Data_Show.show(Types_Component.showEstimationResourceId)(rowData.resourceId),
                columns: columns(setSelectedResources)
            };
        };
    };
};
var component = TofuHooks.mkHookComponent("CostEstimation.ResourcesView.MatchResourcesModal")(function (v) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var tableRef = UseNullableRef.useNullableRef();
        var matchedPrices$prime = StateHooks.useSelector(function (v1) {
            return v1.estimationResourcePriceMatches;
        })();
        var v1 = TofuHooks.useState(TacoTable_Types.Ascending.value)();
        var v2 = TofuHooks.useState(ByOldName.value)();
        var setSortingProperty = function (newProp) {
            var $63 = Data_Eq.eq(eqSortProperty)(v2.value0)(newProp);
            if ($63) {
                return v1.value1(TacoTable_Types.reverse);
            };
            return v2.value1(function (v3) {
                return newProp;
            });
        };
        var mkMatchTuple = function (match) {
            return new Data_Tuple.Tuple(match.resourceId, match.matchingPrices);
        };
        var matchedPrices = (function () {
            if (matchedPrices$prime instanceof RemoteDataUtils.Fetched) {
                return matchedPrices$prime.value0;
            };
            return [  ];
        })();
        var matchMap = Data_Map_Internal.fromFoldable(Types_Component.ordEstimationResourceId)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(mkMatchTuple)(matchedPrices));
        var v3 = TofuHooks.useState(Data_Set.empty)();
        TofuHooks.useEffect([ matchedPrices$prime ])(function __do() {
            (function () {
                if (matchedPrices$prime instanceof RemoteDataUtils.Fetched) {
                    var resourcesWithMatchedPrices = Data_Functor.map(Data_Functor.functorArray)(function (v4) {
                        return v4.resourceId;
                    })(Data_Array.filter((function () {
                        var $92 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"]);
                        return function ($93) {
                            return $92((function (v4) {
                                return v4.matchingPrices;
                            })($93));
                        };
                    })())(matchedPrices$prime.value0));
                    Data_Unit.unit;
                    return v3.value1(function (v4) {
                        return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordEstimationResourceId)(resourcesWithMatchedPrices);
                    })();
                };
                return Data_Unit.unit;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var mkMatchParam = function (mergedResource) {
            var updateSourcePriceId$prime = Data_Functor.map(Data_Maybe.functorMaybe)(function (v4) {
                return v4.orgResourcePriceId;
            })(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.head)(Data_Map_Internal.lookup(Types_Component.ordEstimationResourceId)(mergedResource.id)(matchMap)));
            var estimationResourceIds = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v4) {
                return v4.id;
            })(Data_Tuple.fst))(Data_Array_NonEmpty.toArray(mergedResource.estimationResources));
            return Control_Bind.bind(Control_Bind.bindArray)(estimationResourceIds)(function (resourceId) {
                if (updateSourcePriceId$prime instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)({
                        resourceId: resourceId,
                        updateSourcePriceId: updateSourcePriceId$prime.value0
                    });
                };
                return [  ];
            });
        };
        var updateResourcesButton = TacoButton.component()()({
            onClick: Data_Maybe.Just.create((function () {
                var selectedMergedResources = Data_Set.filter(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.ordTaxrate))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "key";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.ordEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "estimationResources";
                    }
                })(Data_Array_NonEmpty_Internal.ordNonEmptyArray(Data_Tuple.ordTuple(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.ordTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(Data_Ord.ordBoolean))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.ordEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Data_Nullable.ordNullable(Types_Component.ordComponentId)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString))))(Data_Maybe.ordMaybe(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(Data_Nullable.ordNullable(Types_Project.ordSubprojectId)))()({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.ordComponentId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(Data_Ord.ordBoolean))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(Data_Ord.ordInt))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(Data_Ord.ordBoolean))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Ord.ordNumber)))))))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString))))(function (resource) {
                    return Data_Set.member(Types_Component.ordEstimationResourceId)(resource.id)(v3.value0);
                })(v.estimationResources);
                var updateSelections = Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(mkMatchParam)(Data_Array.fromFoldable(Data_Set.foldableSet)(selectedMergedResources)));
                var updateParams = {
                    projectId: v.projectId,
                    updateSelections: updateSelections
                };
                return function __do() {
                    dispatch(new Actions.EstimationResourcePriceUpdateActionRequest(updateParams))();
                    return v.closeModal();
                };
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "P\xe4ivit\xe4 panokset",
            disabled: false
        });
        var cancelButton = TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v.closeModal),
            text: "Peruuta"
        });
        var withOrder = (function () {
            var $71 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(v1.value0)(TacoTable_Types.Descending.value);
            if ($71) {
                return Data_Function.flip;
            };
            return Control_Category.identity(Control_Category.categoryFn);
        })();
        var rows = Data_Functor.map(Data_Functor.functorArray)(mkRowData(v3.value0)(matchMap))(Data_Array.fromFoldable(Data_Set.foldableSet)(v.estimationResources));
        var orderRows = function (sortProp) {
            return function (a) {
                return function (b) {
                    return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(getSortingValue(sortProp)(a))(getSortingValue(sortProp)(b));
                };
            };
        };
        var sortedRows = Data_Array.sortBy(orderRows(v2.value0))(rows);
        var table = resourceTable({
            sortProperty: new Data_Maybe.Just(v2.value0),
            onSort: setSortingProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: sortedRows,
            mkRow: mkRow(v3.value1),
            rowHeight: TacoTable_Types.RowSmall.value,
            containerRef: tableRef,
            showHeader: true,
            columns: columns(v3.value1),
            styleSet: TacoTable_Styles.optimizedTableStyleSet,
            initialScrollRow: Data_Maybe.Nothing.value
        });
        return TacoModal.component()()({
            onClose: v.closeModal,
            isActive: true,
            heading: "P\xe4ivit\xe4 panokset",
            contents: [ TacoModal.modalBody({
                contents: [ table ]
            }), TacoModal.modalActions({
                contents: [ cancelButton, updateResourcesButton ]
            }) ],
            containerStyles: [ Box.MaxWidth.create(new TacoConstants.CustomSize("90%")) ]
        });
    };
});
module.exports = {
    component: component
};
