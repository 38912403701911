// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_BuildingElementsControls = require("../CostEstimation.BuildingElementsControls/index.js");
var CostEstimation_BuildingElementsView = require("../CostEstimation.BuildingElementsView/index.js");
var CostEstimation_BuildingElementsView_ElementDetailsContainer = require("../CostEstimation.BuildingElementsView.ElementDetailsContainer/index.js");
var CostEstimation_BuildingElementsView_Table = require("../CostEstimation.BuildingElementsView.Table/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var ElementUtils = require("../ElementUtils/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseRefProjects = require("../UseRefProjects/index.js");
var UseSelectedComponentScrollPosition = require("../UseSelectedComponentScrollPosition/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var UseWorkAchievement = require("../UseWorkAchievement/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var referenceModeFollowOptionsByChoices = [ {
    text: "Luokka",
    value: "code"
}, {
    text: "Koodi",
    value: "rtCode"
} ];
var handleAddComponent = function (appDispatch) {
    return function (v) {
        var v4 = Data_Array.head(v.subprojects);
        if (v.selectedEditViewElement instanceof Data_Maybe.Just && (v.selectedDetailsComponent instanceof Data_Maybe.Just && (v.selectedElement instanceof Data_Maybe.Just && v.selectedEditViewElementMeasures instanceof Data_Maybe.Just))) {
            var targetElementId = (function (v6) {
                return v6.elementId;
            })(Data_Newtype.unwrap()((function (v6) {
                return v6.element;
            })(Data_Newtype.unwrap()(v.selectedEditViewElement.value0))));
            var subproject = Data_Array.head(Data_Array.filter(function (subproj) {
                return subproj.code === (function (v6) {
                    return v6.subprojectCode;
                })(Data_Newtype.unwrap()(v.selectedEditViewElement.value0));
            })(v.subprojects));
            var specIds = (function () {
                var $144 = Data_Set.isEmpty(v.checkedSpecs);
                if ($144) {
                    return Data_Functor.map(Data_Functor.functorArray)(function (v6) {
                        return v6.elementSpecId;
                    })((function (v6) {
                        return v6.specs;
                    })(Data_Newtype.unwrap()(v.selectedElement.value0)));
                };
                return Data_Array.fromFoldable(Data_Set.foldableSet)(v.checkedSpecs);
            })();
            var sourceElementId = (function (v6) {
                return v6.elementId;
            })(Data_Newtype.unwrap()((function (v6) {
                return v6.element;
            })(Data_Newtype.unwrap()(v.selectedElement.value0))));
            var measureConflicts = ElementUtils.conflictingMeasures(new Data_Maybe.Just(v.selectedElement.value0))(v.elementMeasures)(new Data_Maybe.Just(targetElementId))(v.selectedEditViewElementMeasures.value0);
            if (subproject instanceof Data_Maybe.Just) {
                return appDispatch(new Actions.EstimationElementActionRequest({
                    projectId: v.projectId,
                    params: new Types_Estimation.CopySelectedElements({
                        estimationElementIds: [ sourceElementId ],
                        targetProjectId: v.projectId,
                        targetSubprojectId: subproject.value0.id,
                        copyOperationType: Types_Estimation.UpdateExistingElement.create(new Types_Estimation.ElementUpdateAddElementSpecs({
                            copyResources: v.copyResources,
                            elementSpecIds: specIds,
                            noCopyingForMeasures: measureConflicts,
                            targetElementId: targetElementId
                        }))
                    }),
                    handler: function (v6) {
                        return v.setCheckedSpecs(function (v7) {
                            return Data_Set.empty;
                        });
                    }
                }));
            };
            if (subproject instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 192, column 11 - line 209, column 33): " + [ subproject.constructor.name ]);
        };
        if (v.selectedEditViewElement instanceof Data_Maybe.Nothing && (v4 instanceof Data_Maybe.Just && (v.selectedDetailsComponent instanceof Data_Maybe.Just && v.selectedElement instanceof Data_Maybe.Just))) {
            var copyOperationType = (function () {
                if (v.copyResources) {
                    return Types_Estimation.CopyComponentWithResources.value;
                };
                return Types_Estimation.CopyComponentOnly.value;
            })();
            var componentSelected = function (selectedSpecs) {
                return function (v6) {
                    return !Data_Set.isEmpty(Data_Set.filter(Types_Newtypes.ordElementSpecId)(Data_Eq.eq(Types_Newtypes.eqElementSpecId)(v6.elementSpecId))(selectedSpecs));
                };
            };
            var allSpecs = (function (v6) {
                return v6.specs;
            })(Data_Newtype.unwrap()(v.selectedElement.value0));
            var selectedElementSpecs = (function () {
                var $155 = Data_Set.isEmpty(v.checkedSpecs);
                if ($155) {
                    return allSpecs;
                };
                return Data_Array.filter(componentSelected(v.checkedSpecs))(allSpecs);
            })();
            var componentIds = Data_Array.foldl(function (acc) {
                return function (componentId) {
                    var v6 = Data_Nullable.toMaybe(componentId);
                    if (v6 instanceof Data_Maybe.Just) {
                        return Data_Array.insert(Types_Component.ordComponentId)(v6.value0)(acc);
                    };
                    if (v6 instanceof Data_Maybe.Nothing) {
                        return acc;
                    };
                    throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 221, column 15 - line 223, column 31): " + [ v6.constructor.name ]);
                };
            })([  ])(Data_Functor.map(Data_Functor.functorArray)(function (v6) {
                return v6.componentId;
            })(selectedElementSpecs));
            return appDispatch(new Actions.EstimationComponentActionRequest({
                projectId: v.projectId,
                params: new Types_Estimation.CopyEstimationComponentsToProject({
                    targetProjectId: v.projectId,
                    targetSubprojectId: v4.value0.id,
                    estimationComponentIds: componentIds,
                    copyOperationType: copyOperationType,
                    allowDuplicates: false
                }),
                handler: function (v6) {
                    return v.setCheckedSpecs(function (v7) {
                        return Data_Set.empty;
                    });
                }
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
var findMatchingElementIndex = function (key) {
    return function (viewElement) {
        return function (elements) {
            var unEE = Data_Newtype.un()(Types_Element.WasmEstimationElement);
            var extract = (function () {
                if (key === "Koodi") {
                    return function ($355) {
                        return (function (v) {
                            return v.rtCode;
                        })(unEE(CostEstimation_BuildingElementsView_Types["_element"]($355)));
                    };
                };
                var $356 = Data_Newtype.unwrap();
                return function ($357) {
                    return $356(CostEstimation_BuildingElementsView_Types["_elementCode"]($357));
                };
            })();
            var matchCode = extract(viewElement);
            var getScore = function (i) {
                return function (str) {
                    var score = Utils.scoreMatch(matchCode)(str);
                    var $173 = score > 0;
                    if ($173) {
                        return new Data_Maybe.Just({
                            i: i,
                            score: score
                        });
                    };
                    return Data_Maybe.Nothing.value;
                };
            };
            var elementsCodes = Data_Functor.map(Data_Functor.functorArray)(extract)(elements);
            var exactMatch = Data_Array.elemIndex(Data_Eq.eqString)(matchCode)(elementsCodes);
            var closeMatch = (function () {
                var scores = Data_Array.catMaybes(Data_Array.mapWithIndex(getScore)(elementsCodes));
                var sortedScores = Data_Array.sortWith(Data_Ord.ordInt)(function (v) {
                    return v.score;
                })(scores);
                var grouped = Data_Array.groupBy(function (x) {
                    return function (y) {
                        return x.score === y.score;
                    };
                })(sortedScores);
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.last(grouped))(function (nea) {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((Data_Array_NonEmpty.head(nea)).i);
                });
            })();
            return Control_Alt.alt(Data_Maybe.altMaybe)(exactMatch)(closeMatch);
        };
    };
};
var elementCopyOperationAction = function (elementIds) {
    return function (targetProjectId) {
        return function (targetSubprojectId) {
            return function (copyOperationType) {
                return function (setCheckedSpecs) {
                    return new Actions.EstimationElementActionRequest({
                        projectId: targetProjectId,
                        params: new Types_Estimation.CopySelectedElements({
                            estimationElementIds: elementIds,
                            targetProjectId: targetProjectId,
                            targetSubprojectId: targetSubprojectId,
                            copyOperationType: copyOperationType
                        }),
                        handler: function (v) {
                            return setCheckedSpecs(function (v1) {
                                return Data_Set.empty;
                            });
                        }
                    });
                };
            };
        };
    };
};
var handleAddElement = function (appDispatch) {
    return function (addElementParams) {
        var v = Data_Array.head(addElementParams.subprojects);
        if (v instanceof Data_Maybe.Just) {
            var copyOperation = new Types_Estimation.CopyElementsToNewProject({
                addWildcardLocation: true,
                copyResources: addElementParams.copyResources
            });
            var checkedElementIds = Data_Array.fromFoldable(Data_Set.foldableSet)(addElementParams.checkedElements);
            var action = elementCopyOperationAction(checkedElementIds)(addElementParams.projectId)(v.value0.id)(copyOperation)(addElementParams.setCheckedElements);
            return appDispatch(action);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 167, column 3 - line 178, column 16): " + [ v.constructor.name ]);
    };
};
var handleElementUpdate = function (appDispatch) {
    return function (elementUpdateParams) {
        return function (mkOperation) {
            return function __do() {
                (function () {
                    var v1 = Data_Array.head(elementUpdateParams.subprojects);
                    if (elementUpdateParams.selectedEditViewElement instanceof Data_Maybe.Just && (v1 instanceof Data_Maybe.Just && elementUpdateParams.selectedElement instanceof Data_Maybe.Just)) {
                        var targetElementId = (function (v3) {
                            return v3.elementId;
                        })(Data_Newtype.unwrap()((function (v3) {
                            return v3.element;
                        })(Data_Newtype.unwrap()(elementUpdateParams.selectedEditViewElement.value0))));
                        var sourceElementId = (function (v3) {
                            return v3.elementId;
                        })(Data_Newtype.unwrap()((function (v3) {
                            return v3.element;
                        })(Data_Newtype.unwrap()(elementUpdateParams.selectedElement.value0))));
                        var elementSpecIds = (function () {
                            var $186 = Data_Set.isEmpty(elementUpdateParams.checkedSpecs);
                            if ($186) {
                                return Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.elementSpecId;
                                })((function (v3) {
                                    return v3.specs;
                                })(Data_Newtype.unwrap()(elementUpdateParams.selectedElement.value0)));
                            };
                            return Data_Array.fromFoldable(Data_Set.foldableSet)(elementUpdateParams.checkedSpecs);
                        })();
                        var copyOperation = Types_Estimation.UpdateExistingElement.create(mkOperation(targetElementId)(elementSpecIds));
                        var action = elementCopyOperationAction([ sourceElementId ])(elementUpdateParams.projectId)(v1.value0.id)(copyOperation)(elementUpdateParams.setCheckedElements);
                        return appDispatch(action)();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Unit.unit;
            };
        };
    };
};
var handleReplaceElement = function (appDispatch) {
    return function (replaceElementParams) {
        var mkReplaceElementOperation = function (targetElementId) {
            return function (elementSpecIds) {
                return new Types_Estimation.ElementUpdateReplaceElement({
                    targetElementId: targetElementId,
                    elementSpecIds: elementSpecIds
                });
            };
        };
        return handleElementUpdate(appDispatch)(replaceElementParams)(mkReplaceElementOperation);
    };
};
var handleReplaceElementSpecs = function (appDispatch) {
    return function (replaceElementSpecsParams) {
        if (replaceElementSpecsParams.selectedEditViewElementMeasures instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        if (replaceElementSpecsParams.selectedEditViewElementMeasures instanceof Data_Maybe.Just) {
            var mkReplaceElementOperation = function (targetElementId) {
                return function (elementSpecIds) {
                    var measureConflicts = ElementUtils.conflictingMeasures(replaceElementSpecsParams.selectedElement)(replaceElementSpecsParams.elementMeasures)(new Data_Maybe.Just(targetElementId))(replaceElementSpecsParams.selectedEditViewElementMeasures.value0);
                    return new Types_Estimation.ElementUpdateReplaceElementSpecs({
                        copyResources: true,
                        noCopyingForMeasures: measureConflicts,
                        targetElementId: targetElementId,
                        elementSpecIds: elementSpecIds
                    });
                };
            };
            return handleElementUpdate(appDispatch)(replaceElementSpecsParams)(mkReplaceElementOperation);
        };
        throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 153, column 3 - line 161, column 93): " + [ replaceElementSpecsParams.selectedEditViewElementMeasures.constructor.name ]);
    };
};
var component = TofuHooks.mkMemoHookComponent("ElementsReferenceView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var mRefPanelMode = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.panelOptions)(function (v) {
            return CostEstimationLayout_Types.ReferenceElementsPanel.value;
        });
        var isPanel = Data_Maybe.isJust(mRefPanelMode);
        var v = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-follow-edit-element-on")(false)(false)();
        var v1 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readString)("view-controls-follow-edit-element-by-criteria")("code")(false)();
        var v2 = Client_WASM.useReferenceEstimationElements(props.projectId)();
        var v3 = Client_WASM.useReferenceEstimationComponents(props.projectId)();
        var v4 = Client_WASM.useReferenceEstimationResources(props.projectId)();
        var v5 = Client_WASM.useEstimationProjects();
        var v6 = Client_WASM.useSubprojects(props.projectId)();
        var v7 = Client_WASM.useProjectLocations(props.projectId)();
        var v8 = UseRefProjects.useRefProjects(props.projectId)();
        var v9 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v10 = UseWorkAchievement.useWorkAchievement(props.projectId)();
        var v11 = Client_WASM.useReferenceElementsData(props.projectId)();
        var v12 = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var initialSelectedElementIdx = (function () {
            if (props.initialSelectedElement instanceof Data_Maybe.Nothing && v12.value0.selectedElementIdx instanceof Data_Maybe.Just) {
                return Edited.Edited.create(new Data_Maybe.Just(v12.value0.selectedElementIdx.value0));
            };
            return Edited.Unedited.value;
        })();
        var v13 = TofuHooks.useState({
            sortingCriteria: new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(CostEstimation_BuildingElementsView_Table.ByCode.value), CostEstimation_BuildingElementsView_Table.getSortingValue),
            checkedElements: Data_Set.empty,
            lastCheckedItem: Data_Maybe.Nothing.value,
            checkedSpecs: Data_Set.empty,
            selectedResourceIds: Data_Set.empty,
            detailsElementIdx: initialSelectedElementIdx,
            shouldScrollToSelection: true,
            referenceActiveFilters: v12.value0.referenceElementFilters
        })();
        var v14 = TofuHooks.useState("")();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(v14.value0) ])(500)(function __do() {
            v13.value1(function (s) {
                var notSearch = function (x) {
                    if (x instanceof CostEstimation_Filters.FilterByElementsDataSearch) {
                        return false;
                    };
                    return true;
                };
                var removedSearch = Data_Array.filter(notSearch)(s.referenceActiveFilters);
                var addedSearch = (function () {
                    var $220 = Data_String_Common["null"](v14.value0);
                    if ($220) {
                        return removedSearch;
                    };
                    return Data_Array.snoc(removedSearch)(new CostEstimation_Filters.FilterByElementsDataSearch(v14.value0));
                })();
                return {
                    sortingCriteria: s.sortingCriteria,
                    checkedElements: s.checkedElements,
                    lastCheckedItem: s.lastCheckedItem,
                    checkedSpecs: s.checkedSpecs,
                    selectedResourceIds: s.selectedResourceIds,
                    detailsElementIdx: s.detailsElementIdx,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    referenceActiveFilters: addedSearch
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        Client_WASM.useSetActiveRefElementFilters(v13.value0.referenceActiveFilters)();
        var setActiveElementsFilters = TofuHooks.useCallback([ ReactHooksUtils.utf(Types_CostEstimation.ReferenceMode.value) ])(function (filters) {
            return function __do() {
                v12.value1(function (v15) {
                    return {
                        project: v15.project,
                        elementFilters: v15.elementFilters,
                        elementGroupProperty: v15.elementGroupProperty,
                        selectedElementIdx: v15.selectedElementIdx,
                        referenceElementFilters: filters,
                        selectedReferenceElementIdx: v15.selectedReferenceElementIdx,
                        componentFilters: v15.componentFilters,
                        componentGroupProperty: v15.componentGroupProperty,
                        selectedComponentIdx: v15.selectedComponentIdx,
                        referenceComponentFilters: v15.referenceComponentFilters,
                        selectedReferenceComponentIdx: v15.selectedReferenceComponentIdx,
                        referenceResourceFilters: v15.referenceResourceFilters,
                        selectedResourceId: v15.selectedResourceId
                    };
                })();
                return v13.value1(function (v15) {
                    return {
                        sortingCriteria: v15.sortingCriteria,
                        checkedElements: v15.checkedElements,
                        lastCheckedItem: v15.lastCheckedItem,
                        checkedSpecs: v15.checkedSpecs,
                        selectedResourceIds: v15.selectedResourceIds,
                        detailsElementIdx: v15.detailsElementIdx,
                        shouldScrollToSelection: v15.shouldScrollToSelection,
                        referenceActiveFilters: filters
                    };
                })();
            };
        })();
        var setRefProjectFilter = (function () {
            var isProjectFilter = function (filter) {
                if (filter instanceof CostEstimation_Filters.FilterByElementsDataProjectOfOrigin) {
                    return true;
                };
                return false;
            };
            var previousProjectFilter = Data_Array.find(isProjectFilter)(v13.value0.referenceActiveFilters);
            var prunedFilters = (function () {
                if (previousProjectFilter instanceof Data_Maybe.Just) {
                    return Data_Array["delete"](CostEstimation_Filters.eqElementsDataPropertyFilter)(previousProjectFilter.value0)(v13.value0.referenceActiveFilters);
                };
                if (previousProjectFilter instanceof Data_Maybe.Nothing) {
                    return v13.value0.referenceActiveFilters;
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 341, column 11 - line 343, column 45): " + [ previousProjectFilter.constructor.name ]);
            })();
            return setActiveElementsFilters(Data_Array.snoc(prunedFilters)(new CostEstimation_Filters.FilterByElementsDataProjectOfOrigin(v8.selectedRefProjects)));
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(Types_CostEstimation.ReferenceMode.value), ReactHooksUtils.utf(v8.selectedRefProjects), ReactHooksUtils.utf(setActiveElementsFilters), ReactHooksUtils.utf(v2.value0) ])(function __do() {
            setRefProjectFilter();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var setDetailsElementIdx$prime = TofuHooks.useCallback([ v13.value1 ])(function (shouldScrollToSelection) {
            return function (update) {
                return v13.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        selectedResourceIds: s.selectedResourceIds,
                        detailsElementIdx: update(s.detailsElementIdx),
                        shouldScrollToSelection: (function () {
                            if (s.detailsElementIdx instanceof Edited.Edited && s.detailsElementIdx.value0 instanceof Data_Maybe.Nothing) {
                                return true;
                            };
                            if (s.detailsElementIdx instanceof Edited.Unedited) {
                                return true;
                            };
                            return shouldScrollToSelection;
                        })(),
                        referenceActiveFilters: s.referenceActiveFilters
                    };
                });
            };
        })();
        var withClearCheckedSpecs = TofuHooks.useCallback([ v13.value1 ])(function (eff) {
            return Control_Apply.applyFirst(Effect.applyEffect)(eff)(v13.value1(function (v15) {
                return {
                    sortingCriteria: v15.sortingCriteria,
                    checkedElements: v15.checkedElements,
                    lastCheckedItem: Data_Maybe.Nothing.value,
                    checkedSpecs: Data_Set.empty,
                    selectedResourceIds: v15.selectedResourceIds,
                    detailsElementIdx: v15.detailsElementIdx,
                    shouldScrollToSelection: v15.shouldScrollToSelection,
                    referenceActiveFilters: v15.referenceActiveFilters
                };
            }));
        })();
        var toggleElementChecked = TofuHooks.useCallback([ v13.value1 ])(function (elementId) {
            return v13.value1(function (s) {
                return {
                    sortingCriteria: s.sortingCriteria,
                    checkedElements: Utils.toggleInSet(Types_Element.ordElementId)(elementId)(s.checkedElements),
                    lastCheckedItem: new Data_Maybe.Just(elementId),
                    checkedSpecs: s.checkedSpecs,
                    selectedResourceIds: s.selectedResourceIds,
                    detailsElementIdx: s.detailsElementIdx,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    referenceActiveFilters: s.referenceActiveFilters
                };
            });
        })();
        var components = ReactHooksUtils.usePersistMemo("ElementsReferenceView_mkComponentWithResources")([ ReactHooksUtils.utf(v3.value0), ReactHooksUtils.utf(v4.value0) ])(function (v15) {
            return CostEstimation_BuildingElementsView.mkComponentWithResources(v3.value0)(v4.value0);
        })();
        var v15 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("view-controls-show-project-details")(true)(false)();
        var v16 = Client_WASM.useFilteredRefElementIds(props.projectId)();
        var v17 = TofuHooks.useMemo([ ReactHooksUtils.utf(v16.value0) ])(function (v17) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(v16.value0);
        })();
        var elementsData = TofuHooks.useMemo([ ReactHooksUtils.utf(v13.value0.sortingCriteria), ReactHooksUtils.utf(v11.value0), ReactHooksUtils.utf(v17) ])(function (v18) {
            return CostEstimation_BuildingElementsView.sortElements(v13.value0.sortingCriteria)(Data_Array.filter(function (ed) {
                return Data_Set.member(Types_Element.ordElementId)((Data_Newtype.unwrap()((Data_Newtype.unwrap()(ed)).element)).elementId)(v17);
            })(v11.value0));
        })();
        var rows = TofuHooks.useMemo([ ReactHooksUtils.utf(v13.value0.checkedElements), ReactHooksUtils.utf(elementsData), ReactHooksUtils.utf(v15.value0) ])(function (v18) {
            return Data_Function.flip(Data_Array.mapWithIndex)(elementsData)(CostEstimation_BuildingElementsView.mkElementRow(v5.value0)(v13.value0.checkedElements)(v15.value0)(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value)(Data_Maybe.Nothing.value));
        })();
        var toggleAreaOfCheckboxes = TofuHooks.useCallback([ ReactHooksUtils.utf(v13.value1), ReactHooksUtils.utf(rows) ])(function (elementId) {
            return Control_Apply.applyFirst(Effect.applyEffect)(WindowUtils.selectionRemoveAllRanges)(v13.value1(function (s) {
                if (s.lastCheckedItem instanceof Data_Maybe.Just) {
                    var notTopicElement = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))((function () {
                        var $358 = Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData);
                        return function ($359) {
                            return (function (v18) {
                                return v18.isTopicRow;
                            })($358((function (v18) {
                                return v18.elementsData;
                            })($359)));
                        };
                    })());
                    var _elementId = function ($360) {
                        return CostEstimation_BuildingElementsView_Types["_elementId"]((function (v18) {
                            return v18.elementsData;
                        })($360));
                    };
                    var areaInIds = function (fstIdx) {
                        return function (sndIdx) {
                            return Data_Functor.map(Data_Functor.functorArray)(_elementId)(Data_Array.filter(notTopicElement)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Array.index(rows))(Data_Array.range(fstIdx)(sndIdx)))));
                        };
                    };
                    var idToIndex = function (id) {
                        return Data_Array.findIndex(function (row) {
                            return Data_Eq.eq(Types_Element.eqElementId)(id)(_elementId(row));
                        })(rows);
                    };
                    var v18 = idToIndex(s.lastCheckedItem.value0);
                    var v19 = idToIndex(elementId);
                    if (v19 instanceof Data_Maybe.Just && v18 instanceof Data_Maybe.Just) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: Data_Set.union(Types_Element.ordElementId)(s.checkedElements)(Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(areaInIds(v19.value0)(v18.value0))),
                            lastCheckedItem: new Data_Maybe.Just(elementId),
                            checkedSpecs: s.checkedSpecs,
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    };
                    return s;
                };
                if (s.lastCheckedItem instanceof Data_Maybe.Nothing) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: Utils.toggleInSet(Types_Element.ordElementId)(elementId)(s.checkedElements),
                        lastCheckedItem: new Data_Maybe.Just(elementId),
                        checkedSpecs: s.checkedSpecs,
                        selectedResourceIds: s.selectedResourceIds,
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceActiveFilters: s.referenceActiveFilters
                    };
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 395, column 5 - line 411, column 12): " + [ s.lastCheckedItem.constructor.name ]);
            }));
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v.value0), ReactHooksUtils.utf(props.panelOptions) ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v.value0)((function () {
                var mSelectedEditViewElement = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v18) {
                    return v18.selectedEditViewElement;
                })(props.panelOptions);
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mSelectedEditViewElement)(function (v18) {
                    return v13.value1(function (v19) {
                        return {
                            sortingCriteria: v19.sortingCriteria,
                            checkedElements: v19.checkedElements,
                            lastCheckedItem: v19.lastCheckedItem,
                            checkedSpecs: v19.checkedSpecs,
                            selectedResourceIds: v19.selectedResourceIds,
                            detailsElementIdx: Edited.Unedited.value,
                            shouldScrollToSelection: v19.shouldScrollToSelection,
                            referenceActiveFilters: v19.referenceActiveFilters
                        };
                    });
                });
            })())();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var selectedElementIdx = TofuHooks.useMemo([ ReactHooksUtils.utf(v13.value0.detailsElementIdx), ReactHooksUtils.utf(props.initialSelectedElement), ReactHooksUtils.utf(elementsData), ReactHooksUtils.utf(props.panelOptions), ReactHooksUtils.utf(v.value0) ])(function (v18) {
            var selectedEditViewElement = (function () {
                if (v.value0) {
                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v19) {
                        return v19.selectedEditViewElement;
                    })(props.panelOptions);
                };
                return Data_Maybe.Nothing.value;
            })();
            var bySelectedEVE = function (selectedEVE) {
                return findMatchingElementIndex(v1.value0)(selectedEVE)(elementsData);
            };
            if (v13.value0.detailsElementIdx instanceof Edited.Unedited && selectedEditViewElement instanceof Data_Maybe.Just) {
                return bySelectedEVE(selectedEditViewElement.value0);
            };
            if (v13.value0.detailsElementIdx instanceof Edited.Edited && (v13.value0.detailsElementIdx.value0 instanceof Data_Maybe.Nothing && selectedEditViewElement instanceof Data_Maybe.Just)) {
                return bySelectedEVE(selectedEditViewElement.value0);
            };
            if (v13.value0.detailsElementIdx instanceof Edited.Unedited && props.initialSelectedElement instanceof Data_Maybe.Just) {
                return Data_Array.elemIndex(Types_Element.eqElementId)(props.initialSelectedElement.value0)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_BuildingElementsView_Types["_elementId"])(elementsData));
            };
            if (v13.value0.detailsElementIdx instanceof Edited.Unedited) {
                return Data_Maybe.Nothing.value;
            };
            if (v13.value0.detailsElementIdx instanceof Edited.Edited) {
                return v13.value0.detailsElementIdx.value0;
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 435, column 10 - line 440, column 28): " + [ v13.value0.detailsElementIdx.constructor.name, props.initialSelectedElement.constructor.name, selectedEditViewElement.constructor.name ]);
        })();
        TofuHooks.useEffect([ selectedElementIdx ])(function __do() {
            v12.value1(function (v18) {
                return {
                    project: v18.project,
                    elementFilters: v18.elementFilters,
                    elementGroupProperty: v18.elementGroupProperty,
                    selectedElementIdx: v18.selectedElementIdx,
                    referenceElementFilters: v18.referenceElementFilters,
                    selectedReferenceElementIdx: selectedElementIdx,
                    componentFilters: v18.componentFilters,
                    componentGroupProperty: v18.componentGroupProperty,
                    selectedComponentIdx: v18.selectedComponentIdx,
                    referenceComponentFilters: v18.referenceComponentFilters,
                    selectedReferenceComponentIdx: v18.selectedReferenceComponentIdx,
                    referenceResourceFilters: v18.referenceResourceFilters,
                    selectedResourceId: v18.selectedResourceId
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectedElement = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElementIdx), ReactHooksUtils.utf(elementsData) ])(function (v18) {
            return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(elementsData))(selectedElementIdx);
        })();
        var detailsElementId = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElement) ])(function (v18) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(CostEstimation_BuildingElementsView_Types["_elementId"])(selectedElement);
        })();
        var detailsElementProjectId = TofuHooks.useMemo([ ReactHooksUtils.utf(detailsElementId) ])(function (v18) {
            var findElement = function (id) {
                return CostEstimation_BuildingElementsView_ElementDetailsContainer.findElementAndComponent(id)(elementsData);
            };
            return Data_Maybe.fromMaybe(props.projectId)((function () {
                var v19 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(findElement)(detailsElementId);
                if (v19 instanceof Data_Maybe.Just) {
                    return Data_Nullable.toMaybe(v19.value0.projectOfOrigin);
                };
                if (v19 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 458, column 25 - line 460, column 25): " + [ v19.constructor.name ]);
            })());
        })();
        var v18 = FetchHooks.useReferenceElementMeasures(detailsElementProjectId)();
        var checkedElementsData = TofuHooks.useMemo([ ReactHooksUtils.utf(v13.value0.checkedElements), ReactHooksUtils.utf(elementsData) ])(function (v19) {
            return CostEstimation_BuildingElementsView_Types.mkCheckedElementsData(v13.value0.checkedElements)(elementsData);
        })();
        var setDetailsElementIdx = TofuHooks.useCallback([ ReactHooksUtils.utf(withClearCheckedSpecs), ReactHooksUtils.utf(setDetailsElementIdx$prime) ])(function (shouldScrollToSelection) {
            return function (idx) {
                return withClearCheckedSpecs(setDetailsElementIdx$prime(shouldScrollToSelection)(function (oldIdx) {
                    var $248 = Data_Eq.eq(Edited.eqEdited(Data_Maybe.eqMaybe(Data_Eq.eqInt)))(oldIdx)(idx);
                    if ($248) {
                        return new Edited.Edited(Data_Maybe.Nothing.value);
                    };
                    return idx;
                }));
            };
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v.value0) ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v.value0)(v13.value1(function (v19) {
                return {
                    sortingCriteria: v19.sortingCriteria,
                    checkedElements: v19.checkedElements,
                    lastCheckedItem: v19.lastCheckedItem,
                    checkedSpecs: v19.checkedSpecs,
                    selectedResourceIds: v19.selectedResourceIds,
                    detailsElementIdx: Edited.Unedited.value,
                    shouldScrollToSelection: v19.shouldScrollToSelection,
                    referenceActiveFilters: v19.referenceActiveFilters
                };
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var setDetailsElementId = TofuHooks.useCallback([ ReactHooksUtils.utf(rows), ReactHooksUtils.utf(setDetailsElementIdx) ])(function (shouldScrollToSelection) {
            return function (id) {
                var newIdx = Data_Array.findIndex(function (r) {
                    return Data_Eq.eq(Types_Element.eqElementId)(CostEstimation_BuildingElementsView.elementIdOfRow(r))(id);
                })(rows);
                return setDetailsElementIdx(shouldScrollToSelection)(new Edited.Edited(newIdx));
            };
        })();
        var closeDetails = TofuHooks.useCallback([ ReactHooksUtils.utf(withClearCheckedSpecs), ReactHooksUtils.utf(setDetailsElementIdx) ])(withClearCheckedSpecs(setDetailsElementIdx(true)(new Edited.Edited(Data_Maybe.Nothing.value))))();
        var elementProps = TofuHooks.useMemo([ ReactHooksUtils.utf(selectedElementIdx), ReactHooksUtils.utf(setDetailsElementIdx), ReactHooksUtils.utf(detailsElementId), ReactHooksUtils.utf(closeDetails) ])(function (v19) {
            return {
                setDetailsElementIdx: setDetailsElementIdx,
                detailsElementId: detailsElementId,
                closeDetails: closeDetails,
                selectedElementIdx: selectedElementIdx
            };
        })();
        CostEstimation_BuildingElementsView.handleCursorNavigation({
            selectElementRelative: function (change) {
                return withClearCheckedSpecs(v13.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        selectedResourceIds: s.selectedResourceIds,
                        detailsElementIdx: (function () {
                            if (s.detailsElementIdx instanceof Edited.Edited && s.detailsElementIdx.value0 instanceof Data_Maybe.Just) {
                                return new Edited.Edited(Data_Maybe.Just.create(s.detailsElementIdx.value0.value0 + change | 0));
                            };
                            return s.detailsElementIdx;
                        })(),
                        shouldScrollToSelection: true,
                        referenceActiveFilters: s.referenceActiveFilters
                    };
                }));
            },
            closeDetails: closeDetails,
            isActive: !isPanel
        })(Data_Array.length(v2.value0))(v2.value0)();
        var tableRef = UseNullableRef.useNullableRef();
        UseSelectedComponentScrollPosition.useSelectedComponentScrollPosition(v13.value0.shouldScrollToSelection)(TacoTable_Types.rowHeightPixels(CostEstimation_BuildingElementsView.tableRowHeight))(selectedElementIdx)(tableRef)();
        var v19 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(elementsData), ReactHooksUtils.utf(v19.value0) ])((function () {
            var match = Control_Bind.bind(Data_Maybe.bindMaybe)(v19.value0)(function (elementId) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(function (v20) {
                    return Data_Eq.eq(Types_Element.eqElementId)((Data_Newtype.un()(Types_Element.WasmEstimationElement)(v20.element)).elementId)(elementId);
                })(elementsData))(function () {
                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(elementId);
                });
            });
            return function __do() {
                (function () {
                    if (match instanceof Data_Maybe.Just) {
                        setDetailsElementId(v13.value0.shouldScrollToSelection)(match.value0)();
                        return v19.value1(function (v20) {
                            return Data_Maybe.Nothing.value;
                        })();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        var onToggleCheckAll = TofuHooks.useCallback([ ReactHooksUtils.utf(v13.value1), ReactHooksUtils.utf(elementsData) ])(v13.value1(function (s) {
            var newCheckedElements = (function () {
                var $256 = Data_Set.isEmpty(s.checkedElements);
                if ($256) {
                    return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_BuildingElementsView_Types["_elementId"])(elementsData));
                };
                return Data_Set.empty;
            })();
            return {
                sortingCriteria: s.sortingCriteria,
                checkedElements: newCheckedElements,
                lastCheckedItem: s.lastCheckedItem,
                checkedSpecs: s.checkedSpecs,
                selectedResourceIds: s.selectedResourceIds,
                detailsElementIdx: s.detailsElementIdx,
                shouldScrollToSelection: s.shouldScrollToSelection,
                referenceActiveFilters: s.referenceActiveFilters
            };
        }))();
        var v20 = CostEstimation_BuildingElementsView.useSelectedComponentIds({
            components: components,
            selectedElement: selectedElement
        })();
        var tableProps = {
            elementProps: elementProps,
            checkedElements: v13.value0.checkedElements,
            toggleElementChecked: toggleElementChecked,
            toggleAreaOfCheckboxes: toggleAreaOfCheckboxes,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            shortenReferenceProjectDetails: v15.value0,
            projectId: props.projectId,
            projectCurrency: props.project.currency,
            appDispatch: appDispatch,
            subprojects: v6.value0,
            selectedInputColumn: Data_Maybe.Nothing.value,
            setSelectedInputColumn: function (v21) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            showSocialExpensesInCosts: v9.showSocialExpensesInCosts
        };
        var setCheckedElements = function (update) {
            return v13.value1(function (s) {
                return {
                    sortingCriteria: s.sortingCriteria,
                    checkedElements: update(s.checkedElements),
                    lastCheckedItem: s.lastCheckedItem,
                    checkedSpecs: s.checkedSpecs,
                    selectedResourceIds: s.selectedResourceIds,
                    detailsElementIdx: s.detailsElementIdx,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    referenceActiveFilters: s.referenceActiveFilters
                };
            });
        };
        var isSelectionEmpty = Data_Set.isEmpty(v13.value0.checkedElements);
        var selectionProps = {
            onToggleSelectAll: onToggleCheckAll,
            isSelectionEmpty: isSelectionEmpty
        };
        var initialScrollRow = (function () {
            if (props.initialSelectedElement instanceof Data_Maybe.Nothing) {
                return v12.value0.selectedElementIdx;
            };
            if (props.initialSelectedElement instanceof Data_Maybe.Just) {
                var es = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(v2.value0);
                var idx = Data_Array.findIndex(function (e) {
                    return Data_Eq.eq(Types_Element.eqElementId)(props.initialSelectedElement.value0)(e.elementId);
                })(es);
                return idx;
            };
            throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 575, column 24 - line 581, column 12): " + [ props.initialSelectedElement.constructor.name ]);
        })();
        var elementsTableControls = [ TacoButton.component()()({
            text: (function () {
                var $260 = Data_Set.size(v13.value0.checkedElements) > 1;
                if ($260) {
                    return "Kopioi rakenteet";
                };
                return "Kopioi rakenne";
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Set.size(v13.value0.checkedElements) === 0,
            onClick: Data_Maybe.Just.create(handleAddElement(appDispatch)({
                projectId: props.projectId,
                checkedElements: v13.value0.checkedElements,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: true,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                },
                setCheckedElements: setCheckedElements
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.elementColors.border,
            size: TacoConstants.L.value,
            testId: "copy-selected-elements-button"
        }), TacoButton.component()()({
            text: (function () {
                var $261 = Data_Set.size(v13.value0.checkedElements) > 1;
                if ($261) {
                    return "Kopioi rakenteet ilman panoksia";
                };
                return "Kopioi rakenne ilman panoksia";
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Set.size(v13.value0.checkedElements) === 0,
            onClick: Data_Maybe.Just.create(handleAddElement(appDispatch)({
                projectId: props.projectId,
                checkedElements: v13.value0.checkedElements,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: false,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                },
                setCheckedElements: setCheckedElements
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.elementColors.border,
            size: TacoConstants.L.value,
            testId: "copy-selected-elements-without-resources-button"
        }) ];
        var floatingElementsControls$prime = CostEstimation_BuildingElementsControls.mkFloatingElementsControls({
            checkedElements: v13.value0.checkedElements,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            onToggleCheckAll: onToggleCheckAll,
            checkedElementsData: checkedElementsData,
            isSocialExpensesToggled: v9.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: true,
            onSocialExpensesToggle: v9.onSocialExpensesToggle,
            isWorkAchievementToggled: v10.showWorkAchievement,
            showWorkAchievementToggleControl: true,
            onWorkAchievementToggle: v10.onWorkAchievementToggle,
            addMemoToExcel: false,
            onAddMemoToExcelToggle: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            activeElementsFilters: v13.value0.referenceActiveFilters,
            setActiveElementsFilters: setActiveElementsFilters,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            searchValue: v14.value0,
            setSearchValue: v14.value1,
            setNewElementId: v19.value1,
            projectId: props.projectId,
            referenceModeFollowOptions: Data_Maybe.Nothing.value,
            extraElementsTableControls: elementsTableControls,
            mkExport: Data_Maybe.Nothing.value,
            mkElementsExport: Data_Maybe.Nothing.value,
            groupBy: Data_Maybe.Nothing.value,
            toggleGroupBy: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showGroupByControls: false
        });
        var columns = CostEstimation_BuildingElementsView_Table.getColumns(tableProps)(selectionProps);
        var elementsTable$prime = function (v21) {
            return CostEstimationTableWrapper.component({
                colorSet: TacoConstants.elementColors,
                children: [ CostEstimation_BuildingElementsView_Table.elementsTableOptimized({
                    rows: rows,
                    columns: columns,
                    mkRow: CostEstimation_BuildingElementsView_Table.getRow(columns)(tableProps),
                    sortProperty: v13.value0.sortingCriteria.value1,
                    sortingOrder: v13.value0.sortingCriteria.value0,
                    rowHeight: CostEstimation_BuildingElementsView.tableRowHeight,
                    onSort: function (newProperty) {
                        return v13.value1(function (v22) {
                            var newSortingCriteria = (function () {
                                if (v22.sortingCriteria.value1 instanceof Data_Maybe.Just && Data_Eq.eq(CostEstimation_BuildingElementsView_Table.eqElementsTableSortProperty)(v22.sortingCriteria.value1.value0)(newProperty)) {
                                    return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(v13.value0.sortingCriteria.value0), new Data_Maybe.Just(v22.sortingCriteria.value1.value0), v22.sortingCriteria.value2);
                                };
                                if (v22.sortingCriteria.value1 instanceof Data_Maybe.Just) {
                                    return new TacoTable_Types.SortingCriteria(v13.value0.sortingCriteria.value0, new Data_Maybe.Just(newProperty), v22.sortingCriteria.value2);
                                };
                                return new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(CostEstimation_BuildingElementsView_Table.ByCode.value), CostEstimation_BuildingElementsView_Table.getSortingValue);
                            })();
                            return {
                                sortingCriteria: newSortingCriteria,
                                checkedElements: v22.checkedElements,
                                lastCheckedItem: Data_Maybe.Nothing.value,
                                checkedSpecs: v22.checkedSpecs,
                                selectedResourceIds: v22.selectedResourceIds,
                                detailsElementIdx: v22.detailsElementIdx,
                                shouldScrollToSelection: v22.shouldScrollToSelection,
                                referenceActiveFilters: v22.referenceActiveFilters
                            };
                        });
                    },
                    containerRef: tableRef,
                    showHeader: true,
                    styleSet: CostEstimation_BuildingElementsView_Table.elementsTableStyleSet,
                    initialScrollRow: initialScrollRow
                }) ],
                floatingActionButtons: floatingElementsControls$prime
            });
        };
        var elementsTable = (function () {
            var $277 = Data_Array["null"](v8.selectedRefProjects);
            if ($277) {
                return v8.emptySelectedRefProjectsButton;
            };
            return elementsTable$prime(Data_Unit.unit);
        })();
        var referenceModeComponentTableControls = [ TacoButton.component()()({
            text: (function () {
                var v21 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(v13.value0.checkedSpecs);
                var v22 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v23) {
                    return v23.selectedEditViewElement;
                })(props.panelOptions);
                if (v22 instanceof Data_Maybe.Just && v21) {
                    return "Lis\xe4\xe4 rakenteelle ilman panoksia";
                };
                if (v22 instanceof Data_Maybe.Just && !v21) {
                    return "Lis\xe4\xe4 kaikki rakenteelle ilman panoksia";
                };
                if (v22 instanceof Data_Maybe.Nothing && v21) {
                    return "Lis\xe4\xe4 hankkeelle ilman panoksia";
                };
                if (v22 instanceof Data_Maybe.Nothing && !v21) {
                    return "Lis\xe4\xe4 kaikki hankkeelle ilman panoksia";
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 794, column 19 - line 798, column 78): " + [ v22.constructor.name, v21.constructor.name ]);
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            onClick: Data_Maybe.Just.create(handleAddComponent(appDispatch)({
                projectId: props.projectId,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: false,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                }
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }), TacoButton.component()()({
            text: (function () {
                var v21 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(v13.value0.checkedSpecs);
                var v22 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v23) {
                    return v23.selectedEditViewElement;
                })(props.panelOptions);
                if (v22 instanceof Data_Maybe.Just && v21) {
                    return "Lis\xe4\xe4 rakenteelle";
                };
                if (v22 instanceof Data_Maybe.Just && !v21) {
                    return "Lis\xe4\xe4 kaikki rakenteelle";
                };
                if (v22 instanceof Data_Maybe.Nothing && v21) {
                    return "Lis\xe4\xe4 hankkeelle";
                };
                if (v22 instanceof Data_Maybe.Nothing && !v21) {
                    return "Lis\xe4\xe4 kaikki hankkeelle";
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 818, column 19 - line 822, column 63): " + [ v22.constructor.name, v21.constructor.name ]);
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            onClick: Data_Maybe.Just.create(handleAddComponent(appDispatch)({
                projectId: props.projectId,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: true,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                }
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }), TacoButton.component()()({
            text: "Vaihda suoritteet",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Maybe.isNothing(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                return v21.selectedEditViewComponentId;
            })(props.panelOptions)),
            onClick: Data_Maybe.Just.create(handleReplaceElementSpecs(appDispatch)({
                projectId: props.projectId,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: true,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                },
                checkedElements: v13.value0.checkedElements,
                setCheckedElements: setCheckedElements
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }), TacoButton.component()()({
            text: "Vaihda rakenne",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Maybe.isNothing(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                return v21.selectedEditViewElement;
            })(props.panelOptions)),
            onClick: Data_Maybe.Just.create(handleReplaceElement(appDispatch)({
                projectId: props.projectId,
                checkedSpecs: v13.value0.checkedSpecs,
                selectedEditViewElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElement;
                })(props.panelOptions),
                subprojects: v6.value0,
                selectedDetailsComponent: v20.selectedDetailsComponent,
                selectedElement: selectedElement,
                elementMeasures: v18.value0,
                selectedEditViewElementMeasures: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                    return v21.selectedEditViewElementMeasures;
                })(props.panelOptions),
                copyResources: true,
                setCheckedSpecs: function (update) {
                    return v13.value1(function (s) {
                        return {
                            sortingCriteria: s.sortingCriteria,
                            checkedElements: s.checkedElements,
                            lastCheckedItem: s.lastCheckedItem,
                            checkedSpecs: update(s.checkedSpecs),
                            selectedResourceIds: s.selectedResourceIds,
                            detailsElementIdx: s.detailsElementIdx,
                            shouldScrollToSelection: s.shouldScrollToSelection,
                            referenceActiveFilters: s.referenceActiveFilters
                        };
                    });
                },
                checkedElements: v13.value0.checkedElements,
                setCheckedElements: setCheckedElements
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.elementColors.border,
            size: TacoConstants.L.value
        }) ];
        var mSelectedEditViewElementSpecId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
            return v21.selectedEditViewElementSpecId;
        })(props.panelOptions);
        var mSelectedEditViewElementId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
            return v21.selectedEditViewElementId;
        })(props.panelOptions);
        var mSelectedEditViewComponentId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
            return v21.selectedEditViewComponentId;
        })(props.panelOptions);
        var resourceActionType = (function () {
            if (mSelectedEditViewElementId instanceof Data_Maybe.Just && (mSelectedEditViewElementSpecId instanceof Data_Maybe.Just && mSelectedEditViewComponentId instanceof Data_Maybe.Just)) {
                return new Types_Estimation.SplitElementComponent({
                    elementId: mSelectedEditViewElementId.value0,
                    elementSpecId: mSelectedEditViewElementSpecId.value0,
                    componentId: mSelectedEditViewComponentId.value0
                });
            };
            return Types_Estimation.InPlaceResourceUpdate.value;
        })();
        var referenceModeResourceTableControls = Data_Monoid.guard(Data_Monoid.monoidArray)(isPanel && Data_Eq.eq(Types_CostEstimation.eqECViewMode)(Types_CostEstimation.ReferenceMode.value)(Types_CostEstimation.ReferenceMode.value))([ TacoButton.component()()({
            text: (function () {
                var $292 = Data_Set.isEmpty(v13.value0.selectedResourceIds);
                if ($292) {
                    return "Lis\xe4\xe4 kaikki panokset";
                };
                return "Lis\xe4\xe4 valitut panokset";
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Maybe.isNothing(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                return v21.selectedEditViewComponentId;
            })(props.panelOptions)) || Data_Maybe.isNothing(v20.selectedDetailsComponent),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.resourceColors.border,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just((function () {
                var v21 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v22) {
                    return v22.selectedEditViewComponentId;
                })(props.panelOptions);
                if (v21 instanceof Data_Maybe.Just) {
                    return appDispatch(new Actions.EstimationResourceActionRequest({
                        projectId: props.projectId,
                        params: {
                            actionType: resourceActionType,
                            actionParams: new Types_Estimation.CopyResourcesToComponent({
                                componentId: v21.value0,
                                resourceIds: (function () {
                                    var $294 = Data_Set.isEmpty(v13.value0.selectedResourceIds);
                                    if ($294) {
                                        if (v20.selectedDetailsComponent instanceof Data_Maybe.Just) {
                                            return Data_Functor.map(Data_Functor.functorArray)(function (v22) {
                                                return v22.id;
                                            })((Data_Newtype.unwrap()(v20.selectedDetailsComponent.value0)).resources);
                                        };
                                        if (v20.selectedDetailsComponent instanceof Data_Maybe.Nothing) {
                                            return [  ];
                                        };
                                        throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 735, column 44 - line 737, column 54): " + [ v20.selectedDetailsComponent.constructor.name ]);
                                    };
                                    return Data_Array.fromFoldable(Data_Set.foldableSet)(v13.value0.selectedResourceIds);
                                })()
                            })
                        },
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                };
                if (v21 instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 725, column 15 - line 743, column 37): " + [ v21.constructor.name ]);
            })())
        }), TacoButton.component()()({
            text: "Vaihda panokset",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Data_Maybe.isNothing(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                return v21.selectedEditViewComponentId;
            })(props.panelOptions)) || Data_Maybe.isNothing(v20.selectedDetailsComponent),
            onClick: Data_Maybe.Just.create((function () {
                var v22 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v23) {
                    return v23.selectedEditViewComponentId;
                })(props.panelOptions);
                if (v22 instanceof Data_Maybe.Just && v20.selectedDetailsComponent instanceof Data_Maybe.Just) {
                    return appDispatch(new Actions.EstimationResourceActionRequest({
                        projectId: props.projectId,
                        params: {
                            actionType: resourceActionType,
                            actionParams: new Types_Estimation.ReplaceComponentResources({
                                componentId: v22.value0,
                                referenceComponentId: (Data_Newtype.unwrap()(v20.selectedDetailsComponent.value0)).component.id
                            })
                        },
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            color: TacoConstants.resourceColors.border
        }), TacoButton.component()()({
            text: "Vaihda suorite",
            disabled: Data_Maybe.isNothing(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v21) {
                return v21.selectedEditViewComponentId;
            })(props.panelOptions)) || Data_Maybe.isNothing(v20.selectedDetailsComponent),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            onClick: Data_Maybe.Just.create(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mSelectedEditViewComponentId)(function (selectedEditViewComponentId) {
                if (v20.selectedDetailsComponent instanceof Data_Maybe.Just) {
                    return appDispatch(new Actions.EstimationComponentActionRequest({
                        projectId: props.projectId,
                        params: new Types_Estimation.ReplaceEstimationComponent({
                            projectId: props.projectId,
                            targetElementId: mSelectedEditViewElementId,
                            targetElementSpecId: mSelectedEditViewElementSpecId,
                            targetComponentId: selectedEditViewComponentId,
                            referenceComponentId: (Data_Newtype.unwrap()(v20.selectedDetailsComponent.value0)).component.id
                        }),
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                };
                if (v20.selectedDetailsComponent instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at CostEstimation.ElementsReferenceView (line 774, column 17 - line 787, column 39): " + [ v20.selectedDetailsComponent.constructor.name ]);
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }) ]);
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.NormalMode.value,
            localStorageKey: "ElementsReferenceView"
        })();
        var elementDetails = Data_Functor.voidLeft(Data_Maybe.functorMaybe)(selectedElement)(CostEstimation_BuildingElementsView_ElementDetailsContainer.mkElementDetails({
            elementProps: elementProps,
            selectedElement: selectedElement,
            elementMeasures: v18.value0,
            projectId: props.projectId,
            checkedSpecs: v13.value0.checkedSpecs,
            setCheckedSpecs: function (update) {
                return v13.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: update(s.checkedSpecs),
                        selectedResourceIds: s.selectedResourceIds,
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceActiveFilters: s.referenceActiveFilters
                    };
                });
            },
            setParentSelectedResourceIds: Data_Maybe.Just.create(function (update) {
                return v13.value1(function (s) {
                    return {
                        sortingCriteria: s.sortingCriteria,
                        checkedElements: s.checkedElements,
                        lastCheckedItem: s.lastCheckedItem,
                        checkedSpecs: s.checkedSpecs,
                        selectedResourceIds: update(s.selectedResourceIds),
                        detailsElementIdx: s.detailsElementIdx,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        referenceActiveFilters: s.referenceActiveFilters
                    };
                });
            }),
            socialExpensePercentagesByCostClass: v9.socialExpensePercentagesByCostClass,
            showSocialExpensesInCosts: v9.showSocialExpensesInCosts,
            showWorkAchievement: v10.showWorkAchievement,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            isPanel: isPanel,
            projectLocations: (function () {
                if (v7.value1) {
                    return [  ];
                };
                return v7.value0;
            })(),
            extraResourceControls: Data_Monoid.guard(Data_Monoid.monoidArray)(isPanel)(referenceModeResourceTableControls),
            extraComponentControls: Data_Monoid.guard(Data_Monoid.monoidArray)(isPanel)(referenceModeComponentTableControls),
            controls: React_Basic.empty,
            selectedComponentIds: v20.selectedComponentIds,
            setSelectedComponentIds: v20.setSelectedComponentIds,
            panelMode: panelProps.detailsPanelMode,
            setPanelMode: panelProps.setDetailsPanelMode,
            dixonOptions: Data_Maybe.Nothing.value,
            existingComponentCodesForEdit: Data_Set.empty,
            projectCurrency: props.project.currency
        }));
        var v21 = TacoLoader.useLoading(false)();
        var elementsControls$prime = CostEstimation_BuildingElementsControls.mkElementsControls({
            checkedElements: v13.value0.checkedElements,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            onToggleCheckAll: onToggleCheckAll,
            checkedElementsData: checkedElementsData,
            isSocialExpensesToggled: v9.showSocialExpensesInCosts,
            showSocialExpensesToggleControl: false,
            onSocialExpensesToggle: v9.onSocialExpensesToggle,
            isWorkAchievementToggled: v10.showWorkAchievement,
            showWorkAchievementToggleControl: false,
            onWorkAchievementToggle: v10.onWorkAchievementToggle,
            addMemoToExcel: false,
            onAddMemoToExcelToggle: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            activeElementsFilters: v13.value0.referenceActiveFilters,
            setActiveElementsFilters: setActiveElementsFilters,
            viewMode: Types_CostEstimation.ReferenceMode.value,
            searchValue: v14.value0,
            setSearchValue: v14.value1,
            setNewElementId: v19.value1,
            projectId: props.projectId,
            referenceModeFollowOptions: new Data_Maybe.Just({
                on: v.value0,
                toggle: v.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
                by: v1.value0,
                set: v1.value1,
                choices: referenceModeFollowOptionsByChoices,
                refProjectsModal: v8.refProjectsModal,
                refProjectsModalButton: v8.refProjectsModalButton,
                shortenReferenceProjectDetails: v15.value0,
                setShortenProjectDetails: v15.value1
            }),
            extraElementsTableControls: elementsTableControls,
            mkExport: Data_Maybe.Nothing.value,
            mkElementsExport: Data_Maybe.Nothing.value,
            groupBy: Data_Maybe.Nothing.value,
            toggleGroupBy: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showGroupByControls: false
        });
        var elementsControls = Box.box("controlsWrapper")([ new Box.FlexGrow(1), new Box.Padding(TacoConstants.S.value) ])([ elementsControls$prime ]);
        return CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(elementsControls),
            controlsLoading: CostEstimationLayout_Types.LoadingFinished.value,
            leftPanel: Data_Maybe.Nothing.value,
            leftSidebar: Data_Maybe.Nothing.value,
            main: elementsTable,
            rightPanel: Data_Maybe.Nothing.value,
            rightSidebar: Data_Maybe.Nothing.value,
            details: elementDetails,
            panelProps: panelProps,
            leftPanelLoading: (function () {
                if (v21.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: mRefPanelMode
        });
    };
});
module.exports = {
    referenceModeFollowOptionsByChoices: referenceModeFollowOptionsByChoices,
    findMatchingElementIndex: findMatchingElementIndex,
    elementCopyOperationAction: elementCopyOperationAction,
    handleElementUpdate: handleElementUpdate,
    handleReplaceElement: handleReplaceElement,
    handleReplaceElementSpecs: handleReplaceElementSpecs,
    handleAddElement: handleAddElement,
    handleAddComponent: handleAddComponent,
    component: component
};
