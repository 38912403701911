import { Input } from "@tocoman/ui";
import { ErrorMessage } from "../../../../components/ErrorMessage";
import { isNumber } from "../../../../utils/validations";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { StringCombobox } from "../../../../components/StringCombobox";
import { useTranslation } from "react-i18next";
import { TransportFileFormData } from "./TransportFileUploadModal";
import { OrgClassification } from "../../../../../../ts-bindings/OrgClassification";
import { useDefault } from "../../../../hooks/useDefault";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { useEffect, useMemo, useState } from "react";
import { FeatureFlagContainer } from "../../../../split/FeatureFlagContainer";
import { FeatureFlags } from "../../../../split/FeatureFlags";
import { useProjectGroups } from "../../ProjectDetailsModal/useProjectGroups";
import { useSetNextAvailableVersion } from "../../../../hooks/useSetNextAvailableVersion";
import {
  useAllowedCurrenciesQuery,
  useDefaultCurrencyQuery,
} from "../../../Admin/Settings/OrganizationCurrencySettings/useOrganizationCurrencySettings";

type ProjectDataFormProps = {
  fileCurrency: string;
};

export function ProjectDataForm({ fileCurrency }: ProjectDataFormProps) {
  const { t } = useTranslation("costControl", {
    keyPrefix: "projectList.transportFileModal",
  });
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TransportFileFormData>();

  const { data: projectGroups } = useProjectGroups();

  const { data: allowedCurrencies } = useAllowedCurrenciesQuery();
  const { data: defaultCurrency } = useDefaultCurrencyQuery();

  const orgClassifications: OrgClassification[] = useDefault(
    useWorkerState("ClassificationsState"),
    []
  );

  const classifications = useMemo(
    () => orgClassifications.map((item) => item.name),
    [orgClassifications]
  );

  const {
    setNextAvailableVersion,
    versionLoading,
  } = useSetNextAvailableVersion(setValue);

  const mkInputProps = (
    field: keyof TransportFileFormData,
    registerOptions?: RegisterOptions<TransportFileFormData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  const [
    currencyNotAllowedMessageVisible,
    setCurrencyNotAllowedMessageVisible,
  ] = useState(false);

  useEffect(() => {
    if (
      allowedCurrencies &&
      fileCurrency &&
      !allowedCurrencies.includes(fileCurrency)
    ) {
      setValue(
        "currency",
        defaultCurrency?.defaultCurrency ?? allowedCurrencies[0]
      );
      setCurrencyNotAllowedMessageVisible(true);
    } else {
      setCurrencyNotAllowedMessageVisible(false);
    }
  }, [fileCurrency]);

  return (
    <div className={"flex-col"}>
      <div className={"mt-1"}>
        <Input
          className={"w-full"}
          {...mkInputProps("name", {
            minLength: {
              value: 3,
              message: t(`errors.tooShort`, { minLength: "3" }),
            },
            maxLength: {
              value: 50,
              message: t(`errors.tooLong`, { maxLength: "50" }),
            },
            required: { value: true, message: t`errors.required` },
          })}
        />
        {errors.name && <ErrorMessage errorMessage={errors.name.message} />}
      </div>
      <div className={"mt-1"}>
        <Input
          className={"w-full"}
          disabled={versionLoading}
          {...mkInputProps("code", {
            maxLength: {
              value: 12,
              message: t(`errors.tooLong`, { maxLength: "12" }),
            },
            required: { value: true, message: t`errors.required` },
            onBlur: (e) => setNextAvailableVersion(e.target.value),
          })}
          testId={"project-code"}
        />
        {errors.code && <ErrorMessage errorMessage={errors.code.message} />}
      </div>
      <div className={"mt-1"}>
        <Controller
          name={"version"}
          control={control}
          render={({ field: { value } }) => (
            <Input
              value={value}
              className={"w-full"}
              {...mkInputProps("version", {
                minLength: {
                  value: 1,
                  message: t(`errors.tooShort`, { minLength: "1" }),
                },
                maxLength: {
                  value: 3,
                  message: t(`errors.tooLong`, { maxLength: "3" }),
                },
                required: { value: true, message: t`errors.required` },
                validate: {
                  isNumber: (value) =>
                    isNumber(value) || t`errors.versionMustBeANumber`,
                },
              })}
            />
          )}
        />
        {errors.version && (
          <>
            <ErrorMessage errorMessage={errors.version.message} />
          </>
        )}
        <Controller
          control={control}
          name={"currency"}
          render={({ field: { onChange, value } }) => (
            <StringCombobox
              label={t`currency`}
              items={allowedCurrencies ?? []}
              onValueChange={onChange}
              initialValue={value}
            />
          )}
        />
        {currencyNotAllowedMessageVisible && (
          <>
            <ErrorMessage
              ns={"costControl"}
              i18nKey={
                "projectList.transportFileModal.currencyNotAllowedMessage"
              }
              values={{ fileCurrency: fileCurrency }}
            />
          </>
        )}
      </div>
      <FeatureFlagContainer feature={FeatureFlags.ADVANCED_IMPORT_MODAL}>
        <div className={"mt-1"}>
          <Controller
            control={control}
            name={"projectGroup"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`projectGroup`}
                items={projectGroups ?? []}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
        </div>
      </FeatureFlagContainer>
      <FeatureFlagContainer feature={FeatureFlags.ADVANCED_IMPORT_MODAL}>
        <div className={"mt-1"}>
          <Controller
            control={control}
            name={"classification"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`classification`}
                items={classifications}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
        </div>
      </FeatureFlagContainer>
    </div>
  );
}
