// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoList = require("../TacoList/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Planning = require("../Types.Planning/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var getCostGroupsTableColumns = function (props) {
    var removeButton = function (r) {
        return TacoButton.component()()({
            text: "Poista",
            onClick: Data_Maybe.Just.create(props.setDeletePrompt(function (v) {
                return new Data_Maybe.Just(r);
            })),
            testId: "cost-group-" + (Data_Show.show(Data_Show.showInt)(r.id) + "-delete-button")
        });
    };
    var prepare = function (r) {
        return {
            key: r.key,
            label: r.label,
            width: r.width,
            cell: r.cell,
            headerJSX: Data_Maybe.Nothing.value,
            sortProperty: TacoTable_Types.noSortProperty
        };
    };
    var nonMatchingCostGroup = function (r) {
        return Data_Array.elem(Data_Eq.eqString)(r.code)(props.nonMatchingCostGroupsCodes);
    };
    var newCostGroupSubmitButton = function (r) {
        return TacoButton.component()()({
            text: "Luo littera",
            onClick: props.nonMatchingToNewCostGroup(r.code)(r.description),
            testId: "new-cost-group-submit-button"
        });
    };
    var rowButton = function (r) {
        var $47 = nonMatchingCostGroup(r);
        if ($47) {
            return newCostGroupSubmitButton(r);
        };
        return removeButton(r);
    };
    var mkInput = function (testId) {
        return function (currValue) {
            return function (exists) {
                return function (mkParams) {
                    return TacoInput.remoteStringField()()({
                        value: currValue,
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        mkAction: function (value) {
                            return function (handler) {
                                return new Actions.PlanningActionRequest({
                                    projectId: props.projectId,
                                    handler: handler,
                                    params: mkParams(value)
                                });
                            };
                        },
                        disabled: exists,
                        testId: testId
                    });
                };
            };
        };
    };
    var descriptionInput = function (r) {
        return mkInput("cost-group-" + (Data_Show.show(Data_Show.showInt)(r.id) + "-description-input"))(r.description)(nonMatchingCostGroup(r))(function (value) {
            return new Types_Planning.EditPlanningCostGroup({
                costGroupId: r.id,
                newCostGroupCode: r.code,
                newDescription: value
            });
        });
    };
    var codeInput = function (r) {
        return mkInput("cost-group-" + (Data_Show.show(Data_Show.showInt)(r.id) + "-code-input"))(r.code)(nonMatchingCostGroup(r))(function (value) {
            return new Types_Planning.EditPlanningCostGroup({
                costGroupId: r.id,
                newCostGroupCode: value,
                newDescription: r.description
            });
        });
    };
    var classificationId = function (r) {
        return TacoText.component()()({
            text: Utils.nullable("")(Data_Show.show(Data_Show.showInt))((function (v) {
                return v.costGroupClassificationId;
            })(r)),
            testId: "cost-group-" + (Data_Show.show(Data_Show.showInt)(r.id) + "-classification-id")
        });
    };
    return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "classificationId",
        label: React_Basic_DOM.text("Nimikkeist\xf6"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.JSXCell([  ], classificationId)
    }, {
        key: "code",
        label: React_Basic_DOM.text("Koodi"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], codeInput)
    }, {
        key: "description",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(1),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], descriptionInput)
    }, {
        key: "",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], rowButton)
    } ]);
};
var costGroupsOptimizedTable = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupClassificationId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString)));
var costGroupsTable = TofuHooks.mkHookComponent("CostGroupsTable")(function (props) {
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        var columns = getCostGroupsTableColumns(props);
        var mkRow = function (v) {
            return function (cg) {
                return {
                    className: "cost-groups-table-row",
                    rowData: cg,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: function (v1) {
                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                    },
                    rowKey: Data_Show.show(Data_Show.showInt)(cg.id),
                    columns: columns
                };
            };
        };
        return costGroupsOptimizedTable({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: props.planningCostGroups,
            mkRow: mkRow,
            rowHeight: TacoTable_Types.RowSmall.value,
            containerRef: tableRef,
            showHeader: true,
            columns: columns,
            styleSet: TacoTable_Styles.optimizedTableStyleSet,
            initialScrollRow: Data_Maybe.Nothing.value
        });
    };
});
var editCostGroupsModal = TofuHooks.mkMemoHookComponent("EditCostGroupsModal")(function (props) {
    var defaultNewCostGroupInputs = {
        code: "",
        description: ""
    };
    return function __do() {
        var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v1 = TofuHooks.useState(defaultNewCostGroupInputs)();
        var dispatch = StateHooks.useDispatch();
        var projectCostGroupClassification = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v2) {
            return v2.projectCostGroupClassification;
        })(props.projectCostGroupClassificationInfo);
        var orgCostGroupClassifications = Data_Maybe.maybe(Common.emptyArray)(function (v2) {
            return v2.orgCostGroupClassifications;
        })(props.projectCostGroupClassificationInfo);
        var optionalEmptyItem = (function () {
            if (projectCostGroupClassification instanceof Data_Maybe.Just) {
                return [  ];
            };
            if (projectCostGroupClassification instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Control_Applicative.applicativeArray)({
                    value: "",
                    text: "-- Valitse tavoitelitteranimikkeist\xf6 --"
                });
            };
            throw new Error("Failed pattern match at ComponentCostGroupsTree (line 223, column 25 - line 228, column 10): " + [ projectCostGroupClassification.constructor.name ]);
        })();
        var nonMatchingToNewCostGroup = function (code) {
            return function (description) {
                return Data_Maybe.Just.create(dispatch(new Actions.PlanningActionRequest({
                    handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                    projectId: props.projectId,
                    params: new Types_Planning.AddPlanningCostGroup({
                        newCostGroupCode: code,
                        newDescription: description
                    })
                })));
            };
        };
        var newCostGroupSubmitButton = TacoButton.component()()({
            text: "Luo littera",
            disabled: Data_String_Common["null"](v1.value0.code) || Data_String_Common["null"](v1.value0.description),
            onClick: new Data_Maybe.Just(dispatch(new Actions.PlanningActionRequest({
                handler: function (v2) {
                    return v1.value1(function (v3) {
                        return defaultNewCostGroupInputs;
                    });
                },
                projectId: props.projectId,
                params: new Types_Planning.AddPlanningCostGroup({
                    newCostGroupCode: v1.value0.code,
                    newDescription: v1.value0.description
                })
            }))),
            testId: "new-cost-group-submit-button"
        });
        var newCostGroupDescription = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0.description,
            onChange: function (fn) {
                return v1.value1(function (r) {
                    return {
                        code: r.code,
                        description: fn(r.description)
                    };
                });
            },
            placeholder: "selite",
            testId: "new-cost-group-description-input"
        });
        var newCostGroupCode = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0.code,
            onChange: function (fn) {
                return v1.value1(function (r) {
                    return {
                        code: fn(r.code),
                        description: r.description
                    };
                });
            },
            placeholder: "koodi",
            testId: "new-cost-group-code-input"
        });
        var newCostGroupColumns = [ {
            key: "code",
            label: React_Basic_DOM.text("Tunnus"),
            width: new TacoTable_Types.Fixed("8rem"),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newCostGroupCode;
            })
        }, {
            key: "name",
            label: React_Basic_DOM.text("Selite"),
            width: new TacoTable_Types.Flex(1),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newCostGroupDescription;
            })
        }, {
            key: "button",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Fixed("12rem"),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newCostGroupSubmitButton;
            })
        } ];
        var newCostGroupRow = TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))({
            rowData: v1.value0,
            css: Data_Monoid.mempty(React_Basic_Emotion.monoidStyle),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            styleSet: TacoTable_Styles.foldingTableStyleSetWithoutSticky.rowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            columns: newCostGroupColumns,
            rowKey: "new-cost-group-row"
        });
        var mkSelectItem = function (r) {
            return {
                text: r.orgClassificationId + (" - " + r.description),
                value: Data_Show.show(Data_Show.showInt)(r.orgCostGroupClassificationId)
            };
        };
        var mCostGroupClassificationSelectValue = Control_Bind.bind(Data_Maybe.bindMaybe)(projectCostGroupClassification)(function (pcgc) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Array.find)(orgCostGroupClassifications)(function (ocgc) {
                return ocgc.description === pcgc.classificationName;
            }))(function (match) {
                return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(match.orgCostGroupClassificationId);
            });
        });
        var deletePromptModalBody = TacoModal.modalBody({
            contents: (function () {
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v.value0 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        text: "Haluatko varmasti poistaa litteran " + (v.value0.value0.code + (" \"" + (v.value0.value0.description + "\"")))
                    }));
                };
                throw new Error("Failed pattern match at ComponentCostGroupsTree (line 318, column 19 - line 321, column 104): " + [ v.value0.constructor.name ]);
            })()
        });
        var deletePromptModalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                    return Data_Maybe.Nothing.value;
                })),
                text: "Peruuta",
                testId: "delete-cost-group-cancel-button"
            }), TacoButton.component()()({
                onClick: (function () {
                    if (v.value0 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v.value0 instanceof Data_Maybe.Just) {
                        return Data_Maybe.Just.create(dispatch(new Actions.PlanningActionRequest({
                            handler: function (v2) {
                                return v.value1(function (v3) {
                                    return Data_Maybe.Nothing.value;
                                });
                            },
                            projectId: props.projectId,
                            params: new Types_Planning.DeletePlanningCostGroup({
                                costGroupId: v.value0.value0.id
                            })
                        })));
                    };
                    throw new Error("Failed pattern match at ComponentCostGroupsTree (line 332, column 26 - line 341, column 26): " + [ v.value0.constructor.name ]);
                })(),
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                testId: "delete-cost-group-confirm-button"
            }) ]
        });
        var deletePromptModal = TacoModal.component()()({
            onClose: v.value1(function (v2) {
                return Data_Maybe.Nothing.value;
            }),
            isActive: Data_Maybe.isJust(v.value0),
            heading: "Poista littera",
            contents: [ deletePromptModalBody, deletePromptModalActions ]
        });
        var currentProjectCostGroupClassificationLabel = (function () {
            if (projectCostGroupClassification instanceof Data_Maybe.Nothing) {
                return "";
            };
            if (projectCostGroupClassification instanceof Data_Maybe.Just) {
                return projectCostGroupClassification.value0.classificationName;
            };
            throw new Error("Failed pattern match at ComponentCostGroupsTree (line 218, column 50 - line 220, column 37): " + [ projectCostGroupClassification.constructor.name ]);
        })();
        var costGroupClassificationSelectValue = Data_Maybe.maybe("")(Data_Show.show(Data_Show.showInt))(mCostGroupClassificationSelectValue);
        var costGroupClassificationSelect = TacoSelect.component()()({
            items: Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalEmptyItem)(Data_Functor.map(Data_Functor.functorArray)(mkSelectItem)(orgCostGroupClassifications)),
            value: costGroupClassificationSelectValue,
            onChange: function (f) {
                var value = f("");
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(StringUtils.readStrToInt(value))(function (id) {
                    var dispatch$prime = dispatch(new Actions.PlanningActionRequest({
                        projectId: props.projectId,
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                        params: new Types_Planning.CopyTemplateCostGroupsToProject({
                            templateCostGroupClassificationId: id
                        })
                    }));
                    var $59 = Data_String_Common["null"](costGroupClassificationSelectValue);
                    if ($59) {
                        return dispatch$prime;
                    };
                    return WindowUtils.confirm("Oletko varma ett\xe4 haluat ylikirjoittaa olemassaolevan nimikkeist\xf6n " + currentProjectCostGroupClassificationLabel)(dispatch$prime);
                });
            },
            testId: "cost-group-classification-select"
        });
        var classificationHeader = Gap.component({
            spacing: TacoConstants.S.value,
            direction: Gap.Row.value,
            overflow: false,
            css: React_Basic_Emotion.css()({
                marginRight: React_Basic_Emotion.str("auto"),
                alignItems: React_Basic_Emotion.str("center")
            }),
            contents: [ TacoText.component()()({
                text: "Vaihda nimikkeist\xf6",
                weight: TacoText_Types.Bold.value
            }), TacoButton.component()()({
                text: "P\xe4ivit\xe4 projektin tavoitelitterat oletuslitteroilla",
                onClick: new Data_Maybe.Just(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mCostGroupClassificationSelectValue)(function (id) {
                    return WindowUtils.confirm("Haluatko varmasti poistaa nykyiset projektin tavoitelitterat ja p\xe4ivit\xe4\xe4 oletuslitterat?")(dispatch(new Actions.PlanningActionRequest({
                        projectId: props.projectId,
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                        params: new Types_Planning.CopyTemplateCostGroupsToProject({
                            templateCostGroupClassificationId: id
                        })
                    })));
                })),
                testId: "copy-template-cost-groups-to-project-button"
            }) ]
        });
        var modalBody = TacoModal.modalBody({
            contents: [ classificationHeader, costGroupClassificationSelect, TacoText.component()()({
                text: "Littera",
                weight: TacoText_Types.Bold.value
            }), costGroupsTable({
                planningCostGroups: props.planningCostGroups,
                projectId: props.projectId,
                setDeletePrompt: v.value1,
                nonMatchingCostGroupsCodes: props.nonMatchingCostGroupsCodes,
                nonMatchingToNewCostGroup: nonMatchingToNewCostGroup
            }), TacoText.component()()({
                text: "Uusi littera",
                weight: TacoText_Types.Bold.value
            }), newCostGroupRow, deletePromptModal ]
        });
        var cancelButton = TacoButton.component()()({
            onClick: new Data_Maybe.Just(props.onClose),
            text: "Peruuta",
            testId: "cost-groups-tree-cancel-button"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ cancelButton ]
        });
        return TacoModal.component()()({
            isActive: props.isActive,
            onClose: props.onClose,
            heading: "Muokkaa litterat",
            contents: [ modalBody, modalActions ],
            containerStyles: [ new Box.Width(new TacoConstants.CustomSize("90vw")), new Box.Height(new TacoConstants.CustomSize("90vh")) ]
        });
    };
});
var componentCostGroupsTree = TofuHooks.mkMemoHookComponent("ComponentCostGroupsTree")(function (props) {
    var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
    return function __do() {
        var v = TofuHooks.useState(false)();
        var v1 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("cost-groups-tree-show-contents-" + Data_Show.show(Types_Project.showProjectId)(props.projectId))(true)(false)();
        var v2 = Client_WASM.usePlanningCostGroups(props.projectId)();
        var v3 = FetchHooks.useProjectCostGroupClassificationInfos(props.projectId)();
        var planningCostGroups = TofuHooks.useMemo([ ReactHooksUtils.utf(v2.value0) ])(function (v4) {
            return Data_Array.sortWith(Data_Ord.ordString)(function (v5) {
                return v5.code;
            })(v2.value0);
        })();
        var v4 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v4) {
            return v4.workerComponentCostGroupsData;
        })("ComponentCostGroupsDataState")();
        var v5 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v5) {
            return v5.workerNonMatchingGroups;
        })("NonMatchingCostGroupsState")();
        var nonMatchingCostGroups = TofuHooks.useMemo([ ReactHooksUtils.utf(v5.value0) ])(function (v6) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v7) {
                return v7.costGroup;
            })(v5.value0);
        })();
        var v6 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v6) {
            return v6.workerUnfilteredSEPComponentCostGroupsData;
        })("UnfilteredSocExpPercentagesComponentCostGroupsDataState")();
        var v7 = TofuHooks.useMemo([ ReactHooksUtils.utf(v5.value0) ])(function (v7) {
            return Data_Functor.map(Data_Functor.functorArray)(function (v8) {
                return v8.costGroup.code;
            })(v5.value0);
        })();
        var dataForEditModal = TofuHooks.useMemo([ ReactHooksUtils.utf(nonMatchingCostGroups), ReactHooksUtils.utf(planningCostGroups) ])(function (v8) {
            var v9 = Data_Array.partition(function (x) {
                return x.description === "?????";
            })(planningCostGroups);
            return Data_Array.concat([ nonMatchingCostGroups, v9.yes, v9.no ]);
        })();
        var prepareItem = TofuHooks.useMemo([ ReactHooksUtils.utf(props.toggleCostGroupFilter) ])(function (v8) {
            return function (cgd) {
                var title = cgd.costGroup.code + (" - " + cgd.costGroup.description);
                return {
                    title: title,
                    id: title,
                    titleJSX: Data_Maybe.Nothing.value,
                    value: TacoList.TextValue.create(Formatters.formatDecimalValue(2)(cgd.total) + (" " + currencySymbol)),
                    onClick: (function () {
                        var $80 = cgd.costGroup.code === "?????";
                        if ($80) {
                            return Data_Maybe.Just.create(props.toggleWithoutCostGroupFilter("no-group"));
                        };
                        return Data_Maybe.Just.create(props.toggleCostGroupFilter(cgd.costGroup.code));
                    })(),
                    initExpanded: false,
                    disabled: cgd.total === 0.0,
                    getItems: function (v9) {
                        return [  ];
                    },
                    hasItems: false
                };
            };
        })();
        var costGroupsItems = TofuHooks.useMemo([ ReactHooksUtils.utf(v4.value0), ReactHooksUtils.utf(v5.value0), ReactHooksUtils.utf(v6.value0), ReactHooksUtils.utf(dataForEditModal), ReactHooksUtils.utf(v7), ReactHooksUtils.utf(prepareItem) ])(function (v8) {
            var v9 = Data_Array.partition(function (x) {
                return x.costGroup.description === "Litteroimattomat";
            })(v4.value0);
            var v10 = Data_Array.partition(function (x) {
                return x.costGroup.description === "?????";
            })(Data_Array.sortWith(Data_Ord.ordString)(function (x) {
                return x.costGroup.code;
            })(v9.no));
            var socialExpenseText = function (text) {
                return TacoText.component()()({
                    text: text,
                    color: TacoConstants.GrayDark.value,
                    testId: "cost-groups-total-social-expense-price"
                });
            };
            var costGroups = Data_Functor.map(Data_Functor.functorArray)(prepareItem)(Data_Array.concat([ v9.yes, v5.value0, v10.yes, v10.no ]));
            var _socialExpensePrice = function (r) {
                return r.socialExpensePrice;
            };
            var totalSocialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_socialExpensePrice)(v6.value0));
            var socialExpenseRowItem = {
                title: "Sosiaalikulut (ei mukana summissa)",
                titleJSX: Data_Maybe.Just.create(socialExpenseText("Sosiaalikulut (ei mukana summissa)")),
                value: TacoList.JSXValue.create(socialExpenseText(Formatters.formatCurrencyValue(props.projectCurrency)(totalSocialExpensePrice))),
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                getItems: function (v11) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false,
                id: "Sosiaalikulut (ei mukana summissa)"
            };
            var socialExpenseRow = (function () {
                if (props.showSocialExpensesInCosts) {
                    return Common.emptyArray;
                };
                return [ socialExpenseRowItem ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(costGroups)(socialExpenseRow);
        })();
        return ReactBasicUtils["div'"]("__ComponentCostGroupsTree")([ Gap.gapX(TacoConstants.M.value)([ React_Basic_DOM_Generated.h3()({
            className: "heading",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Littera"))
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa",
            onClick: Data_Maybe.Just.create(v.value1(function (v8) {
                return true;
            })),
            testId: "edit-component-cost-groups-button"
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: (function () {
                if (v1.value0) {
                    return "Piilota";
                };
                return "N\xe4yt\xe4";
            })(),
            onClick: Data_Maybe.Just.create(v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            testId: "toggle-component-cost-groups-button"
        }) ]), Data_Monoid.guard(React_Basic.monoidJSX)(v1.value0)(TacoList.component({
            items: costGroupsItems
        })), editCostGroupsModal({
            isActive: v.value0,
            onClose: v.value1(function (v8) {
                return false;
            }),
            projectId: props.projectId,
            planningCostGroups: dataForEditModal,
            nonMatchingCostGroupsCodes: v7,
            projectCostGroupClassificationInfo: v3.value0
        }) ]);
    };
});
module.exports = {
    componentCostGroupsTree: componentCostGroupsTree,
    editCostGroupsModal: editCostGroupsModal
};
