import { Popover } from "@mui/material";
import { useState } from "react";
import { Button, IconChevronDownV1 } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { AdditionalRow } from "./PremiumOfferPageTable";

type PremiumOfferButtonsProps = {
  data: AdditionalRow[] | null;
  handleAddRow: (type: string) => void;
};

export const PremiumOfferButtons = ({
  data,
  handleAddRow,
}: PremiumOfferButtonsProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isAddRowPopoverOpen, setIsAddRowPopoverOpen] = useState(false);
  const handleOpenAddRowPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setIsAddRowPopoverOpen(true);
  };

  const handleAddNewRowClicked = (type: string) => {
    handleAddRow(type);
    setIsAddRowPopoverOpen(false);
  };

  const addNewRowItems = (
    <div className={"flex flex-col justify-start"}>
      <Button
        label={t("risk")}
        disabled={data?.some((row) => row?.code === "risk")}
        onClick={() => handleAddNewRowClicked("risk")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("general")}
        disabled={data?.some((row) => row?.code === "general")}
        onClick={() => handleAddNewRowClicked("general")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("change")}
        disabled={data?.some((row) => row?.code === "change")}
        onClick={() => handleAddNewRowClicked("change")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("specialContract")}
        disabled={data?.some((row) => row?.code === "specialContract")}
        onClick={() => handleAddNewRowClicked("specialContract")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("margin")}
        disabled={data?.some((row) => row?.code === "margin")}
        onClick={() => handleAddNewRowClicked("margin")}
        color={"gray"}
        variant={"text"}
      />
    </div>
  );
  return (
    <div className={"float-right mr-5"}>
      <Button
        label={t("addNewRow")}
        onClick={handleOpenAddRowPopover}
        icon={IconChevronDownV1}
      />
      <Popover
        open={isAddRowPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setIsAddRowPopoverOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: "flex flex-col p-[5px]",
        }}
      >
        {addNewRowItems}
      </Popover>
    </div>
  );
};
