// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var $$Math = require("../Math/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Classic = require("../React.Basic.Classic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var UseEffectAlways = require("../UseEffectAlways/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseWindowResizeListener = require("../UseWindowResizeListener/index.js");
var Utils_Listeners = require("../Utils.Listeners/index.js");
var Web_DOM_Element = require("../Web.DOM.Element/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var SetSortProperty = (function () {
    function SetSortProperty(value0) {
        this.value0 = value0;
    };
    SetSortProperty.create = function (value0) {
        return new SetSortProperty(value0);
    };
    return SetSortProperty;
})();
var update = function (dictEq) {
    return function (dictEq1) {
        return function (v) {
            return function (v1) {
                var $62 = Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(new Data_Maybe.Just(v1.value0))(v.state.sortProperty);
                if ($62) {
                    return new React_Basic_Classic.Update({
                        sortProperty: v.state.sortProperty,
                        sortingOrder: TacoTable_Types.reverse(v.state.sortingOrder)
                    });
                };
                return new React_Basic_Classic.Update({
                    sortProperty: new Data_Maybe.Just(v1.value0),
                    sortingOrder: v.state.sortingOrder
                });
            };
        };
    };
};
var mkTableStateless = function (dictGetName) {
    return function (name) {
        return ReactHooksUtils.createComponent("TableStateless" + (name + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value)));
    };
};
var mkTableSortable = function (dictGetName) {
    return function (name) {
        return React_Basic_Classic.createComponent("TableSortable" + (name + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value)));
    };
};
var mkRow = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (sortProperty) {
                return function (styleSet) {
                    return function (rowItem) {
                        var _row = Data_Newtype.un()(TacoTable_Types.TacoRow)(rowItem);
                        return React_Basic.keyed(_row.rowKey)(TacoTable_Row.row(dictEq)(dictEq1)({
                            columns: _row.columns,
                            sortProperty: sortProperty,
                            rowData: _row.rowData,
                            onClick: _row.onClick,
                            className: _row.className,
                            rowHeight: TacoTable_Types.RowMedium.value,
                            styleSet: styleSet,
                            rowKey: _row.rowKey,
                            css: React_Basic_Emotion.css()({}),
                            style: React_Basic_DOM_Internal.css({}),
                            foldingElement: _row.foldingElement
                        }));
                    };
                };
            };
        };
    };
};
var mkHeader_ = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (nodeRef) {
                return TofuHooks.mkMemoHookComponent("TacoTable__Header" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (v) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.guard(React_Basic.monoidJSX)(v.showHeader)(TacoTable_Header.header_(dictGetName)(dictEq)(dictEq1)(nodeRef)({
                        sortProperty: v.sortProperty,
                        sortingOrder: v.sortingOrder,
                        columns: (function () {
                            var v1 = Data_Array.head(v.rows);
                            if (v1 instanceof Data_Maybe.Just) {
                                return (function (v2) {
                                    return v2.columns;
                                })(Data_Newtype.un()(TacoTable_Types.TacoRow)(v1.value0));
                            };
                            return [  ];
                        })(),
                        onSort: v.onSort,
                        styleSet: v.styleSet.headerStyleSet,
                        foldingElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)((function () {
                            var $128 = Data_Newtype.un()(TacoTable_Types.TacoRow);
                            return function ($129) {
                                return (function (v1) {
                                    return v1.foldingElement;
                                })($128($129));
                            };
                        })())(Data_Array.head(v.rows))
                    })));
                });
            };
        };
    };
};
var tableOptimized = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return ReactHooksUtils.make()()("TableOptimized" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (props) {
                var rowHeightInt = TacoTable_Types.rowHeightPixels(props.rowHeight);
                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useState(1)))(function (v) {
                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useState(0)))(function (v1) {
                        var calcFirstVisibleRow = function (scrollEvent) {
                            return Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(scrollEvent.scrollTop)(rowHeightInt);
                        };
                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useEffect([  ])(function __do() {
                            var maybeElement = Data_Functor.map(Effect.functorEffect)(function (v2) {
                                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v2);
                            })(React_Basic_Hooks.readRefMaybe(props.containerRef))();
                            if (maybeElement instanceof Data_Maybe.Nothing) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            };
                            if (maybeElement instanceof Data_Maybe.Just) {
                                var handler = Utils_Listeners.asDistinctStream(function (prev) {
                                    return function (next) {
                                        return calcFirstVisibleRow(prev) !== calcFirstVisibleRow(next);
                                    };
                                })(function (scrollEvent) {
                                    return v1.value1(function (prevIdx) {
                                        var newIdx = calcFirstVisibleRow(scrollEvent);
                                        var $78 = $$Math.remainder(Data_Int.toNumber(newIdx - prevIdx | 0))(2.0) === 0.0;
                                        if ($78) {
                                            return newIdx;
                                        };
                                        return newIdx - 1 | 0;
                                    });
                                })();
                                var listenerF = Utils_Listeners.addScrollEventListenerToElement(maybeElement.value0)(handler)();
                                return Utils_Listeners.removeScrollEventListenerFromElement(maybeElement.value0)(listenerF);
                            };
                            throw new Error("Failed pattern match at TacoTable (line 309, column 5 - line 318, column 70): " + [ maybeElement.constructor.name ]);
                        })))(function () {
                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseEffectAlways.useEffectAlways(function __do() {
                                var maybeElement = Data_Functor.map(Effect.functorEffect)(function (v2) {
                                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v2);
                                })(React_Basic_Hooks.readRefMaybe(props.containerRef))();
                                Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(maybeElement)(function (element) {
                                    return function __do() {
                                        var height = Data_Functor.map(Effect.functorEffect)(Data_Int.ceil)(Web_DOM_Element.clientHeight(Web_HTML_HTMLElement.toElement(element)))();
                                        return v.value1(function (v2) {
                                            return height;
                                        })();
                                    };
                                })();
                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                            })))(function () {
                                var updateMaxHeight = function (resizeEvent) {
                                    return v.value1(function (v2) {
                                        return resizeEvent.innerHeight;
                                    });
                                };
                                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseWindowResizeListener.useWindowResizeListener(updateMaxHeight)))(function () {
                                    var lastRowIdx = (v1.value0 + Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v.value0)(rowHeightInt) | 0) + 5 | 0;
                                    var firstRowIdx = Data_Ord.max(Data_Ord.ordInt)(0)(v1.value0 - 5 | 0);
                                    var mkRowWithStyle = function (sortProperty) {
                                        return function (rowIdx) {
                                            return function (rowItem) {
                                                var absIdx = firstRowIdx + rowIdx | 0;
                                                var _row = Data_Newtype.un()(TacoTable_Types.TacoRow)(props.mkRow(absIdx)(rowItem));
                                                return React_Basic.keyed(_row.rowKey)(TacoTable_Row.row(dictEq)(dictEq1)({
                                                    columns: _row.columns,
                                                    sortProperty: sortProperty,
                                                    rowData: _row.rowData,
                                                    onClick: _row.onClick,
                                                    className: _row.className + (" idx-" + Data_Show.show(Data_Show.showInt)(absIdx)),
                                                    rowHeight: props.rowHeight,
                                                    styleSet: props.styleSet.rowStyleSet,
                                                    css: React_Basic_Emotion.css()({
                                                        position: React_Basic_Emotion.str("absolute")
                                                    }),
                                                    style: React_Basic_DOM_Internal.css({
                                                        top: Data_Show.show(Data_Show.showInt)(absIdx * rowHeightInt | 0) + "px"
                                                    }),
                                                    foldingElement: _row.foldingElement,
                                                    rowKey: _row.rowKey
                                                }));
                                            };
                                        };
                                    };
                                    var rowsToRender = Data_Array.slice(firstRowIdx)(lastRowIdx)(props.rows);
                                    var rows = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                        className: "table-rows",
                                        css: TacoTable_Styles.optimizedTableRowsContainerStyles({
                                            flexBasis: (rowHeightInt * Data_Array.length(props.rows) | 0) + 1 | 0
                                        }),
                                        children: Data_Array.mapWithIndex(mkRowWithStyle(props.sortProperty))(rowsToRender)
                                    });
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useMemo([ ReactHooksUtils.utf(props.rows), ReactHooksUtils.utf(props.columns) ])(function (v2) {
                                        return Data_Functor.map(Data_Functor.functorArray)(props.mkRow(0))(Data_Array.take(1)(rowsToRender));
                                    })))(function (headerRows) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseNullableRef.useNullableRef))(function (headerRef) {
                                            var header = mkHeader_(dictGetName)(dictEq)(dictEq1)(headerRef)({
                                                rows: headerRows,
                                                columns: props.columns,
                                                sortProperty: props.sortProperty,
                                                sortingOrder: props.sortingOrder,
                                                onSort: props.onSort,
                                                showHeader: props.showHeader,
                                                styleSet: props.styleSet
                                            });
                                            var tableElement = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                className: "table-optimized __TacoTable",
                                                css: props.styleSet.tableStyles,
                                                ref: props.containerRef,
                                                children: [ header, rows ]
                                            });
                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useLayoutEffect([  ])(function __do() {
                                                (function () {
                                                    if (props.initialScrollRow instanceof Data_Maybe.Just) {
                                                        var headerM = React_Basic_Hooks.readRefMaybe(headerRef)();
                                                        var headerHeight = (function () {
                                                            var v2 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(headerM);
                                                            if (v2 instanceof Data_Maybe.Just) {
                                                                return Web_HTML_HTMLElement.offsetHeight(v2.value0)();
                                                            };
                                                            if (v2 instanceof Data_Maybe.Nothing) {
                                                                return 0.0;
                                                            };
                                                            throw new Error("Failed pattern match at TacoTable (line 394, column 25 - line 396, column 30): " + [ v2.constructor.name ]);
                                                        })();
                                                        return ReactHooksUtils.performRefElementEffect(props.containerRef)(function (container) {
                                                            var scrollTo = headerHeight + Data_Int.toNumber(props.initialScrollRow.value0 * TacoTable_Types.rowHeightPixels(props.rowHeight) | 0);
                                                            return Web_DOM_Element.setScrollTop(scrollTo)(Web_HTML_HTMLElement.toElement(container));
                                                        })();
                                                    };
                                                    if (props.initialScrollRow instanceof Data_Maybe.Nothing) {
                                                        return Data_Unit.unit;
                                                    };
                                                    throw new Error("Failed pattern match at TacoTable (line 391, column 5 - line 401, column 27): " + [ props.initialScrollRow.constructor.name ]);
                                                })();
                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                            })))(function () {
                                                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(tableElement);
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        };
    };
};
var mkHeader = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return TofuHooks.mkMemoHookComponent("TacoTable__Header" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.guard(React_Basic.monoidJSX)(v.showHeader)(TacoTable_Header.header(dictGetName)(dictEq)(dictEq1)({
                    sortProperty: v.sortProperty,
                    sortingOrder: v.sortingOrder,
                    columns: (function () {
                        var v1 = Data_Array.head(v.rows);
                        if (v1 instanceof Data_Maybe.Just) {
                            return (function (v2) {
                                return v2.columns;
                            })(Data_Newtype.un()(TacoTable_Types.TacoRow)(v1.value0));
                        };
                        return [  ];
                    })(),
                    onSort: v.onSort,
                    styleSet: v.styleSet.headerStyleSet,
                    foldingElement: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)((function () {
                        var $130 = Data_Newtype.un()(TacoTable_Types.TacoRow);
                        return function ($131) {
                            return (function (v1) {
                                return v1.foldingElement;
                            })($130($131));
                        };
                    })())(Data_Array.head(v.rows))
                })));
            });
        };
    };
};
var tableStateless = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (component) {
                return ReactHooksUtils.make_(component)(function (props) {
                    var rows = React_Basic_DOM_Generated.div()({
                        className: "table-rows",
                        children: Data_Functor.map(Data_Functor.functorArray)(mkRow(dictGetName)(dictEq)(dictEq1)(props.sortProperty)(props.styleSet.rowStyleSet))(props.rows)
                    });
                    var header = mkHeader(dictGetName)(dictEq)(dictEq1)(props);
                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "table-stateless __TacoTable",
                        css: props.styleSet.tableStyles,
                        children: [ header, rows ]
                    }));
                });
            };
        };
    };
};
var tableStateless$prime = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (showHeader) {
                return function (inst) {
                    return function (rows) {
                        return function (columns) {
                            return tableStateless(dictGetName)(dictEq)(dictEq1)(inst)({
                                sortProperty: Data_Maybe.Nothing.value,
                                onSort: function (v) {
                                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                                },
                                sortingOrder: TacoTable_Types.Ascending.value,
                                rows: rows,
                                columns: columns,
                                showHeader: showHeader,
                                styleSet: TacoTable_Styles.foldingTableStyleSet
                            });
                        };
                    };
                };
            };
        };
    };
};
var tableStateless_ = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (component) {
                return function (containerRef) {
                    return ReactHooksUtils.make_(component)(function (props) {
                        var rows = React_Basic_DOM_Generated.div()({
                            className: "table-rows",
                            children: Data_Functor.map(Data_Functor.functorArray)(mkRow(dictGetName)(dictEq)(dictEq1)(props.sortProperty)(props.styleSet.rowStyleSet))(props.rows)
                        });
                        var header = mkHeader(dictGetName)(dictEq)(dictEq1)(props);
                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                            className: "table-stateless __TacoTable",
                            css: props.styleSet.tableStyles,
                            ref: containerRef,
                            children: [ header, rows ]
                        }));
                    });
                };
            };
        };
    };
};
var compareBySortingValue = function (dictEq) {
    return function (dictEq1) {
        return function (state) {
            return function (sortingCriteria) {
                return function (sortProperty) {
                    return function (a) {
                        return function (b) {
                            var withOrder = (function () {
                                var $101 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(state.sortingOrder)(TacoTable_Types.Descending.value);
                                if ($101) {
                                    return Data_Function.flip;
                                };
                                return Control_Category.identity(Control_Category.categoryFn);
                            })();
                            var sortableValue = function (x) {
                                return sortingCriteria.value2(sortProperty)((function (v) {
                                    return v.rowData;
                                })(Data_Newtype.un()(TacoTable_Types.TacoRow)(x)));
                            };
                            return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
                        };
                    };
                };
            };
        };
    };
};
var sortedRows = function (dictEq) {
    return function (dictEq1) {
        return function (state) {
            return function (props) {
                return function (sortingCriteria) {
                    if (state.sortProperty instanceof Data_Maybe.Just) {
                        return Data_Array.sortBy(compareBySortingValue(dictEq)(dictEq1)(state)(sortingCriteria)(state.sortProperty.value0))(props.rows);
                    };
                    if (state.sortProperty instanceof Data_Maybe.Nothing) {
                        return props.rows;
                    };
                    throw new Error("Failed pattern match at TacoTable (line 173, column 42 - line 175, column 24): " + [ state.sortProperty.constructor.name ]);
                };
            };
        };
    };
};
var tableSortable = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (component) {
                return function (sortingCriteria) {
                    return function (handleSortedRows) {
                        var render = function (v) {
                            var rows = React_Basic_DOM_Generated.div()({
                                className: "table-rows",
                                "_data": Components_Utils.mkTestIdAttrs("taco-table-rows"),
                                children: Data_Functor.map(Data_Functor.functorArray)(mkRow(dictGetName)(dictEq)(dictEq1)(v.state.sortProperty)(v.props.styleSet.rowStyleSet))(sortedRows(dictEq)(dictEq1)(v.state)(v.props)(sortingCriteria))
                            });
                            var header = mkHeader(dictGetName)(dictEq)(dictEq1)({
                                sortProperty: v.state.sortProperty,
                                sortingOrder: v.state.sortingOrder,
                                onSort: (function () {
                                    var $132 = React_Basic_Classic.runUpdate(update(dictEq)(dictEq1))(v);
                                    return function ($133) {
                                        return $132(SetSortProperty.create($133));
                                    };
                                })(),
                                rows: v.props.rows,
                                columns: v.props.columns,
                                showHeader: v.props.showHeader,
                                styleSet: v.props.styleSet
                            });
                            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                className: "table-sortable __TacoTable",
                                css: v.props.styleSet.tableStyles,
                                "_data": Components_Utils.mkTestIdAttrs("taco-table"),
                                children: [ header, rows ]
                            });
                        };
                        var initialState = {
                            sortProperty: sortingCriteria.value1,
                            sortingOrder: sortingCriteria.value0
                        };
                        var didUpdate = function (v) {
                            return function (v1) {
                                if (handleSortedRows instanceof Data_Maybe.Just) {
                                    var oldRows = sortedRows(dictEq)(dictEq1)(v1.prevState)(v1.prevProps)(sortingCriteria);
                                    var newRows = sortedRows(dictEq)(dictEq1)(v.state)(v.props)(sortingCriteria);
                                    var newIds = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                                        return v2.rowKey;
                                    })(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(TacoTable_Types.TacoRow))(newRows));
                                    var oldIds = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                                        return v2.rowKey;
                                    })(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(TacoTable_Types.TacoRow))(oldRows));
                                    var $117 = Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(newIds)(oldIds);
                                    if ($117) {
                                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                                    };
                                    return handleSortedRows.value0(newRows);
                                };
                                if (handleSortedRows instanceof Data_Maybe.Nothing) {
                                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                                };
                                throw new Error("Failed pattern match at TacoTable (line 136, column 7 - line 143, column 29): " + [ handleSortedRows.constructor.name ]);
                            };
                        };
                        var didMount = function (v) {
                            if (handleSortedRows instanceof Data_Maybe.Just) {
                                return handleSortedRows.value0(sortedRows(dictEq)(dictEq1)(v.state)(v.props)(sortingCriteria));
                            };
                            if (handleSortedRows instanceof Data_Maybe.Nothing) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            };
                            throw new Error("Failed pattern match at TacoTable (line 131, column 7 - line 134, column 29): " + [ handleSortedRows.constructor.name ]);
                        };
                        return React_Basic_Classic.make()(component)({
                            initialState: initialState,
                            didMount: didMount,
                            didUpdate: didUpdate,
                            render: render
                        });
                    };
                };
            };
        };
    };
};
module.exports = {
    mkTableStateless: mkTableStateless,
    mkTableSortable: mkTableSortable,
    tableSortable: tableSortable,
    tableStateless: tableStateless,
    tableStateless_: tableStateless_,
    "tableStateless'": tableStateless$prime,
    tableOptimized: tableOptimized
};
