// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var CostEstimation_BuildingElementsView_AddBuildingElement = require("../CostEstimation.BuildingElementsView.AddBuildingElement/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_CopyProjectElements = require("../CostEstimation.CopyProjectElements/index.js");
var CostEstimation_ProductionPlanningWarning = require("../CostEstimation.ProductionPlanningWarning/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationComponentFilter = require("../EstimationComponentFilter/index.js");
var EstimationExcelExportButton = require("../EstimationExcelExportButton/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Roles_Constants = require("../Roles.Constants/index.js");
var SocialExpensesControls = require("../SocialExpensesControls/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSearchInfo = require("../TacoSearchInfo/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var UseFetch = require("../UseFetch/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var WorkAchievementControls = require("../WorkAchievementControls/index.js");
var mkElementsControls = TofuHooks.mkMemoHookComponent("ElementsControls")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
            return v.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var v1 = Data_Functor.map(Effect.functorEffect)(UseFetch.defaultFetchValue(Roles_Constants.noAppAccessRights))(UseFetch.useConditionalFetchValue(Data_Maybe.eqMaybe(Types_Organization.eqOrganization))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.organization;
        })(v.value0))(function (v1) {
            return v1.appAccessRights;
        })(function (key) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Actions.GetUserAppAccessRightsRequest.create)(key);
        }))();
        var workAchievementControls = WorkAchievementControls.component({
            projectId: props.projectId,
            isWorkAchievementToggled: props.isWorkAchievementToggled,
            onToggle: props.onWorkAchievementToggle,
            disabled: Data_Maybe.Nothing.value
        });
        var superAdminSection = Data_Monoid.guard(Data_Monoid.monoidArray)(v1.value0.superAdmin)(Control_Applicative.pure(Control_Applicative.applicativeArray)({
            title: new Data_Maybe.Just("Superadmin toiminnot"),
            items: [ TacoButton.component()()({
                text: "Uudelleenlaske kaavat",
                align: TacoButton_Types.Left.value,
                onClick: Data_Maybe.Just.create(dispatch(new Actions.ElementActionRequest({
                    projectId: props.projectId,
                    params: Types_Element.RefreshFormulas.value,
                    handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                })))
            }) ]
        }));
        var socialExpensesControls = SocialExpensesControls.component({
            projectId: props.projectId,
            isSocialExpensesToggled: props.isSocialExpensesToggled,
            onToggle: props.onSocialExpensesToggle,
            disabled: Data_Maybe.Nothing.value
        });
        var mkShortenProjectDetailsCheckbox = function (v2) {
            return TacoCheckbox.component()()({
                className: "shorten-project-details",
                isChecked: !v2.shortenReferenceProjectDetails,
                onToggle: TacoCheckbox.NoEvent.create(v2.setShortenProjectDetails(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 projektitiedot")),
                testId: "shorten-project-details"
            });
        };
        var mkFlex = function (jsx) {
            return Box.box("flexWrapper")([ new Box.FlexGrow(0), new Box.FlexBasis("auto") ])([ jsx ]);
        };
        var dropdown = TacoDropdown.uncontrolled({
            sections: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                title: Data_Maybe.Nothing.value,
                items: [ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesToggleControl)(socialExpensesControls), Data_Monoid.guard(React_Basic.monoidJSX)(props.showWorkAchievementToggleControl)(workAchievementControls), (function () {
                    if (props.referenceModeFollowOptions instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    if (props.referenceModeFollowOptions instanceof Data_Maybe.Just) {
                        return Box.box("referenceModeFollowOptionsContainer")([ Box.FlexRow.value, Box.Style.create(React_Basic_Emotion.css()({
                            flexWrap: React_Basic_Emotion.str("nowrap")
                        })) ])([ TacoCheckbox.component()()({
                            className: "checkbox",
                            isChecked: props.referenceModeFollowOptions.value0.on,
                            onToggle: new TacoCheckbox.NoEvent(props.referenceModeFollowOptions.value0.toggle),
                            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Seuraa laskelman rivi\xe4")),
                            color: TacoConstants.PrimaryBlue.value,
                            testId: "follow-by-toggle"
                        }), Box.box("followByContainer")([ Box.Width.create(new TacoConstants.CustomSize("100px")) ])([ TacoSelect.component()()({
                            items: props.referenceModeFollowOptions.value0.choices,
                            value: props.referenceModeFollowOptions.value0.by,
                            onChange: props.referenceModeFollowOptions.value0.set,
                            testId: "follow-by-select"
                        }) ]) ]);
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 160, column 19 - line 179, column 24): " + [ props.referenceModeFollowOptions.constructor.name ]);
                })(), Data_Maybe.maybe(React_Basic.empty)(mkShortenProjectDetailsCheckbox)(props.referenceModeFollowOptions), Data_Maybe.maybe(React_Basic.empty)(function ($66) {
                    return mkFlex((function (v2) {
                        return v2.refProjectsModalButton;
                    })($66));
                })(props.referenceModeFollowOptions), Data_Monoid.guard(React_Basic.monoidJSX)(props.showGroupByControls)(TacoCheckbox.component()()({
                    isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_BuildingElementsView.eqElementsTableGroupBy))(props.groupBy)(new Data_Maybe.Just(Types_BuildingElementsView.GroupBySubproject.value)),
                    onToggle: TacoCheckbox.NoEvent.create(props.toggleGroupBy(Types_BuildingElementsView.GroupBySubproject.value)),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele alakohteittain")),
                    testId: "group-by-subproject-control"
                })), Data_Monoid.guard(React_Basic.monoidJSX)(props.showGroupByControls)(TacoCheckbox.component()()({
                    isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_BuildingElementsView.eqElementsTableGroupBy))(props.groupBy)(new Data_Maybe.Just(Types_BuildingElementsView.GroupByClassification.value)),
                    onToggle: TacoCheckbox.NoEvent.create(props.toggleGroupBy(Types_BuildingElementsView.GroupByClassification.value)),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele nimikkeist\xf6\xf6n")),
                    testId: "group-by-classification-control"
                })), Data_Monoid.guard(React_Basic.monoidJSX)(props.showGroupByControls)(TacoCheckbox.component()()({
                    isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Types_BuildingElementsView.eqElementsTableGroupBy))(props.groupBy)(new Data_Maybe.Just(Types_BuildingElementsView.GroupByLocation.value)),
                    onToggle: TacoCheckbox.NoEvent.create(props.toggleGroupBy(Types_BuildingElementsView.GroupByLocation.value)),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ryhmittele sijainneittain")),
                    testId: "group-by-location-control"
                })), (function () {
                    if (props.mkExport instanceof Data_Maybe.Just) {
                        return TacoCheckbox.component()()({
                            isChecked: props.addMemoToExcel,
                            onToggle: TacoCheckbox.NoEvent.create(props.onAddMemoToExcelToggle(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 muistio Exceliss\xe4")),
                            testId: "show-memo-in-excel-control"
                        });
                    };
                    if (props.mkExport instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 201, column 19 - line 209, column 40): " + [ props.mkExport.constructor.name ]);
                })(), (function () {
                    if (props.mkExport instanceof Data_Maybe.Just) {
                        return EstimationExcelExportButton.estimationExcelExportButton({
                            mkExport: props.mkExport.value0
                        });
                    };
                    if (props.mkExport instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 211, column 19 - line 213, column 40): " + [ props.mkExport.constructor.name ]);
                })(), (function () {
                    if (props.mkElementsExport instanceof Data_Maybe.Just) {
                        return EstimationExcelExportButton.estimationElementsExcelExportButton({
                            mkExport: props.mkElementsExport.value0
                        });
                    };
                    if (props.mkElementsExport instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 214, column 19 - line 216, column 40): " + [ props.mkElementsExport.constructor.name ]);
                })() ]
            } ])(superAdminSection),
            align: TacoDropdown_Types.AlignRight.value,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-elements-view-actions-dropdown"
        });
        return Box.box("controlsContainer")([ new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ Controls.component()()({
            leftControls: [ TacoText.component()()({
                text: (function () {
                    if (props.viewMode instanceof Types_CostEstimation.EditMode) {
                        return "Rakenteet";
                    };
                    if (props.viewMode instanceof Types_CostEstimation.ReferenceMode) {
                        return "Viitehankkeiden rakenteet";
                    };
                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 228, column 23 - line 230, column 63): " + [ props.viewMode.constructor.name ]);
                })(),
                variant: new TacoText_Types.Heading(2),
                wrap: TacoText_Types.NoWrap.value,
                overflow: TacoText_Types.TextOverflowEllipsis.value
            }), EstimationComponentFilter.estimationElementsFilter({
                activeFilters: props.activeElementsFilters,
                onSetActiveFilters: props.setActiveElementsFilters,
                viewMode: props.viewMode,
                showTargetCostGroup: false
            }), Box.box("searchInputWrapper")([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                value: props.searchValue,
                placeholder: "Hae",
                onChange: props.setSearchValue,
                clearButton: true,
                testId: "elements-search-input"
            }) ]), TacoSearchInfo.component({}), CostEstimation_ProductionPlanningWarning.projectPublishingStateWarning({
                isReportingProject: props.isReportingProject,
                programType: props.programType
            }), (function () {
                if (props.groupBy instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                if (props.groupBy instanceof Data_Maybe.Just && props.groupBy.value0 instanceof Types_BuildingElementsView.GroupBySubproject) {
                    return TacoText.component()()({
                        text: "Ryhmittely: alakohde"
                    });
                };
                if (props.groupBy instanceof Data_Maybe.Just && props.groupBy.value0 instanceof Types_BuildingElementsView.GroupByClassification) {
                    return TacoText.component()()({
                        text: "Ryhmittely: nimikkeist\xf6"
                    });
                };
                if (props.groupBy instanceof Data_Maybe.Just && props.groupBy.value0 instanceof Types_BuildingElementsView.GroupByLocation) {
                    return TacoText.component()()({
                        text: "Ryhmittely: sijainti"
                    });
                };
                throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 254, column 13 - line 261, column 68): " + [ props.groupBy.constructor.name ]);
            })() ],
            rightControls: [ dropdown, Data_Maybe.maybe(React_Basic.empty)(function ($67) {
                return mkFlex((function (v2) {
                    return v2.refProjectsModal;
                })($67));
            })(props.referenceModeFollowOptions) ],
            gap: true,
            bottomGutter: false
        }) ]);
    };
});
var mkDuplicateElementsWithinProjectAction = function (targetProjectId) {
    return function (targetSubprojectId) {
        return function (elementId) {
            return function (handler) {
                return new Actions.EstimationElementActionRequest({
                    projectId: targetProjectId,
                    params: new Types_Estimation.CopySelectedElements({
                        estimationElementIds: [ elementId ],
                        targetProjectId: targetProjectId,
                        targetSubprojectId: targetSubprojectId,
                        copyOperationType: Types_Estimation.DuplicateElementsWithinProject.value
                    }),
                    handler: function (v) {
                        return handler;
                    }
                });
            };
        };
    };
};
var mkDeleteElementRequest = function (props) {
    return function (deleteComponents) {
        return new Actions.ElementActionRequest({
            params: new Types_Element.DeleteElements({
                elementIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.checkedElements),
                deleteComponents: deleteComponents
            }),
            projectId: props.projectId,
            handler: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            }
        });
    };
};
var _editMultipleElementsBtn = TofuHooks.mkHookComponent("EditMultipleElementsBtn")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var dispatch = StateHooks.useDispatch();
        var v1 = TofuHooks.useState(false)();
        var resetInputFields = v1.value1(function (v2) {
            return false;
        });
        TofuHooks.useEffect([ ReactHooksUtils.utf(props.checkedElements) ])(function __do() {
            resetInputFields();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var resetQuantitiesInput = TacoCheckbox.component()()({
            isChecked: v1.value0,
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Nollaa")),
            color: TacoConstants.DangerRedDark.value,
            title: "Varoitus: t\xe4m\xe4 toiminto poistaa m\xe4\xe4r\xe4t kaikilta valituilta rakenteilta",
            testId: "reset-element-quantities-control"
        });
        var resetQuantitiesContents = [ TacoText.component()()({
            text: "Nollaa rakenteiden m\xe4\xe4r\xe4t",
            weight: TacoText_Types.Bold.value
        }), resetQuantitiesInput ];
        var modalBody = TacoModal.modalBody({
            contents: resetQuantitiesContents
        });
        var closeModal = function __do() {
            resetInputFields();
            return v.value1(function (v2) {
                return false;
            })();
        };
        var saveResetQuantities = dispatch(new Actions.ElementActionRequest({
            handler: function (v2) {
                return function __do() {
                    WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationElementsData({
                        request_params: props.projectId
                    }))();
                    v1.value1(function (v3) {
                        return false;
                    })();
                    return closeModal();
                };
            },
            projectId: props.projectId,
            params: new Types_Element.ResetElementAmounts({
                elementIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.checkedElements)
            })
        }));
        var saveAndCloseModal = Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v1.value0)(saveResetQuantities);
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                testId: "cancel-element-multi-edit"
            }), TacoButton.component()()({
                onClick: new Data_Maybe.Just(saveAndCloseModal),
                text: "Muokkaa",
                testId: "submit-element-multi-edit"
            }) ]
        });
        var modal = TacoModal.component()()({
            isActive: v.value0,
            onClose: closeModal,
            heading: "Muokkaa montaa rakennetta",
            contents: [ modalBody, modalActions ]
        });
        var button = TacoButton.component()()({
            text: "Muokkaa montaa rakennetta",
            onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            color: TacoConstants.elementActionColors.border,
            testId: "edit-multiple-elements-button"
        });
        return React_Basic.fragment([ button, modal ]);
    };
});
var _deleteCheckedElementsBtn = TofuHooks.mkHookComponent("DeleteCheckedElementsBtn")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var renderMessage = function (singleSelectionMessage) {
            return function (multipleSelectionMessage) {
                var $54 = props.countOfCheckedElements > 1;
                if ($54) {
                    return multipleSelectionMessage;
                };
                return singleSelectionMessage;
            };
        };
        var deleteElementsText = renderMessage("Poista valittu rakenne")("Poista valitut rakenteet");
        var deleteElementOnlyButtonText = renderMessage("Poista vain rakenne")("Poista vain rakenteet");
        var deleteElementAndComponentsButtonText = renderMessage("Poista rakenne ja suoritteet")("Poista rakenteet ja suoritteet");
        var deleteCheckedElementsButton = TacoButton.component()()({
            text: deleteElementsText,
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            onClick: Data_Maybe.Just.create(v.value1(function (v1) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            testId: "delete-checked-elements-button"
        });
        var confirmDeleteElementMessage = renderMessage("Rakenteelle on lis\xe4tty suoritteita, poistetaanko vain rakenne vai lis\xe4ksi my\xf6s ne suoritteet, jotka ovat vain t\xe4ll\xe4 rakenteella?")("Rakenteille on lis\xe4tty suoritteita, poistetaanko vain rakenteet vai lis\xe4ksi my\xf6s ne suoritteet, jotka ovat vain valituilla rakenteilla?");
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: confirmDeleteElementMessage,
                testId: "confirm-delete-element-message"
            }) ]
        });
        var closeDeleteCheckedElementsModal = v.value1(function (v1) {
            return false;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeDeleteCheckedElementsModal),
                text: "Peruuta",
                testId: "cancel-delete-element-button"
            }), TacoButton.component()()({
                text: deleteElementOnlyButtonText,
                color: TacoConstants.SecondaryRed.value,
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
                onClick: new Data_Maybe.Just(function __do() {
                    props.onDeleteElements();
                    return closeDeleteCheckedElementsModal();
                }),
                testId: "delete-element-only-button"
            }), TacoButton.component()()({
                text: deleteElementAndComponentsButtonText,
                color: TacoConstants.SecondaryRed.value,
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
                onClick: new Data_Maybe.Just(function __do() {
                    props.onDeleteElementsWithComponents();
                    return closeDeleteCheckedElementsModal();
                }),
                testId: "delete-element-and-components-button"
            }) ]
        });
        var deleteCheckedElementsModal = (function () {
            if (!v.value0) {
                return React_Basic.empty;
            };
            if (v.value0) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteCheckedElementsModal,
                    heading: deleteElementsText,
                    contents: [ modalBody, modalActions ]
                });
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsControls (line 501, column 34 - line 511, column 10): " + [ v.value0.constructor.name ]);
        })();
        return React_Basic.fragment([ deleteCheckedElementsModal, deleteCheckedElementsButton ]);
    };
});
var mkFloatingElementsControls = TofuHooks.mkMemoHookComponent("FloatingElementsControls")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(false)();
        var mkFlex = function (jsx) {
            return Box.box("flexWrapper")([ new Box.FlexGrow(0), new Box.FlexBasis("auto") ])([ jsx ]);
        };
        var isSelectionEmpty = Data_Set.isEmpty(props.checkedElements);
        var editMultipleElementsBtn = _editMultipleElementsBtn({
            onToggleCheckAll: props.onToggleCheckAll,
            checkedElements: props.checkedElements,
            projectId: props.projectId
        });
        var countOfSelectedRows = Data_Set.size(props.checkedElements);
        var deleteCheckedElementsBtn = _deleteCheckedElementsBtn({
            onDeleteElements: function __do() {
                dispatch(mkDeleteElementRequest(props)(false))();
                return props.onToggleCheckAll();
            },
            onDeleteElementsWithComponents: function __do() {
                dispatch(mkDeleteElementRequest(props)(true))();
                return props.onToggleCheckAll();
            },
            countOfCheckedElements: countOfSelectedRows
        });
        var duplicateSingleElementBtn = TacoButton.component()()({
            text: "Monista rakenne",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            onClick: new Data_Maybe.Just((function () {
                var head = Data_Array.head(props.checkedElementsData);
                var v1 = function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (countOfSelectedRows === 1 && head instanceof Data_Maybe.Just) {
                    var $61 = CostEstimation_BuildingElementsView_Types["_subprojectId"](head.value0);
                    if ($61 instanceof Data_Maybe.Just) {
                        return dispatch(mkDuplicateElementsWithinProjectAction(props.projectId)($61.value0)(CostEstimation_BuildingElementsView_Types["_elementId"](head.value0))(props.onToggleCheckAll));
                    };
                    return v1(true);
                };
                return v1(true);
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.elementActionColors.border,
            size: TacoConstants.L.value,
            testId: "duplicate-single-element-button"
        });
        var copySelectedElementsButton = TacoButton.component()()({
            text: "Kopioi valitut rakenteet toiselle hankkeelle",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            onClick: Data_Maybe.Just.create(v.value1(function (v1) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.elementActionColors.border,
            size: TacoConstants.L.value,
            testId: "copy-selected-elements-to-other-project-button"
        });
        var canEdit = EstimationUtils.canEditView(props);
        var addNewElement = CostEstimation_BuildingElementsView_AddBuildingElement.component({
            projectId: props.projectId,
            setNewElementId: props.setNewElementId
        });
        return React_Basic.fragment(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(mkFlex(addNewElement)), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && countOfSelectedRows === 1)(mkFlex(duplicateSingleElementBtn)), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !isSelectionEmpty)(mkFlex(editMultipleElementsBtn)), Data_Monoid.guard(React_Basic.monoidJSX)(!isSelectionEmpty)(mkFlex(React_Basic.fragment([ CostEstimation_CopyProjectElements.copyProjectElementsModal({
            checkedElements: props.checkedElements,
            checkedElementsData: props.checkedElementsData,
            projectId: props.projectId,
            closeCopyProjectElementsModal: v.value1(function (v1) {
                return false;
            }),
            copyProjectElementsModalOpen: v.value0
        }), copySelectedElementsButton ]))) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(props.extraElementsTableControls)([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !isSelectionEmpty)(mkFlex(deleteCheckedElementsBtn)) ])));
    };
});
module.exports = {
    mkElementsControls: mkElementsControls,
    mkDeleteElementRequest: mkDeleteElementRequest,
    mkFloatingElementsControls: mkFloatingElementsControls,
    "_editMultipleElementsBtn": _editMultipleElementsBtn,
    "_deleteCheckedElementsBtn": _deleteCheckedElementsBtn,
    mkDuplicateElementsWithinProjectAction: mkDuplicateElementsWithinProjectAction
};
