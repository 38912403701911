// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Ascending = (function () {
    function Ascending() {

    };
    Ascending.value = new Ascending();
    return Ascending;
})();
var Descending = (function () {
    function Descending() {

    };
    Descending.value = new Descending();
    return Descending;
})();
var RowSmall = (function () {
    function RowSmall() {

    };
    RowSmall.value = new RowSmall();
    return RowSmall;
})();
var RowMedium = (function () {
    function RowMedium() {

    };
    RowMedium.value = new RowMedium();
    return RowMedium;
})();
var RowLarge = (function () {
    function RowLarge() {

    };
    RowLarge.value = new RowLarge();
    return RowLarge;
})();
var Numeric = (function () {
    function Numeric() {

    };
    Numeric.value = new Numeric();
    return Numeric;
})();
var Input = (function () {
    function Input() {

    };
    Input.value = new Input();
    return Input;
})();
var Folded = (function () {
    function Folded() {

    };
    Folded.value = new Folded();
    return Folded;
})();
var Unfolded = (function () {
    function Unfolded() {

    };
    Unfolded.value = new Unfolded();
    return Unfolded;
})();
var FoldingElement = (function () {
    function FoldingElement(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FoldingElement.create = function (value0) {
        return function (value1) {
            return new FoldingElement(value0, value1);
        };
    };
    return FoldingElement;
})();
var IntValue = (function () {
    function IntValue(value0) {
        this.value0 = value0;
    };
    IntValue.create = function (value0) {
        return new IntValue(value0);
    };
    return IntValue;
})();
var NumberValue = (function () {
    function NumberValue(value0) {
        this.value0 = value0;
    };
    NumberValue.create = function (value0) {
        return new NumberValue(value0);
    };
    return NumberValue;
})();
var StringValue = (function () {
    function StringValue(value0) {
        this.value0 = value0;
    };
    StringValue.create = function (value0) {
        return new StringValue(value0);
    };
    return StringValue;
})();
var BooleanValue = (function () {
    function BooleanValue(value0) {
        this.value0 = value0;
    };
    BooleanValue.create = function (value0) {
        return new BooleanValue(value0);
    };
    return BooleanValue;
})();
var JSDateValue = (function () {
    function JSDateValue(value0) {
        this.value0 = value0;
    };
    JSDateValue.create = function (value0) {
        return new JSDateValue(value0);
    };
    return JSDateValue;
})();
var TupleValue = (function () {
    function TupleValue(value0) {
        this.value0 = value0;
    };
    TupleValue.create = function (value0) {
        return new TupleValue(value0);
    };
    return TupleValue;
})();
var SortingCriteria = (function () {
    function SortingCriteria(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    SortingCriteria.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new SortingCriteria(value0, value1, value2);
            };
        };
    };
    return SortingCriteria;
})();
var Flex = (function () {
    function Flex(value0) {
        this.value0 = value0;
    };
    Flex.create = function (value0) {
        return new Flex(value0);
    };
    return Flex;
})();
var FlexMinWidth = (function () {
    function FlexMinWidth(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    FlexMinWidth.create = function (value0) {
        return function (value1) {
            return new FlexMinWidth(value0, value1);
        };
    };
    return FlexMinWidth;
})();
var Fixed = (function () {
    function Fixed(value0) {
        this.value0 = value0;
    };
    Fixed.create = function (value0) {
        return new Fixed(value0);
    };
    return Fixed;
})();
var PlainTextCell = (function () {
    function PlainTextCell(value0) {
        this.value0 = value0;
    };
    PlainTextCell.create = function (value0) {
        return new PlainTextCell(value0);
    };
    return PlainTextCell;
})();
var PlainNumberCell = (function () {
    function PlainNumberCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PlainNumberCell.create = function (value0) {
        return function (value1) {
            return new PlainNumberCell(value0, value1);
        };
    };
    return PlainNumberCell;
})();
var MaybePlainNumberCell = (function () {
    function MaybePlainNumberCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MaybePlainNumberCell.create = function (value0) {
        return function (value1) {
            return new MaybePlainNumberCell(value0, value1);
        };
    };
    return MaybePlainNumberCell;
})();
var PlainEuroCell = (function () {
    function PlainEuroCell(value0) {
        this.value0 = value0;
    };
    PlainEuroCell.create = function (value0) {
        return new PlainEuroCell(value0);
    };
    return PlainEuroCell;
})();
var MaybePlainEuroCell = (function () {
    function MaybePlainEuroCell(value0) {
        this.value0 = value0;
    };
    MaybePlainEuroCell.create = function (value0) {
        return new MaybePlainEuroCell(value0);
    };
    return MaybePlainEuroCell;
})();
var ColoredEuroCell = (function () {
    function ColoredEuroCell(value0) {
        this.value0 = value0;
    };
    ColoredEuroCell.create = function (value0) {
        return new ColoredEuroCell(value0);
    };
    return ColoredEuroCell;
})();
var PlainCurrencyCell = (function () {
    function PlainCurrencyCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PlainCurrencyCell.create = function (value0) {
        return function (value1) {
            return new PlainCurrencyCell(value0, value1);
        };
    };
    return PlainCurrencyCell;
})();
var MaybePlainCurrencyCell = (function () {
    function MaybePlainCurrencyCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MaybePlainCurrencyCell.create = function (value0) {
        return function (value1) {
            return new MaybePlainCurrencyCell(value0, value1);
        };
    };
    return MaybePlainCurrencyCell;
})();
var PlainDateStringCell = (function () {
    function PlainDateStringCell(value0) {
        this.value0 = value0;
    };
    PlainDateStringCell.create = function (value0) {
        return new PlainDateStringCell(value0);
    };
    return PlainDateStringCell;
})();
var PlainDateTimeStringCell = (function () {
    function PlainDateTimeStringCell(value0) {
        this.value0 = value0;
    };
    PlainDateTimeStringCell.create = function (value0) {
        return new PlainDateTimeStringCell(value0);
    };
    return PlainDateTimeStringCell;
})();
var PlainDateCell = (function () {
    function PlainDateCell(value0) {
        this.value0 = value0;
    };
    PlainDateCell.create = function (value0) {
        return new PlainDateCell(value0);
    };
    return PlainDateCell;
})();
var PlainPercentageCell = (function () {
    function PlainPercentageCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    PlainPercentageCell.create = function (value0) {
        return function (value1) {
            return new PlainPercentageCell(value0, value1);
        };
    };
    return PlainPercentageCell;
})();
var MaybePlainPercentageCell = (function () {
    function MaybePlainPercentageCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    MaybePlainPercentageCell.create = function (value0) {
        return function (value1) {
            return new MaybePlainPercentageCell(value0, value1);
        };
    };
    return MaybePlainPercentageCell;
})();
var ColoredPercentageCell = (function () {
    function ColoredPercentageCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ColoredPercentageCell.create = function (value0) {
        return function (value1) {
            return new ColoredPercentageCell(value0, value1);
        };
    };
    return ColoredPercentageCell;
})();
var ColoredEuroAndPercentCell = (function () {
    function ColoredEuroAndPercentCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ColoredEuroAndPercentCell.create = function (value0) {
        return function (value1) {
            return new ColoredEuroAndPercentCell(value0, value1);
        };
    };
    return ColoredEuroAndPercentCell;
})();
var JSXCell = (function () {
    function JSXCell(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    JSXCell.create = function (value0) {
        return function (value1) {
            return new JSXCell(value0, value1);
        };
    };
    return JSXCell;
})();
var FoldingIndicatorPlaceholder = (function () {
    function FoldingIndicatorPlaceholder() {

    };
    FoldingIndicatorPlaceholder.value = new FoldingIndicatorPlaceholder();
    return FoldingIndicatorPlaceholder;
})();
var Column = function (x) {
    return x;
};
var TacoRow = function (x) {
    return x;
};
var rowHeightPixels = function (v) {
    if (v instanceof RowSmall) {
        return 22;
    };
    if (v instanceof RowMedium) {
        return 26;
    };
    if (v instanceof RowLarge) {
        return 30;
    };
    throw new Error("Failed pattern match at TacoTable.Types (line 157, column 1 - line 157, column 36): " + [ v.constructor.name ]);
};
var rowHeightToSize = function (v) {
    if (v instanceof RowSmall) {
        return TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(rowHeightPixels(RowSmall.value)) + "px");
    };
    if (v instanceof RowMedium) {
        return TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(rowHeightPixels(RowMedium.value)) + "px");
    };
    if (v instanceof RowLarge) {
        return TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(rowHeightPixels(RowLarge.value)) + "px");
    };
    throw new Error("Failed pattern match at TacoTable.Types (line 162, column 1 - line 162, column 37): " + [ v.constructor.name ]);
};
var reverse = function (v) {
    if (v instanceof Ascending) {
        return Descending.value;
    };
    if (v instanceof Descending) {
        return Ascending.value;
    };
    throw new Error("Failed pattern match at TacoTable.Types (line 95, column 1 - line 95, column 40): " + [ v.constructor.name ]);
};
var noSortProperty = Data_Maybe.Nothing.value;
var newtypeRow = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeColumn = {
    Coercible0: function () {
        return undefined;
    }
};
var genericSortingOrder = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Ascending.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return Descending.value;
        };
        throw new Error("Failed pattern match at TacoTable.Types (line 90, column 1 - line 90, column 62): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Ascending) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Descending) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at TacoTable.Types (line 90, column 1 - line 90, column 62): " + [ x.constructor.name ]);
    }
};
var tofuJSONSortingOrder = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Ascending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Descending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Ascending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Descending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericSortingOrder)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Ascending";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Descending";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Ascending";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Descending";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericSortingOrder)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Ascending";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Descending";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Ascending";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Descending";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Ascending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Descending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Ascending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Descending";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var eqSortingOrder = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Ascending && y instanceof Ascending) {
                return true;
            };
            if (x instanceof Descending && y instanceof Descending) {
                return true;
            };
            return false;
        };
    }
};
var eqRowHeight = {
    eq: function (x) {
        return function (y) {
            if (x instanceof RowSmall && y instanceof RowSmall) {
                return true;
            };
            if (x instanceof RowMedium && y instanceof RowMedium) {
                return true;
            };
            if (x instanceof RowLarge && y instanceof RowLarge) {
                return true;
            };
            return false;
        };
    }
};
var eqJSXCellProp = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Numeric && y instanceof Numeric) {
                return true;
            };
            if (x instanceof Input && y instanceof Input) {
                return true;
            };
            return false;
        };
    }
};
var eqFoldingState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Folded && y instanceof Folded) {
                return true;
            };
            if (x instanceof Unfolded && y instanceof Unfolded) {
                return true;
            };
            return false;
        };
    }
};
var ordFoldingState = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Folded && y instanceof Folded) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Folded) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Folded) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Unfolded && y instanceof Unfolded) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at TacoTable.Types (line 33, column 1 - line 33, column 52): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqFoldingState;
    }
};
var eqComparableValue = {
    eq: function (x) {
        return function (y) {
            if (x instanceof IntValue && y instanceof IntValue) {
                return x.value0 === y.value0;
            };
            if (x instanceof NumberValue && y instanceof NumberValue) {
                return x.value0 === y.value0;
            };
            if (x instanceof StringValue && y instanceof StringValue) {
                return x.value0 === y.value0;
            };
            if (x instanceof BooleanValue && y instanceof BooleanValue) {
                return x.value0 === y.value0;
            };
            if (x instanceof JSDateValue && y instanceof JSDateValue) {
                return Data_Eq.eq(Data_JSDate.eqJSDate)(x.value0)(y.value0);
            };
            if (x instanceof TupleValue && y instanceof TupleValue) {
                return Data_Eq.eq(Data_Tuple.eqTuple(eqComparableValue)(eqComparableValue))(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var ordComparableValue = {
    compare: function (v) {
        return function (v1) {
            if (v instanceof IntValue && v1 instanceof NumberValue) {
                return Data_Ord.compare(Data_Ord.ordNumber)(Data_Int.toNumber(v.value0))(v1.value0);
            };
            if (v instanceof IntValue && v1 instanceof IntValue) {
                return Data_Ord.compare(Data_Ord.ordInt)(v.value0)(v1.value0);
            };
            if (v instanceof IntValue && v1 instanceof StringValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof IntValue && v1 instanceof BooleanValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof IntValue && v1 instanceof JSDateValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof NumberValue && v1 instanceof IntValue) {
                return Data_Ord.compare(Data_Ord.ordNumber)(v.value0)(Data_Int.toNumber(v1.value0));
            };
            if (v instanceof NumberValue && v1 instanceof NumberValue) {
                return Data_Ord.compare(Data_Ord.ordNumber)(v.value0)(v1.value0);
            };
            if (v instanceof NumberValue && v1 instanceof StringValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof NumberValue && v1 instanceof BooleanValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof NumberValue && v1 instanceof JSDateValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof StringValue && v1 instanceof IntValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof StringValue && v1 instanceof NumberValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof StringValue && v1 instanceof StringValue) {
                return Data_String_Common.localeCompare(v.value0)(v1.value0);
            };
            if (v instanceof StringValue && v1 instanceof BooleanValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof StringValue && v1 instanceof JSDateValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof BooleanValue && v1 instanceof BooleanValue) {
                return Data_Ord.compare(Data_Ord.ordBoolean)(v.value0)(v1.value0);
            };
            if (v instanceof BooleanValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof JSDateValue && v1 instanceof JSDateValue) {
                return Data_Ord.compare(Data_JSDate.ordJSDate)(v.value0)(v1.value0);
            };
            if (v instanceof JSDateValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof TupleValue && v1 instanceof TupleValue) {
                return Data_Ord.compare(Data_Tuple.ordTuple(ordComparableValue)(ordComparableValue))(v.value0)(v1.value0);
            };
            if (v instanceof TupleValue) {
                return Data_Ordering.GT.value;
            };
            if (v1 instanceof TupleValue) {
                return Data_Ordering.GT.value;
            };
            throw new Error("Failed pattern match at TacoTable.Types (line 107, column 1 - line 129, column 32): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqComparableValue;
    }
};
var sortItemsByComparable = function (getComparableValue) {
    return function (order) {
        return function (property) {
            return function (items) {
                var withOrder = (function () {
                    var $104 = Data_Eq.eq(eqSortingOrder)(order)(Descending.value);
                    if ($104) {
                        return Data_Function.flip;
                    };
                    return Control_Category.identity(Control_Category.categoryFn);
                })();
                var sortable = getComparableValue(property);
                var compareByProperty = function (a) {
                    return function (b) {
                        return withOrder(Data_Ord.compare(ordComparableValue))(sortable(a))(sortable(b));
                    };
                };
                return Data_Array.sortBy(compareByProperty)(items);
            };
        };
    };
};
var emptyCell = new PlainTextCell(function (v) {
    return "";
});
module.exports = {
    TacoRow: TacoRow,
    Folded: Folded,
    Unfolded: Unfolded,
    FoldingElement: FoldingElement,
    Column: Column,
    noSortProperty: noSortProperty,
    Numeric: Numeric,
    Input: Input,
    PlainTextCell: PlainTextCell,
    PlainNumberCell: PlainNumberCell,
    MaybePlainNumberCell: MaybePlainNumberCell,
    PlainEuroCell: PlainEuroCell,
    MaybePlainEuroCell: MaybePlainEuroCell,
    ColoredEuroCell: ColoredEuroCell,
    PlainCurrencyCell: PlainCurrencyCell,
    MaybePlainCurrencyCell: MaybePlainCurrencyCell,
    PlainDateStringCell: PlainDateStringCell,
    PlainDateTimeStringCell: PlainDateTimeStringCell,
    PlainDateCell: PlainDateCell,
    PlainPercentageCell: PlainPercentageCell,
    MaybePlainPercentageCell: MaybePlainPercentageCell,
    ColoredPercentageCell: ColoredPercentageCell,
    ColoredEuroAndPercentCell: ColoredEuroAndPercentCell,
    JSXCell: JSXCell,
    FoldingIndicatorPlaceholder: FoldingIndicatorPlaceholder,
    emptyCell: emptyCell,
    SortingCriteria: SortingCriteria,
    Ascending: Ascending,
    Descending: Descending,
    reverse: reverse,
    IntValue: IntValue,
    NumberValue: NumberValue,
    StringValue: StringValue,
    BooleanValue: BooleanValue,
    JSDateValue: JSDateValue,
    TupleValue: TupleValue,
    sortItemsByComparable: sortItemsByComparable,
    Flex: Flex,
    FlexMinWidth: FlexMinWidth,
    Fixed: Fixed,
    RowSmall: RowSmall,
    RowMedium: RowMedium,
    RowLarge: RowLarge,
    rowHeightPixels: rowHeightPixels,
    rowHeightToSize: rowHeightToSize,
    newtypeRow: newtypeRow,
    eqFoldingState: eqFoldingState,
    ordFoldingState: ordFoldingState,
    newtypeColumn: newtypeColumn,
    eqJSXCellProp: eqJSXCellProp,
    eqSortingOrder: eqSortingOrder,
    genericSortingOrder: genericSortingOrder,
    tofuJSONSortingOrder: tofuJSONSortingOrder,
    eqComparableValue: eqComparableValue,
    ordComparableValue: ordComparableValue,
    eqRowHeight: eqRowHeight
};
