import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { useEffect } from "react";
import { getClientConfigVarOptional } from "src/client/config";
import { useCurrentUser } from "../auth/useCurrentUser";

export function PostHogWrapper({ children }: { children: React.ReactNode }) {
  const user = useCurrentUser();

  const posthogTokenKey = getClientConfigVarOptional("CLIENT_POSTHOG_TOKEN");
  const posthogApiHostKey = getClientConfigVarOptional("CLIENT_POSTHOG_HOST");
  const stage = getClientConfigVarOptional("CLIENT_STAGE");
  const posthogDisabled =
    getClientConfigVarOptional("CLIENT_POSTHOG_DISABLE") === "true";

  if (posthogDisabled) {
    return <>{children}</>;
  }

  if (!posthogTokenKey || !posthogApiHostKey) {
    console.warn(
      "PostHog token or API host not set(CLIENT_POSTHOG_TOKEN, CLIENT_POSTHOG_HOST), not initializing PostHog SDK"
    );
    return <>{children}</>;
  }

  posthog.init(posthogTokenKey, {
    api_host: posthogApiHostKey,
  });

  useEffect(() => {
    posthog.register_once({
      product: "estima",
      environment: stage ?? "unknown",
    });
    posthog.identify(user.userId, {
      email: user.email,
      organization: user.organization,
    });
    posthog?.group("tenant", user.organization ?? "");
  }, [posthog]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
