// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var optimizedTableStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.FlexGrow(1), Box.FlexColumn.value, new Box.Overflow(Box.Visible.value, Box.Scroll.value), Box.Style.create(React_Basic_Emotion.css()({
    willChange: React_Basic_Emotion.str("transform")
})), new Box.Selector(".table-rows", [ new Box.Position(Box.Relative.value) ]) ]);
var optimizedTableRowsContainerStyles = function (v) {
    return Box.boxStyle([ new Box.Position(Box.Relative.value), new Box.FlexGrow(1), new Box.FlexShrink(0), Box.FlexBasis.create(Data_Show.show(Data_Show.showInt)(v.flexBasis) + "px") ]);
};
var isCellNumeric = function (v) {
    if (v instanceof TacoTable_Types.PlainNumberCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.MaybePlainNumberCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.PlainEuroCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.MaybePlainEuroCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.ColoredEuroCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.PlainCurrencyCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.MaybePlainCurrencyCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.JSXCell) {
        return Data_Foldable.elem(Data_Foldable.foldableArray)(TacoTable_Types.eqJSXCellProp)(TacoTable_Types.Numeric.value)(v.value0);
    };
    if (v instanceof TacoTable_Types.PlainPercentageCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.MaybePlainPercentageCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.ColoredPercentageCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.ColoredEuroAndPercentCell) {
        return true;
    };
    if (v instanceof TacoTable_Types.PlainTextCell) {
        return false;
    };
    if (v instanceof TacoTable_Types.PlainDateStringCell) {
        return false;
    };
    if (v instanceof TacoTable_Types.PlainDateTimeStringCell) {
        return false;
    };
    if (v instanceof TacoTable_Types.PlainDateCell) {
        return false;
    };
    if (v instanceof TacoTable_Types.FoldingIndicatorPlaceholder) {
        return false;
    };
    throw new Error("Failed pattern match at TacoTable.Styles (line 300, column 17 - line 317, column 39): " + [ v.constructor.name ]);
};
var hoverStyles = Box.boxStyle([ new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLightest.value), new Box.Cursor(Box.Pointer.value) ]) ]);
var tableHoverStyles = Box.boxStyle([ Box.Selector.create(".row")([ new Box.Style(hoverStyles) ]) ]);
var getColumnWidthStyle = function (width) {
    if (width instanceof TacoTable_Types.Flex) {
        return React_Basic_Emotion.css()({
            flex: React_Basic_Emotion["int"](width.value0)
        });
    };
    if (width instanceof TacoTable_Types.FlexMinWidth) {
        return React_Basic_Emotion.css()({
            flex: React_Basic_Emotion["int"](width.value0),
            minWidth: React_Basic_Emotion.str(width.value1)
        });
    };
    if (width instanceof TacoTable_Types.Fixed) {
        return React_Basic_Emotion.css()({
            minWidth: React_Basic_Emotion.str(width.value0),
            width: React_Basic_Emotion.str(width.value0)
        });
    };
    throw new Error("Failed pattern match at TacoTable.Styles (line 286, column 29 - line 289, column 74): " + [ width.constructor.name ]);
};
var foldingIndicatorStyles = Box.boxStyle([ new Box.Width(TacoConstants.XL.value), Box.FlexRow.value, Box.AlignCenter.value, Box.JustifyCenter.value, new Box.ForegroundColor(TacoConstants.PrimaryBlue.value), new Box.Cursor(Box.Pointer.value) ]);
var defaultTableContainerStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), Box.FlexColumn.value ]);
var defaultRowStyles = function (foldingState) {
    return function (rowHeight) {
        var unfolded = Data_Maybe.maybe(false)(Data_Eq.eq(TacoTable_Types.eqFoldingState)(TacoTable_Types.Unfolded.value))(foldingState);
        var folded = Data_Maybe.maybe(false)(Data_Eq.eq(TacoTable_Types.eqFoldingState)(TacoTable_Types.Folded.value))(foldingState);
        return Box.boxStyle([ Box.FlexColumn.value, Box.JustifyCenter.value, Box.MinHeight.create(TacoTable_Types.rowHeightToSize(rowHeight)), Box.Style.create(React_Basic_Emotion.css()({
            boxSizing: React_Basic_Emotion.str("content-box")
        })), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(folded)(Box.Hover.create)([ new Box.Cursor(Box.Pointer.value), new Box.BackgroundColor(TacoConstants.GrayLightest.value) ]), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(unfolded)(Box.Style.create)(Box.boxStyle([ new Box.BackgroundColor(TacoConstants.GrayLightest.value), new Box.Position(Box.Relative.value), new Box.Selector("&:after", [ new Box.BackgroundColor(TacoConstants.PrimaryBlue.value), new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0px")), Box.PosLeft.create(new TacoConstants.CustomSize("0px")), Box.Height.create(new TacoConstants.CustomSize("100%")), new Box.Width(TacoConstants.XXS.value), Box.Style.create(React_Basic_Emotion.css()({
            content: React_Basic_Emotion.str("' '")
        })) ]) ])) ]);
    };
};
var optimizedTableRowStyles = function (mfs) {
    return function (rowHeight) {
        var rowHeightAsSize = TacoTable_Types.rowHeightToSize(rowHeight);
        var v = TacoTable_Types.rowHeightPixels(rowHeight) + 1 | 0;
        return React_Basic_Emotion.merge([ defaultRowStyles(mfs)(rowHeight), Box.boxStyle([ new Box.Position(Box.Relative.value), Box.PosLeft.create(new TacoConstants.CustomSize("0")), Box.PosRight.create(new TacoConstants.CustomSize("0")), new Box.MinHeight(rowHeightAsSize), new Box.Height(rowHeightAsSize), Box.Style.create(React_Basic_Emotion.css()({
            boxSizing: React_Basic_Emotion.str("content-box")
        })) ]) ]);
    };
};
var defaultRowCellsContainerStyles = function (foldingState) {
    var unfolded = Data_Maybe.maybe(false)(Data_Eq.eq(TacoTable_Types.eqFoldingState)(TacoTable_Types.Unfolded.value))(foldingState);
    return Box.boxStyle([ Box.FlexRow.value, Box.AlignStretch.value, Box.Height.create(new TacoConstants.CustomSize("100%")), Data_Monoid.guard(Box.monoidBoxProp)(unfolded)(Box.Style.create(React_Basic_Emotion.css()({
        fontWeight: React_Basic_Emotion.str("bold")
    }))), Data_Monoid.guard(Box.monoidBoxProp)(unfolded)(new Box.Cursor(Box.Pointer.value)) ]);
};
var optimizedTableRowCellsContainerStyles = defaultRowCellsContainerStyles;
var defaultHeaderStyles = Box.boxStyle([ Box.FlexRow.value, new Box.FlexBasis("auto"), new Box.FlexShrink(0), new Box.Position(Box.Sticky.value), new Box.ZIndex(TacoConstants.zIndexTableHeader), Box.PosTop.create(new TacoConstants.CustomSize("0")) ]);
var defaultHeaderCellStyles = function (colWidth) {
    return Box.boxStyle([ Box.Style.create(getColumnWidthStyle(colWidth)), new Box.Selector(".__TacoButton", [ Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.PaddingY(TacoConstants.XS.value), new Box.PaddingX(TacoConstants.XXS.value), Box.Style.create(React_Basic_Emotion.css()({
        border: React_Basic_Emotion.str("0")
    })) ]), Box.FlexRow.value, Box.JustifyCenter.value, new Box.Overflow(Box.Hidden.value, Box.Visible.value), new Box.BackgroundColor(TacoConstants.AppBackground.value), Box.Style.create(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
    })) ]);
};
var defaultHeaderStyleSet = {
    headerStyles: defaultHeaderStyles,
    headerCellStyles: defaultHeaderCellStyles
};
var defaultCellValueStyles = function (cell) {
    return function (rowHeight) {
        var usePadding = (function () {
            if (cell instanceof TacoTable_Types.JSXCell) {
                return !Data_Foldable.elem(Data_Foldable.foldableArray)(TacoTable_Types.eqJSXCellProp)(TacoTable_Types.Input.value)(cell.value0);
            };
            return true;
        })();
        var paddingY = (function () {
            if (rowHeight instanceof TacoTable_Types.RowSmall) {
                return new TacoConstants.CustomSize("4px");
            };
            if (rowHeight instanceof TacoTable_Types.RowMedium) {
                return TacoConstants.XS.value;
            };
            if (rowHeight instanceof TacoTable_Types.RowLarge) {
                return TacoConstants.S.value;
            };
            throw new Error("Failed pattern match at TacoTable.Styles (line 176, column 16 - line 179, column 21): " + [ rowHeight.constructor.name ]);
        })();
        var numeric = isCellNumeric(cell);
        return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(usePadding)(new Box.PaddingY(paddingY)), Data_Monoid.guard(Box.monoidBoxProp)(usePadding)(new Box.PaddingX(TacoConstants.XXS.value)), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.FlexRow.value, Box.AlignCenter.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Data_Monoid.guard(Box.monoidBoxProp)(numeric)(Box.Style.create(React_Basic_Emotion.css()({
            justifyContent: React_Basic_Emotion.str("flex-end"),
            textAlign: React_Basic_Emotion.str("right")
        }))), Box.Style.create(React_Basic_Emotion.css()({
            wordBreak: React_Basic_Emotion.str("break-word"),
            hyphens: React_Basic_Emotion.str("auto")
        })), new Box.Selector("& .currency-positive, & .percentage-positive", [ new Box.ForegroundColor(TacoConstants.StatusGreen.value) ]), new Box.Selector("& .currency-negative, & .percentage-negative", [ new Box.ForegroundColor(TacoConstants.StatusOrange.value) ]), new Box.Selector("& .currency-zero, & .percentage-zero", [ new Box.ForegroundColor(TacoConstants.GrayMedium.value) ]) ]);
    };
};
var optimizedTableCellValueStyles = function (cell) {
    return function (rowHeight) {
        return React_Basic_Emotion.merge([ defaultCellValueStyles(cell)(rowHeight), Box.boxStyle((function () {
            if (cell instanceof TacoTable_Types.JSXCell) {
                return [  ];
            };
            return [ new Box.Overflow(Box.Hidden.value, Box.Hidden.value), new Box.Display(Box.Block.value), Box.Style.create(React_Basic_Emotion.css()({
                whiteSpace: React_Basic_Emotion.str("nowrap"),
                textOverflow: React_Basic_Emotion.str("ellipsis")
            })) ];
        })()) ]);
    };
};
var defaultCellStyles = function (colWidth) {
    return Box.boxStyle([ Box.Style.create(getColumnWidthStyle(colWidth)), Box.Style.create(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
    })) ]);
};
var optimizedTableCellStyles = function (colWidth) {
    return React_Basic_Emotion.merge([ defaultCellStyles(colWidth), Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value ]) ]);
};
var optimizedTableCellStyleSet = {
    cellStyles: optimizedTableCellStyles,
    cellValueStyles: optimizedTableCellValueStyles
};
var optimizedTableRowStyleSet = {
    rowStyles: optimizedTableRowStyles,
    rowCellsContainerStyles: defaultRowCellsContainerStyles,
    cellStyleSet: optimizedTableCellStyleSet
};
var optimizedTableStyleSet = {
    tableStyles: optimizedTableStyles,
    headerStyleSet: defaultHeaderStyleSet,
    rowStyleSet: optimizedTableRowStyleSet
};
var defaultCellStyleSet = {
    cellStyles: defaultCellStyles,
    cellValueStyles: defaultCellValueStyles
};
var defaultRowStyleSet = {
    rowStyles: defaultRowStyles,
    rowCellsContainerStyles: defaultRowCellsContainerStyles,
    cellStyleSet: defaultCellStyleSet
};
var foldingTableStyleSet = {
    tableStyles: defaultTableContainerStyles,
    headerStyleSet: defaultHeaderStyleSet,
    rowStyleSet: defaultRowStyleSet
};
var foldingTableStyleSetWithoutSticky = (function () {
    var headerStyleSet = {
        headerStyles: Data_Semigroup.append(React_Basic_Emotion.semigroupStyle)(defaultHeaderStyles)(Box.boxStyle([ new Box.Position(Box.Relative.value) ])),
        headerCellStyles: defaultHeaderCellStyles
    };
    return {
        tableStyles: defaultTableContainerStyles,
        headerStyleSet: headerStyleSet,
        rowStyleSet: defaultRowStyleSet
    };
})();
var summaryTableStyleSet = (function () {
    var tableStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), Box.FlexColumn.value, new Box.Position(Box.Sticky.value), Box.PosBottom.create(new TacoConstants.CustomSize("0")), new Box.ZIndex(TacoConstants.zIndexTableHeader), new Box.BackgroundColor(TacoConstants.White.value), new Box.Border(TacoConstants.BorderLight.value) ]);
    return {
        tableStyles: tableStyles,
        headerStyleSet: defaultHeaderStyleSet,
        rowStyleSet: defaultRowStyleSet
    };
})();
module.exports = {
    foldingTableStyleSet: foldingTableStyleSet,
    foldingTableStyleSetWithoutSticky: foldingTableStyleSetWithoutSticky,
    defaultTableContainerStyles: defaultTableContainerStyles,
    defaultHeaderStyleSet: defaultHeaderStyleSet,
    defaultRowStyleSet: defaultRowStyleSet,
    defaultCellStyleSet: defaultCellStyleSet,
    defaultHeaderStyles: defaultHeaderStyles,
    defaultHeaderCellStyles: defaultHeaderCellStyles,
    defaultRowStyles: defaultRowStyles,
    defaultRowCellsContainerStyles: defaultRowCellsContainerStyles,
    defaultCellStyles: defaultCellStyles,
    defaultCellValueStyles: defaultCellValueStyles,
    optimizedTableStyleSet: optimizedTableStyleSet,
    optimizedTableStyles: optimizedTableStyles,
    optimizedTableRowStyleSet: optimizedTableRowStyleSet,
    optimizedTableRowStyles: optimizedTableRowStyles,
    optimizedTableRowCellsContainerStyles: optimizedTableRowCellsContainerStyles,
    optimizedTableCellStyleSet: optimizedTableCellStyleSet,
    optimizedTableCellStyles: optimizedTableCellStyles,
    optimizedTableCellValueStyles: optimizedTableCellValueStyles,
    tableHoverStyles: tableHoverStyles,
    hoverStyles: hoverStyles,
    foldingIndicatorStyles: foldingIndicatorStyles,
    getColumnWidthStyle: getColumnWidthStyle,
    optimizedTableRowsContainerStyles: optimizedTableRowsContainerStyles,
    isCellNumeric: isCellNumeric,
    summaryTableStyleSet: summaryTableStyleSet
};
