import "../../index.css";
import { useEffect, useState } from "react";
import { Button, IconExclamation, TabProps, Tabs } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "./Modals/ErrorMessage";
import { OrganizationsTab } from "./Tabs/OrganizationsTab";
import { UsersTab } from "./Tabs/UsersTab";
import { useWorkerState } from "../../hooks/useWorkerState";
import { useWorkerRequest } from "src/client-ts/hooks/useWorkerRequest";
import { mkStateUpdate } from "../../actions/state";
import { PricelistUpdateTab } from "./Tabs/PricelistUpdateTab";

export const SuperAdminContainer = () => {
  const { t } = useTranslation("superAdmin");

  const msg = useWorkerState("SuperAdminGetErrorMsg")?.outageMessage ?? "";

  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(msg);

  type TabIds = "users" | "organizations" | "pricelistUpdate";

  const fetchSuperAdminState = useWorkerRequest(() =>
    mkStateUpdate({ type: "FetchSuperAdminState", value: true })
  );
  fetchSuperAdminState();

  const tabs: TabProps<TabIds>[] = [
    { id: "users", color: "components", label: t`users` },
    { id: "organizations", color: "components", label: t`organizations` },
    {
      id: "pricelistUpdate",
      color: "components",
      label: t`pricelist.pricelistUpdate`,
    },
  ];

  const [selectedTab, setSelectedTab] = useState<TabIds>("users");

  useEffect(() => {
    setErrorMessage(msg);
  }, [msg]);

  return (
    <>
      <div className={"flex justify-between mb-5"}>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
        <Button
          label={errorMessage !== "" ? t`editErrorBtn` : t`setErrorBtn`}
          color="danger"
          size="large"
          icon={IconExclamation}
          onClick={() => setErrorIsOpen(true)}
        />
      </div>
      {selectedTab === "users" && <UsersTab />}
      {selectedTab === "organizations" && <OrganizationsTab />}
      {selectedTab === "pricelistUpdate" && <PricelistUpdateTab />}
      {errorIsOpen && (
        <ErrorMessage
          isOpen={errorIsOpen}
          errorMsg={errorMessage}
          setOpen={setErrorIsOpen}
        />
      )}
    </>
  );
};
