import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { ProjectDetailsData } from "../../../../../ts-bindings/ProjectDetailsData";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { Measurement } from "../../../../../ts-bindings/Measurement";
import { ProjectFormData } from "../../../../../ts-bindings/ProjectFormData";
import {
  mkProjectFormCreateAction,
  mkProjectDeleteAction,
  mkProjectFormUpdateAction,
  mkPublishToReportingAction,
  mkUnpublishFromReportingAction,
} from "../../../actions/project";
import { UpdateActionStatus } from "../../../../../ts-bindings/UpdateActionStatus";

type ProjectSaveAction = (
  projectId: number | undefined,
  projectDetails: ProjectDetailsData,
  costClasses: CostClass[],
  measurementUnits: Measurement[]
) => void;
type ProjectSave = [UpdateActionStatus, ProjectSaveAction];

export const useSaveProject = (newProject?: boolean): ProjectSave => {
  const [saveStatus, saveProjectForm] = useWorkerAction(
    "ProjectFormUpdateActionState",
    (
      projectId: number | undefined,
      projectDetails: ProjectDetailsData,
      costClasses: CostClass[],
      measurementUnits: Measurement[]
    ) => {
      const projectForm: ProjectFormData = {
        projectDetails: {
          ...projectDetails,
          programType: projectDetails.productionPlanning ? 4 : 0,
          projectGroup: projectDetails.projectGroup || null,
        },
        costClasses,
        measurementUnits,
        costGroupClassification: projectDetails.costGroupClassification,
        currency: projectDetails.currency ?? "EUR",
      };
      if (!newProject && projectId) {
        return mkProjectFormUpdateAction(projectId, projectForm);
      }
      return mkProjectFormCreateAction(projectForm);
    }
  );

  return [saveStatus, saveProjectForm];
};

type ProjectDeleteAction = (
  projectId: number,
  projectVersion: string,
  projectCode: string
) => void;

type ProjectDelete = [UpdateActionStatus, ProjectDeleteAction];

export const useDeleteProject = (): ProjectDelete => {
  const [deleteStatus, deleteProject] = useWorkerAction(
    "ProjectFormUpdateActionState",
    (projectId: number, projectVersion: string, projectCode: string) => {
      return mkProjectDeleteAction(projectId, projectVersion, projectCode);
    }
  );

  return [deleteStatus, deleteProject];
};

export const useReportingAction = () => {
  const [publishToReporting] = usePublishToReporting();
  const [unpublishFromReporting] = useUnpublishFromReporting();

  const handleReportingAction = (
    projectId: number,
    oldReportingStatus: boolean,
    newReportingStatus: boolean
  ) => {
    if (!oldReportingStatus && newReportingStatus) {
      publishToReporting(projectId);
    } else if (oldReportingStatus && !newReportingStatus) {
      unpublishFromReporting(projectId);
    }
  };

  return [handleReportingAction];
};

export const usePublishToReporting = () => {
  const [_, publishProjectToReporting] = useWorkerAction(
    "PublishToReportingActionState",
    (projectId: number) => {
      return mkPublishToReportingAction(projectId);
    }
  );

  return [publishProjectToReporting];
};

export const useUnpublishFromReporting = () => {
  const [_, unpublishProjectfromReporting] = useWorkerAction(
    "PublishToReportingActionState",
    (projectId: number) => {
      return mkUnpublishFromReportingAction(projectId);
    }
  );

  return [unpublishProjectfromReporting];
};
